import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Card, CardContent, Grid, Collapse, Box, Tooltip, IconButton } from '@material-ui/core';
import { Delete, Edit, MoreVert, Settings } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import data from '@coremat/CmtTimeLine/data';
import EditPostDialog from '../EditPostDialog';

const PostDialog = props => {
  const actions = [
    {
      icon: <Edit color="secondary" fontSize="small" />,
      label: 'Edit',
      onClick: id => handleEdit(id),
    },
    // {
    //   icon: <Settings color="secondary" fontSize="small" />,
    //   label: 'Activate/Deactivate',
    //   onClick: id => handleActivate(id),
    // },
    // {
    //   icon: <Delete color="secondary" fontSize="small" />,
    //   label: 'Delete',
    //   onClick: id => handleDelete(id),
    // },
  ];

  const actionComment = [
    {
      icon: <Edit color="secondary" fontSize="small" />,
      label: 'Edit',
      onClick: id => handleEditComment(id),
    },
    // {
    //   icon: <Settings color="secondary" fontSize="small" />,
    //   label: 'Activate/Deactivate',
    //   onClick: id => handleActivateComment(id),
    // },
    // {
    //   icon: <Delete color="secondary" fontSize="small" />,
    //   label: 'Delete',
    //   onClick: id => handleDeleteComment(id),
    // },
  ];

  const actionSubComment = [
    {
      icon: <Edit color="secondary" fontSize="small" />,
      label: 'Edit',
      onClick: id => handleEditSubComment(id),
    },
    // {
    //   icon: <Settings color="secondary" fontSize="small" />,
    //   label: 'Activate/Deactivate',
    //   onClick: id => handleActivateSubComment(id),
    // },
    // {
    //   icon: <Delete color="secondary" fontSize="small" />,
    //   label: 'Delete',
    //   onClick: id => handleDeleteSubComment(id),
    // },
  ];

  const [postDetails, setPostDetails] = useState({
    open: true,
    success: '',
  });

  const [openEditPostDialog, setOpenEditPostDialog] = useState(false);
  const [currentSocial, setCurrentSocial] = useState('');
  const [rowData, setRowData] = useState('');
  const [postID, setPostID] = useState('');
  const [commentList, setCommentList] = useState(props && props.rowDetails);

  const handleClose = () => {
    setPostDetails({
      ...postDetails,
      open: false,
      success: false,
    });
  };

  const handleViewComment = post => {
    // console.log('comment', post);
    setPostID(post.postID);
  };

  const handleEdit = data => {
    setOpenEditPostDialog(true);
    setCurrentSocial('Post');
    setRowData(data.memID);
  };

  const sendBack = call => {
    // console.log('call', call);
    if (call.success === 'Post' && call.open === false) {
      setOpenEditPostDialog(false);
      setCurrentSocial('');
      setRowData('');
      commentList.socialPost.length &&
        commentList.socialPost.map(com => {
          if (com.postID === call.postID) {
            return (com.postTitle = call.title), (com.postActive = call.active === true ? 'Active' : 'Inactive');
          }
        });
    } else if (call.success === 'Comment' && call.open === false) {
      setOpenEditPostDialog(false);
      setCurrentSocial('');
      setRowData('');
      commentList.socialPost.length &&
        commentList.socialPost.map(com => {
          if (com.postID === call.postID) {
            com.socialPostComment.length > 0 &&
              com.socialPostComment.map(comment => {
                if (comment.commentsID === call.commentID) {
                  return (
                    (comment.commentText = call.title),
                    (comment.commentActive = call.active === true ? 'Active' : 'Inactive')
                  );
                }
              });
          }
        });
    } else if (call.success === 'SubComment' && call.open === false) {
      setOpenEditPostDialog(false);
      setCurrentSocial('');
      setRowData('');
      commentList.socialPost.length &&
        commentList.socialPost.map(com => {
          if (com.postID === call.postID) {
            com.socialPostComment.length > 0 &&
              com.socialPostComment.map(comment => {
                if (comment.commentsID === call.parentCommentID) {
                  comment.socialPostSubComment.length > 0 &&
                    comment.socialPostSubComment.map(sub => {
                      if (sub.subCommentsID === call.commentID) {
                        return (
                          (sub.subCommentText = call.title),
                          (sub.subCommentActive = call.active === true ? 'Active' : 'Inactive')
                        );
                      }
                    });
                }
              });
          }
        });
    } else if (call.open === false) {
      setOpenEditPostDialog(false);
      setCurrentSocial('');
      setRowData('');
    }
  };

  const handleActivate = id => {
    console.log('post activate id', id);
  };

  const handleDelete = id => {
    console.log('post delete id', id);
  };

  const handleEditComment = data => {
    setOpenEditPostDialog(true);
    setCurrentSocial('Comment');
    setRowData(data.memID);
  };

  const handleActivateComment = id => {
    console.log('comment activate id', id);
  };

  const handleDeleteComment = id => {
    console.log('comment delete id', id);
  };

  const handleEditSubComment = data => {
    setOpenEditPostDialog(true);
    setCurrentSocial('SubComment');
    setRowData(data.memID);
  };

  const handleActivateSubComment = id => {
    console.log('sub comment activate id', id);
  };

  const handleDeleteSubComment = id => {
    console.log('sub comment delete id', id);
  };

  // console.log('props', props);

  props.sendBackPost(postDetails);

  return (
    <Dialog open={postDetails.open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <h2>{commentList.socialGroupName} post details</h2>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={3}>
            {commentList.socialPost.length > 0 ? (
              commentList.socialPost.map((post, index) => (
                <Grid item md={12}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid item md={6}>
                          {index + 1 + '.' + ' ' + post.postTitle}
                        </Grid>
                        <Grid item md={2}>
                          {post.postActive}
                        </Grid>
                        <Grid item md={2}>
                          {post.socialPostComment.length > 0
                            ? post.socialPostComment.length + ' Comments'
                            : post.socialPostComment.length + ' Comment'}
                        </Grid>
                        <Grid item md={1}>
                          {post.socialPostComment.length > 0 && (
                            <Button variant="contained" color="secondary" onClick={() => handleViewComment(post)}>
                              View
                            </Button>
                          )}
                        </Grid>
                        <Grid item md={1}>
                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <CmtDropdownMenu
                              TriggerComponent={
                                <Tooltip title="More">
                                  <IconButton size="small" style={{ marginLeft: 10 }}>
                                    <MoreVert />
                                  </IconButton>
                                </Tooltip>
                              }
                              items={actions}
                              memID={post}
                            />
                          </Box>
                        </Grid>
                        {post.postID === postID && (
                          // <Collapse in={true}>
                          <Grid item md={12} style={{ marginTop: '10px' }}>
                            <Grid
                              container
                              spacing={2}
                              style={{
                                borderBottom: '1px solid #f1f1f1',
                                padding: '5px',
                              }}>
                              <Grid item md={7}>
                                <strong>Comment Title </strong>
                              </Grid>
                              <Grid item md={2}>
                                <strong> UserName</strong>
                              </Grid>
                              <Grid item md={2}>
                                <strong> Status</strong>
                              </Grid>
                              <Grid item md={1}>
                                <strong>Actions</strong>
                              </Grid>
                            </Grid>
                            {post.socialPostComment.length > 0 &&
                              post.socialPostComment.map(comment => (
                                <Grid
                                  container
                                  spacing={2}
                                  style={{
                                    borderBottom: '1px solid #f1f1f1',
                                    padding: '5px',
                                  }}>
                                  <Grid item md={7}>
                                    {comment.commentText}
                                  </Grid>

                                  <Grid item md={2}>
                                    {comment.commentedByUserName}
                                  </Grid>
                                  <Grid item md={2}>
                                    {comment.commentActive}
                                  </Grid>
                                  <Grid item md={1}>
                                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                                      <CmtDropdownMenu
                                        TriggerComponent={
                                          <Tooltip title="More">
                                            <IconButton size="small" style={{ marginLeft: 10 }}>
                                              <MoreVert />
                                            </IconButton>
                                          </Tooltip>
                                        }
                                        items={actionComment}
                                        memID={comment}
                                      />
                                    </Box>
                                  </Grid>
                                  {comment.socialPostSubComment.length > 0 && (
                                    <Grid
                                      container
                                      spacing={2}
                                      style={{
                                        borderBottom: '1px solid #f1f1f1',
                                        marginTop: '20px',
                                      }}>
                                      <Grid item sm={2} />
                                      <Grid item md={5}>
                                        <strong>Sub Comment Title </strong>
                                      </Grid>
                                      <Grid item md={2}>
                                        <strong> UserName</strong>
                                      </Grid>
                                      <Grid item md={2}>
                                        <strong> Status</strong>
                                      </Grid>
                                      <Grid item md={1}>
                                        <strong>Actions</strong>
                                      </Grid>
                                    </Grid>
                                  )}
                                  {comment.socialPostSubComment.length > 0 &&
                                    comment.socialPostSubComment.map((sub, index) => (
                                      <Grid
                                        container
                                        spacing={2}
                                        style={{
                                          borderBottom:
                                            comment.socialPostSubComment.length - 1 === index ? '0' : '1px solid #f1f1f1',
                                          marginBottom: comment.socialPostSubComment.length - 1 === index ? '20px' : '0',
                                          padding: '5px',
                                        }}>
                                        <Grid item sm={2} />
                                        <Grid item sm={5}>
                                          {sub.subCommentText}
                                        </Grid>
                                        <Grid item sm={2}>
                                          {sub.commentedByUserName}
                                        </Grid>
                                        <Grid item md={2}>
                                          {sub.subCommentActive}
                                        </Grid>
                                        <Grid item md={1}>
                                          <Box display="flex" alignItems="center" justifyContent="flex-end">
                                            <CmtDropdownMenu
                                              TriggerComponent={
                                                <Tooltip title="More">
                                                  <IconButton size="small" style={{ marginLeft: 10 }}>
                                                    <MoreVert />
                                                  </IconButton>
                                                </Tooltip>
                                              }
                                              items={actionSubComment}
                                              memID={sub}
                                            />
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    ))}
                                </Grid>
                              ))}
                          </Grid>
                          // </Collapse>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <p>No posts on this group</p>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>

      {openEditPostDialog && <EditPostDialog rowData={rowData} currentSocial={currentSocial} sendBack={sendBack} />}
    </Dialog>
  );
};

export default PostDialog;
