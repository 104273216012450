import React, { useEffect, useState } from 'react';
import {
  IconButton,
  makeStyles,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  useTheme,
  TableCell,
  TableBody,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { httpClient } from 'appUtility/Api';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '8px 24px 24px',
  },
  table: {
    minWidth: 650,
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  subHeader: {
    backgroundColor: '#DDEBF7',
    fontWeight: 'bold',
  },
  bodyCell: {
    textAlign: 'center',
  },
  categoryTotal: {
    fontWeight: 'bold',
    backgroundColor: '#f9f9f9',
  },
  currentSOH: {
    fontWeight: 'bold',
    backgroundColor: '#f9f9f9',
  },
  categoryDivider: {
    borderRight: '2px solid #e0e0e0',
  },
  borderLeft: {
    borderLeft: '1px solid #e0e0e0',
  },
  borderRight: {
    borderRight: '1px solid #e0e0e0',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StockOnHandDialog = props => {
  const classes = useStyles();

  const [stockOnHandData, setStockOnHandData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const theme = useTheme();

  const getStockOnHandData = () => {
    setLoading(true);

    httpClient.get(`member/${props.memberID}/getstocks`).then(({ data }) => {
      if (data.success) {
        setLoading(false);

        const formattedData = {
          categories: data.data.categories?.map(category => {
            return {
              ...category,
              inventory_category_name:
                category.categoryID === 1 ? 'Office Order' : category.categoryID === 4 ? 'Shampoo Order New' : '',
            };
          }),
          stockTake: data.data.stockTake.map(stockTake => {
            const category = data.data.categories.find(category => category.categoryID === stockTake.categoryID);
            return {
              ...stockTake,
              stockQuantity: category ? category.stockQuantity : null,
              lastStockTakeDate: stockTake.createdAt,
            };
          }),
        };

        setStockOnHandData(formattedData);
      } else {
        setLoading(false);
        // setOpen(true);
        // setMessage(data.message);
        // setMessageState('error');

        setTimeout(() => {
          setOpen(false); // close the modal
        }, 3000);

        return;
      }
    });
  };

  useEffect(() => {
    getStockOnHandData();
  }, []);

  const calculateCategoryTotal = category => {
    return category.data.reduce((total, item) => total + parseInt(item.stockQuantity, 10), 0);
  };

  const category4StockOnHand =
    stockOnHandData &&
    stockOnHandData?.stockTake &&
    stockOnHandData?.stockTake.length > 0 &&
    stockOnHandData?.stockTake[0].data;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog open={props.open} onClose={() => props.setOpen(false)} maxWidth="lg">
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            <h2>Stock on Hand</h2>
          </DialogTitle>
          <IconButton onClick={() => props.setOpen(false)} className={classes.closeBtn}>
            <Close style={{ color: theme.palette.error.main }} />
          </IconButton>
        </Box>
        <DialogContent className={classes.content}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3} className={classes.header}>
                    Shampoo Order
                  </TableCell>
                </TableRow>
                <TableRow className={classes.subHeader}>
                  <TableCell style={{ borderRight: '1px solid #e0e0e0' }}>Item Name</TableCell>
                  <TableCell style={{ borderRight: '1px solid #e0e0e0' }}>Total Quantity (in Bottles)</TableCell>
                  <TableCell style={{ borderRight: '2px solid #e0e0e0' }}>Last Used Bottle % Left</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockOnHandData && stockOnHandData?.stockTake && stockOnHandData?.stockTake.length > 0 ? (
                  <>
                    {[...Array(category4StockOnHand.length)].map((_, index) => (
                      <TableRow key={index}>
                        {category4StockOnHand[index] ? (
                          <>
                            <TableCell style={{ borderRight: '1px solid #e0e0e0' }}>
                              {category4StockOnHand[index].inventoryName}
                            </TableCell>
                            <TableCell className={classes.bodyCell} style={{ borderRight: '1px solid #e0e0e0' }}>
                              {category4StockOnHand[index].stockQuantity}
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                              {category4StockOnHand[index].lastRemainingPercent}
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                    <TableRow className={classes.subHeader}>
                      <TableCell style={{ borderRight: '1px solid #e0e0e0' }} className={classes.categoryTotal}>
                        Last Stock Take Date
                      </TableCell>
                      <TableCell className={classes.categoryTotal}>
                        {stockOnHandData?.stockTake[0].lastStockTakeDate
                          ? moment(stockOnHandData?.stockTake[0].lastStockTakeDate).format('MMMM D, YYYY h:mm A')
                          : ''}
                      </TableCell>
                      <TableCell className={classes.categoryTotal}></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.categoryTotal} style={{ borderRight: '1px solid #e0e0e0' }}>
                        Category Total (in Bottles)
                      </TableCell>
                      <TableCell className={classes.categoryTotal}>
                        {calculateCategoryTotal(stockOnHandData?.stockTake[0])}
                      </TableCell>
                      <TableCell className={classes.categoryTotal}></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classes.currentSOH}
                        style={{ borderLeft: '2px solid #e0e0e0', borderRight: '1px solid #e0e0e0' }}>
                        Current Stock on Hand (in Bottles)
                      </TableCell>
                      <TableCell className={classes.currentSOH}>{stockOnHandData?.stockTake[0].stockQuantity}</TableCell>
                      <TableCell className={classes.currentSOH}></TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          paddingTop: '16px',
                          paddingBottom: '16px',
                        }}>
                        {loading ? (
                          <CircularProgress size={30} />
                        ) : (
                          <Typography variant="h4">No Stock on Hand found</Typography>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StockOnHandDialog;
