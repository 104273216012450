import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ViewLeads from '../ViewLeads';
import TableActiveComponent from '../TableActiveComponent';
import moment from 'moment';

const columns = [
  { id: 'firstName', label: 'Full Name' },
  { id: 'leadsGeneratedFrom', label: 'Company Name' },
  { id: 'address', label: 'Address' },
  { id: 'email', label: 'Email' },
  { id: 'phone', label: 'Phone' },
  { id: 'addedDate', label: 'Added Date' },
  { id: 'convertedToCustomer', label: 'Converted To Customer' },
  { id: 'forwardedToHeadOffice', label: 'Forwarded to Head Office' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnoozedLeads = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [leadID, setLeadID] = useState('');
  const [viewDialog, setViewDialog] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    firstName: '',
    currentFrom: '',
    currentTo: '',
    from: '',
    to: '',

    lastName: '',
    serviceArea: '',
    postCode: '',
    phone: '',
    email: '',
    address: '',
    company: '',
    companyName: '',
  });
  const [filterData, setFilterData] = useState({
    firstName: '',
    lastName: '',

    currentFrom: '',
    currentTo: '',
    from: '',
    to: '',

    serviceArea: '',
    postCode: '',
    phone: '',
    email: '',
    address: '',
    company: '',
    companyName: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.firstName === '' &&
      filterData.lastName === '' &&
      filterData.serviceArea === '' &&
      filterData.postCode === '' &&
      filterData.currentFrom === '' &&
      filterData.currentTo === '' &&
      filterData.from === '' &&
      filterData.to === '' &&
      filterData.email === '' &&
      filterData.address === '' &&
      filterData.company === '' &&
      filterData.companyName === '' &&
      filterData.phone === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.firstName === ' ') filterData.firstName = '';
    if (filterData.lastName === ' ') filterData.lastName = '';
    if (filterData.serviceArea === ' ') filterData.serviceArea = '';
    if (filterData.currentFrom === ' ') filterData.currentFrom = '';
    if (filterData.currentTo === ' ') filterData.currentTo = '';
    if (filterData.from === ' ') filterData.from = '';
    if (filterData.to === ' ') filterData.to = '';
    if (filterData.email === ' ') filterData.email = '';
    if (filterData.address === ' ') filterData.address = '';
    if (filterData.postCode === ' ') filterData.postCode = '';
    if (filterData.phone === ' ') filterData.phone = '';
    if (filterData.company === ' ') filterData.company = '';
    if (filterData.companyName === ' ') filterData.companyName = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let leadStorage = JSON.parse(localStorage.getItem('Snoozedleads_filter'));
    leadStorage !== null && setFilterData(leadStorage);

    leadStorage == null
      ? getLeads()
      : leadStorage.firstName == '' &&
        leadStorage.lastName == '' &&
        leadStorage.serviceArea == '' &&
        leadStorage.currentFrom == '' &&
        leadStorage.currentTo == '' &&
        leadStorage.from == '' &&
        leadStorage.to == '' &&
        leadStorage.postCode == '' &&
        leadStorage.phone == '' &&
        leadStorage.address == '' &&
        leadStorage.email == '' &&
        leadStorage.company == '' &&
        leadStorage.companyName == '' &&
        leadStorage.removed == false
      ? getLeads()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      serviceArea: filterData.serviceArea,
      currentFrom: filterData.currentFrom,
      currentTo: filterData.currentTo,
      from: filterData.from,
      to: filterData.to,

      postCode: filterData.postCode,
      phone: filterData.phone,
      address: filterData.address,
      email: filterData.email,
      company: filterData.company,
      companyName: filterData.companyName,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('Snoozedleads_filter', JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.firstName ||
      filterData.lastName ||
      filterData.serviceArea ||
      // filterData.currentFrom ||
      // filterData.currentTo ||
      filterData.from ||
      filterData.to ||
      filterData.postCode ||
      filterData.address ||
      filterData.email ||
      filterData.company ||
      filterData.companyName ||
      filterData.phone
    ) {
      httpClient
        .get(
          `lead/snoozed?firstName=${filterData.firstName}&lastName=${filterData.lastName}&address=${filterData.address}&email=${filterData.email}&service_area_postcode=${filterData.postCode}&service_area=${filterData.serviceArea}&startDate=${filterData.currentFrom}&endDate=${filterData.currentTo}&companyName=${filterData.company}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setLeads(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getLeads();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'company') {
      filterData.companyName = value;
    }
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleChangeCompany = value => {
    setFilterData({
      ...filterData,
      company: value !== null ? value.companyID : '',
      companyName: value !== null ? value.tradingName : '',
    });
  };

  const handleDateFrom = e => {
    setFilterData({
      ...filterData,
      currentFrom: moment(e).format('DD-MM-YYYY'),
      from: moment(e).format('MM-DD-YYYY'),
      removed: false,
    });
  };

  const handleDateTo = e => {
    console.log('e', e);
    setFilterData({
      ...filterData,
      currentTo: moment(e).format('DD-MM-YYYY'),
      to: moment(e).format('MM-DD-YYYY'),
      removed: false,
    });
  };

  const handleRemove = data => {
    if (data === 'company') {
      filterData.company = '';
      filterData.companyName = '';
    }
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    console.log('direction', direction);
    httpClient
      .get(`lead/snoozed?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setLeads(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `lead/snoozed?firstName=${filterData.firstName}&lastName=${filterData.lastName}&address=${filterData.address}&email=${filterData.email}&service_area_postcode=${filterData.postCode}&service_area=${filterData.serviceArea}&startDate=${filterData.currentFrom}&endDate=${filterData.currentTo}&companyName=${filterData.company}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setLeads(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`lead/snoozed?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setLeads(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `lead/snoozed?firstName=${filterData.firstName}&lastName=${filterData.lastName}&address=${
              filterData.address
            }&email=${filterData.email}&service_area_postcode=${filterData.postCode}&service_area=${
              filterData.serviceArea
            }&startDate=${filterData.currentFrom}&endDate=${filterData.currentTo}&companyName=${
              filterData.company
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setLeads(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          })
      : httpClient.get(`lead/snoozed?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setLeads(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setPage(data.meta.current_page);
            setLoading(false);
          }
        });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getLeads = () => {
    setLoading(true);
    httpClient.get(`lead/snoozed?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setLeads(data.data);
        setTotal(data.meta.total);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
      }
    });
  };

  const handleView = id => {
    setLeadID(id);
    setViewDialog(true);
  };

  const sendData = callback => {
    console.log('callback', callback);
    if (callback.open === false) {
      setViewDialog(false);
    }
    if (callback.reflag === true) {
      httpClient.get(`lead/reFlagTocopy/${callback.leadID}`).then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          getLeads();
        } else {
          setOpen(true);
          setMessage('Failed Re-Flag to copy the current lead');
          setMessageState('error');
        }
      });
    }
    if (callback.deletion === true) {
      httpClient.get(`lead/flagForDeletion/${callback.leadID}`).then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          getLeads();
        } else {
          setOpen(true);
          setMessage('Failed to delete the current lead');
          setMessageState('error');
        }
      });
    }
  };

  return (
    <PageContainer heading="Leads">
      <TableActiveComponent
        columns={columns}
        selectedProducts={leads}
        handleView={handleView}
        title={'Snoozed Leads'}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        fromTable={from}
        toTable={to}
        rowsPerPage={rowsPerPage}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleChangeCompany={handleChangeCompany}
        handleDateFrom={handleDateFrom}
        handleDateTo={handleDateTo}
        handleRemove={handleRemove}
        currentColumn={currentColumn}
        direction={direction}
      />

      {viewDialog && <ViewLeads leadID={leadID} sendData={sendData} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default SnoozedLeads;
