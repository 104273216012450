import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { makeStyles, Card, Grid, TextField, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddShippingCompany = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [shippingID, setShippingID] = useState(props && props.location.pathname.slice(27));

  const [shippingDetails, setShippingDetails] = useState({
    shippingCompanyName: '',
    shippingCompanyActive: false,
    trackingURL: '',
  });

  useEffect(() => {
    getSingleShipping();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    shippingID === ''
      ? httpClient
          .post(
            '/shipping/company',
            qs.stringify({
              shippingCompanyName: shippingDetails.shippingCompanyName,
              shippingCompanyActive: shippingDetails.shippingCompanyActive === true ? 1 : 0,
              trackingURL: shippingDetails.trackingURL,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setShippingDetails({
                shippingCompanyName: '',
                shippingCompanyActive: false,
                trackingURL: '',
              });
            } else {
              setOpen(true);
              setMessage('failed to add Shipping Company');
              setMessageState('error');
            }
          })
      : httpClient
          .post(
            `/shipping/company/${shippingID}`,
            qs.stringify({
              shippingCompanyName: shippingDetails.shippingCompanyName,
              shippingCompanyActive: shippingDetails.shippingCompanyActive === true ? 1 : 0,
              trackingURL: shippingDetails.trackingURL,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              history.push('/orders/listShippingCompany');
            } else {
              setOpen(true);
              setMessage('failed to edit Shipping Company');
              setMessageState('error');
            }
          });
  };

  const getSingleShipping = () => {
    shippingID &&
      httpClient.get(`/shipping/company/${shippingID}`).then(({ data }) => {
        if (data.success === true) {
          setShippingDetails({
            shippingCompanyName: data.data.shippingCompanyName,
            shippingCompanyActive: data.data.shippingCompanyActive === 1 ? true : false,
            trackingURL: data.data.trackingURL,
          });
        }
      });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setShippingDetails({
      ...shippingDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setShippingDetails({
      ...shippingDetails,
      [name]: checked,
    });
  };

  const handleBack = () => {
    history.push('/orders/listShippingCompany');
  };

  return (
    <PageContainer heading={shippingID === '' ? 'Add Shipping Company' : 'Edit Shipping Company'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Shipping Company Name"
                variant="outlined"
                name="shippingCompanyName"
                type="text"
                value={shippingDetails.shippingCompanyName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Tracking Url"
                variant="outlined"
                name="trackingURL"
                type="text"
                value={shippingDetails.trackingURL}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="shippingCompanyActive"
                    checked={shippingDetails.shippingCompanyActive}
                    onChange={handleCheck}
                    value={shippingDetails.shippingCompanyActive}
                    color="primary"
                  />
                }
                label="Shipping Company Active?"
              />
            </Grid>
            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {shippingID === '' ? 'Add Shipping Company' : 'Edit Shipping Company'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddShippingCompany;
