import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddUniform from './AddUniform';
import ListUniform from './ListUniform';
import AttributeType from './AttributeType';
import AddAttributeType from './AddAttributeType';
import ProductAttribute from './ProductAttribute';
import AddProductAttribute from './AddProductAttribute';
import { UniformContextProvider } from './Context';

const Uniform = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <UniformContextProvider>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listUniform`} />
          <Route path={`${requestedUrl}/listUniform`} component={ListUniform} />
          <Route path={`${requestedUrl}/addUniform`} component={AddUniform} />
          <Route path={`${requestedUrl}/listAttributeType`} component={AttributeType} />
          <Route path={`${requestedUrl}/addAttributeType`} component={AddAttributeType} />
          <Route path={`${requestedUrl}/addProductAttribute`} component={AddProductAttribute} />
          <Route path={`${requestedUrl}/listProductAttribute`} component={ProductAttribute} />

          <Route component={lazy(() => import('../ExtraPages/404'))} />
        </Switch>
      </Suspense>
    </UniformContextProvider>
  );
};

export default Uniform;
