import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import Dropzone from 'react-dropzone';
import { Add, AddPhotoAlternateOutlined, Close } from '@material-ui/icons';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  input: {
    marginBottom: '10px',
  },
  collapseClass: {
    border: '1px solid #d1d1d1',
    borderRadius: '5px',
    padding: '30px',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddInventory = props => {
  const classes = useStyles();
  const [inventoryDetails, setInventoryDetails] = useState({
    itemCode: '',
    inventory_category_id: '',
    inventoryItemName: '',
    inventoryItemImage: '',
    resetInventoryItemImage: false,
    inventoryItemDescription: '',
    inventoryStatus: '',
    inventoryItemsShipsTypeID: '',
    pricePerBox: 0,
    weightPerItem: '',
    inventoryItemsPerShipment: 0,
    inventoryOrder: '',
    inventoryItemsActive: false,

    // variationActive: false,
  });

  // const [sizeID, setSizeID] = useState(['']);
  // const [colorID, setColorID] = useState(['']);
  // const [priceID, setPriceID] = useState(['']);
  // const [variationID, setVariationID] = useState(['']);

  // const [sizeList, setSizeList] = useState([]);
  // const [colorList, setColorList] = useState([]);

  const [inventoryID, setInventoryID] = useState(props && props.location.pathname.slice(19));
  const [category, setCategory] = useState([]);
  const [shipment, setShipment] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSingleInventory();
    getInventoryCategory();
    getShipmentTypes();
    // getProductAttributelist();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setInventoryDetails({
      ...inventoryDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setInventoryDetails({
      ...inventoryDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    let newData = new FormData();
    newData.append('itemCode', inventoryDetails.itemCode);
    newData.append('inventory_category_id', inventoryDetails.inventory_category_id);
    newData.append('inventoryItemName', inventoryDetails.inventoryItemName);
    newData.append('inventoryItemDescription', inventoryDetails.inventoryItemDescription);
    newData.append('inventoryStatus', inventoryDetails.inventoryStatus);
    newData.append('pricePerBox', inventoryDetails.pricePerBox);
    newData.append('weightPerItem', inventoryDetails.weightPerItem);
    newData.append('inventoryItemsShipsTypeID', inventoryDetails.inventoryItemsShipsTypeID);
    newData.append('inventoryItemsPerShipment', inventoryDetails.inventoryItemsPerShipment);
    newData.append('inventoryOrder', inventoryDetails.inventoryOrder || 0);
    newData.append(
      'inventoryItemImage',
      inventoryDetails.inventoryItemImage.path ? inventoryDetails.inventoryItemImage : ' ',
    );
    newData.append('inventoryItemsActive', inventoryDetails.inventoryItemsActive == true ? 1 : 0);

    if (inventoryID) {
      newData.append('resetInventoryItemImage', inventoryDetails.inventoryItemImage.path ? 0 : 1);
    }

    inventoryID == ''
      ? httpClient.post('inventory', newData).then(({ data }) => {
          if (data.success == true) {
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            setInventoryDetails({
              itemCode: '',
              inventory_category_id: '',
              inventoryItemName: '',
              inventoryItemImage: '',
              inventoryItemDescription: '',
              inventoryStatus: '',
              inventoryItemsShipsTypeID: '',
              pricePerBox: 0,
              weightPerItem: '',
              inventoryItemsPerShipment: 0,
              inventoryOrder: '',
              inventoryItemsActive: false,
              variationActive: false,
            });

            setTimeout(() => {
              history.push('/inventory/listItem');
            }, 1000);

            setTimeout(() => {
              setLoading(false);
            }, 1500);
          } else {
            setOpen(true);
            // setMessage('Inventory Failed to be added');
            setMessageState('error');
            !data.error ? setMessage(data.message[0]) : setMessage(data.error);
          }
        })
      : httpClient.post(`inventory/${inventoryID}`, newData).then(({ data }) => {
          if (data.success == true) {
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            setTimeout(() => {
              history.push('/inventory/listItem');
            }, 1500);
          } else {
            setOpen(true);
            // setMessage('Inventory Failed to be added');
            setMessageState('error');
            !data.error ? setMessage(data.message[0]) : setMessage(data.error);
          }
        });
  };

  const getInventoryCategory = () => {
    httpClient.get('inventory/category').then(({ data }) => {
      if (data.success == true) {
        setCategory(data.data);
      }
    });
  };

  const getShipmentTypes = () => {
    httpClient.get('inventoryShipmentTypes').then(({ data }) => {
      if (data.success == true) {
        setShipment(data.data);
      }
    });
  };

  const getSingleInventory = () => {
    inventoryID &&
      httpClient.get(`inventory/${inventoryID}`).then(({ data }) => {
        if (data.success == true) {
          setInventoryDetails({
            itemCode: data.data.itemCode,
            inventory_category_id: data.data.inventory_category_id.inventory_category_id,
            inventoryItemName: data.data.inventoryItemName,
            inventoryItemImage: data.data.inventoryItemImage,
            resetInventoryItemImage: data.data.resetInventoryItemImage,
            inventoryItemDescription: data.data.inventoryItemDescription,
            inventoryStatus: data.data.inventoryStatus == 'InStock' ? 1 : 2,
            inventoryItemsShipsTypeID: data?.data?.inventoryItemsPerShipment?.shipmentTypeID,
            pricePerBox: data.data.pricePerBox,
            inventoryOrder: data.data.inventoryOrder,
            weightPerItem: data.data.weightPerItem,
            inventoryItemsPerShipment: data.data.TotalinventoryItemsPerShipment,
            inventoryItemsActive: data.data.inventoryItemsActive == '1' ? true : false,
          });
        }
      });
  };

  const onDrop = file => {
    // return file[0];
    setInventoryDetails({
      ...inventoryDetails,
      // Image: (URL.createObjectURL(file[0]))
      inventoryItemImage: file[0],
      resetInventoryItemImage: false,
    });
  };

  const handleBack = () => {
    history.push('/inventory/listItem');
  };

  return (
    <PageContainer heading={inventoryID == '' ? 'Add Inventory Item' : 'Edit Inventory Item'}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Item Code"
                variant="outlined"
                name="itemCode"
                value={inventoryDetails.itemCode}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Item Name"
                variant="outlined"
                name="inventoryItemName"
                value={inventoryDetails.inventoryItemName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                multiline
                rows={4}
                label="Item Description"
                variant="outlined"
                name="inventoryItemDescription"
                value={inventoryDetails.inventoryItemDescription}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Inventory Category</InputLabel>
                <Select
                  required
                  name="inventory_category_id"
                  label="Inventory Category"
                  value={inventoryDetails.inventory_category_id}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Select Inventory Category</em>
                  </MenuItem>

                  {category.length > 0 &&
                    category.map(cat => (
                      <MenuItem key={cat.inventory_category_id} value={cat.inventory_category_id}>
                        {cat.inventory_category_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Inventory Status</InputLabel>
                <Select
                  required
                  name="inventoryStatus"
                  label="Inventory Status"
                  value={inventoryDetails.inventoryStatus}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Select Inventory Status</em>
                  </MenuItem>
                  <MenuItem value={1}>In-Stock</MenuItem>
                  <MenuItem value={2}>Out of Stock</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Shipment Type</InputLabel>
                <Select
                  required
                  name="inventoryItemsShipsTypeID"
                  label="Shipment Type"
                  value={inventoryDetails.inventoryItemsShipsTypeID}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Select Shipment Type</em>
                  </MenuItem>

                  {shipment.length > 0 &&
                    shipment.map(ship => (
                      <MenuItem key={ship.shipmentTypeID} value={ship.shipmentTypeID}>
                        {ship.shipmentTypeName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={3}>
              <div>
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                    return (
                      <div>
                        {inventoryDetails.inventoryItemImage ? (
                          inventoryDetails.inventoryItemImage.path ? (
                            <img
                              src={URL.createObjectURL(inventoryDetails.inventoryItemImage)}
                              className={classes.imgClass}
                              alt={inventoryDetails.inventoryItemImage.name}
                            />
                          ) : (
                            <img
                              src={inventoryDetails.inventoryItemImage}
                              className={classes.imgClass}
                              alt={inventoryDetails.newsTitle}
                            />
                          )
                        ) : (
                          <div {...getRootProps()} className={classes.uploadImage}>
                            <input {...getInputProps()} />
                            <AddPhotoAlternateOutlined color="primary" />
                            <p>Upload Image here</p>
                          </div>
                        )}
                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              setInventoryDetails({
                                ...inventoryDetails,
                                inventoryItemImage: '',
                                resetInventoryItemImage: true,
                              })
                            }>
                            {' '}
                            Reset Image
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
              </div>
            </Grid>
            <Grid item xs={1} md={7}></Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Price Per Box"
                variant="outlined"
                type="number"
                name="pricePerBox"
                value={inventoryDetails.pricePerBox}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Weight Per Item(Kg)"
                variant="outlined"
                name="weightPerItem"
                value={inventoryDetails.weightPerItem}
                className={classes.text}
                onChange={handleChange}
                type="number"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Items Per Shipment"
                variant="outlined"
                name="inventoryItemsPerShipment"
                value={inventoryDetails.inventoryItemsPerShipment}
                className={classes.text}
                onChange={handleChange}
                type="number"
                helperText={'eg: 10 items per shipment'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Inventory Order"
                variant="outlined"
                type="number"
                name="inventoryOrder"
                value={inventoryDetails.inventoryOrder || ''}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="inventoryItemsActive"
                    checked={inventoryDetails.inventoryItemsActive}
                    onChange={handleCheck}
                    value={inventoryDetails.inventoryItemsActive}
                    color="primary"
                  />
                }
                label="Make Inventory Item Active"
              />
            </Grid>

            <Grid item sm={12}>
              <Box textAlign="right" pt={4}>
                <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={loading}>
                  {inventoryID === '' ? 'Add Inventory Item' : 'Edit Inventory Item'}
                </Button>
                {loading && (
                  <CircularProgress
                    fontSize={'small'}
                    style={{
                      position: 'relative',
                      top: '13px',
                      right: '-13px',
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddInventory;
