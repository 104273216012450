import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import TableCatsComponent from './../TableCatsComponent';
import { history } from 'redux/store';

const columns = [
  { id: 'categoryName', label: 'Category Name' },
  { id: 'items', label: '# of items' },
  { id: 'pickup', label: 'Pickup Free' },
  { id: 'min', label: 'Min Order' },
  { id: 'AddedDate', label: 'Added Date' },
  { id: 'state', label: 'State' },
  { id: 'Actions', label: 'Actions' },
];

const ListInventoryCategory = () => {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    orderPickupFee: '',
    minOrderQty: '',
  });
  const [filterData, setFilterData] = useState({
    orderPickupFee: '',
    minOrderQty: '',
    removed: false,
  });

  useEffect(() => {
    if (filterData.orderPickupFee === '' && filterData.minOrderQty === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.orderPickupFee === ' ') filterData.orderPickupFee = '';
    if (filterData.minOrderQty === ' ') filterData.minOrderQty = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let inventoryCategoryStorage = JSON.parse(localStorage.getItem('inventoryCategory_filter'));
    inventoryCategoryStorage !== null && setFilterData(inventoryCategoryStorage);

    inventoryCategoryStorage == null
      ? getAllInventoryCategory()
      : inventoryCategoryStorage.minOrderQty == '' &&
        inventoryCategoryStorage.orderPickupFee == '' &&
        inventoryCategoryStorage.removed == false
      ? getAllInventoryCategory()
      : console.log('');
  }, []);

  const getAllInventoryCategory = () => {
    setLoading(true);
    httpClient.get('inventory/category').then(({ data }) => {
      if (data.success === true) {
        setCategory(data.data);
        setLoading(false);
      }
    });
  };

  const handleSubmitData = () => {
    setSubmittedData({
      minOrderQty: filterData.minOrderQty,
      orderPickupFee: filterData.orderPickupFee,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('inventoryCategory_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.minOrderQty || filterData.orderPickupFee) {
      httpClient
        .get(`inventory/category/filter?&minOrderQty=${filterData.minOrderQty}&orderPickupFee=${filterData.orderPickupFee}`)
        .then(({ data }) => {
          if (data.success === true) {
            setCategory(data.data);
            setLoading(false);
          }
        });
    } else {
      getAllInventoryCategory();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleEdit = id => {
    history.push(`/inventory/addInventoryCategory/${id}`);
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getAllInventoryCategory();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`inventory/category/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setCategory(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="List Inventory Category">
      <TableCatsComponent
        columns={columns}
        selectedProducts={category}
        title={'List Inventory Items'}
        handleEdit={handleEdit}
        // handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        loading={loading}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
      />
    </PageContainer>
  );
};

export default ListInventoryCategory;
