import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade, IconButton, CircularProgress, TableFooter, TablePagination } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { ExpandLess, ExpandMore, UnfoldMore } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  scrollbarRoot: {
    marginLeft: -24,
    marginRight: -24,
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
  },
  tableRowCellRoot: {
    fontSize: 12,
    '& .MuiSvgIcon-root': {
      fontSize: '13px',
      position: 'relative',
      top: '2px',
      cursor: 'pointer',
    },
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  loader: {
    position: 'relative',
    height: '100px',
  },
  loadingSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  flexBtn: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const columns = [
  { id: 'serviceName', label: 'Service Name' },
  { id: 'serviceDuration', label: 'Service Duration' },
  { id: 'servicePrice', label: 'Service Price' },
  { id: 'serviceActive', label: 'Service Active' },
  { id: 'servicesUsed', label: 'Service Used' },
  { id: 'numOfBooking', label: 'No. of Bookings' },
  { id: 'totalAmount', label: 'Total Amount', align: 'right' },
];

function formatNumberWithCommas(number) {
  return new Intl.NumberFormat('en-US', {
    useGrouping: true,
    maximumFractionDigits: 2,
  }).format(number);
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  return (
    <div className={classes.flexBtn}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage)} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ServiceTable = ({
  selectedProducts,
  loading,
  handleSort,
  direction,
  handleChangePage,
  handleChangeRowsPerPage,
  currentColumn,
  rowsPerPage,
  page,
  total,
  toTable,
  fromTable,
}) => {
  const classes = useStyles();

  return (
    <PerfectScrollbar className={classes.scrollbarRoot}>
      <Box className="Cmt-table-responsive">
        <Table id="table-to-xls">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  className={clsx(classes.tableCellRoot, classes.tableRowCellRoot)}
                  onClick={() => handleSort(column)}>
                  {currentColumn === column ? <span style={{ fontWeight: '900' }}>{column.label}</span> : column.label}
                  {currentColumn === column ? direction ? <ExpandLess /> : <ExpandMore /> : <UnfoldMore />}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody className={classes.loader}>
              <Box textAlign="center" className={classes.loadingSection}>
                <CircularProgress />
              </Box>
            </TableBody>
          ) : (
            <>
              <TableBody>
                {selectedProducts.map((row, index) => {
                  return (
                    <TableRow className={classes.tableRowRoot} key={index}>
                      <TableCell className={classes.tableCellRoot}>{row.serviceName}</TableCell>
                      <TableCell className={classes.tableCellRoot}>{row.serviceDuration}</TableCell>
                      <TableCell className={classes.tableCellRoot}> ${formatNumberWithCommas(row.servicePrice)}</TableCell>
                      <TableCell className={classes.tableCellRoot}>
                        {row.serviceActive === 1 ? (
                          <CheckIcon fontSize="small" color="primary" />
                        ) : (
                          <ClearIcon fontSize="small" color="secondary" />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCellRoot}> {row.servicesUsed}</TableCell>
                      <TableCell className={classes.tableCellRoot}> {row.numOfBooking}</TableCell>
                      <TableCell className={classes.tableCellRoot} align="right">
                        {row.totalAmount ? ` $${formatNumberWithCommas(row.totalAmount)}` : '-'}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {selectedProducts.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ display: 'flex', justifyContent: 'center' }}>No Service Found</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 70, 100]}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={total && total}
                    SelectProps={{
                      native: true,
                    }}
                    labelDisplayedRows={() =>
                      `${fromTable === null ? 0 : fromTable} - ${toTable === null ? 0 : toTable} to ${total}`
                    }
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

export default ServiceTable;
