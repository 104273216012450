import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddExpenseCategory from './AddExpenseCategory';
import AddExpenseSuperCategory from './AddExpenseSuperCategory';
import AddIncomeCategory from './AddIncomeCategory';
import ListExpenseCategory from './ListExpenseCategory';
import ListExpenseSuperCategory from './ListExpenseSuperCategory';
import ListIncomeCategory from './ListIncomeCategory';

const Financial = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listExpenseCategory`} />
        <Route path={`${requestedUrl}/listExpenseCategory`} component={ListExpenseCategory} />
        <Route path={`${requestedUrl}/listExpenseSuperCategory`} component={ListExpenseSuperCategory} />
        <Route path={`${requestedUrl}/addExpenseCategory`} component={AddExpenseCategory} />
        <Route path={`${requestedUrl}/addExpenseSuperCategory`} component={AddExpenseSuperCategory} />
        <Route path={`${requestedUrl}/listIncomeCategory`} component={ListIncomeCategory} />
        <Route path={`${requestedUrl}/addIncomeCategory`} component={AddIncomeCategory} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Financial;
