import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableComponent from './../TableComponent';
import { useUniformContext } from '../Context';

const columns = [
  { id: 'inventoryItemName', label: 'Item Name' },
  // { id: 'inventoryItemsPerShipmenmt', label: 'Items per Shipment' },
  { id: 'pricePerbox', label: 'Price/Item' },
  { id: 'weightPerItem', label: 'Weight Per Item' },
  { id: 'inventoryItemsActive', label: 'Item Active' },
  { id: 'inventoryStatus', label: 'Status' },
  { id: 'actions', label: 'Actions' },
];
const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListUniform = () => {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  // const [page, setPage] = useState(1);
  const { uniformPagination, setUniformPagination } = useUniformContext();
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    inventoryItemsActive: '',
    pricePerBox: '',
    weightPerItem: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    inventoryItemsActive: '',
    pricePerBox: '',
    weightPerItem: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.inventoryItemsActive === '' &&
      filterData.search === '' &&
      filterData.pricePerBox === '' &&
      filterData.weightPerItem === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.inventoryItemsActive === ' ') filterData.inventoryItemsActive = '';
    if (filterData.search === ' ') filterData.search = '';
    if (filterData.pricePerBox === ' ') filterData.pricePerBox = '';
    if (filterData.weightPerItem === ' ') filterData.weightPerItem = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let inventoryStorage = JSON.parse(localStorage.getItem('uniform_filter'));
    inventoryStorage !== null && setFilterData(inventoryStorage);

    inventoryStorage == null
      ? getAllUniform()
      : inventoryStorage.inventoryItemsActive == '' &&
        inventoryStorage.search == '' &&
        inventoryStorage.pricePerBox == '' &&
        inventoryStorage.weightPerItem == '' &&
        inventoryStorage.removed == false
      ? getAllUniform()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      inventoryItemsActive: filterData.inventoryItemsActive,
      search: filterData.search,
      pricePerBox: filterData.pricePerBox,
      weightPerItem: filterData.weightPerItem,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('uniform_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.inventoryItemsActive || filterData.search || filterData.pricePerBox || filterData.weightPerItem) {
      httpClient
        .get(
          `uniform/filter?q=${filterData.search}&inventoryItemsActive=${filterData.inventoryItemsActive}&pricePerBox=${filterData.pricePerBox}&weightPerItem=${filterData.weightPerItem}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setInventory(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            handlePageNumChange(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllUniform();
    }
  };

  //to set context value
  const handlePageNumChange = pageNum => {
    setUniformPagination(prev => ({ ...prev, uniformListPageNum: pageNum }));
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const getAllUniform = () => {
    setLoading(true);
    httpClient.get(`uniform?&pagination=${rowsPerPage}&page=${uniformPagination.uniformListPageNum}`).then(({ data }) => {
      setInventory(data.data);
      handlePageNumChange(data.meta.current_page);
      setTotal(data.meta.total);
      setFrom(data.meta.from);
      setTo(data.meta.to);
      setLoading(false);
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    // console.log('direction', direction);
    httpClient
      .get(`uniform?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setInventory(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `uniform/filter?q=${filterData.search}&inventoryItemsActive=${filterData.inventoryItemsActive}&pricePerBox=${filterData.pricePerBox}&weightPerItem=${filterData.weightPerItem}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setInventory(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              handlePageNumChange(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`uniform?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setInventory(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            handlePageNumChange(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `uniform/filter?q=${filterData.search}&inventoryItemsActive=${filterData.inventoryItemsActive}&pricePerBox=${
              filterData.pricePerBox
            }&weightPerItem=${filterData.weightPerItem}&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setInventory(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              handlePageNumChange(data.meta.current_page);
              setLoading(false);
            }
          })
      : httpClient.get(`uniform?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setInventory(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            handlePageNumChange(data.meta.current_page);
            setLoading(false);
          }
        });
  };

  const handleEdit = val => {
    history.push(`/uniform/addUniform/${val.memID}`);
  };

  // const handleInventoryStatus = val => {
  //   history.push(`/inventory/changeStatus/${val.memID}`);
  // };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getAllUniform();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`inventory/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setInventory(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="Uniform">
      <TableComponent
        columns={columns}
        selectedProducts={inventory}
        title={'List Uniforms'}
        handleEdit={handleEdit}
        // handleInventoryStatus={handleInventoryStatus}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={uniformPagination.uniformListPageNum}
        total={total}
        fromTable={from}
        toTable={to}
        rowsPerPage={rowsPerPage}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        direction={direction}
        currentColumn={currentColumn}
      />
    </PageContainer>
  );
};

export default ListUniform;
