import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import AlertSocialActive from '../AlertSocialActivate';
import SocialPostDialog from '../SocialPostDialog';
import TablePostComponent from '../TablePostComponent';
import qs from 'qs';

const columns = [
  { id: 'postTitle', label: 'Title' },
  { id: 'groupName', label: 'Group Name' },
  { id: 'postedBy', label: 'Posted By' },
  { id: 'postCommentsCount', label: 'Comments' },
  { id: 'postActive', label: 'Active' },
  { id: 'Actions', label: 'Actions' },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ManageSocialPosts = () => {
  const [socialPosts, setSocialPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [showPostDialog, setShowPostDialog] = useState(false);
  const [alertActiveDialog, setAlertActiveDialog] = useState(false);
  const [selectedID, setSelectedID] = useState('');
  const [activeStatus, setActiveStatus] = useState('');
  const [rowDetails, setRowDetails] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    title: '',
    groupName: '',
    postedBy: '',
    // active: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    title: '',
    groupName: '',
    postedBy: '',
    // active: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.title === '' &&
      filterData.groupName === '' &&
      filterData.postedBy === '' &&
      // filterData.active === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.title === ' ') filterData.title = '';
    if (filterData.groupName === ' ') filterData.groupName = '';
    if (filterData.postedBy === ' ') filterData.postedBy = '';
    // if (filterData.active === ' ') filterData.active = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let social_post = JSON.parse(localStorage.getItem('social_post'));
    social_post !== null && setFilterData(social_post);

    social_post == null
      ? getSocial()
      : social_post.title == '' &&
        social_post.groupName == '' &&
        social_post.postedBy == '' &&
        // social_post.active == '' &&
        social_post.search == '' &&
        social_post.removed == false
      ? getSocial()
      : console.log('social_post');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      title: filterData.title,
      groupName: filterData.groupName,
      postedBy: filterData.postedBy,
      // active: filterData.active,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('social_post', JSON.stringify(filterData));
    if (
      filterData.title ||
      filterData.groupName ||
      filterData.postedBy ||
      // filterData.active ||
      filterData.search
    ) {
      httpClient
        .get(
          `social-post/filter?q=${filterData.search}&postTitle=${filterData.title}&groupName=${filterData.groupName}&postedBy=${filterData.postedBy}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setSocialPosts(data.data);
            setTotal(data.meta.total);
            setPage(data.meta.current_page);
            setRowsPerPage(data.meta.per_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getSocial();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  // useEffect(() => {
  //   getSocial();
  // }, []);

  const getSocial = () => {
    setLoading(true);
    httpClient.get(`social-post?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setSocialPosts(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(data.meta.per_page);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`social-post?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setSocialPosts(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            filterData.title || filterData.groupName || filterData.postedBy || filterData.search
              ? `social-post/filter?q=${filterData.search}&postTitle=${filterData.title}&groupName=${filterData.groupName}&postedBy=${filterData.postedBy}&pagination=${rowsPerPage}&page=${page}`
              : `social-post?pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setSocialPosts(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`social-post?&direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setSocialPosts(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            filterData.title || filterData.groupName || filterData.postedBy || filterData.search
              ? `social-post/filter?q=${filterData.search}&postTitle=${filterData.title}&groupName=${
                  filterData.groupName
                }&postedBy=${filterData.postedBy}&pagination=${+event.target.value}&page=${1}`
              : `social-post?pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setSocialPosts(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`social-post?sort_by=companyID&direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setSocialPosts(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  //   const handleEdit = id => {
  //     history.push(`/new-forum/addSocialGroup/${id}`);
  //   };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getSocial();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`social-post/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setSocialPosts(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  const viewDetails = data => {
    setShowPostDialog(true);
    setRowDetails(data.memID);
  };

  const sendBackPost = callBack => {
    if (callBack.success === true && callBack.open === false) {
      setShowPostDialog(false);
      setRowDetails('');
      handleSubmitData();
    } else if (callBack.open === false) {
      setShowPostDialog(false);
      setRowDetails('');
    }
  };

  const handleActivate = val => {
    setAlertActiveDialog(true);
    setSelectedID(val.memID.postID);
    setActiveStatus('Activate');
  };

  const handleDeactivate = val => {
    setAlertActiveDialog(true);
    setSelectedID(val.memID.postID);
    setActiveStatus('Deactivate');
  };

  const sendDeactivate = callback => {
    callback.open == false && setAlertActiveDialog(false);
    if (callback.success === true) {
      httpClient
        .get(
          `social-post/activate-deactivate/${selectedID}`,
          // qs.stringify({
          //   postActive: activeStatus === 'Activate' ? 1 : 0,
          // }),
        )
        .then(({ data }) => {
          if (data.success === true) {
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            setOpen(true);
            setMessage(data.message);
            setMessageState('error');
          }
        });
    }
  };

  const handleDelete = () => {};

  return (
    <PageContainer heading="Manage Social Posts">
      <TablePostComponent
        columns={columns}
        selectedProducts={socialPosts}
        title={'Social Posts'}
        viewDetails={viewDetails}
        handleActivate={handleActivate}
        handleDeactivate={handleDeactivate}
        handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        fromTable={from}
        toTable={to}
        direction={direction}
        currentColumn={currentColumn}
      />

      {alertActiveDialog && <AlertSocialActive sendDeactivate={sendDeactivate} activeStatus={activeStatus} />}

      {showPostDialog && <SocialPostDialog rowDetails={rowDetails} sendBackPost={sendBackPost} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ManageSocialPosts;
