import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableComponent from './../TableComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertDeleteDialog from '../AlertDeleteDialog';

const columns = [
  { id: 'emailInternalName', label: 'Email Name' },
  { id: 'emailSubject', label: 'Subject' },
  { id: 'emailBody', label: 'Body Content' },
  { id: 'emailtype', label: 'Email Type' },
  { id: 'emailActive', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListAutoReply = () => {
  const [autoReply, setAutoReply] = useState([]);
  const [openDeleteDialog, setDeleteDialog] = useState(false);
  const [currentID, setCurrentID] = useState('');

  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  useEffect(() => {
    getAutoReply();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    active: '',
    documentTypeName: '',
    documentType: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    active: '',
    documentTypeName: '',
    documentType: '',
    search: '',
    removed: false,
  });

  // useEffect(() => {
  //   if (
  //     filterData.documentTypeName === '' &&
  //     filterData.documentType === '' &&
  //     filterData.active === '' &&
  //     filterData.search === ''
  //   ) {
  //     setSubmittedData({
  //       ...submittedData,
  //       submit: false,
  //     });
  //   }
  //   if (filterData.active === ' ') filterData.active = '';
  //   if (filterData.documentTypeName === ' ') filterData.documentTypeName = '';
  //   if (filterData.documentType === ' ') filterData.documentType = '';
  //   if (filterData.search === ' ') filterData.search = '';

  //   filterData.removed === true && handleSubmitData();
  // }, [filterData]);

  // useEffect(() => {
  //   let documentStorage = JSON.parse(localStorage.getItem('document_filter'));
  //   documentStorage !== null && setFilterData(documentStorage);

  //   documentStorage == null
  //     ? getDocuments()
  //     : documentStorage.active == '' &&
  //       documentStorage.search == '' &&
  //       documentStorage.documentTypeName == '' &&
  //       documentStorage.documentType == '' &&
  //       documentStorage.removed == false
  //     ? getDocuments()
  //     : console.log('');
  // }, []);

  // const handleSubmitData = () => {
  //   setSubmittedData({
  //     active: filterData.active,
  //     documentTypeName: filterData.documentTypeName,
  //     documentType: filterData.documentType,
  //     search: filterData.search,
  //     submit: true,
  //   });

  //   filterData.removed = true;
  //   localStorage.setItem('document_filter', JSON.stringify(filterData));
  //   setLoading(true);
  //   if (filterData.active || filterData.search || filterData.documentTypeName || filterData.documentType) {
  //     httpClient
  //       .get(
  //         `document/filter?q=${filterData.search}&documentTitle=${filterData.documentTypeName}&documentTypeID=${filterData.documentType}&documentActive=${filterData.active}&pagination=${rowsPerPage}`,
  //       )
  //       .then(({ data }) => {
  //         if (data.success === true) {
  //           setAutoReply(data.data);
  //           setTotal(data.meta.total);
  //           setRowsPerPage(data.meta.per_page);
  //           setPage(data.meta.current_page);
  //           setFrom(data.meta.from);
  //           setTo(data.meta.to);
  //           setLoading(false);
  //         }
  //       });
  //   } else {
  //     getDocuments();
  //   }
  // };

  const handleFilter = e => {
    e.preventDefault();
    // handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const getAutoReply = () => {
    setLoading(true);
    httpClient.get(`email-template?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setAutoReply(data.data);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
        setTotal(data.meta.total);
      }
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`email-template?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setAutoReply(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    // submittedData.submit
    //   ? httpClient
    //       .get(
    //         `email/filter?q=${filterData.search}&documentTitle=${filterData.documentTypeName}&documentTypeID=${filterData.documentType}&documentActive=${filterData.active}&pagination=${rowsPerPage}&page=${page}`,
    //       )
    //       .then(({ data }) => {
    //         if (data.success === true) {
    //           setAutoReply(data.data);
    //           setTotal(data.meta.total);
    //           setRowsPerPage(parseInt(data.meta.per_page));
    //           setPage(data.meta.current_page);
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setLoading(false);
    //         }
    //       })
    //   :
    httpClient.get(`email-template?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
      if (data.success === true) {
        setAutoReply(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    // submittedData.submit
    //   ? httpClient
    //       .get(
    //         `document/filter?q=${filterData.search}&documentTitle=${filterData.documentTypeName}&documentTypeID=${
    //           filterData.documentType
    //         }&documentActive=${filterData.active}&pagination=${+event.target.value}&page=${1}`,
    //       )
    //       .then(({ data }) => {
    //         setLoading(true);
    //         if (data.success === true) {
    //           setAutoReply(data.data);
    //           setTotal(data.meta.total);
    //           setRowsPerPage(parseInt(data.meta.per_page));
    //           setPage(data.meta.current_page);
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setLoading(false);
    //         }
    //       })
    //   :
    httpClient.get(`email-template?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
      setLoading(true);
      if (data.success === true) {
        setAutoReply(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleEdit = id => {
    history.push(`/emailTemplate/addEmail/${id}`);
  };

  const handleDelete = id => {
    setDeleteDialog(true);
    setCurrentID(id);
  };

  const sendTo = child => {
    if (child.open === false) {
      setDeleteDialog(false);
      setCurrentID('');
    }
    if (child.success === true) {
      deleteFuncn();
      setDeleteDialog(false);
    }
  };

  const deleteFuncn = () => {
    httpClient.get(`email-template/delete/${currentID}`).then(({ data }) => {
      if (data.success) {
        setOpen(true);
        setMessage(data.message);
        setMessageState('success');
        setCurrentID('');
        getAutoReply();
      } else {
        setCurrentID('');
        setOpen(true);
        setMessage('Failed to delete Email Template');
        setMessageState('error');
      }
    });
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getDocuments();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`document/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setAutoReply(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="Email Template">
      <TableComponent
        columns={columns}
        selectedProducts={autoReply}
        title={'List Emails'}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        fromTable={from}
        toTable={to}
        rowsPerPage={rowsPerPage}
        direction={direction}
        currentColumn={currentColumn}
      />

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>

      {openDeleteDialog && <AlertDeleteDialog sendTo={sendTo} />}
    </PageContainer>
  );
};

export default ListAutoReply;
