import React, { useEffect, useState } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import TableComponent from './../TableComponent';
import { httpClient } from 'appUtility/Api';
import ReactiveDialog from '../ReactiveDIalog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ViewDialog from '../ViewDialog';
// import { history } from 'redux/store';

const columns = [
  { id: 'firstName', label: 'Full Name' },
  { id: 'suburb', label: 'Suburb' },
  { id: 'postCode', label: 'PostCode' },
  { id: 'address', label: 'Address' },
  { id: 'primaryEmail', label: 'Email' },
  { id: 'customerID', label: 'State Name' },
  { id: 'v2_PhoneMobile', label: 'Phone' },
  { id: 'customerActive', label: 'Customer Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListCustomer = props => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [customers, setCustomers] = useState([]);
  // const [activeCustomer, setActiveCustomer] = useState([]);
  // const [inActiveCustomer, setInActiveCustomer] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);

  const [reactiveID, setReactiveID] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    customerActive: '',
    officeStateID: '',
    franchiseeID: '',
    franchiseeName: '',
    suburb: '',
    itemCount: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    customerActive: '',
    officeStateID: '',
    suburb: '',
    franchiseeID: '',
    franchiseeName: '',
    itemCount: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.franchiseeID === '' &&
      filterData.franchiseeName === '' &&
      filterData.customerActive === '' &&
      filterData.officeStateID === '' &&
      filterData.suburb === '' &&
      filterData.itemCount === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.customerActive === ' ') filterData.customerActive = '';
    if (filterData.franchiseeID === ' ') filterData.franchiseeID = '';
    if (filterData.franchiseeName === ' ') filterData.franchiseeName = '';
    if (filterData.officeStateID === ' ') filterData.officeStateID = '';
    if (filterData.suburb === ' ') filterData.suburb = '';
    if (filterData.itemCount === ' ') filterData.itemCount = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let customerStorage = JSON.parse(localStorage.getItem('customer_filter'));
    customerStorage !== null && setFilterData(customerStorage);

    customerStorage == null
      ? getAllCustomers()
      : customerStorage.customerActive == '' &&
        customerStorage.officeStateID == '' &&
        customerStorage.franchiseeID == '' &&
        customerStorage.franchiseeName == '' &&
        customerStorage.itemCount == '' &&
        customerStorage.suburb == '' &&
        customerStorage.search == '' &&
        customerStorage.removed == false
      ? getAllCustomers()
      : console.log('customers');
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmitData = () => {
    setSubmittedData({
      customerActive: filterData.customerActive,
      officeStateID: filterData.officeStateID,
      suburb: filterData.suburb,
      itemCount: filterData.itemCount,
      franchiseeID: filterData.franchiseeID,
      franchiseeName: filterData.franchiseeName,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('customer_filter', JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.customerActive ||
      filterData.officeStateID ||
      filterData.itemCount ||
      filterData.suburb ||
      filterData.franchiseeID ||
      filterData.franchiseeName ||
      filterData.search
    ) {
      httpClient
        .get(
          `customer/filter?q=${filterData.search}&franchiseeID=${filterData.franchiseeID}&customerActive=${filterData.customerActive}&customerActive=${filterData.customerActive}&officeStateID=${filterData.officeStateID}&suburb=${filterData.suburb}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setCustomers(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllCustomers();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };
  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'franchiseeID') {
      filterData.franchiseeName = value;
    }
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleChangeFranchise = value => {
    setFilterData({
      ...filterData,
      franchiseeID: value !== null ? value.memberID : '',
      franchiseeName: value !== null ? value.firstName + ' ' + value.lastName : '',
    });
  };

  const handleRemove = data => {
    if (data === 'franchiseeID') {
      filterData.franchiseeName = '';
      submittedData.franchiseeName = '';
    }
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`customer?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setCustomers(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `customer/filter?q=${filterData.search}&itemCount=${filterData.itemCount}&franchiseeID=${filterData.franchiseeID}&customerActive=${filterData.customerActive}&officeStateID=${filterData.officeStateID}&suburb=${filterData.suburb}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setCustomers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`customer?sort_by=customerID&direction=desc&pagination=${rowsPerPage}&page=${page}`)
          .then(({ data }) => {
            if (data.success === true) {
              setCustomers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `customer/filter?q=${filterData.search}&itemCount=${filterData.itemCount}&franchiseeID=${
              filterData.franchiseeID
            }&customerActive=${filterData.customerActive}&officeStateID=${filterData.officeStateID}&suburb=${
              filterData.suburb
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setCustomers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`customer?sort_by=customerID&direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setCustomers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleView = val => {
    setReactiveID(val.memID.customerID);
    setViewDialogOpen(true);
  };

  const sendBackView = callback => {
    // console.log('callback>>>', callback);
    if (callback == false) {
      setViewDialogOpen(false);
      setReactiveID('');
    }
  };

  const handleReactive = val => {
    setReactiveID(val.memID.customerID);
    setDialogOpen(true);
  };

  const sendTo = child => {
    // console.log('child', child);
    child.open == false && setDialogOpen(false);

    if (child.success == true) {
      httpClient.get(`customer/re-activate/${reactiveID}`).then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage('Customer Re-Activated Successfully');
          setMessageState('success');
          setReactiveID('');

          setTimeout(() => {
            getAllCustomers();
          }, 1000);
        } else {
          setOpen(true);
          setMessage('Customer Failed to Re-Activate');
          setMessageState('error');
        }
      });
    }
  };

  const getAllCustomers = () => {
    setLoading(true);
    props.location.query && props.location.query.customer_type
      ? httpClient
          .get(`customer/type/${props.location.query && props.location.query.customer_type}?&pagination=${rowsPerPage}`)
          .then(({ data }) => {
            if (data) {
              setCustomers(data.data);
              setTotal(data.meta.total);
              // setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            } else {
            }
          })
      : httpClient.get(`customer?&pagination=${rowsPerPage}`).then(({ data }) => {
          if (data) {
            setCustomers(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          } else {
          }
        });
  };

  return (
    <PageContainer heading="Customers">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableComponent
              columns={columns}
              selectedProducts={customers}
              title={
                (props.location.query && props.location.query.customer_type == 'active' && 'Active Customers') ||
                (props.location.query && props.location.query.customer_type == 'inactive' && 'InActive Customers') ||
                (props.location.query && props.location.query.customer_type == 'deleted' && 'Deleted Customers') ||
                'List Customers'
              }
              handleView={handleView}
              handleReactive={handleReactive}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleChangeFranchise={handleChangeFranchise}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>

      {dialogOpen && <ReactiveDialog sendTo={sendTo} />}
      {viewDialogOpen && <ViewDialog sendBackView={sendBackView} customerID={reactiveID} />}
    </PageContainer>
  );
};

export default ListCustomer;
