import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  text: {
    margin: '10px 0',
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddServices = props => {
  const classes = useStyles();
  const [serviceDetail, setServiceDetail] = useState({
    serviceName: '',
    serviceDescription: '',
    serviceGroupID: '',
    service_pet_type: '',
    displayPosition: '',
    servicePrice: 0.0,
    serviceDuration: '',
    serviceActive: false,
    shampooRequired: false,
  });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [groups, setGroups] = useState([]);
  const [serviceID, setServiceID] = useState(props && props.location.pathname.slice(22));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllGroups();
    getSingleService();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'displayPosition') {
      if (serviceID) {
        return;
      }
    }

    setServiceDetail({
      ...serviceDetail,
      [name]: value,
    });
  };

  const handleChangePrice = e => {
    const { name, value } = e.target;
    setServiceDetail({
      ...serviceDetail,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setServiceDetail({
      ...serviceDetail,
      [name]: checked,
    });
  };

  const getAllGroups = e => {
    httpClient.get('service-group').then(({ data }) => {
      if (data.success === true) {
        setGroups(data.data);
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    serviceID === ''
      ? httpClient
          .post(
            'service',
            qs.stringify({
              serviceName: serviceDetail.serviceName,
              serviceDescription: serviceDetail.serviceDescription,
              serviceGroupID: serviceDetail.serviceGroupID,
              service_pet_type: serviceDetail.service_pet_type,
              displayPosition: Number(serviceDetail.displayPosition),
              servicePrice: serviceDetail.servicePrice,
              serviceDuration: serviceDetail.serviceDuration,
              serviceActive: serviceDetail.serviceActive === true ? 1 : 0,
              shampooRequired: serviceDetail.shampooRequired ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setServiceDetail({
                serviceName: '',
                serviceDescription: '',
                serviceGroupID: '',
                service_pet_type: '',
                displayPosition: '',
                servicePrice: 0.0,
                serviceDuration: '',
                serviceActive: false,
              });
              setTimeout(() => {
                history.push('/services/listServices');
              }, 1000);

              setTimeout(() => {
                setLoading(false);
              }, 1500);
            } else {
              setLoading(false);
              setOpen(true);
              setMessage('Service failed to be added');
              setMessageState('error');
            }
          })
      : httpClient
          .put(
            `service/${serviceID}`,
            qs.stringify({
              serviceName: serviceDetail.serviceName,
              serviceDescription: serviceDetail.serviceDescription,
              serviceGroupID: serviceDetail.serviceGroupID,
              service_pet_type: serviceDetail.service_pet_type,
              displayPosition: Number(serviceDetail.displayPosition),
              servicePrice: serviceDetail.servicePrice,
              serviceDuration: serviceDetail.serviceDuration,
              serviceActive: serviceDetail.serviceActive === true ? 1 : 0,
              shampooRequired: serviceDetail.shampooRequired ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                history.push('/services/listServices');
              }, 1000);

              setTimeout(() => {
                setLoading(false);
              }, 1500);
            } else {
              setLoading(false);
              setOpen(true);
              setMessage('Service failed to be added');
              setMessageState('error');
            }
          });
  };

  const getSingleService = () => {
    serviceID &&
      httpClient.get(`/service/${serviceID}`).then(({ data }) => {
        if (data.success === true) {
          setServiceDetail({
            serviceName: data.data.serviceName,
            serviceDescription: data.data.serviceDescription,
            serviceGroupID: data.data.group.groupID,
            service_pet_type: data.data.service_pet_type,
            displayPosition: data.data.displayPosition,
            servicePrice: data.data.servicePrice,
            serviceDuration: data.data.serviceDuration,
            serviceActive: data.data.serviceActive === 1 ? true : false,
            shampooRequired: data.data.shampooRequired === 1 ? true : false,
          });
        }
      });
  };

  const handleBack = () => {
    history.push('/services/listServices');
  };

  return (
    <PageContainer heading={serviceID === '' ? 'Add Service' : 'Edit Service'}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Service Name"
                variant="outlined"
                name="serviceName"
                value={serviceDetail.serviceName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={4}
                label="Service Description"
                variant="outlined"
                name="serviceDescription"
                value={serviceDetail.serviceDescription}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>All Pet Sizes</InputLabel>
                <Select
                  required
                  name="service_pet_type"
                  label="All Pet Sizes"
                  value={serviceDetail.service_pet_type}
                  onChange={handleChange}>
                  <MenuItem value="">
                    <em>Select Service Pet Size</em>
                  </MenuItem>
                  <MenuItem value="Toy">Toy</MenuItem>
                  <MenuItem value="Small">Small</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Large">Large</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Service Group</InputLabel>
                <Select
                  required
                  name="serviceGroupID"
                  label="Service Group"
                  value={serviceDetail.serviceGroupID}
                  onChange={handleChange}>
                  <MenuItem value="">
                    <em>Select Service Group</em>
                  </MenuItem>
                  {groups &&
                    groups.map(grp => (
                      <MenuItem key={grp.GroupID} value={grp.GroupID}>
                        {grp.GroupName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Display Position"
                variant="outlined"
                type="number"
                name="displayPosition"
                value={serviceDetail.displayPosition}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Default Service Price"
                variant="outlined"
                name="servicePrice"
                value={serviceDetail.servicePrice}
                className={classes.text}
                onChange={handleChangePrice}
                type="number"
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Normal Service Duration(displayed in Minutes)"
                variant="outlined"
                name="serviceDuration"
                type="number"
                value={serviceDetail.serviceDuration}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="shampooRequired"
                    checked={serviceDetail.shampooRequired}
                    onChange={handleCheck}
                    value={serviceDetail.shampooRequired}
                    color="primary"
                  />
                }
                label="Shampoo Required"
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="serviceActive"
                    checked={serviceDetail.serviceActive}
                    onChange={handleCheck}
                    value={serviceDetail.serviceActive}
                    color="primary"
                  />
                }
                label="Make Service Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Box textAlign="right" pt={4}>
                <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={loading}>
                  {serviceID === '' ? 'Add Service' : 'Edit Service'}
                </Button>
                {loading && (
                  <CircularProgress
                    fontSize="small"
                    style={{
                      position: 'relative',
                      top: '13px',
                      right: '-13px',
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddServices;
