import React, { useState, useEffect } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField, IconButton } from '@material-ui/core';
import { history } from 'redux/store';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();

  const handleClose = () => {
    props.handleCloseButton();
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <IconButton onClick={handleClose} style={{ position: 'relative', right: '20px' }}>
          <Close />
        </IconButton>
      </div>
      <CmtCardContent>
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
