import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  text: {
    width: '100%',
  },
  form: {
    width: '100%',
  },
  file: {
    width: '100%',
    border: '1px solid #999',
    margin: '7px 0',
    borderRadius: '5px',
    padding: '10px',
    cursor: 'pointer',
  },
  small: {
    fontSize: '12px',
    color: '#999',
  },
  reset: {
    color: 'blue',
    cursor: 'pointer',
    marginLeft: '20px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddDocument = props => {
  const classes = useStyles();

  const [documentID, setDocumentID] = useState(props && props.location.pathname.slice(23));

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [docType, setDocType] = useState([]);

  const [isReset, setIsReset] = useState(false);
  const [originalFileName, setOriginalFileName] = useState('');

  const [documentDetails, setDocumentDetails] = useState({
    documentTitle: '',
    documentDescription: '',
    documentTypeID: '',
    documentFileName: '',
    resetDocumentItemImage: false,
    documentActive: false,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDocumentType();
    getSingleDocument();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    let newData = new FormData();
    newData.append('documentTitle', documentDetails.documentTitle);
    newData.append('documentDescription', documentDetails.documentDescription);
    newData.append('documentTypeID', documentDetails.documentTypeID);
    newData.append('documentActive', documentDetails.documentActive === true ? 1 : 0);
    newData.append('documentFileName', documentDetails.documentFileName.type ? documentDetails.documentFileName : ' ');

    if (documentID) {
      newData.append('resetDocumentItemImage', documentDetails.documentFileName ? 0 : 1);
    }

    documentID === ''
      ? httpClient
          .post(
            'document',
            newData,
            // qs.stringify({
            //   documentTitle: documentDetails.documentTitle,
            //   documentDescription: documentDetails.documentDescription,
            //   documentTypeID: documentDetails.documentTypeID,
            //   documentFileName: documentDetails.documentFileName,
            //   documentActive: documentDetails.documentActive === true ? 1 : 0,
            // }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setDocumentDetails({
                documentTitle: '',
                documentDescription: '',
                documentTypeID: '',
                documentFileName: '',
                documentActive: false,
              });

              setTimeout(() => {
                history.push('/documents/listDocuments');
              }, 1000);

              setTimeout(() => {
                setLoading(false);
              }, 1500);
            } else {
              setLoading(false);
              setOpen(true);
              setMessage('Failed to add document');
              setMessageState('error');
            }
          })
      : httpClient
          .post(
            `document/${documentID}`,
            newData,
            // qs.stringify({
            //   documentTitle: documentDetails.documentTitle,
            //   documentDescription: documentDetails.documentDescription,
            //   documentTypeID: documentDetails.documentTypeID,
            //   documentFileName: documentDetails.documentFileName,
            //   documentActive: documentDetails.documentActive === true ? 1 : 0,
            // }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');

              setTimeout(() => {
                history.push('/documents/listDocuments');
              }, 1000);

              setTimeout(() => {
                setLoading(false);
              }, 1500);
            } else {
              setLoading(false);
              setOpen(true);
              setMessage('Failed to edit document');
              setMessageState('error');
            }
          });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setDocumentDetails({
      ...documentDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setDocumentDetails({
      ...documentDetails,
      [name]: checked,
    });
  };

  const getSingleDocument = () => {
    documentID &&
      httpClient.get(`document/${documentID}`).then(({ data }) => {
        if (data.success === true) {
          setOriginalFileName(data.data.documentFileName);

          setDocumentDetails({
            documentTitle: data.data.documentTitle,
            documentDescription: data.data.documentDescription,
            documentTypeID: data.data.documentType.documentTypeID,
            documentFileName: data.data.documentFileName,
            resetDocumentItemImage: false,
            documentActive: data.data.documentActive === 1 ? true : false,
          });
        }
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getDocumentType = () => {
    httpClient.get('document/type').then(({ data }) => {
      if (data.success === true) {
        setDocType(data.data);
      }
    });
  };

  const handleBack = () => {
    history.push('/documents/listDocuments');
  };

  const handleFileUpload = e => {
    setDocumentDetails({
      ...documentDetails,
      documentFileName: e.target.files[0],
      resetDocumentItemImage: false,
    });
    setIsReset(false);
  };

  const handleReset = () => {
    const shouldResetDocumentItemImage = documentDetails.documentFileName === originalFileName;

    setDocumentDetails({
      ...documentDetails,
      documentFileName: '',
      resetDocumentItemImage: shouldResetDocumentItemImage,
    });
    setIsReset(true);
  };

  const renderFileInput = () => {
    if (documentID === '' || isReset || !documentDetails.documentFileName) {
      return (
        <input
          type="file"
          accept="image/*,application/pdf,application/msword,applicatioon/msexcel,application/mspowerpoint/application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip,application/x-zip,application/x-zip-compressed"
          name="documentFileName"
          onChange={handleFileUpload}
          className={classes.file}
        />
      );
    } else {
      return (
        <div>
          <span>
            {typeof documentDetails.documentFileName === 'string'
              ? documentDetails.documentFileName.split('/').pop()
              : documentDetails.documentFileName.name}
          </span>
          <span className={classes.reset} onClick={handleReset}>
            Reset
          </span>
        </div>
      );
    }
  };

  return (
    <PageContainer heading={documentID === '' ? 'Add Document' : 'Edit Document'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Document Title"
                variant="outlined"
                name="documentTitle"
                type="text"
                value={documentDetails.documentTitle}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={4}
                label="Document Description"
                variant="outlined"
                name="documentDescription"
                type="text"
                value={documentDetails.documentDescription}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Document Type</InputLabel>
                <Select
                  required
                  name="documentTypeID"
                  label="Document Type"
                  value={documentDetails.documentTypeID}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value="">
                    <em>Select Document Type</em>
                  </MenuItem>
                  {docType &&
                    docType.map(doc => (
                      <MenuItem key={doc.documentTypeID} value={doc.documentTypeID}>
                        {doc.documentTypeName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item sm={12} md={12}>
              <TextField
                required
                label="Document File Name"
                variant="outlined"
                name="documentFileName"
                type="text"
                value={documentDetails.documentFileName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid> */}

            <Grid item sm={12} md={12}>
              <label>Select File</label>
              <br />
              {renderFileInput()}
              <br />
              <span className={classes.small}>Allowed file types: Word, PDF, Excel, Images, PowerPoint, ZIP</span>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="documentActive"
                    checked={documentDetails.documentActive}
                    onChange={handleCheck}
                    value={documentDetails.documentActive}
                    color="primary"
                  />
                }
                label="Active Document?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button onClick={handleBack} variant="contained" color="primary" style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                {documentID === '' ? 'Add Document' : 'Edit Document'}
              </Button>
              {loading && (
                <CircularProgress
                  fontSize="small"
                  style={{
                    position: 'relative',
                    top: '13px',
                    right: '-13px',
                  }}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddDocument;
