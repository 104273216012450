import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  text: {
    margin: '10px 0',
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddProductAttribute = props => {
  const classes = useStyles();
  const [productAttributeDetail, setProductAttributeDetail] = useState({
    attributeName: '',
    typeID: '',
    attributeDescription: '',
    // minOrderQty: 0,
    // orderPickupFee: 0,
    attributeActive: false,
  });
  const [attributeID, setAttributeID] = useState(props && props.location.pathname.slice(29));

  // console.log('attribute idddd', attributeID);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [loading, setLoading] = useState(false);
  const [attributeType, setAttributeType] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // need separate loader for form submission loading state

  useEffect(() => {
    if (attributeID) {
      getSingleAttribute();
    }
    getAttributeType();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setProductAttributeDetail({
      ...productAttributeDetail,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setProductAttributeDetail({
      ...productAttributeDetail,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);

    attributeID == ''
      ? httpClient
          .post(
            'product-attribute',
            qs.stringify({
              attributeName: productAttributeDetail.attributeName,
              typeID: productAttributeDetail.typeID,
              attributeDescription: productAttributeDetail.attributeDescription,
              groupBy: 'uniform',
              //   orderPickupFee: productAttributeDetail.orderPickupFee,
              attributeActive: productAttributeDetail.attributeActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setProductAttributeDetail({
                attributeName: '',
                typeID: '',
                attributeDescription: '',
                // orderPickupFee: 0,
                attributeActive: false,
              });
              setTimeout(() => {
                history.push('/uniform/listProductAttribute');
              }, 1000);
              setTimeout(() => {
                setIsLoading(false);
              }, 1500);
            } else {
              setIsLoading(false);
              setOpen(true);
              setMessage(data.message);
              setMessageState('error');
              // !data.error ? setMessage(data.message[0]) : setMessage(data.error);
            }
          })
      : httpClient
          .post(
            `product-attribute/${attributeID}`,
            qs.stringify({
              attributeName: productAttributeDetail.attributeName,
              typeID: productAttributeDetail.typeID,
              attributeDescription: productAttributeDetail.attributeDescription,
              groupBy: 'uniform',
              //   minOrderQty: productAttributeDetail.minOrderQty,
              //   orderPickupFee: productAttributeDetail.orderPickupFee,
              attributeActive: productAttributeDetail.attributeActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                history.push('/uniform/listProductAttribute');
              }, 1000);

              setTimeout(() => {
                setIsLoading(false);
              }, 1500);
            } else {
              setIsLoading(false);
              setOpen(true);
              setMessage(data.message);
              setMessageState('error');
              // !data.error ? setMessage(data.message[0]) : setMessage(data.error);
            }
          });
  };

  const getSingleAttribute = () => {
    setLoading(true);
    httpClient.get(`product-attribute/${attributeID}`).then(({ data }) => {
      if (data.success == true) {
        setProductAttributeDetail({
          attributeName: data.data.attributeName,
          typeID: data.data.typeID,
          attributeDescription: data.data.attributeDescription,
          // minOrderQty: data.data.minOrderQty,
          // orderPickupFee: data.data.orderPickupFee,
          attributeActive: data.data.attributeActive === 1 ? true : false,
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const getAttributeType = () => {
    httpClient.get('attribute-type/all?groupBy=uniform').then(({ data }) => {
      if (data.success) {
        const selectableAttrTypes = data.data.filter(attr => attr.typeStyle === 'selectable' && attr.typeActive === 1);
        setAttributeType(selectableAttrTypes);
      }
    });
  };

  const handleBack = () => {
    history.push('/uniform/listProductAttribute');
  };

  return (
    <PageContainer heading={attributeID == '' ? 'Add Product Attribute' : 'Edit Product Attribute'}>
      <Card className={classes.root}>
        {loading ? (
          <Box p={2} textAlign={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl variant="outlined" className={classes.text}>
                  <InputLabel>Attribute Type</InputLabel>
                  <Select
                    required
                    name="typeID"
                    label="Attribute Type"
                    value={productAttributeDetail.typeID}
                    onChange={handleChange}
                    className={classes.text}>
                    <MenuItem value={0}>
                      <em>Select Attribute Type</em>
                    </MenuItem>
                    {attributeType &&
                      attributeType.map(att => (
                        <MenuItem value={att.typeID} key={att.typeID}>
                          {att.typeName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item sm={12} md={12}>
                <TextField
                  required
                  label="Attribute Name"
                  variant="outlined"
                  name="attributeName"
                  value={productAttributeDetail.attributeName}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={12} md={12}>
                <TextField
                  required
                  multiline
                  rows={4}
                  label="Attribute Description"
                  variant="outlined"
                  name="attributeDescription"
                  value={productAttributeDetail.attributeDescription}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="attributeActive"
                      checked={productAttributeDetail.attributeActive}
                      onChange={handleCheck}
                      value={productAttributeDetail.attributeActive}
                      color="primary"
                    />
                  }
                  label="Make Attribute Active"
                />
              </Grid>
              <Grid item sm={12}>
                <Box textAlign="right" pt={4}>
                  <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit" disabled={isLoading}>
                    {attributeID === '' ? 'Add Product Attribute' : 'Edit Product Attribute'}
                  </Button>
                  {isLoading && (
                    <CircularProgress
                      fontSize={'small'}
                      style={{
                        position: 'relative',
                        top: '13px',
                        right: '-13px',
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddProductAttribute;
