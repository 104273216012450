import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddDocumentType = props => {
  const classes = useStyles();

  const [documentTypeID, setDocumentTypeID] = useState(props && props.location.pathname.slice(27));

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [members, setMembers] = useState([]);
  const [documentTypeDetails, setDocumentTypeDetails] = useState({
    documentTypeName: '',
    companyID: '',
    documentTypeActive: false,
  });

  useEffect(() => {
    getSingleDocumentType();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    documentTypeID === ''
      ? httpClient
          .post(
            'document/type',
            qs.stringify({
              documentTypeName: documentTypeDetails.documentTypeName,
              companyID: documentTypeDetails.companyID,
              documentTypeActive: documentTypeDetails.documentTypeActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setDocumentTypeDetails({
                documentTypeName: '',
                companyID: '',
                documentTypeActive: false,
              });
            } else {
              setOpen(true);
              setMessage('failed to add document type');
              setMessageState('error');
            }
          })
      : httpClient
          .put(
            `document/type/${documentTypeID}`,
            qs.stringify({
              documentTypeName: documentTypeDetails.documentTypeName,
              companyID: documentTypeDetails.companyID,
              documentTypeActive: documentTypeDetails.documentTypeActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              history.push('/documents/listDocumentType');
            } else {
              setOpen(true);
              setMessage('failed to edit document type');
              setMessageState('error');
            }
          });
  };

  const getSingleDocumentType = () => {
    documentTypeID &&
      httpClient.get(`/document/type/${documentTypeID}`).then(({ data }) => {
        if (data.success === true) {
          setDocumentTypeDetails({
            documentTypeName: data.data.documentTypeName,
            companyID: data.data.companyID,
            documentTypeActive: data.data.documentTypeActive === 1 ? true : false,
          });
        }
      });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setDocumentTypeDetails({
      ...documentTypeDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setDocumentTypeDetails({
      ...documentTypeDetails,
      [name]: checked,
    });
  };

  const handleBack = () => {
    history.push('/documents/listDocumentType');
  };

  return (
    <PageContainer heading={documentTypeID === '' ? 'Add Document Type' : 'Edit Document Type'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Document Type Name"
                variant="outlined"
                name="documentTypeName"
                type="text"
                value={documentTypeDetails.documentTypeName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="documentTypeActive"
                    checked={documentTypeDetails.documentTypeActive}
                    onChange={handleCheck}
                    value={documentTypeDetails.documentTypeActive}
                    color="primary"
                  />
                }
                label="Active Document Type?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button variant="contained" color="secondary" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {documentTypeID === '' ? 'Add Document Type' : 'Edit Document Type'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddDocumentType;
