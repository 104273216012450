import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddMembers from './AddMembers.js';
import BuySellProcess from './BuySellProcess';
import EnableDisableIPad from './EnableDisableIPad';
import ForumMgmt from './ForumMgmt';
import ListMembers from './ListMembers.js';
import ManageAdmins from './ManageAdmins.js';
import ResetPassword from './ResetPassword';
import TopUpCredit from './TopUpCredit';
import ViewStockTake from './ViewStockTake';

const Members = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listMembers`} />
        <Route path={`${requestedUrl}/listMembers`} component={ListMembers} />
        <Route path={`${requestedUrl}/addMembers`} component={AddMembers} />
        <Route path={`${requestedUrl}/resetPassword`} component={ResetPassword} />
        <Route path={`${requestedUrl}/enableDisableIPad`} component={EnableDisableIPad} />
        <Route path={`${requestedUrl}/forumMgmt`} component={ForumMgmt} />
        <Route path={`${requestedUrl}/topUpCredit`} component={TopUpCredit} />
        <Route path={`${requestedUrl}/buySellProcess`} component={BuySellProcess} />
        <Route path={`${requestedUrl}/manageAdmins`} component={ManageAdmins} />
        <Route path={`${requestedUrl}/viewStockTake`} component={ViewStockTake} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Members;
