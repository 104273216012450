import { Dialog } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useState, useEffect } from 'react';
import TableMemberComponent from '../TableMemberComponent';

const columns = [
  { id: 'loginDate', label: 'Last Login' },
  { id: 'loginIP', label: 'IP Address' },
  { id: 'browser', label: 'Browser' },
  { id: 'os', label: 'OS' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ViewIpadDialog = props => {
  const [open, setOpen] = useState(true);
  const [viewData, setViewData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);
  const [total, setTotal] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );

  useEffect(() => {
    getLogMembers();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const getLogMembers = () => {
    setLoading(true);
    props.viewID &&
      httpClient.get(`member/report/ipad-log/${props.viewID}`).then(({ data }) => {
        if (data.success == true) {
          setViewData(data.data.data);
          setTotal(data.data.total);
          // setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.data.current_page);
          setFrom(data.data.from);
          setTo(data.data.to);
          setLoading(false);
        }
      });
  };

  props.sendTo(open);

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(
        `member/report/ipad-log/${props.viewID}?sort_by=${column.id}&direction=${
          direction ? 'asc' : 'desc'
        }&pagination=${rowsPerPage}`,
      )
      .then(({ data }) => {
        if (data.success === true) {
          setViewData(data.data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);

    httpClient
      .get(`member/report/ipad-log/${props.viewID}?&direction=desc&pagination=${rowsPerPage}&page=${page}`)
      .then(({ data }) => {
        if (data.success === true) {
          setViewData(data.data.data);
          setTotal(data.data.total);
          setRowsPerPage(parseInt(data.data.per_page));
          setPage(data.data.current_page);
          setFrom(data.data.from);
          setTo(data.data.to);
          setLoading(false);
        }
      });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    httpClient
      .get(`member/report/ipad-log/${props.viewID}?&direction=desc&pagination=${+event.target.value}&page=${1}`)
      .then(({ data }) => {
        setLoading(true);
        if (data.success === true) {
          setViewData(data.data.data);
          setTotal(data.data.total);
          setRowsPerPage(parseInt(data.data.per_page));
          setFrom(data.data.from);
          setTo(data.data.to);
          setLoading(false);
        }
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg" scroll="body">
      <TableMemberComponent
        columns={columns}
        selectedProducts={viewData}
        title={`System logs of Member: ${props.title}`}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total && total}
        fromTable={from}
        toTable={to}
        rowsPerPage={rowsPerPage}
        direction={direction}
        currentColumn={currentColumn}
        handleCloseButton={handleClose}
      />
    </Dialog>
  );
};

export default ViewIpadDialog;
