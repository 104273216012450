import React, { useEffect, useState } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import SearchIcon from '@material-ui/icons/Search';
import { httpClient } from 'appUtility/Api';
import { Close, FilterList } from '@material-ui/icons';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);
  const [superCategoryList, setSuperCategoryList] = useState([]);
  const [addedbyList, setAddedbyList] = useState([]);
  var [page, setPage] = useState(1);
  var catName, memberName;

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  useEffect(() => {
    getSuperCategoryList();
    getAddedbyList();
  }, []);

  const getSuperCategoryList = () => {
    httpClient.get(`/expenses/category/super`).then(({ data }) => {
      if (data.success === true) {
        setSuperCategoryList(data.data);
      }
    });
  };

  const getAddedbyList = () => {
    httpClient.get('expenses/category/company').then(({ data }) => {
      if (data.success === true) {
        setAddedbyList(data.data);
      }
    });
  };

  superCategoryList &&
    superCategoryList.map(item => {
      if (item.expenseSuperCategoryID === props.submittedData.category) {
        catName = item.expenseSuperCategoryName;
      }
    });

  addedbyList &&
    addedbyList.map(item => {
      if (props.submittedData.addedBy == '0') {
        memberName = 'Super Admin';
      } else if (props.submittedData.addedBy === item.companyID) {
        memberName = item.tradingName;
      }
    });

  // console.log('props.submittedData',props.filterData)

  const sendTo = () => {
    history.push('/financial/addExpenseCategory');
  };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '10px' }}>
            +Add
          </Button>
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Search </InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Category Name </InputLabel>
                  <TextField
                    variant="outlined"
                    name="categoryName"
                    value={props.filterData.categoryName}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Super Category</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="category"
                      // label="Active Members"
                      value={props.filterData.category}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">&nbsp;</MenuItem>
                      {superCategoryList &&
                        superCategoryList.map(item => {
                          return (
                            <MenuItem value={item.expenseSuperCategoryID} key={item.expenseSuperCategoryID}>
                              {item.expenseSuperCategoryName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Added By</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="addedBy"
                      value={props.filterData.addedBy}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">&nbsp;</MenuItem>
                      <MenuItem value={'0'}>Super Admin</MenuItem>

                      {addedbyList &&
                        addedbyList.map(item => {
                          return (
                            <MenuItem value={item.companyID} key={item.companyID}>
                              {item.tradingName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Gst</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="gst"
                      // label="Migrated Members"
                      value={props.filterData.gst}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value={1}>Include Gst</MenuItem>
                      <MenuItem value={0}>Exclude Gst</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Active</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="active"
                      value={props.filterData.active}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.categoryName ||
        props.submittedData.category ||
        props.submittedData.addedBy ||
        props.submittedData.gst ||
        props.submittedData.active ||
        props.submittedData.search ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.active && (
              <p>
                Active Members: <span>{props.submittedData.active == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('active')} />
              </p>
            )}
            {props.submittedData.gst && (
              <p>
                Include Gst:<span> {props.submittedData.gst == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('gst')} />
              </p>
            )}
            {props.submittedData.categoryName && (
              <p>
                Category Name: <span>{props.submittedData.categoryName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('categoryName')} />
              </p>
            )}

            {props.submittedData.addedBy && (
              <p>
                Added By: <span>{memberName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('addedBy')} />
              </p>
            )}

            {props.submittedData.category && (
              <p>
                Super Category: <span>{catName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('category')} />
              </p>
            )}

            {props.submittedData.search && (
              <p>
                Search:<span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleEdit={props.handleEdit}
          handleDelete={props.handleDelete}
          loading={props.loading}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
