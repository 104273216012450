import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableComponent from './../TableComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const columns = [
  { id: 'serviceName', label: 'Service Name' },
  { id: 'number_of_item_used', label: 'Service Group' },
  { id: 'service_pet_type', label: 'Pet Size' },
  { id: 'displayPosition', label: 'Display Position' },
  { id: 'servicePrice', label: 'Service Price' },
  { id: 'serviceDuration', label: 'Service Duration' },
  { id: 'stockItem', label: '# of Stock Items Used' },
  { id: 'serviceActive', label: 'Service Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListServices = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    serviceGroupID: '',
    service_pet_type: '',
    servicePrice: '',
    serviceDuration: '',
    numberOfStockUsed: '',
    serviceActive: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    serviceGroupID: '',
    service_pet_type: '',
    servicePrice: '',
    serviceDuration: '',
    numberOfStockUsed: '',
    serviceActive: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.serviceGroupID === '' &&
      filterData.service_pet_type === '' &&
      filterData.servicePrice === '' &&
      filterData.serviceDuration === '' &&
      filterData.numberOfStockUsed === '' &&
      filterData.serviceActive === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.serviceGroupID === ' ') filterData.serviceGroupID = '';
    if (filterData.service_pet_type === ' ') filterData.service_pet_type = '';
    if (filterData.servicePrice === ' ') filterData.servicePrice = '';
    if (filterData.serviceDuration === ' ') filterData.serviceDuration = '';
    if (filterData.numberOfStockUsed === ' ') filterData.numberOfStockUsed = '';
    if (filterData.serviceActive === ' ') filterData.serviceActive = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let serviceStorage = JSON.parse(localStorage.getItem('service_filter'));
    serviceStorage !== null && setFilterData(serviceStorage);

    serviceStorage == null
      ? getAllServices()
      : serviceStorage.serviceGroupID == '' &&
        serviceStorage.service_pet_type == '' &&
        serviceStorage.servicePrice == '' &&
        serviceStorage.serviceDuration == '' &&
        serviceStorage.numberOfStockUsed == '' &&
        serviceStorage.serviceActive == '' &&
        serviceStorage.search == '' &&
        serviceStorage.removed == false
      ? getAllServices()
      : console.log('services');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      serviceGroupID: filterData.serviceGroupID,
      service_pet_type: filterData.service_pet_type,
      servicePrice: filterData.servicePrice,
      serviceDuration: filterData.serviceDuration,
      numberOfStockUsed: filterData.numberOfStockUsed,
      serviceActive: filterData.serviceActive,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('service_filter', JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.serviceGroupID ||
      filterData.service_pet_type ||
      filterData.servicePrice ||
      filterData.serviceDuration ||
      filterData.numberOfStockUsed ||
      filterData.serviceActive ||
      filterData.search
    ) {
      httpClient
        .get(
          `service/filter?q=${filterData.search}&serviceActive=${filterData.serviceActive}&numberOfStockUsed=${filterData.numberOfStockUsed}&serviceDuration=${filterData.serviceDuration}&servicePrice=${filterData.servicePrice}&serviceGroupID=${filterData.serviceGroupID}&service_pet_type=${filterData.service_pet_type}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setServices(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllServices();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`service?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setServices(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `service/filter?q=${filterData.search}&serviceActive=${filterData.serviceActive}&numberOfStockUsed=${filterData.numberOfStockUsed}&serviceDuration=${filterData.serviceDuration}&servicePrice=${filterData.servicePrice}&serviceGroupID=${filterData.serviceGroupID}&service_pet_type=${filterData.service_pet_type}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setServices(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`service?&direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setServices(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `service/filter?q=${filterData.search}&serviceActive=${filterData.serviceActive}&numberOfStockUsed=${
              filterData.numberOfStockUsed
            }&serviceDuration=${filterData.serviceDuration}&servicePrice=${filterData.servicePrice}&serviceGroupID=${
              filterData.serviceGroupID
            }&service_pet_type=${filterData.service_pet_type}&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setServices(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`service?sort_by=companyID&direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setServices(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleEdit = val => {
    history.push(`/services/addServices/${val.memID}`);
  };

  const handleInventory = val => {
    history.push(`/services/inventoryUsage/${val.memID}`);
  };

  const getAllServices = () => {
    setLoading(true);
    httpClient.get(`service?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setServices(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getAllServices();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`service/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setServices(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="List Services">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableComponent
              columns={columns}
              selectedProducts={services}
              title="List Services"
              handleEdit={handleEdit}
              handleInventory={handleInventory}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              rowsPerPage={rowsPerPage}
              fromTable={from}
              toTable={to}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListServices;
