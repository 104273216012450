import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import Dropzone from 'react-dropzone';
import { AddPhotoAlternateOutlined, History } from '@material-ui/icons';
// import Blank from '/images/image.png'

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddNews = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [imageError, setImageError] = useState(false);

  const [newsID, setNewsID] = useState(props && props.location.pathname.slice(14));

  const [category, setCategory] = useState([]);
  const [newsDetails, setNewsDetails] = useState({
    newsCategoryID: '',
    newsTitle: '',
    newsDescription: '',
    newsDate: '',
    highPriority: false,
    newsActive: false,
    newsImage: '',
  });

  useEffect(() => {
    getSingleNews();
    getNewsCategory();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleImageError = (e) => {
    e.target.onError = null;
    e.target.style.height = "150px";
    e.target.style.width = "150px";
    e.target.style.objectFit = "contain";
    setImageError(true);
  }

  console.log('image error', imageError);

  const handleSubmit = e => {
    e.preventDefault();

    const newsData = new FormData();
    newsData.append('newsCategoryID', newsDetails.newsCategoryID);
    newsData.append('highPriority', newsDetails.highPriority === true ? 1 : 0);
    newsData.append('newsActive', newsDetails.newsActive === true ? 1 : 0);
    newsData.append('newsTitle', newsDetails.newsTitle);
    newsData.append('newsDescription', newsDetails.newsDescription);
    newsData.append('newsDate', newsDetails.newsDate);
    // newsData.append('newsImage', newsDetails.newsImage.path ? newsDetails.newsImage : ' ');
    newsData.append('newsImage',newsDetails.newsImage);

    newsID === ''
      ? httpClient.post('/news', newsData).then(({ data }) => {
          if (data.success === true) {
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            setNewsDetails({
              newsCategoryID: '',
              newsTitle: '',
              newsDescription: '',
              newsDate: '',
              highPriority: false,
              newsActive: false,
              newsImage: '',
            });
            setTimeout(() => {
              history.push('/news/listNews');
            },1000)
          } else {
            setOpen(true);
            setMessage(typeof(data.message) == "string" ? data.message : data.message[0]);
            setMessageState('error');
          }
        })
      : httpClient.post(`/news/${newsID}`, newsData).then(({ data }) => {
          if (data.success === true) {
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            setTimeout(() => {
              history.push('/news/listNews');
            },1000)
          } else {
            setOpen(true);
            setMessage(typeof(data.message) == "string" ? data.message : data.message[0]);
            setMessageState('error');
          }
        });
  };

  const getSingleNews = () => {
    newsID &&
      httpClient.get(`/news/${newsID}`).then(({ data }) => {
        if (data.success === true) {
          setNewsDetails({
            newsCategoryID: data.data.newsCategory.CategoryID,
            newsTitle: data.data.newsTitle,
            newsDescription: data.data.newsDescription,
            newsDate: data.data.newsDate,
            highPriority: data.data.highPriority === 1 ? true : false,
            newsActive: data.data.newsActive === 1 ? true : false,
            newsImage: data.data.newsImage,
          });
        }
      });
  };

  const getNewsCategory = () => {
    httpClient.get('/news/category').then(({ data }) => {
      if (data.success === true) {
        setCategory(data.data);
      }
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setNewsDetails({
      ...newsDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setNewsDetails({
      ...newsDetails,
      [name]: checked,
    });
  };

  const onDrop = file => {
    // return file[0];
    setNewsDetails({
      ...newsDetails,
      // Image: (URL.createObjectURL(file[0]))
      newsImage: file[0],
    });
  };

  const handleBack = () => {
    history.push('/news/listNews');
  };

  return (
    <PageContainer heading={newsID === '' ? 'Add News' : 'Edit News'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="News Title"
                variant="outlined"
                name="newsTitle"
                type="text"
                value={newsDetails.newsTitle}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                multiline
                rows={4}
                label="News Description"
                variant="outlined"
                name="newsDescription"
                type="text"
                value={newsDetails.newsDescription}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                type="date"
                label="News Date"
                variant="outlined"
                name="newsDate"
                value={newsDetails.newsDate}
                className={classes.text}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>News Category</InputLabel>
                <Select
                  required
                  name="newsCategoryID"
                  label="Affiliated To Business"
                  value={newsDetails.newsCategoryID}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value="">
                    <em>Select News Category</em>
                  </MenuItem>
                  {category &&
                    category.map(cats => (
                      <MenuItem key={cats.categoryID} value={cats.categoryID}>
                        {cats.categoryTitle}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={3}>
              <div>
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                    return (
                      <div>
                        {newsDetails.newsImage ? (
                          newsDetails.newsImage.path ? (
                            <img
                              src={URL.createObjectURL(newsDetails.newsImage)}
                              className={classes.imgClass}
                              alt={newsDetails.newsImage.name}
                            />
                          ) : (
                            <img src={imageError ? "/images/image.png" : newsDetails.newsImage} className={classes.imgClass} alt={newsDetails.newsTitle} 
                            onError={handleImageError}
                            
                            />
                          )
                        ) : (
                          <div {...getRootProps()} className={classes.uploadImage}>
                            <input {...getInputProps()} />
                            <AddPhotoAlternateOutlined color="primary" />
                            <p>Upload Image here</p>
                          </div>
                        )}
                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              setNewsDetails({
                                ...newsDetails,
                                newsImage: '',
                              })
                            }>
                            {' '}
                            Reset Image
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
              </div>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="highPriority"
                    checked={newsDetails.highPriority}
                    onChange={handleCheck}
                    value={newsDetails.highPriority}
                    color="primary"
                  />
                }
                label="High Priority?"
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="newsActive"
                    checked={newsDetails.newsActive}
                    onChange={handleCheck}
                    value={newsDetails.newsActive}
                    color="primary"
                  />
                }
                label="Active News?"
              />
            </Grid>
            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {newsID === '' ? 'Add News' : 'Edit News'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddNews;
