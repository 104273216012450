import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddAutoReply from './AddAutoReply';
import ListAutoReply from './ListAutoReply';

const AutoReply = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listEmails`} />
        <Route path={`${requestedUrl}/listEmails`} component={ListAutoReply} />
        <Route path={`${requestedUrl}/addEmail`} component={AddAutoReply} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default AutoReply;
