import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddGroups = props => {
  const classes = useStyles();

  const [groupID, setGroupID] = useState(props && props.location.pathname.slice(17));
  const [forumCategory, setForumCategory] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [groupDetails, setGroupDetails] = useState({
    forumGroupName: '',
    forumGroupShortDesc: '',
    forumCategoryID: '',
    forumGroupActive: false,
  });

  useEffect(() => {
    getSingleForumGroup();
    getForumCategory();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setGroupDetails({
      ...groupDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setGroupDetails({
      ...groupDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    groupID == ''
      ? httpClient
          .post(
            'forum-group',
            qs.stringify({
              forumGroupName: groupDetails.forumGroupName,
              forumGroupShortDesc: groupDetails.forumGroupShortDesc,
              forumCategoryID: groupDetails.forumCategoryID,
              forumGroupActive: groupDetails.forumGroupActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setGroupDetails({
                forumGroupName: '',
                forumGroupShortDesc: '',
                forumCategoryID: '',
                forumGroupActive: false,
              });
            } else {
              setOpen(true);
              setMessage('Forum Group Failed to be added');
              setMessageState('error');
            }
          })
      : httpClient
          .post(
            `forum-group/${groupID}`,
            qs.stringify({
              forumGroupName: groupDetails.forumGroupName,
              forumGroupShortDesc: groupDetails.forumGroupShortDesc,
              forumCategoryID: groupDetails.forumCategoryID,
              forumGroupActive: groupDetails.forumGroupActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setGroupDetails({
                forumGroupName: '',
                forumGroupShortDesc: '',
                forumCategoryID: '',
                forumGroupActive: false,
              });
              setTimeout(() => {
                history.push('/forum/listGroups');
              }, 1000);
            } else {
              setOpen(true);
              setMessage('Forum Category Failed to be updated');
              setMessageState('error');
            }
          });
  };

  const getSingleForumGroup = () => {
    groupID &&
      httpClient.get(`/forum-group/${groupID}`).then(({ data }) => {
        setGroupDetails({
          forumGroupName: data.data.forumGroupName,
          forumGroupShortDesc: data.data.forumGroupShortDesc,
          forumCategoryID: data.data.forumCategory.forumCategoryID,
          forumGroupActive: data.data.forumGroupActive == 'Active' ? true : false,
        });
      });
  };

  const getForumCategory = () => {
    httpClient.get(`/forum-category`).then(({ data }) => {
      if (data) {
        setForumCategory(data.data);
      }
    });
  };

  const handleBack = () => {
    history.push('/forum/listGroups');
  };

  return (
    <PageContainer heading={groupID === '' ? 'Add Forum Group' : 'Edit Forum Group'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Forum Group Name"
                variant="outlined"
                name="forumGroupName"
                type="text"
                value={groupDetails.forumGroupName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={3}
                label="Forum Short Description"
                variant="outlined"
                name="forumGroupShortDesc"
                type="text"
                value={groupDetails.forumGroupShortDesc}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Category *</InputLabel>
                <Select
                  required
                  name="forumCategoryID"
                  label="category"
                  value={groupDetails.forumCategoryID}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value="">Select Forum Category</MenuItem>
                  {forumCategory &&
                    forumCategory.map(cats => (
                      <MenuItem key={cats.forumCategoryID} value={cats.forumCategoryID}>
                        {cats.forumCategoryName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="forumGroupActive"
                    checked={groupDetails.forumGroupActive}
                    onChange={handleCheck}
                    value={groupDetails.forumGroupActive}
                    color="primary"
                  />
                }
                label="Make Forum Group Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                {groupID === '' ? 'Add Forum Group' : 'Edit Forum Group'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddGroups;
