import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableComponent from './../TableComponent';

const columns = [
  { id: 'freightTitle', label: 'Rule Name' },
  { id: 'shippingZone', label: 'Shipping Zone' },
  { id: 'productCategory', label: 'Product Category' },
  { id: 'ruleApplyFor', label: 'Rule Apply For' },
  { id: 'freightCost', label: 'Freight Cost' },
  { id: 'freightActive', label: 'Freight Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListFreights = () => {
  const [freight, setFreight] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    freightActive: '',
    shipping_zone_id: '',
    inventory_category_id: '',
    freightCost: '',
    freightPerKG: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    freightActive: '',
    shipping_zone_id: '',
    inventory_category_id: '',
    freightCost: '',
    freightPerKG: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.freightPerKG === '' &&
      filterData.inventory_category_id === '' &&
      filterData.freightCost === '' &&
      filterData.freightActive === '' &&
      filterData.shipping_zone_id === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.freightActive === ' ') filterData.freightActive = '';
    if (filterData.shipping_zone_id === ' ') filterData.shipping_zone_id = '';
    if (filterData.inventory_category_id === ' ') filterData.inventory_category_id = '';
    if (filterData.freightCost === ' ') filterData.freightCost = '';
    if (filterData.freightPerKG === ' ') filterData.freightPerKG = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let freightStorage = JSON.parse(localStorage.getItem('freight_filter'));
    freightStorage !== null && setFilterData(freightStorage);

    freightStorage == null
      ? getAllFreights()
      : freightStorage.freightActive == '' &&
        freightStorage.freightCost == '' &&
        freightStorage.search == '' &&
        freightStorage.shipping_zone_id == '' &&
        freightStorage.inventory_category_id == '' &&
        freightStorage.freightPerKG == '' &&
        freightStorage.removed == false
      ? getAllFreights()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      freightActive: filterData.freightActive,
      shipping_zone_id: filterData.shipping_zone_id,
      inventory_category_id: filterData.inventory_category_id,
      freightCost: filterData.freightCost,
      freightPerKG: filterData.freightPerKG,
      search: filterData.search,
      submit: true,
    });

    filterData.removed = true;
    localStorage.setItem('freight_filter', JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.freightActive ||
      filterData.freightCost ||
      (filterData.search && filterData.shipping_zone_id) ||
      filterData.inventory_category_id ||
      filterData.freightPerKG
    ) {
      httpClient
        .get(
          `inventory/freight/filter?q=${filterData.search}&freightPerKG=${filterData.freightPerKG}&freightCost=${filterData.freightCost}&inventory_category_id=${filterData.inventory_category_id}&freightActive=${filterData.freightActive}&shipping_zone_id=${filterData.shipping_zone_id}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setFreight(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllFreights();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const getAllFreights = () => {
    setLoading(true);
    httpClient.get(`inventory/freight?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setFreight(data.data);
        setTotal(data.meta.total);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
      }
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    // console.log('direction', direction);
    httpClient
      .get(`inventory/freight?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setFreight(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `inventory/freight/filter?q=${filterData.search}&freightPerKG=${filterData.freightPerKG}&freightCost=${filterData.freightCost}&inventory_category_id=${filterData.inventory_category_id}&freightActive=${filterData.freightActive}&shipping_zone_id=${filterData.shipping_zone_id}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setFreight(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`inventory/freight?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setFreight(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `inventory/freight/filter?q=${filterData.search}&freightPerKG=${filterData.freightPerKG}&freightCost=${
              filterData.freightCost
            }&inventory_category_id=${filterData.inventory_category_id}&freightActive=${
              filterData.freightActive
            }&shipping_zone_id=${filterData.shipping_zone_id}&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setFreight(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`inventory/freight?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setFreight(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleEdit = id => {
    history.push(`/freight/addFreight/${id}`);
  };

  // const handleDelete = () => {};

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getAllFreights();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`inventory/freight/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setFreight(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };
  return (
    <PageContainer heading="List Freights">
      <TableComponent
        columns={columns}
        selectedProducts={freight}
        title={'List Freight Items'}
        handleEdit={handleEdit}
        // handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        fromTable={from}
        toTable={to}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        currentColumn={currentColumn}
        direction={direction}
      />
    </PageContainer>
  );
};

export default ListFreights;
