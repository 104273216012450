import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, fade } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { NavLink } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import ReCAPTCHA from 'react-google-recaptcha';
import { httpClient } from 'appUtility/Api';
import { history } from 'redux/store';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [capcha, setCapcha] = useState('');
  // const dispatch = useDispatch();
  const recaptchaRef = React.createRef();

  const classes = useStyles({ variant });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [superAdmin, setSuperAdmin] = useState(0);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    httpClient
      .post(
        'login',
        qs.stringify({
          action: 'verify_login',
          sysAdminUserName: email,
          sysAdminPassword: password,
          GRecaptchaResponse: capcha,
          SuperAdmin: superAdmin,
        }),
      )
      .then(({ data }) => {
        if (data.success === true) {
          localStorage.setItem('token', JSON.stringify(data.data.access_token));
          localStorage.setItem('login', JSON.stringify(data.data));
          setTimeout(function() {
            setOpen(true);
            setMessage('Successfully Logged in');
            setMessageState('success');
            setLoading(false);
          }, 1000);
          setTimeout(function() {
            // history.push('/dashboard');
            window.location = '/dashboard/dashboard';
          }, 2000);
        } else {
          setTimeout(function() {
            setOpen(true);
            setMessage("Username or Password doesn't match");
            setMessageState('error');
            setLoading(false);
          }, 1000);
        }
      });
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo_bluewheeler.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Admin Login
        </Typography>
        <form>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.username" />}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Le6NYQbAAAAAMdyL-1r0-HGA-G_LO_8ELA25xGh"
              onChange={e => setCapcha(recaptchaRef.current.getValue())}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <FormControlLabel
              className={classes.formcontrolLabelRoot}
              control={<Checkbox name="checkedA" />}
              label="Remember me"
            />
            {/* <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/forgot-password">
                <IntlMessages id="appModule.forgotPassword" />
              </NavLink>
            </Box> */}
          </Box>

          <Box display="flex" alignItems="center" mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary" disabled={loading ? true : false}>
              <IntlMessages id="appModule.signIn" />
            </Button>
            {loading ? <CircularProgress size={20} style={{ marginLeft: '10px' }} /> : ''}
            {/* <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/signup">
                <IntlMessages id="signIn.signUp" />
              </NavLink>
            </Box> */}
          </Box>
        </form>

        {/* {dispatch(AuhMethods[method].getSocialMediaIcons())} */}

        <ContentLoader />

        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={messageState}>
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
