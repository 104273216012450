import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import Dropzone from 'react-dropzone';
import { Add, AddPhotoAlternateOutlined, Close } from '@material-ui/icons';
import ActiveAttributesFields from './ActiveAttributesFields';
import useGetProductAttributesList from './hooks/useGetProductAttributesList';
import useGetActiveAttributesTypesList from './hooks/useGetActiveAttributesTypesList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  input: {
    marginBottom: '5px',
  },
  collapseClass: {
    // border: '1px solid #d1d1d1',
    borderRadius: '5px',
    // padding: '30px',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
}));

const initInventoryDetails = {
  itemCode: '',
  // inventory_category_id: '',
  inventoryItemName: '',
  inventoryItemImage: '',
  resetInventoryItemImage: false,
  inventoryItemDescription: '',
  inventoryStatus: '',
  // inventoryItemsShipsTypeID: '',
  pricePerBox: 0,
  weightPerItem: '',
  inventoryItemsPerShipment: 0,
  inventoryItemsActive: false,
  inventoryType: 0,
  inventoryOrder: 0,
  variationActive: false,
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddFlyers = props => {
  const classes = useStyles();
  const [inventoryDetails, setInventoryDetails] = useState(initInventoryDetails);
  const [isLoading, setIsLoading] = useState(false);

  const { productAttributesList } = useGetProductAttributesList();
  const { activeAttributesTypesList } = useGetActiveAttributesTypesList();
  const [inventoryVariationsList, setInventoryVariationsList] = useState([]);

  const [sizeID, setSizeID] = useState(['']);
  const [colorID, setColorID] = useState(['']);
  const [priceID, setPriceID] = useState(['']);
  const [variationID, setVariationID] = useState(['']);

  const [inventoryID, setInventoryID] = useState(props && props.location.pathname.slice(18));
  const [category, setCategory] = useState([]);
  const [shipment, setShipment] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [imgError, setImgError] = useState(false);

  // useEffect(() => console.log('inventoryVariationList', inventoryVariationsList), [inventoryVariationsList]);

  useEffect(() => {
    getInventoryCategory();
    getShipmentTypes();
  }, []);

  useEffect(() => getSingleInventory(), [productAttributesList, activeAttributesTypesList]);

  //callback
  const getInventoryVariationDetail = (index, detail) => {
    setInventoryVariationsList(variation => variation.map((attr, i) => (index === i ? detail : attr)));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setInventoryDetails({
      ...inventoryDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setInventoryDetails({
      ...inventoryDetails,
      [name]: checked,
    });

    const obj = activeAttributesTypesList.map((type, index) => ({
      attrTypeID: parseInt(type.typeID),
      attrTypeValue: '',
    })); //init fields
    if (inventoryVariationsList.length === 0) setInventoryVariationsList(prev => [...prev, obj]);
  };

  /**
   * Function to check for duplicate Variation No of Copies
   * No of Copies shouldn't be duplicate between among varitaions
   */
  const checkDuplicateVariationNoOfCopies = list => {
    const noOfCopiesVariationValues = new Set();
    let hasDuplicate = false;

    for (let i = 0; i < list.length; i++) {
      const sublist = list[i];

      const noOfCopiesItem = sublist.find(item => item.attrTypeID === 6);

      if (noOfCopiesItem && noOfCopiesItem.attrTypeValue !== '') {
        if (noOfCopiesVariationValues.has(noOfCopiesItem.attrTypeValue)) {
          setOpen(true);
          setMessage(`Duplicate No of Copies found at Variation No. ${i + 1}`);
          setMessageState('error');
          hasDuplicate = true;
          return hasDuplicate; // Duplicate found
        }
        noOfCopiesVariationValues.add(noOfCopiesItem.attrTypeValue);
      }
    }

    return hasDuplicate; // No duplicate found
  };

  /**
   * Function to check for missing values of No of Copies and Price
   * No of Copies and Price are required variations attribute
   */
  const checkForMissingVariation = list => {
    for (let i = 0; i < list.length; i++) {
      const sublist = list[i];

      const missingNoOfCopiesValue = sublist.some(item => item.attrTypeID === 6 && item.attrTypeValue === '');
      const missingPriceValue = sublist.some(
        item => item.attrTypeID === 7 && (item.attrTypeValue === '' || item.attrTypeValue === '0'),
      );

      if (missingNoOfCopiesValue) {
        setOpen(true);
        setMessage(`No of Copies is missing at Variation No. ${i + 1}`);
        setMessageState('error');
        return true; // No of Copies missing
      }

      if (missingPriceValue) {
        setOpen(true);
        setMessage(`Price is missing at Variation No. ${i + 1}`);
        setMessageState('error');
        return true; // Price missing
      }
    }
    return false; // Not missing
  };

  const handleSubmit = e => {
    e.preventDefault();

    var inventoryVariationArray = [];
    if (inventoryDetails.variationActive) {
      const duplicateNoOfCopies = checkDuplicateVariationNoOfCopies(inventoryVariationsList);
      const attributeMissing = checkForMissingVariation(inventoryVariationsList);

      if (duplicateNoOfCopies) return;
      if (attributeMissing) return;

      sizeID.map((s, index) => {
        var newInventoryVariation = {
          sizeID: s,
          colorID: colorID[index],
          priceID: priceID[index],
          variationID: variationID[index],
        };
        inventoryVariationArray.push(newInventoryVariation);
      });
    }

    setIsLoading(true);

    let newData = new FormData();
    newData.append('itemCode', inventoryDetails.itemCode);
    // newData.append('inventory_category_id', inventoryDetails.inventory_category_id);
    newData.append('inventoryItemName', inventoryDetails.inventoryItemName);
    newData.append('inventoryItemDescription', inventoryDetails.inventoryItemDescription);
    newData.append('inventoryStatus', inventoryDetails.inventoryStatus);
    newData.append('pricePerBox', inventoryDetails.pricePerBox);
    newData.append('weightPerItem', inventoryDetails.weightPerItem);
    // newData.append('inventoryItemsShipsTypeID', inventoryDetails.inventoryItemsShipsTypeID);
    newData.append('inventoryItemsPerShipment', inventoryDetails.inventoryItemsPerShipment);
    newData.append(
      'inventoryItemImage',
      inventoryDetails.inventoryItemImage.path ? inventoryDetails.inventoryItemImage : ' ',
    );
    newData.append('inventoryItemsActive', inventoryDetails.inventoryItemsActive === true ? 1 : 0);
    newData.append('inventoryType', inventoryDetails.inventoryType || 0);
    newData.append('inventoryOrder', inventoryDetails.inventoryOrder);
    newData.append('variationActive', inventoryDetails.variationActive === true ? 1 : 0);

    if (inventoryID) {
      newData.append('resetInventoryItemImage', inventoryDetails.inventoryItemImage.path ? 0 : 1);
    }

    if (inventoryDetails.variationActive === true) {
      if (inventoryID === '') {
        inventoryVariationsList.map((variation, variationIndex) => {
          variation.map((attr, attrIndex) => {
            newData.append(`  inventoryVariations[${variationIndex}][${attrIndex}][attributeTypeID]:`, attr.attrTypeID);
            newData.append(
              `  inventoryVariations[${variationIndex}][${attrIndex}][attributeValue]:`,
              attr.attrTypeValue || '0',
            );
          });
        });
      } else {
        inventoryVariationsList.map((variation, variationIndex) => {
          variation.map((attr, attrIndex) => {
            newData.append(`  inventoryVariations[${variationIndex}][${attrIndex}][attributeTypeID]:`, attr.attrTypeID);
            newData.append(
              `  inventoryVariations[${variationIndex}][${attrIndex}][attributeValue]:`,
              attr.attrTypeValue || '0',
            );
          });
        });
      }
    }

    inventoryID === ''
      ? httpClient.post('flyers', newData).then(({ data }) => {
          if (data.success === true) {
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            setInventoryDetails(initInventoryDetails);

            setTimeout(() => {
              history.push('/flyers/listFlyers');
            }, 1000);

            setTimeout(() => {
              setIsLoading(false);
            }, 1500);
          } else {
            setIsLoading(false);
            setOpen(true);
            // setMessage('Inventory Failed to be added');
            setMessageState('error');
            typeof data.message === 'string' ? setMessage(data.error) : setMessage(data.message[0]);
          }
        })
      : httpClient.post(`flyers/${inventoryID}`, newData).then(({ data }) => {
          if (data.success === true) {
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');

            setTimeout(() => {
              history.push('/flyers/listFlyers');
            }, 1000);

            setTimeout(() => {
              setIsLoading(false);
            }, 1500);
          } else {
            setIsLoading(false);
            setOpen(true);
            // setMessage('Inventory Failed to be added');
            setMessageState('error');
            typeof data.message === 'string' ? setMessage(data.error) : setMessage(data.message[0]);
          }
        });
  };

  const getInventoryCategory = () => {
    httpClient.get('inventory/category').then(({ data }) => {
      if (data.success === true) {
        setCategory(data.data);
      }
    });
  };

  const getShipmentTypes = () => {
    httpClient.get('inventoryShipmentTypes').then(({ data }) => {
      if (data.success === true) {
        setShipment(data.data);
      }
    });
  };

  const getSingleInventory = () => {
    inventoryID &&
      httpClient.get(`flyers/${inventoryID}`).then(({ data }) => {
        if (data.success === true) {
          setInventoryDetails({
            itemCode: data.data.itemCode,
            // inventory_category_id: data.data.inventory_category_id.inventory_category_id,
            inventoryItemName: data.data.inventoryItemName,
            inventoryItemImage: data.data.inventoryItemImage,
            resetInventoryItemImage: data.data.resetInventoryItemImage,
            inventoryItemDescription: data.data.inventoryItemDescription,
            inventoryStatus: data.data.inventoryStatus === 'InStock' ? 1 : 2,
            // inventoryItemsShipsTypeID: data.data.inventoryItemsPerShipment.shipmentTypeID,
            pricePerBox: data.data.pricePerBox,
            weightPerItem: data.data.weightPerItem,
            inventoryItemsPerShipment: data.data.TotalinventoryItemsPerShipment,
            inventoryType: data.data.inventoryType,
            inventoryOrder: data.data.inventoryOrder,
            inventoryItemsActive: data.data.inventoryItemsActive === 1 ? true : false,
            variationActive: data.data.variationActive === 1 ? true : false,
          });

          if (data.data.inventoryVariations.length > 0) {
            sizeID.pop();
            colorID.pop();
            priceID.pop();
            variationID.pop();

            data.data.inventoryVariations &&
              data.data.inventoryVariations.map((invent, index) => {
                sizeID.push(invent.sizeID);
                colorID.push(invent.colorID);
                priceID.push(invent.pricePerBox);
                variationID.push(invent.variationID);
              });
          }

          const newVariationList = data.data.inventoryVariations.map((variation, index) => {
            const keys = Object.keys(variation);
            const newVariation = keys.map(key => ({
              attrTypeID: parseInt(key),
              attrTypeValue: variation[parseInt(key)],
            }));
            let correctedNewVariation = newVariation;

            activeAttributesTypesList.map(attr => {
              const found = newVariation.some(attr1 => parseInt(attr.typeID) === parseInt(attr1.attrTypeID));
              if (!found)
                correctedNewVariation.push({
                  attrTypeID: parseInt(attr.typeID),
                  attrTypeValue: '',
                });
            });

            return correctedNewVariation;
          });

          setInventoryVariationsList(newVariationList);
        }
      });
  };

  const onDrop = file => {
    // return file[0];
    setInventoryDetails({
      ...inventoryDetails,
      // Image: (URL.createObjectURL(file[0]))
      inventoryItemImage: file[0],
      resetInventoryItemImage: false,
    });
  };

  const handleBack = () => {
    history.push('/flyers/listFlyers');
  };

  const handleChangeSize = (e, index) => {
    const { name, value } = e.target;

    sizeID[index] = value;

    setSizeID([...sizeID]);
  };

  const handleChangeColor = (e, index) => {
    const { name, value } = e.target;

    colorID[index] = value;

    setColorID([...colorID]);
  };

  const handleChangePrice = (e, index) => {
    const { name, value } = e.target;

    priceID[index] = value;
    setPriceID([...priceID]);
  };

  const handleAdd = (e, index) => {
    setSizeID(prev => [...prev, '']);
    setColorID(prev => [...prev, '']);
    setPriceID(prev => [...prev, '']);
    setVariationID(prev => [...prev, '']);

    const duplicateNoOfCopies = checkDuplicateVariationNoOfCopies(inventoryVariationsList);
    const attributeMissing = checkForMissingVariation(inventoryVariationsList);

    if (duplicateNoOfCopies) return;
    if (attributeMissing) return;

    const obj = activeAttributesTypesList.map(type => ({
      attrTypeID: parseInt(type.typeID),
      attrTypeValue: '',
    }));
    setInventoryVariationsList(prev => [...prev, obj]);
  };

  const handleRemove = (e, index) => {
    setSizeID(prev => prev.splice(index, 1));
    setColorID(prev => prev.splice(index, 1));
    setPriceID(prev => prev.splice(index, 1));
    setVariationID(prev => prev.splice(index, 1));

    setInventoryVariationsList(prev => prev.filter((_, i) => i !== index));
  };

  const handleError = e => {
    e.target.onError = null;
    setImgError(true);
  };

  const onDropRejected = () => {
    setOpen(true);
    setMessage('File type not accepted. Only image files (jpg,png etc.) are allowed.');
    setMessageState('error');
  };

  return (
    <PageContainer heading={inventoryID === '' ? 'Add Inventory Item' : 'Edit Inventory Item'}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Item Code"
                variant="outlined"
                name="itemCode"
                value={inventoryDetails.itemCode}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Item Name"
                variant="outlined"
                name="inventoryItemName"
                value={inventoryDetails.inventoryItemName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={4}
                label="Item Description"
                variant="outlined"
                name="inventoryItemDescription"
                value={inventoryDetails.inventoryItemDescription}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            {/* <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Inventory Category</InputLabel>
                <Select
                  required
                  name="inventory_category_id"
                  label="Inventory Category"
                  value={inventoryDetails.inventory_category_id}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Select Inventory Category</em>
                  </MenuItem>

                  {category.length > 0 &&
                    category.map(cat => (
                      <MenuItem key={cat.inventory_category_id} value={cat.inventory_category_id}>
                        {cat.inventory_category_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid> */}

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Inventory Status</InputLabel>
                <Select
                  required
                  name="inventoryStatus"
                  label="Inventory Status"
                  value={inventoryDetails.inventoryStatus}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Select Inventory Status</em>
                  </MenuItem>
                  <MenuItem value={1}>In-Stock</MenuItem>
                  <MenuItem value={2}>Out of Stock</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Shipment Type</InputLabel>
                <Select
                  required
                  name="inventoryItemsShipsTypeID"
                  label="Shipment Type"
                  value={inventoryDetails.inventoryItemsShipsTypeID}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Select Shipment Type</em>
                  </MenuItem>

                  {shipment.length > 0 &&
                    shipment.map(ship => (
                      <MenuItem key={ship.shipmentTypeID} value={ship.shipmentTypeID}>
                        {ship.shipmentTypeName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid> */}

            <Grid item sm={12} md={3}>
              <div>
                <Dropzone onDrop={onDrop} accept="image/*" onDropRejected={onDropRejected}>
                  {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                    return (
                      <div>
                        {inventoryDetails.inventoryItemImage ? (
                          inventoryDetails.inventoryItemImage.path ? (
                            <img
                              src={URL.createObjectURL(inventoryDetails.inventoryItemImage)}
                              className={classes.imgClass}
                              alt={inventoryDetails.inventoryItemImage.name}
                            />
                          ) : (
                            <img
                              src={imgError ? '/images/image.png' : inventoryDetails.inventoryItemImage}
                              className={classes.imgClass}
                              alt={inventoryDetails.newsTitle}
                              onError={handleError}
                            />
                          )
                        ) : (
                          <div {...getRootProps()} className={classes.uploadImage}>
                            <input {...getInputProps()} />
                            <AddPhotoAlternateOutlined color="primary" />
                            <p>Upload Image here</p>
                          </div>
                        )}
                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              setInventoryDetails({
                                ...inventoryDetails,
                                inventoryItemImage: '',
                                resetInventoryItemImage: true,
                              })
                            }>
                            {' '}
                            Reset Image
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
              </div>
            </Grid>
            <Grid item xs={1} md={7}></Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Per unit"
                variant="outlined"
                // type="number"
                name="pricePerBox"
                value={inventoryDetails.pricePerBox}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Weight Per Item(Kg)"
                variant="outlined"
                name="weightPerItem"
                value={inventoryDetails.weightPerItem}
                className={classes.text}
                onChange={handleChange}
                type="number"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Items Per Shipment"
                variant="outlined"
                name="inventoryItemsPerShipment"
                value={inventoryDetails.inventoryItemsPerShipment}
                className={classes.text}
                onChange={handleChange}
                type="number"
                helperText={'eg: 10 items per shipment'}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Inventory Type</InputLabel>
                <Select
                  required
                  name="inventoryType"
                  label="Inventory Type"
                  value={inventoryDetails.inventoryType}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Select Inventory Type</em>
                  </MenuItem>
                  <MenuItem value={1}>Dog Dash</MenuItem>
                  <MenuItem value={2}>Blue Wheelers</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Inventory Order"
                variant="outlined"
                type="number"
                name="inventoryOrder"
                value={inventoryDetails.inventoryOrder || ''}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="variationActive"
                    checked={inventoryDetails.variationActive}
                    onChange={handleCheck}
                    value={inventoryDetails.variationActive}
                    color="primary"
                  />
                }
                label="Product variation?"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Collapse in={inventoryDetails.variationActive}>
                {inventoryVariationsList.map((variation, index) => (
                  <Box key={index}>
                    <strong>{index + 1}.</strong>
                    <Grid container spacing={8} className={classes.collapseClass}>
                      <Grid item xs={11} md={11}>
                        <Grid container spacing={4} className={classes.collapseClass}>
                          <ActiveAttributesFields
                            variationAttributesValue={variation}
                            classes={classes}
                            sendVariationDetails={detail => getInventoryVariationDetail(index, detail)}
                            productAttributesList={productAttributesList}
                            activeAttributesTypesList={activeAttributesTypesList}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={1} md={1}>
                        <div
                          style={{
                            height: '100%',
                            paddingTop: '27px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItems: 'end',
                          }}>
                          <div style={{ display: 'flex', gap: '10px' }}>
                            {inventoryVariationsList.length > 1 && (
                              <Tooltip title="Remove">
                                <IconButton
                                  style={{
                                    border: '1px solid',
                                    borderRadius: '5px',
                                    padding: '4px',
                                  }}
                                  onClick={e => handleRemove(e, index)}>
                                  <Close color="error" />
                                </IconButton>
                              </Tooltip>
                            )}

                            {inventoryVariationsList.length === index + 1 && (
                              <Tooltip title="Add">
                                <IconButton
                                  style={{
                                    border: '1px solid',
                                    borderRadius: '5px',
                                    padding: '4px',
                                  }}
                                  onClick={e => handleAdd(e, index)}>
                                  <Add color="primary" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Collapse>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="inventoryItemsActive"
                    checked={inventoryDetails.inventoryItemsActive}
                    onChange={handleCheck}
                    value={inventoryDetails.inventoryItemsActive}
                    color="primary"
                  />
                }
                label="Make Inventory Item Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Box textAlign="right" pt={4}>
                <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={isLoading}>
                  {inventoryID === '' ? 'Add Inventory Item' : 'Edit Inventory Item'}
                </Button>
                {isLoading && (
                  <CircularProgress
                    fontSize={'small'}
                    style={{
                      position: 'relative',
                      top: '13px',
                      right: '-13px',
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddFlyers;
