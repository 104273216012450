import { useEffect, useState } from 'react';
import { httpClient } from 'appUtility/Api';

const useGetActiveAttributesTypesList = () => {
  const [activeAttributesTypesList, setActiveAttributesTypeList] = useState([]);
  const getAttributesTypesList = () => {
    httpClient.get('attribute-type?groupBy=flyers').then(res => {
      const filteredDataByActive = res.data.data.filter(attribute => attribute.typeActive === 1);

      const arrangedData = filteredDataByActive.sort((a, b) => {
        // Custom sorting function to keep price at last
        if (a.typeName.toLowerCase().includes('price') && !b.typeName.toLowerCase().includes('price')) {
          return 1; // a comes before b
        } else if (!a.typeName.toLowerCase().includes('price') && b.typeName.toLowerCase().includes('price')) {
          return -1; // b comes before a
        } else {
          return 0; // order remains unchanged
        }
      });

      setActiveAttributesTypeList(arrangedData);
    });
  };
  useEffect(() => getAttributesTypesList(), []);

  return { activeAttributesTypesList };
};

export default useGetActiveAttributesTypesList;
