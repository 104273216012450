import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { httpClient } from 'appUtility/Api';
import { CircularProgress, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import DialogBox from '../DialogBox.js';
import { Close } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
    // '& .MuiDialog-paperScrollPaper': {
    //   width: '45%',
    // },
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    display: 'flex',
    position: 'relative',
    '& span': {
      fontSize: '22px',
    },
  },
  btnClose: {
    position: 'absolute',
    right: '10px',
    top: '8px',
  },
  body: {
    padding: '25px 40px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  head: {
    marginRight: '10px',
  },
  tail: {
    fontSize: '20px',
  },
  main: {
    padding: '10px 0',
  },
  action: {
    padding: '15px 20px',
    justifyContent: 'space-between',
  },
  close: {
    color: '#fff',
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    width: '100%',
  },
}));

const ViewLeads = props => {
  const classes = useStyles();

  const [leadDetails, setLeadDetails] = useState({
    open: true,
    reflag: false,
    deletion: false,
    leadID: props.leadID,
    customerContacted: false,
    state: [],
    commentText: '',
  });

  const [alertDialog, setAlertDialog] = useState(false);
  const [reflag, setReflag] = useState(false);
  const [deletion, setDeletion] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSingleLead();
  }, []);

  const getSingleLead = () => {
    setLoading(true);
    httpClient.get(`lead/${leadDetails.leadID}`).then(({ data }) => {
      if (data.success === true) {
        setLoading(false);
        setLeadDetails({
          ...leadDetails,
          state: data.data,
        });
      }
    });
  };

  const handleClose = () => {
    setLeadDetails({
      ...leadDetails,
      open: false,
    });
  };

  const handleReflag = () => {
    setAlertDialog(true);
    setReflag(true);
    setDeletion(false);
  };

  const handleDeletion = () => {
    setAlertDialog(true);
    setDeletion(true);
    setReflag(false);
  };

  const handleCustomer = () => {
    setLeadDetails({
      ...leadDetails,
      customerContacted: true,
      open: false,
    });
  };

  const handleCommentText = e => {
    setLeadDetails({
      ...leadDetails,
      commentText: e.target.value,
    });
  };

  const alertData = value => {
    if (value.open === false) {
      setAlertDialog(false);
    }

    if (value.reflag === true && value.success === true) {
      setLeadDetails({
        ...leadDetails,
        open: false,
        reflag: true,
      });
    }

    if (value.deletion === true && value.success === true) {
      setLeadDetails({
        ...leadDetails,
        open: false,
        deletion: true,
      });
    }
  };

  console.log('lead details', leadDetails);

  props.sendData(leadDetails);

  return (
    <div>
      <Dialog open={leadDetails.open} onClose={handleClose} className={classes.root} fullWidth maxWidth="md">
        <DialogTitle className={classes.header}>
          <span>View Lead Detail</span>
          <IconButton className={classes.btnClose} onClick={handleClose}>
            <Close className={classes.close} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.body}>
          {loading ? (
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div className={classes.main}>
                <span className={classes.head}>Name: </span>
                <span className={classes.tail}>
                  {(leadDetails.state.firstName ? leadDetails.state.firstName : '') +
                    ' ' +
                    (leadDetails.state.lastName ? leadDetails.state.lastName : '')}
                </span>
              </div>
              <div className={classes.main}>
                <span className={classes.head}>Added Date: </span>
                <span className={classes.tail}>
                  {leadDetails.state.addedDate
                    ? moment(leadDetails.state.addedDate + ' ' + leadDetails.state.addedTime).format('MMMM DD YYYY, hh:mm a')
                    : '-'}
                </span>
              </div>
              <div className={classes.main}>
                <span className={classes.head}>Address: </span>
                <span className={classes.tail}>{leadDetails.state.address ? leadDetails.state.address : '-'}</span>
              </div>
              <div className={classes.main}>
                <span className={classes.head}>Suburb: </span>
                <span className={classes.tail}>{leadDetails.state.suburb ? leadDetails.state.suburb : '-'}</span>
              </div>

              <div className={classes.main}>
                <span className={classes.head}>Service Area: </span>
                <span className={classes.tail}>{leadDetails.state.service_area ? leadDetails.state.service_area : '-'}</span>
              </div>
              <div className={classes.main}>
                <span className={classes.head}>Post Code: </span>
                <span className={classes.tail}>
                  {leadDetails.state.service_area_postcode ? leadDetails.state.service_area_postcode : '-'}
                </span>
              </div>

              <div className={classes.main}>
                <span className={classes.head}>Assigned to Franchisee: </span>
                <span className={classes.tail}>
                  {(leadDetails.state.assignToFranchisee && leadDetails.state.assignToFranchisee.franchiseeName) == -1
                    ? '-'
                    : leadDetails.state.assignToFranchisee && leadDetails.state.assignToFranchisee.franchiseeName}
                </span>
              </div>

              <div className={classes.main}>
                <span className={classes.head}>Mate Phone Number: </span>
                <span className={classes.tail}>
                  {leadDetails.state.assignToFranchisee && leadDetails.state.assignToFranchisee.franchiseePhone
                    ? leadDetails.state.assignToFranchisee && leadDetails.state.assignToFranchisee.franchiseePhone
                    : '-'}
                </span>
              </div>

              <div className={classes.main}>
                <span className={classes.head}>Website Phone Number: </span>
                <span className={classes.tail}>
                  {(leadDetails.state.assignToFranchisee && leadDetails.state.assignToFranchisee.service_area_phoneNumber) !=
                  null
                    ? leadDetails.state.assignToFranchisee && leadDetails.state.assignToFranchisee.service_area_phoneNumber
                    : '-'}
                </span>
              </div>

              <div className={classes.main}>
                <span className={classes.head}>Customer Phone Number:</span>
                <span className={classes.tail}>{leadDetails.state.phone ? leadDetails.state.phone : '-'}</span>
              </div>

              <div className={classes.main}>
                <span className={classes.head}>Customer Email:</span>
                <span className={classes.tail}>{leadDetails.state.email ? leadDetails.state.email : '-'}</span>
              </div>

              {props.customerNotified && (
                <div className={classes.main}>
                  <span className={classes.head}>Customer Notified : </span>
                  <span className={classes.tail}>{leadDetails.state.customerNotified === 0 ? 'No' : 'Yes'}</span>
                </div>
              )}

              <div className={classes.main}>
                <span className={classes.head}>Customer Notes</span>
                <div className={classes.tail}>{leadDetails.state.notes ? leadDetails.state.notes : '-'}</div>
              </div>

              {props.HoCompleted && (
                <div className={classes.main}>
                  <span className={classes.head}>Comments</span>
                  <div className={classes.tail}>
                    {leadDetails.state.forwardToHeadOfficeComment ? leadDetails.state.forwardToHeadOfficeComment : '-'}
                  </div>
                </div>
              )}
              {props.hoForwarded && (
                <div className={classes.main}>
                  <span className={classes.head}>Make Comment</span>
                  <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    placeholder="Notes"
                    name="commentText"
                    value={leadDetails.commentText}
                    onChange={handleCommentText}
                    style={{ width: '100%' }}
                  />
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button onClick={handleReflag} color="primary">
            Re-Flag to Copy
          </Button>
          {props.hoForwarded && (
            <Button onClick={handleCustomer} color="primary">
              Customer Contacted
            </Button>
          )}
          <Button onClick={handleDeletion} color="secondary">
            Flag for Deletion
          </Button>
        </DialogActions>
      </Dialog>

      {alertDialog && <DialogBox alertData={alertData} reflag={reflag} deletion={deletion} />}
    </div>
  );
};

export default ViewLeads;
