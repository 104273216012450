import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableGroupComponent from './../TableGroupComponent';

const columns = [
  { id: 'groupName', label: 'Group Name' },
  { id: 'groupDescription', label: 'Group Description' },
  { id: 'active', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

const ListGroups = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    serviceGroupActive: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    serviceGroupActive: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (filterData.serviceGroupActive === '' && filterData.search === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.serviceGroupActive === ' ') filterData.serviceGroupActive = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let serviceGroupStorage = JSON.parse(localStorage.getItem('serviceGroup_filter'));
    serviceGroupStorage !== null && setFilterData(serviceGroupStorage);

    serviceGroupStorage == null
      ? getAllGroups()
      : serviceGroupStorage.serviceGroupActive == '' &&
        serviceGroupStorage.search == '' &&
        serviceGroupStorage.removed == false
      ? getAllGroups()
      : console.log('group');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      serviceGroupActive: filterData.serviceGroupActive,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('serviceGroup_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.serviceGroupActive || filterData.search) {
      httpClient
        .get(`service-group/filter?q=${filterData.search}&serviceGroupActive=${filterData.serviceGroupActive}`)
        .then(({ data }) => {
          if (data.success === true) {
            setGroups(data.data);
            // setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            // setPage(data.meta.current_page);
            // setFrom(data.meta.from);
            // setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllGroups();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleEdit = id => {
    history.push(`/services/addGroups/${id}`);
  };

  const getAllGroups = () => {
    setLoading(true);
    httpClient.get('service-group').then(({ data }) => {
      if (data.success === true) {
        setGroups(data.data);
        setLoading(false);
      } else {
      }
    });
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getAllGroups();
  // };

  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`service-group/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setGroups(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="List Groups">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableGroupComponent
              columns={columns}
              selectedProducts={groups}
              title="List Groups"
              handleEdit={handleEdit}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
            />
          </Box>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default ListGroups;
