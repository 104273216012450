import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ReactiveDialog = props => {
  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleReactive = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: true,
    });
  };

  props.sendTo(alertData);

  return (
    <Dialog open={alertData.open} onClose={handleClose}>
      <DialogTitle>Re-Activate Customer</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to re-activate selected customer?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleReactive} color="secondary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReactiveDialog;
