import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableCategoryComponent from './../TableCategoryComponent';

const columns = [
  { id: 'forumCategoryName', label: 'Category Name' },
  { id: 'forumCategoryAddedDate', label: 'Added Date' },
  { id: 'forumCaegoryLastUpdate', label: 'Last Updated Date' },
  { id: 'forumCategoryActive', label: 'Status' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    // name: '',
    // email: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    // name: '',
    // email: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      // filterData.name === '' && filterData.email === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    // if (filterData.name === ' ') filterData.name = '';
    // if (filterData.email === ' ') filterData.email = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let forumCats = JSON.parse(localStorage.getItem('forum_category_filters'));
    forumCats !== null && setFilterData(forumCats);

    (forumCats == null || forumCats.search == '') && getForumCategory();
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      // name: filterData.name,
      // email: filterData.email,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('forum_category_filters', JSON.stringify(filterData));
    if (
      // filterData.name || filterData.email ||
      filterData.search
    ) {
      httpClient.get(`forum-category/filter?q=${filterData.search}&pagination=${rowsPerPage}`).then(({ data }) => {
        if (data.success === true) {
          setCategories(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(data.meta.per_page);
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      });
    } else {
      getForumCategory();
    }
  };

  const handleFilter = e => {
    e.preventDefault();

    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`forum-category?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setCategories(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(`forum-category/filter?q=${filterData.search}&pagination=${rowsPerPage}&page=${page}`)
          .then(({ data }) => {
            if (data.success === true) {
              setCategories(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`forum-category?&direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setCategories(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(`forum-category/filter?q=${filterData.search}&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setCategories(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`forum-category?&direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setCategories(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const getForumCategory = () => {
    setLoading(true);
    httpClient.get(`forum-category?pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setCategories(data.data);
        setTotal(data.meta.total);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleEdit = id => {
    history.push(`/forum/addCategories/${id}`);
  };

  return (
    <PageContainer>
      <TableCategoryComponent
        columns={columns}
        selectedProducts={categories}
        title={'List Forum Categories'}
        handleEdit={handleEdit}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        fromTable={from}
        toTable={to}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        currentColumn={currentColumn}
        direction={direction}
      />
    </PageContainer>
  );
};

export default ListCategories;
