import React, { useState, useEffect } from 'react';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, InputLabel, TextField } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { httpClient } from 'appUtility/Api';
import { Autocomplete } from '@material-ui/lab';

const WeeklySales = props => {
  const classes = useStyles();

  const [filter, setFilter] = useState(false);
  var [memberName, setMemberName] = useState('');
  const [company, setCompany] = useState([]);
  const [serviceGroup, setServiceGroup] = useState([]);

  function checkUrlType(url) {
    const urlObject = new URL(url);

    // Extract the hostname
    const hostname = urlObject.hostname;

    // Use a regular expression to check for 'admin' or 'backadmin'
    if (/^admin\./.test(hostname)) {
      return 'admin';
    } else if (/^backadmin\./.test(hostname)) {
      return 'backadmin';
    } else {
      return 'none';
    }
  }

  const currentUrl = window.location.href;
  const urlType = checkUrlType(currentUrl);
  const isAdminUrl = urlType === 'admin';

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  const getCompany = () => {
    httpClient.get(`member/all`).then(({ data }) => {
      if (data) {
        const companyData = data.data;
        setCompany(companyData);
      } else {
      }
    });
  };

  company &&
    company.map(com => {
      if (com.memberID === props.submittedData.memberID) {
        memberName = com.firstName + ' ' + com.lastName;
      }
    });

  useEffect(() => {
    getCompany();
  }, []);

  const getServiceGroup = () => {
    httpClient.get('/service-group').then(({ data }) => {
      if (data) {
        setServiceGroup(data.data);
      } else {
      }
    });
  };

  useEffect(() => {
    getServiceGroup();
  }, []);

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          <a
            download="service_user_report.csv"
            className={classes.exportButton}
            href={`https://${
              isAdminUrl ? 'admin' : 'backadmin'
            }.franchise.care/php/api/app/1.0/service-group/report/export?startDate=${
              props.filterData.currStartDate
            }&endDate=${props.filterData.currEndDate}&memberID=${props.filterData.memberID}&serviceGroupID=${
              props.filterData.groupID
            }`}
            target="_blank"
            rel="noreferrer">
            + Export
          </a>
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                  <DatePicker
                    inputVariant="outlined"
                    variant="inline"
                    label="Start Date"
                    name="startDate"
                    value={props.filterData.startDate ? props.filterData.startDate : null}
                    onChange={props.handleStartDate}
                    className={classes.dateField}
                    format="DD-MM-YYYY"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <DatePicker
                    inputVariant="outlined"
                    variant="inline"
                    label="End Date"
                    name="endDate"
                    value={props.filterData.endDate ? props.filterData.endDate : null}
                    onChange={props.handleEndDate}
                    format="DD-MM-YYYY"
                    className={classes.dateField}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Franchisee Name</InputLabel>
                  <Autocomplete
                    disablePortal
                    id="member_id"
                    options={company}
                    onChange={(event, newValue) => {
                      props.handleChangeMember(newValue);
                    }}
                    inputValue={props.filterData.memberName}
                    getOptionLabel={option => option.firstName + ' ' + option.lastName}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={props.handleChange}
                        value={props.filterData.memberName}
                        variant="outlined"
                        name="memberID"
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Service Group</InputLabel>
                  <Autocomplete
                    disablePortal
                    id="group_id"
                    options={serviceGroup}
                    onChange={(event, newValue) => {
                      props.handleChangeServiceGroup(newValue);
                    }}
                    inputValue={props.filterData.groupName}
                    getOptionLabel={option => option.GroupName}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={props.handleChange}
                        value={props.filterData.groupName}
                        variant="outlined"
                        name="groupID"
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.startDate ||
        props.submittedData.endDate ||
        props.submittedData.memberID ||
        props.submittedData.groupID ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.startDate && (
              <p>
                Start Date: <span>{moment(props.submittedData.startDate).format('Do MMMM , YYYY')} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('startDate')} />
              </p>
            )}

            {props.submittedData.endDate && (
              <p>
                End Date:<span> {moment(props.submittedData.endDate).format('Do MMMM , YYYY')} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('endDate')} />
              </p>
            )}
            {props.submittedData.memberID && (
              <p>
                Franchisee Name:<span> {memberName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('memberID')} />
              </p>
            )}
            {props.submittedData.groupID && (
              <p>
                Service Group:<span> {props.submittedData.groupName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('groupID')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
