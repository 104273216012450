import React from 'react';

let GlobalURL;

const loginData = JSON.parse(localStorage.getItem('login'));

let LocalGlobalURL = [
  {
    url: 'https://backadmin.franchise.care/php/api/app/1.0/',
    // videourl: 'http://staging.franchise.care/uploaded/videofile/',
    // paypalid: 'sb',
    // paypaltype: 'sandbox',
    version: '1.0',
  },
];

let AdminLocalGlobalURL = [
  {
    url: 'https://backadmin.franchise.care/php/api/member/app/1.0/',
    // videourl: 'http://staging.franchise.care/uploaded/videofile/',
    // paypalid: 'sb',
    // paypaltype: 'sandbox',
    version: '1.0',
  },
];

let LiveGlobalURL = [
  {
    url: 'https://admin.franchise.care/php/api/app/1.0/',
    // videourl: 'https://tfg.franchise.care/uploaded/videofile/',
    // paypalid: 'AZ1XjgwM4m-dg7L4OlQMf6jOdzq1H_6IDKLBVf7mh7VqIgJPsYSIKN03iC5LO2XtfSoLUac9NF5R0x8v',
    // paypaltype: 'live',
    version: '1.0',
  },
];

let AdminLiveGlobalURL = [
  {
    url: 'https://admin.franchise.care/php/api/member/app/1.0/',
    // videourl: 'https://tfg.franchise.care/uploaded/videofile/',
    // paypalid: 'AZ1XjgwM4m-dg7L4OlQMf6jOdzq1H_6IDKLBVf7mh7VqIgJPsYSIKN03iC5LO2XtfSoLUac9NF5R0x8v',
    // paypaltype: 'live',
    version: '1.0',
  },
];

let StagingGlobalURL = [
  {
    url: 'https://backadmin.franchise.care/php/api/app/1.0/',
    // videourl: 'http://staging.franchise.care/uploaded/videofile/',
    // paypalid: 'sb',
    // paypaltype: 'sandbox',
    version: '1.0',
  },
];

let AdminStagingGlobalURL = [
  {
    url: 'https://backadmin.franchise.care/php/api/member/app/1.0',
    // videourl: 'http://staging.franchise.care/uploaded/videofile/',
    // paypalid: 'sb',
    // paypaltype: 'sandbox',
    version: '1.0',
  },
];

export default GlobalURL =
  window.location.hostname === 'localhost'
    ? loginData && loginData.user.SuperAdmin === '0'
      ? AdminLocalGlobalURL
      : LocalGlobalURL
    : window.location.hostname === 'backadmin.franchise.care'
    ? loginData && loginData.user.SuperAdmin === '0'
      ? AdminStagingGlobalURL
      : StagingGlobalURL
    : loginData && loginData.user.SuperAdmin === '0'
    ? AdminLiveGlobalURL
    : LiveGlobalURL;
