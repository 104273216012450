import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import Dashboard from './Dashboard';
import IncomeUserReport from './IncomeUserReport';
import IpadLog from './IpadLogs';
import LoginLog from './LoginLog';
import ServiceGroupReport from './ServiceGroupReport';
import ServiceReport from './ServiceReport';

const Reports = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/dashboard`} />
        <Route path={`${requestedUrl}/dashboard`} component={Dashboard} />
        <Route path={`${requestedUrl}/loginlog`} component={LoginLog} />
        <Route path={`${requestedUrl}/ipadlog`} component={IpadLog} />
        <Route path={`${requestedUrl}/incomeReport`} component={IncomeUserReport} />
        <Route path={`${requestedUrl}/serviceGroupReport`} component={ServiceGroupReport} />
        <Route path={`${requestedUrl}/serviceReport`} component={ServiceReport} />
        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Reports;
