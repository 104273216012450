import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddDocument from './AddDocument';
import AddDocumentType from './AddDocumenType';
import ListDocument from './ListDocument';
import ListDocumentType from './ListDocumentType';

const Documents = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listDocumentType`} />
        <Route path={`${requestedUrl}/listDocumentType`} component={ListDocumentType} />
        <Route path={`${requestedUrl}/addDocumentType`} component={AddDocumentType} />
        <Route path={`${requestedUrl}/listDocuments`} component={ListDocument} />
        <Route path={`${requestedUrl}/addDocument`} component={AddDocument} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Documents;
