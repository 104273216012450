import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Button,
  Card,
  makeStyles,
  TextField,
  Grid,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { httpClient } from 'appUtility/Api';
import { history } from 'redux/store';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddPaymentType = props => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [paymentID, setPaymentID] = useState(props && props.location.pathname.slice(28));
  const [members, setMembers] = useState([]);

  const [paymentDetails, setPaymentDetails] = useState({
    companyID: '',
    paymentType: '',
    displayPosition: '',
    paymentActive: false,
  });

  useEffect(() => {
    getSinglePayment();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    paymentID === ''
      ? httpClient
          .post(
            'payment/type',
            qs.stringify({
              companyID: paymentDetails.companyID,
              paymentType: paymentDetails.paymentType,
              displayPosition: paymentDetails.displayPosition,
              paymentActive: paymentDetails.paymentActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setPaymentDetails({
                companyID: '',
                paymentType: '',
                displayPosition: '',
                paymentActive: false,
              });
            } else {
              setOpen(true);
              setMessage('failed to add payment type');
              setMessageState('error');
            }
          })
      : httpClient
          .put(
            `payment/type/${paymentID}`,
            qs.stringify({
              companyID: paymentDetails.companyID,
              paymentType: paymentDetails.paymentType,
              displayPosition: paymentDetails.displayPosition,
              paymentActive: paymentDetails.paymentActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              history.push('/paymentType/listPaymentType');
            } else {
              setOpen(true);
              setMessage('failed to edit payment type');
              setMessageState('error');
            }
          });
  };
  const handleChange = e => {
    const { name, value } = e.target;
    setPaymentDetails({
      ...paymentDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setPaymentDetails({
      ...paymentDetails,
      [name]: checked,
    });
  };

  const getSinglePayment = () => {
    paymentID &&
      httpClient.get(`payment/type/${paymentID}`).then(({ data }) => {
        if (data.success === true) {
          setPaymentDetails({
            companyID: data.data.companyID,
            paymentType: data.data.paymentType,
            displayPosition: data.data.displayPosition,
            paymentActive: data.data.paymentActive === 1 ? true : false,
          });
        }
      });
  };

  const handleBack = () => {
    history.push('/paymentType/listPaymentType');
  };

  return (
    <PageContainer heading="Add Payment Type">
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Payment Type"
                variant="outlined"
                name="paymentType"
                type="text"
                value={paymentDetails.paymentType}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Display Position"
                variant="outlined"
                name="displayPosition"
                type="number"
                value={paymentDetails.displayPosition}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="paymentActive"
                    checked={paymentDetails.paymentActive}
                    onChange={handleCheck}
                    value={paymentDetails.paymentActive}
                    color="primary"
                  />
                }
                label="Active Payment Type?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {paymentID === '' ? 'Add Income' : 'Edit Income'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddPaymentType;
