import {
  Card,
  Grid,
  makeStyles,
  TextField,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';

import { Close } from '@material-ui/icons';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
    width: '50%',
    margin: '0 auto',
  },
  textField: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '25px',
  },
  formControl: {
    width: '100%',
  },
  resetBtn: {
    marginTop: '10px',
    float: 'right',
  },
  closeBtn: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  content: {
    padding: '35px',
  },
}));

const ResetPassword = props => {
  const classes = useStyles();
  const [memberID, setMemberID] = useState(props && props.passwordID);
  const [resetDetails, setResetDetails] = useState({
    password: '',
    RePassword: '',
    memberName: '',
    open: true,
    loading: false,
    submit: false,
  });

  const [response, setResponse] = useState({
    open: false,
    message: '',
    messageState: '',
  });

  const handleClose = () => {
    setResetDetails({
      ...resetDetails,
      open: false,
    });
  };

  useEffect(() => {
    getSingleMember();
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setResetDetails({
      ...resetDetails,
      [name]: value,
    });
  };

  const getSingleMember = () => {
    memberID &&
      httpClient.get(`/member/${memberID}`).then(({ data }) => {
        if (data.success === true) {
          setResetDetails({
            ...resetDetails,
            memberName: data.data.firstName + ' ' + data.data.lastName,
          });
        }
      });
  };

  const handleReset = () => {
    setResetDetails({
      ...resetDetails,
      loading: true,
    });
    httpClient
      .post(
        'member/reset-password',
        qs.stringify({
          memberID: memberID,
          password: resetDetails.password,
          RePassword: resetDetails.RePassword,
        }),
      )
      .then(({ data }) => {
        if (data.success === true) {
          // setOpen(true);
          // setMessage(data.message);
          // setMessageState('success');
          setResponse({
            open: true,
            message: data.message,
            messageState: 'success',
          });
          setResetDetails({
            ...resetDetails,
            loading: false,
            open: false,
            submit: true,
          });

          // history.push('/members/listMembers');
        } else {
          // setOpen(true);
          // setMessage('Password Changed Failed');
          // setMessageState('error');
          setResponse({
            open: true,
            message: 'Password Change Failed',
            messageState: 'error',
          });
          setResetDetails({
            ...resetDetails,
            loading: false,
            open: true,
            submit: true,
          });
        }
      });
  };

  props.sendResponse(response);
  props.sendData(resetDetails);

  return (
    <Dialog open={resetDetails.open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <h2>Reset Password</h2>
      </DialogTitle>
      <IconButton onClick={handleClose} className={classes.closeBtn}>
        <Close />
      </IconButton>
      <DialogContent className={classes.content}>
        <Grid container spacing={4}>
          <form className={classes.formControl}>
            <Grid item sm={12} md={12}>
              <InputLabel> Member Name</InputLabel>
              <TextField
                // label="Member Name"
                variant="outlined"
                name="memberName"
                value={resetDetails.memberName}
                className={classes.textField}
                onChange={handleChange}
                disabled
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <InputLabel> Password</InputLabel>

              <TextField
                // label="Password"
                error={resetDetails.password.length > 5 || resetDetails.password.length === 0 ? false : true}
                helperText={
                  resetDetails.password.length > 5 || resetDetails.password.length === 0
                    ? ''
                    : 'Password must contain more than 6 letters'
                }
                variant="outlined"
                name="password"
                type="password"
                value={resetDetails.password}
                className={classes.textField}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <InputLabel>Re-Type Password</InputLabel>

              <TextField
                // label="Re-Type Password"
                error={
                  resetDetails.password === resetDetails.RePassword || resetDetails.RePassword.length === 0 ? false : true
                }
                helperText={
                  resetDetails.password === resetDetails.RePassword || resetDetails.RePassword.length === 0
                    ? ''
                    : 'Password does not match!'
                }
                variant="outlined"
                name="RePassword"
                type="password"
                value={resetDetails.RePassword}
                className={classes.textField}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  className={classes.resetBtn}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleReset}
                  disabled={
                    resetDetails.password === resetDetails.RePassword && resetDetails.RePassword.length > 5 ? false : true
                  }>
                  Reset Password
                </Button>
                {resetDetails.loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
              </div>
            </Grid>
          </form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPassword;
