import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableComponent from '../TableComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertDialogCancel from '../AlertDialogCancel';

const columns = [
  { id: 'orderedDate', label: 'Order Date' },
  { id: 'inventoryOrderID', label: 'Order #' },
  { id: 'paymentOptionChoosed', label: 'Order By' },
  { id: 'items', label: '# of Items' },
  { id: 'ItemsQty', label: 'Items Qty' },
  { id: 'onlinePaid', label: 'Order Paid' },
  { id: 'OrderAmount', label: 'Order Amount' },
  { id: 'orderStatus', label: 'Order Status' },
  { id: 'ordertype', label: 'Order Type' },
  { id: 'shippedDate', label: 'Shipped' },
  { id: 'actions', label: 'Actions' },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListUndeliveredOrders = () => {
  const [orders, setOrders] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [orderID, setOrderID] = useState('');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    memberID: '',
    memberName: '',
    ItemNumber: '',
    onlinePaid: '',
    shipped: '',
    orderStatus: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    memberID: '',
    memberName: '',
    ItemNumber: '',
    onlinePaid: '',
    shipped: '',
    orderStatus: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.memberID === '' &&
      filterData.memberName === '' &&
      filterData.ItemNumber === '' &&
      filterData.onlinePaid === '' &&
      filterData.shipped === '' &&
      filterData.orderStatus === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.memberID === ' ') filterData.memberID = '';
    if (filterData.memberName === ' ') filterData.memberName = '';
    if (filterData.ItemNumber === ' ') filterData.ItemNumber = '';
    if (filterData.onlinePaid === ' ') filterData.onlinePaid = '';
    if (filterData.shipped === ' ') filterData.shipped = '';
    if (filterData.orderStatus === ' ') filterData.orderStatus = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    // getOrders();
    let undeliveredStorage = JSON.parse(localStorage.getItem('undeliveredOrder_filter'));
    undeliveredStorage !== null && setFilterData(undeliveredStorage);

    undeliveredStorage == null
      ? getOrders()
      : undeliveredStorage.memberID == '' &&
        undeliveredStorage.memberName == '' &&
        undeliveredStorage.ItemNumber == '' &&
        undeliveredStorage.onlinePaid == '' &&
        undeliveredStorage.shipped == '' &&
        undeliveredStorage.orderStatus == '' &&
        undeliveredStorage.search == '' &&
        undeliveredStorage.removed == false
      ? getOrders()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      memberID: filterData.memberID,
      memberName: filterData.memberName,
      ItemNumber: filterData.ItemNumber,
      onlinePaid: filterData.onlinePaid,
      shipped: filterData.shipped,
      orderStatus: filterData.orderStatus,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('undeliveredOrder_filter', JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.memberID ||
      filterData.memberName ||
      filterData.ItemNumber ||
      filterData.onlinePaid ||
      filterData.shipped ||
      filterData.orderStatus ||
      filterData.search
    ) {
      httpClient
        .get(
          `order/undelivered/filter?q=${filterData.search}&orderStatus=${filterData.orderStatus}&shipped=${filterData.shipped}&onlinePaid=${filterData.onlinePaid}&memberID=${filterData.memberID}&ItemNumber=${filterData.ItemNumber}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setOrders(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getOrders();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'memberID') {
      filterData.memberName = value;
    }
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleChangeMember = value => {
    setFilterData({
      ...filterData,
      memberID: value !== null ? value.memberID : '',
      memberName: value !== null ? value.firstName + ' ' + value.lastName : '',
    });
  };

  const handleRemove = data => {
    if (data === 'memberID') {
      filterData.memberID = '';
      filterData.memberName = '';
    }
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const getOrders = () => {
    setLoading(true);
    httpClient.get(`order/undelivered?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setOrders(data.data);
        setTotal(data.meta.total);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`order/undelivered?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setOrders(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `order/undelivered/filter?q=${filterData.search}&orderStatus=${filterData.orderStatus}&shipped=${filterData.shipped}&onlinePaid=${filterData.onlinePaid}&memberID=${filterData.memberID}&ItemNumber=${filterData.ItemNumber}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOrders(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`order/undelivered?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setOrders(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(
            `order/undelivered/filter?q=${filterData.search}&orderStatus=${filterData.orderStatus}&shipped=${
              filterData.shipped
            }&onlinePaid=${filterData.onlinePaid}&memberID=${filterData.memberID}&ItemNumber=${
              filterData.ItemNumber
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setOrders(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          })
      : httpClient.get(`order/undelivered?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setOrders(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setPage(data.meta.current_page);
            setLoading(false);
          }
        });
  };

  const handleEdit = val => {
    history.push(`/orders/addOrder/${val.memID}`);
  };

  const handleCancel = val => {
    setOrderID(val.memID);
    setCancelDialogOpen(true);
  };

  const sendCancelData = callback => {
    if (callback.open === false) {
      setCancelDialogOpen(false);
    }
    if (callback.success === true) {
      httpClient.put(`order/cancel/${orderID}`).then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage('Marked Order as cancelled');
          setMessageState('success');
          getOrders();
        } else {
          setOpen(true);
          setMessage('Failed to cancel the order');
          setMessageState('error');
        }
      });
    }
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getOrders();
  // };

  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`order/past/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setOrders(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="Undelivered Orders">
      <TableComponent
        columns={columns}
        selectedProducts={orders}
        title={'List Undelivered Orders'}
        past={true}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        loading={loading}
        handleEdit={handleEdit}
        handleCancel={handleCancel}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        fromTable={from}
        toTable={to}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleChangeMember={handleChangeMember}
        handleRemove={handleRemove}
        direction={direction}
        currentColumn={currentColumn}
      />

      {cancelDialogOpen && <AlertDialogCancel sendCancelData={sendCancelData} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListUndeliveredOrders;
