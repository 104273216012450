import React, { useEffect, useState } from 'react';
import { Button, Collapse, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Close from '@material-ui/icons/Close';
import { DatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { httpClient } from 'appUtility/Api';

const Filter = ({
  classes,
  filter,
  handleFilter,
  filterData,
  handleStartDate,
  handleEndDate,
  setFilter,
  submittedData,
  handleChangeService,
  handleChange,
  handleRemove,
}) => {
  const [services, setServices] = useState([]);

  const getServices = () => {
    httpClient.get(`service/filter?pagination=1000`).then(({ data }) => {
      if (data.success) {
        setServices(data.data);
      }
    });
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <>
      {filter && (
        <Collapse in={filter} className={classes.filterBox}>
          <form onSubmit={handleFilter}>
            <Grid container spacing={4}>
              <Grid item sm={12} md={6}>
                <DatePicker
                  inputVariant="outlined"
                  variant="inline"
                  label="Start Date"
                  name="startDate"
                  value={filterData.startDate ? filterData.startDate : null}
                  onChange={handleStartDate}
                  className={classes.dateField}
                  format="DD-MM-YYYY"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <DatePicker
                  inputVariant="outlined"
                  variant="inline"
                  label="End Date"
                  name="endDate"
                  value={filterData.endDate ? filterData.endDate : null}
                  onChange={handleEndDate}
                  format="DD-MM-YYYY"
                  className={classes.dateField}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel className={classes.inputLabel}>Services</InputLabel>
                <Autocomplete
                  disablePortal
                  id="serviceID"
                  options={services}
                  onChange={(event, newValue) => {
                    handleChangeService(newValue);
                  }}
                  inputValue={filterData.serviceName}
                  getOptionLabel={option => option.serviceName}
                  renderInput={params => (
                    <TextField
                      {...params}
                      onChange={handleChange}
                      value={filterData.memberName}
                      variant="outlined"
                      placeholder="Services"
                      name="serviceID"
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel className={classes.inputLabel}>Service Active</InputLabel>
                <FormControl variant="outlined" className={classes.text}>
                  <Select
                    name="serviceActive"
                    value={filterData.serviceActive}
                    onChange={handleChange}
                    className={classes.text}>
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item sm={12}>
                <Box textAlign="right">
                  <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                    Cancel
                  </Button>
                  <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                    Filter Data
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Collapse>
      )}
      {submittedData.startDate || submittedData.endDate || submittedData.serviceID || submittedData.serviceActive ? (
        <Box className={classes.productView}>
          <p>Filtered: </p>
          {submittedData.startDate && (
            <p>
              Start Date: <span>{moment(submittedData.startDate).format('Do MMMM , YYYY')} </span>
              <Close fontSize="small" onClick={() => handleRemove('startDate')} />
            </p>
          )}
          {submittedData.endDate && (
            <p>
              End Date:<span> {moment(submittedData.endDate).format('Do MMMM , YYYY')} </span>
              <Close fontSize="small" onClick={() => handleRemove('endDate')} />
            </p>
          )}
          {submittedData.serviceID && (
            <p>
              Service:<span> {submittedData.serviceName} </span>
              <Close fontSize="small" onClick={() => handleRemove('serviceID')} />
            </p>
          )}
          {submittedData.serviceActive && (
            <p>
              Service Active: <span>{submittedData.serviceActive === '1' ? 'Yes' : 'No'} </span>
              <Close fontSize="small" onClick={() => handleRemove('serviceActive')} />
            </p>
          )}
        </Box>
      ) : (
        <Box className={classes.productView} />
      )}
    </>
  );
};

export default Filter;
