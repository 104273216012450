import { makeStyles, Card, Grid, TextField, FormControlLabel, Checkbox, Box, Button } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles({
  text: {
    width: '100%',
    margin: '10px',
  },
  root: {
    padding: '40px',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddReason = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [reasonID, setReasonID] = useState(props && props.location.pathname.slice(29));

  const [reasonDetails, setReasonDetails] = useState({
    reason: '',
    status: false,
  });

  useEffect(() => {
    getSingleReason();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setReasonDetails({
      ...reasonDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setReasonDetails({
      ...reasonDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    reasonID === ''
      ? httpClient
          .post(
            'suspend-lead',
            qs.stringify({
              reason: reasonDetails.reason,
              status: reasonDetails.status ? '1' : '0',
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setReasonDetails({
                reason: '',
                status: false,
              });
              setTimeout(() => {
                history.push('/leadSuspendReason/leadsReason');
              }, 1500);
            } else {
              setOpen(true);
              setMessage('failed to add suspend reason!');
              setMessageState('error');
            }
          })
      : httpClient
          .post(
            `suspend-lead/${reasonID}`,
            qs.stringify({
              reason: reasonDetails.reason,
              status: reasonDetails.status ? '1' : '0',
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                history.push('/leadSuspendReason/leadsReason');
              }, 1500);
            } else {
              setOpen(true);
              setMessage('failed to edit suspend reason!');
              setMessageState('error');
            }
          });
  };

  const getSingleReason = () => {
    reasonID &&
      httpClient.get(`/suspend-lead/${reasonID}`).then(({ data }) => {
        if (data.success === true) {
          setReasonDetails({
            reason: data.data.reason,
            status: data.data.status == 'Active' ? true : false,
          });
        }
      });
  };

  const handleBack = () => {
    history.push('/leadSuspendReason/leadsReason');
  };

  return (
    <PageContainer heading={reasonID === '' ? 'Add Suspend Reason' : 'Edit Suspend Reason'}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Reason"
                variant="outlined"
                name="reason"
                value={reasonDetails.reason}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="status"
                    checked={reasonDetails.status}
                    onChange={handleCheck}
                    value={reasonDetails.status}
                    color="primary"
                  />
                }
                label="Active ?"
              />
            </Grid>
            <Grid item sm={12}>
              <Box textAlign="right" pt={4}>
                <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  {reasonID === '' ? 'Add Reason' : 'Edit Reason'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddReason;
