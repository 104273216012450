import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    position: 'relative',
    '& .Cmt-root-avatar-group': {
      position: 'relative',
      marginLeft: -4,
    },
  },
  exportButton: {
    marginRight: '10px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '4px',
    fontSize: '13px',
    padding: '6px 16px',
    border: 'none',
    fontWeight: '500',
    lineHeight: '1.75',
    borderRadius: '4px',
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.8),
    },
  },
  text: {
    width: '100%',
  },
  dateField: {
    width: '100%',
  },
  inputLabel: {
    fontSize: '12px',
    color: '#222',
    marginBottom: '5px',
  },
  filterBox: {
    marginBottom: '30px',
    // paddingBottom:"15px",
  },
  tableHead: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '10px',
  },
  flexSearch: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  searchIcon: {
    position: 'relative',
    left: '-30px',
  },
  searchForm: {
    padding: '9px',
    borderRadius: '20px',
    outline: 'none',
    width: '300px',
    border: '1px solid #999',
  },
  subTitleRoot: {
    fontSize: 12,
    color: theme.palette.text.disabled,
    marginBottom: 0,
    marginTop: 4,
  },
  productView: {
    backgroundColor: fade(theme.palette.common.dark, 0.04),
    padding: '8px 24px',
    marginLeft: -24,
    marginRight: -24,
    display: 'flex',
    alignItems: 'center',
    marginTop: -10,
    flexWrap: 'wrap',
    flexDirection: 'row',
    '& p': {
      paddingRight: '10px',
      fontWeight: 900,
      display: 'flex',
      padding: '5px',
    },
    '& span': {
      fontWeight: 300,
      padding: '0 5px',
    },
    '& .MuiSvgIcon-root': {
      borderRadius: '50%',
      padding: '4px',
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      marginRight: '5px',
      cursor: 'pointer',
    },
  },
  updateProductListScrollbar: {
    height: 240,
    padding: '8px 24px 8px 8px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  listItemTitle: {
    marginLeft: 8,
  },
  collapseRoot: {
    color: theme.palette.text.primary,
    '& g.recharts-layer': {
      fill: theme.palette.text.primary,
    },
  },
}));

export default useStyles;
