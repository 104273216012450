import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import TableAttribute from './../TableAttribute';
import { history } from 'redux/store';

const columns = [
  { id: 'attributeName', label: 'Attribute Name' },
  { id: 'attributeType', label: 'Attribute Type' },
  { id: 'attributeActive', label: 'Active' },
  { id: 'Actions', label: 'Actions' },
];

const ProductAttribute = () => {
  const [attribute, setAttribute] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  //   const [submittedData, setSubmittedData] = useState({
  //     submit: false,
  //     orderPickupFee: '',
  //     minOrderQty: '',
  //   });
  //   const [filterData, setFilterData] = useState({
  //     orderPickupFee: '',
  //     minOrderQty: '',
  //     removed: false,
  //   });

  //   useEffect(() => {
  //     if (filterData.orderPickupFee === '' && filterData.minOrderQty === '') {
  //       setSubmittedData({
  //         ...submittedData,
  //         submit: false,
  //       });
  //     }
  //     if (filterData.orderPickupFee === ' ') filterData.orderPickupFee = '';
  //     if (filterData.minOrderQty === ' ') filterData.minOrderQty = '';

  //     filterData.removed === true && handleSubmitData();
  //   }, [filterData]);

  //   useEffect(() => {
  //     let inventoryCategoryStorage = JSON.parse(localStorage.getItem('inventoryCategory_filter'));
  //     inventoryCategoryStorage !== null && setFilterData(inventoryCategoryStorage);

  //     inventoryCategoryStorage == null
  //       ? getProductAttributes()
  //       : inventoryCategoryStorage.minOrderQty == '' &&
  //         inventoryCategoryStorage.orderPickupFee == '' &&
  //         inventoryCategoryStorage.removed == false
  //       ? getProductAttributes()
  //       : console.log('');
  //   }, []);

  useEffect(() => {
    getProductAttributes();
  }, []);

  const getProductAttributes = () => {
    setLoading(true);
    httpClient.get('product-attribute?groupBy=uniform').then(({ data }) => {
      if (data.success === true) {
        setAttribute(data.data);
        setLoading(false);
      }
    });
  };

  //   const handleSubmitData = () => {
  //     setSubmittedData({
  //       minOrderQty: filterData.minOrderQty,
  //       orderPickupFee: filterData.orderPickupFee,
  //       submit: true,
  //     });
  //     filterData.removed = true;
  //     localStorage.setItem('inventoryCategory_filter', JSON.stringify(filterData));
  //     setLoading(true);
  //     if (filterData.minOrderQty || filterData.orderPickupFee) {
  //       httpClient
  //         .get(`inventory/category/filter?&minOrderQty=${filterData.minOrderQty}&orderPickupFee=${filterData.orderPickupFee}`)
  //         .then(({ data }) => {
  //           if (data.success === true) {
  //             setAttribute(data.data);
  //             setLoading(false);
  //           }
  //         });
  //     } else {
  //       getProductAttributes();
  //     }
  //   };

  //   const handleFilter = e => {
  //     e.preventDefault();
  //     handleSubmitData();
  //   };

  //   const handleChange = e => {
  //     const { name, value } = e.target;
  //     setFilterData({
  //       ...filterData,
  //       [name]: value,
  //       removed: false,
  //     });
  //   };

  //   const handleRemove = data => {
  //     setFilterData({
  //       ...filterData,
  //       [data]: '',
  //       removed: true,
  //     });
  //     setSubmittedData({
  //       ...submittedData,
  //       [data]: '',
  //     });
  //   };

  const handleEdit = id => {
    history.push(`/uniform/addProductAttribute/${id}`);
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getProductAttributes();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`inventory/category/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setAttribute(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="List Inventory Attribute">
      <TableAttribute
        columns={columns}
        selectedProducts={attribute}
        title={'Product Attribute'}
        handleEdit={handleEdit}
        // handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        loading={loading}
        // handleFilter={handleFilter}
        // filterData={filterData}
        // submittedData={submittedData}
        // handleChange={handleChange}
        // handleRemove={handleRemove}
      />
    </PageContainer>
  );
};

export default ProductAttribute;
