import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddPosts = props => {
  const classes = useStyles();

  const [postID, setPostID] = useState(props && props.location.pathname.slice(16));
  const [forumGroup, setForumGroup] = useState([]);
  const [forumCategory, setForumCategory] = useState([]);
  const [forumTopic, setForumTopic] = useState([]);
  const [members, setMembers] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [postDetails, setPostDetails] = useState({
    forumCategoryID: '',
    forumGroupID: '',
    forumTopicID: '',
    memberID: '',
    postMessage: '',
    postedAs: '',
    postActive: false,
    postDate: moment(),
    memberName: '',
  });

  useEffect(() => {
    getSinglePost();
    getForumGroup();
    getMembers();
    getForumCategory();
    getForumTopic();
  }, []);

  useEffect(() => {
    members.length > 0 &&
      members.map(mem => {
        if (mem.memberID == postDetails.memberID) {
          postDetails.postedAs = mem.firstName + ' ' + mem.lastName;
        }
      });
  }, [members, postDetails.memberID]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'memberID') {
      postDetails.postedAs = value;
    }
    setPostDetails({
      ...postDetails,
      [name]: value,
    });
  };

  const handleChangeMembers = value => {
    setPostDetails({
      ...postDetails,
      memberID: value !== null ? value.memberID : '',
      postedAs: value !== null ? value.firstName + ' ' + value.lastName : '',
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setPostDetails({
      ...postDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    postID == ''
      ? httpClient
          .post(
            'forum-post',
            qs.stringify({
              forumCategoryID: postDetails.forumCategoryID,
              forumGroupID: postDetails.forumGroupID,
              forumTopicID: postDetails.forumTopicID,
              memberID: postDetails.memberID,
              postMessage: postDetails.postMessage,
              postActive: postDetails.postActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setPostDetails({
                forumCategoryID: '',
                forumGroupID: '',
                forumTopicID: '',
                memberID: '',
                postMessage: '',
                postActive: false,
              });
            } else {
              setOpen(true);
              setMessage('Forum Post Failed to be added');
              setMessageState('error');
            }
          })
      : httpClient
          .post(
            `forum-post/status/${postID}`,
            qs.stringify({
              postActive: postDetails.postActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setPostDetails({
                forumCategoryID: '',
                forumGroupID: '',
                forumTopicID: '',
                memberID: '',
                postMessage: '',
                postActive: false,
              });
              setTimeout(() => {
                history.push('/forum/listPosts');
              }, 1000);
            } else {
              setOpen(true);
              setMessage('Forum Post Failed to be updated');
              setMessageState('error');
            }
          });
  };

  const getSinglePost = () => {
    postID &&
      httpClient.get(`/forum-post/${postID}`).then(({ data }) => {
        setPostDetails({
          memberName: data.data.member.memberName,
          postDate: moment(data.data.postDate).format('MMMM DD YYYY, hh:mm a'),
          postMessage: data.data.postMessage,
          postActive: data.data.postActive == 'Active' ? true : false,
        });
      });
  };

  const getForumGroup = () => {
    postID == '' &&
      httpClient.get(`/forum-group`).then(({ data }) => {
        if (data) {
          setForumGroup(data.data);
        }
      });
  };

  const getForumCategory = () => {
    postID == '' &&
      httpClient.get(`/forum-category`).then(({ data }) => {
        if (data) {
          setForumCategory(data.data);
        }
      });
  };

  const getForumTopic = () => {
    postID == '' &&
      httpClient.get(`/forum-topic`).then(({ data }) => {
        if (data) {
          setForumTopic(data.data);
        }
      });
  };

  const getMembers = () => {
    postID == '' &&
      httpClient.get(`/member/all`).then(({ data }) => {
        if (data) {
          setMembers(data.data);
        }
      });
  };

  const handleBack = () => {
    history.push('/forum/listPosts');
  };

  return (
    <PageContainer heading={postID === '' ? 'Add Forum Post' : 'Edit Post Status'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            {postID == '' && (
              <Grid item sm={12} md={12}>
                <FormControl variant="outlined" className={classes.text}>
                  <InputLabel>Category *</InputLabel>
                  <Select
                    required
                    name="forumCategoryID"
                    label="Category"
                    value={postDetails.forumCategoryID}
                    onChange={handleChange}
                    className={classes.text}>
                    <MenuItem value="">Select Forum Category</MenuItem>
                    {forumCategory &&
                      forumCategory.map(cat => (
                        <MenuItem key={cat.forumCategoryID} value={cat.forumCategoryID}>
                          {cat.forumCategoryName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {postID == '' && (
              <Grid item sm={12} md={12}>
                <FormControl variant="outlined" className={classes.text}>
                  <InputLabel>Topic *</InputLabel>
                  <Select
                    required
                    name="forumTopicID"
                    label="Topic"
                    value={postDetails.forumTopicID}
                    onChange={handleChange}
                    className={classes.text}>
                    <MenuItem value="">Select Forum Topic</MenuItem>
                    {forumTopic &&
                      forumTopic.map(topic => (
                        <MenuItem key={topic.topicID} value={topic.topicID}>
                          {topic.topicName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {postID == '' && (
              <Grid item sm={12} md={12}>
                <FormControl variant="outlined" className={classes.text}>
                  <InputLabel>Group *</InputLabel>
                  <Select
                    required
                    name="forumGroupID"
                    label="Group"
                    value={postDetails.forumGroupID}
                    onChange={handleChange}
                    className={classes.text}>
                    <MenuItem value="">Select Forum Group</MenuItem>
                    {forumGroup &&
                      forumGroup.map(group => (
                        <MenuItem key={group.forumGroupID} value={group.forumGroupID}>
                          {group.forumGroupName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {postID == '' && (
              <Grid item sm={12} md={12}>
                <Autocomplete
                  disablePortal
                  id="post_as"
                  options={members}
                  onChange={(event, newValue) => {
                    handleChangeMembers(newValue);
                  }}
                  inputValue={postDetails.postedAs}
                  // sx={{ width: 300 }}
                  getOptionLabel={option => option.firstName + ' ' + option.lastName}
                  renderInput={params => (
                    <TextField
                      {...params}
                      onChange={handleChange}
                      value={postDetails.postedAs}
                      variant="outlined"
                      name="memberID"
                      label="Posted As"
                    />
                  )}
                />

                {/* <FormControl variant="outlined" className={classes.text}>
                  <InputLabel>Post As *</InputLabel>
                  <Select
                    required
                    name="memberID"
                    label="Post As"
                    value={postDetails.memberID}
                    onChange={handleChange}
                    className={classes.text}>
                    <MenuItem value="">Select Forum Group</MenuItem>
                    {members &&
                      members.map(mem => (
                        <MenuItem key={mem.memberID} value={mem.memberID}>
                          {mem.firstName + ' ' + mem.lastName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              </Grid>
            )}

            {postID && (
              <Grid item sm={12} md={12}>
                <TextField
                  label="Member Name"
                  variant="outlined"
                  name="memberName"
                  type="text"
                  value={postDetails.memberName}
                  className={classes.text}
                  onChange={handleChange}
                  disabled={true}
                />
              </Grid>
            )}
            {postID && (
              <Grid item sm={12} md={12}>
                <TextField
                  label="Post Date"
                  variant="outlined"
                  name="postDate"
                  type="text"
                  value={postDetails.postDate}
                  className={classes.text}
                  onChange={handleChange}
                  disabled={true}
                />
              </Grid>
            )}
            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={4}
                label="Post Message"
                variant="outlined"
                name="postMessage"
                type="text"
                value={postDetails.postMessage}
                className={classes.text}
                onChange={handleChange}
                disabled={postID ? true : false}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="postActive"
                    checked={postDetails.postActive}
                    onChange={handleCheck}
                    value={postDetails.postActive}
                    color="primary"
                  />
                }
                label="Make Forum Post Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                {postID === '' ? 'Add Forum Post' : 'Update Status'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddPosts;
