import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertSocialActive = props => {
  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleDeactivate = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: true,
    });
  };

  props.sendDeactivate(alertData);

  return (
    <Dialog open={alertData.open} onClose={handleClose}>
      <DialogTitle>
        {props.activeStatus == 'Deactivate'
          ? `Deactive ${props.comment ? 'Comment' : 'Post'}`
          : `Activate ${props.comment ? 'Comment' : 'Post'}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.activeStatus == 'Deactivate'
            ? `Are you sure, you want to De-Activate this ${props.comment ? 'comment' : 'post'}?`
            : `Are you sure you want to Activate the ${props.comment ? 'comment' : 'post'}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleDeactivate} color="secondary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertSocialActive;
