import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import AlertSocialActive from '../AlertSocialActivate';
import SocialCommentDialog from '../SocialCommentDialog';
import TableCommentComponent from '../TableCommentComponent';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const columns = [
  { id: 'commentText', label: 'Title' },
  { id: 'commentedByUserName', label: 'Commented By' },
  { id: 'socialPostSubComment', label: 'Sub Comments' },
  { id: 'commentActive', label: 'Active' },
  { id: 'Actions', label: 'Actions' },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ManageSocialComment = () => {
  const [socialComments, setSocialComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [showPostDialog, setShowPostDialog] = useState(false);
  const [rowDetails, setRowDetails] = useState('');

  const [alertActiveDialog, setAlertActiveDialog] = useState(false);
  const [selectedID, setSelectedID] = useState('');
  const [activeStatus, setActiveStatus] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    socialGroupName: '',
    isSocialGroupPublic: '',
    addedBy: '',
    active: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    socialGroupName: '',
    isSocialGroupPublic: '',
    addedBy: '',
    active: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.socialGroupName === '' &&
      filterData.isSocialGroupPublic === '' &&
      filterData.addedBy === '' &&
      filterData.active === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.socialGroupName === ' ') filterData.socialGroupName = '';
    if (filterData.isSocialGroupPublic === ' ') filterData.isSocialGroupPublic = '';
    if (filterData.addedBy === ' ') filterData.addedBy = '';
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let social_post_comment = JSON.parse(localStorage.getItem('social_post_comment'));
    social_post_comment !== null && setFilterData(social_post_comment);

    social_post_comment == null
      ? getSocial()
      : social_post_comment.socialGroupName == '' &&
        social_post_comment.isSocialGroupPublic == '' &&
        social_post_comment.addedBy == '' &&
        social_post_comment.active == '' &&
        social_post_comment.search == '' &&
        social_post_comment.removed == false
      ? getSocial()
      : console.log('social_post_comment');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      socialGroupName: filterData.socialGroupName,
      isSocialGroupPublic: filterData.isSocialGroupPublic,
      addedBy: filterData.addedBy,
      active: filterData.active,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('social_post_comment', JSON.stringify(filterData));
    if (
      filterData.socialGroupName ||
      filterData.isSocialGroupPublic ||
      filterData.addedBy ||
      filterData.active ||
      filterData.search
    ) {
      httpClient
        .get(
          `social-post-comment/filter?q=${filterData.search}&socialGroupName=${filterData.socialGroupName}&isSocialGroupPublic=${filterData.isSocialGroupPublic}&createdByUserID=${filterData.addedBy}&socialGroupActive=${filterData.active}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setSocialComments(data.data);
            setTotal(data.meta.total);
            setPage(data.meta.current_page);
            setRowsPerPage(data.meta.per_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getSocial();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // useEffect(() => {
  //   getSocial();
  // }, []);

  const getSocial = () => {
    setLoading(true);
    httpClient.get(`social-post-comment?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setSocialComments(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(data.meta.per_page);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`social-post-comment?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setSocialComments(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `social-post-comment/filter?q=${filterData.search}&socialGroupName=${filterData.socialGroupName}&isSocialGroupPublic=${filterData.isSocialGroupPublic}&createdByUserID=${filterData.addedBy}&socialGroupActive=${filterData.active}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setSocialComments(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`social-post-comment?&direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setSocialComments(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `social-post-comment/filter?q=${filterData.search}&socialGroupName=${
              filterData.socialGroupName
            }&isSocialGroupPublic=${filterData.isSocialGroupPublic}&createdByUserID=${
              filterData.addedBy
            }&socialGroupActive=${filterData.active}&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setSocialComments(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`social-post-comment?sort_by=companyID&direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setSocialComments(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  //   const handleEdit = id => {
  //     history.push(`/new-forum/addSocialGroup/${id}`);
  //   };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getSocial();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`social-post-comment/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setSocialComments(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  const viewDetails = data => {
    setShowPostDialog(true);
    setRowDetails(data.memID);
  };

  const sendBackPost = callBack => {
    console.log('success', callBack);
    if (callBack.success === true && callBack.open === false) {
      setShowPostDialog(false);
      setRowDetails('');
      handleSubmitData();
    } else if (callBack.open === false) {
      setShowPostDialog(false);
      setRowDetails('');
    }
  };

  const handleActivate = val => {
    setAlertActiveDialog(true);
    setSelectedID(val.memID.postID);
    setActiveStatus('Activate');
  };

  const handleDeactivate = val => {
    setAlertActiveDialog(true);
    setSelectedID(val.memID.postID);
    setActiveStatus('Deactivate');
  };

  const sendDeactivate = callback => {
    callback.open == false && setAlertActiveDialog(false);
    if (callback.success === true) {
      httpClient
        .get(
          `social-post-comment/activate-deactivate/${selectedID}`,
          // qs.stringify({
          //   postActive: activeStatus === 'Activate' ? 1 : 0,
          // }),
        )
        .then(({ data }) => {
          if (data.success === true) {
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            setOpen(true);
            setMessage(data.message);
            setMessageState('error');
          }
        });
    }
  };

  return (
    <PageContainer heading="Manage Social Comments">
      <TableCommentComponent
        columns={columns}
        selectedProducts={socialComments}
        title={'Social Comments'}
        viewDetails={viewDetails}
        handleActivate={handleActivate}
        handleDeactivate={handleDeactivate}
        // handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        fromTable={from}
        toTable={to}
        direction={direction}
        currentColumn={currentColumn}
      />

      {alertActiveDialog && <AlertSocialActive comment={true} sendDeactivate={sendDeactivate} activeStatus={activeStatus} />}

      {showPostDialog && <SocialCommentDialog rowDetails={rowDetails} sendBackPost={sendBackPost} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ManageSocialComment;
