import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import ApplyDiscountDialog from '../ApplyDiscountDialog';
// import { indexOf } from '@amcharts/amcharts4/.internal/core/utils/Array';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  text: {
    margin: '10px 0',
    width: '100%',
  },
  form: {
    width: '100%',
  },
  flexInput: {
    display: 'flex',
    alignItems: 'center',
  },
  flexAmount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shippingPart: {
    borderTop: '1px solid #f1f1f1',
    marginTop: '20px',
    paddingTop: '10px',
  },
  priceSmall: {
    color: '#777',
    fontSize: '12px',
    marginLeft: '10px',
  },
  accordion: {
    padding: '20px',
  },
  accordionMargin: {
    marginTop: '20px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddOrder = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [loading, setLoading] = useState(false);

  const [shipLoading, setShipLoading] = useState(false);

  const [members, setMembers] = useState([]);
  const [company, setCompany] = useState([]);
  const [orderID, setOrderID] = useState(props && props.location.pathname.slice(17));

  const [shipping, setShipping] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [uniformAttribute, setUniformAttribute] = useState([]);
  const [flyersAttribute, setFlyersAttribute] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [mappedOrderType, setMappedOrderType] = useState([]);
  const [variationCost, setVariationCost] = useState([]);

  const [orderDetails, setOrderDetails] = useState({
    companyID: '',
    companyName: '',
    memberID: '',
    memberName: '',
    // orderEditable: '',
    surchargeAmount: 0,
    pickUpFee: 0,
    shippingCost: 0,
    orderedDate: '',
    onlinePaid: false,
    orderReference: '',
    orderStatus: '',
    shippedDate: '',
    // orderCompletedDate: '',
    shippingCompanyID: '',
    TrackingCode: '',
    // deliveryDate: '',
    inventoryItemsID: [],
    inventoryCategories: [],
    orderedQuantity: [],
    orderedItemRate: [],
    orderedVariation: {
      size: [],
      numofcopies: [],
    },
    orderType: [],
    itemTotal: [],
    totalWeight: 0,
    delivered: false,
    discount: false,
    discountAmount: '',
    discountReason: '',
  });

  const [orderQuantityChanged, setOrderQuantityChanged] = useState(false);

  const [openDiscountModal, setOpenDiscountModal] = useState(false);

  const [unique, setUnique] = useState({
    uniqueInventoryID: [],
    uniqueOrderRate: [],
    uniqueTotal: [],
    match: [],
    shippingQuantity: [],
  });

  useEffect(() => {
    getAllCompany();
    // getAllMember();
    getShipping();
    getInventoryUseage();
    getSingleOrder();
  }, []);

  // useEffect(() => {
  //   uniqueDatas();
  // }, [orderDetails]);

  // useEffect(() => {
  //   company.length > 0 &&
  //     company.map(com => {
  //       if (com.companyID == orderDetails.companyID) {
  //         orderDetails.companyName = (com.tradingName !== null ? com.tradingName : '');
  //       }
  //     });
  //   members.length > 0 &&
  //     members.map(mem => {
  //       if (mem.memberID == orderDetails.memberID) {
  //         orderDetails.memberName = mem.firstName + ' ' + mem.lastName;
  //       }
  //     });
  // }, [members, company]);

  useEffect(() => {
    let weight = [];
    inventoryData.length > 0 &&
      inventoryData.map((inventory, i) => {
        inventory.inventory.map(invent => {
          orderDetails.orderedQuantity.map((order, i) => {
            if (i === invent.inventoryItemsID) {
              weight.push(order * invent.weightPerItem);
            }
          });
        });
      });

    let totalKg = weight.length > 0 && weight.reduce((a, b) => a + b, 0);

    orderDetails.totalWeight = totalKg;

    setOrderDetails({
      ...orderDetails,
      totalWeight: orderDetails.totalWeight,
    });
  }, [inventoryData.length > 0, orderDetails.totalWeight]);

  const handleBack = () => {
    // history.push('/orders/listRecentOrders');
    history.goBack();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'shippingCompanyID') {
      orderDetails.orderStatus = 5;
    }
    if (name === 'memberID') {
      orderDetails.memberName = value;
    }
    if (name === 'companyID') {
      orderDetails.companyName = value;
    }
    setOrderDetails({
      ...orderDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    setOrderDetails({
      ...orderDetails,
      orderType: mappedOrderType,
    });
  }, [mappedOrderType]);

  const handleChangeCompany = value => {
    setOrderDetails({
      ...orderDetails,
      companyID: value !== null ? value.companyID : '',
      companyName: value !== null ? value.tradingName : '',
      memberName: '',
      memberID: '',
    });
    if (value !== null) {
      setMembers(value.member);
    }
  };

  const handleChangeMember = value => {
    setOrderDetails({
      ...orderDetails,
      memberID: value !== null ? value.memberID : '',
      memberName: value !== null ? value.firstName + ' ' + value.lastName : '',
    });
  };

  const handleChangeVariation = (e, id) => {
    const { name } = e.target;

    const value = JSON.parse(e.target.value);
    const modifiedValue = { ...value };
    delete modifiedValue.price;

    variationCost[id] = value.price;

    if (name === 'variationUniform') {
      if (!value) {
        delete orderDetails.orderedVariation.size[id];
        delete orderDetails.orderedItemRate[id];
        delete orderDetails.itemTotal[id];

        setOrderDetails({
          ...orderDetails,
          orderedVariation: orderDetails.orderedVariation,
          orderedItemRate: orderDetails.orderedItemRate,
          itemTotal: orderDetails.itemTotal,
        });
      } else {
        orderDetails.orderedVariation.size[id] = modifiedValue;
        orderDetails.orderedItemRate[id] = Number(value.price);
        orderDetails.itemTotal[id] = orderDetails.orderedItemRate[id] * orderDetails.orderedQuantity[id];

        setOrderDetails({
          ...orderDetails,
          orderedVariation: orderDetails.orderedVariation,
          orderedItemRate: orderDetails.orderedItemRate,
          itemTotal: orderDetails.itemTotal,
        });
      }
    }

    if (name === 'variationFlyers') {
      if (!value) {
        delete orderDetails.orderedVariation.numofcopies[id];
        delete orderDetails.orderedItemRate[id];
        delete orderDetails.itemTotal[id];

        setOrderDetails({
          ...orderDetails,
          orderedVariation: orderDetails.orderedVariation,
          orderedItemRate: orderDetails.orderedItemRate,
          itemTotal: orderDetails.itemTotal,
        });
      } else {
        orderDetails.orderedVariation.numofcopies[id] = modifiedValue;
        orderDetails.orderedItemRate[id] = Number(value.price);
        orderDetails.itemTotal[id] = orderDetails.orderedItemRate[id] * orderDetails.orderedQuantity[id];

        setOrderDetails({
          ...orderDetails,
          orderedVariation: orderDetails.orderedVariation,
          orderedItemRate: orderDetails.orderedItemRate,
          itemTotal: orderDetails.itemTotal,
        });
      }
    }
  };

  const categoryMapping = {
    1: 'Shampoo',
    2: 'Treats',
    5: 'Uniform',
    6: 'Flyers',
  };

  useEffect(() => {
    inventoryData.length > 0 &&
      inventoryData.map(inventory => {
        inventory.inventory.map(inventoryItem => {
          orderDetails.inventoryItemsID
            .filter(value => value !== null)
            .map(itemId => {
              if (itemId === inventoryItem.inventoryItemsID && !orderTypes.includes(inventory.inventory_category_id)) {
                setOrderTypes(prev => [...prev, inventory.inventory_category_id]);
              }
            });
        });
      });
  }, [orderDetails.inventoryItemsID]);

  useEffect(() => {
    const mapped = orderTypes.map(orderType => categoryMapping[orderType]);
    setMappedOrderType(mapped);
  }, [orderTypes]);

  const handleCheck = e => {
    const { name, checked } = e.target;
    setOrderDetails({
      ...orderDetails,
      [name]: checked,
    });
  };

  const handleOrderedQuantity = (e, id, index) => {
    const { name, value } = e.target;

    orderDetails.orderedQuantity[id] = Number(value);
    unique.shippingQuantity[index] = value;

    if (orderDetails.orderedQuantity[id] === '' || orderDetails.orderedQuantity[id] === 0) {
      delete orderDetails.orderedQuantity[id];
      setOrderDetails({
        ...orderDetails,
        orderedQuantity: orderDetails.orderedQuantity,
      });
    }

    setOrderDetails({
      ...orderDetails,
      orderedQuantity: orderDetails.orderedQuantity,
    });
    setUnique({
      ...unique,
      shippingQuantity: unique.shippingQuantity,
    });

    setOrderQuantityChanged(true);

    inventoryData.map((inventory, i) => {
      inventory.inventory.map(invent => {
        if (id === invent.inventoryItemsID) {
          orderDetails.itemTotal[id] = orderDetails.itemTotal[id] =
            orderDetails.orderedItemRate[id] * orderDetails.orderedQuantity[id] ||
            invent.pricePerBox * orderDetails.orderedQuantity[id];

          orderDetails.inventoryItemsID[id] = invent.inventoryItemsID;
          orderDetails.orderedItemRate[id] = orderDetails.orderedItemRate[id] || invent.pricePerBox;

          setOrderDetails({
            ...orderDetails,
            inventoryItemsID: orderDetails.inventoryItemsID,
            orderedItemRate: orderDetails.orderedItemRate,
            itemTotal: orderDetails.itemTotal,
            // totalWeight: orderDetails.totalWeight + (invent.weightPerItem * orderDetails.orderedQuantity[id]),
          });
        }
      });
    });

    // filtering inventoryItems ID
    if (!orderDetails.orderedQuantity[id]) {
      // orderDetails.orderedQuantity.splice(id, 1);
      delete orderDetails.itemTotal[id];
      delete orderDetails.inventoryItemsID[id];
      delete orderDetails.orderedItemRate[id];

      let filterItemsID = orderDetails.inventoryItemsID.filter(item => item !== id);

      // filtering Items
      var filterOrderRate = [];
      var filterItem = [];

      inventoryData.map((inventory, i) => {
        inventory.inventory.map(invent => {
          if (id === invent.inventoryItemsID) {
            var match = '';
            filterOrderRate = orderDetails.orderedItemRate.filter(item => item !== invent.pricePerBox);
            orderDetails.orderedItemRate.map(item => item === invent.pricePerBox && (match = item));
            // orderDetails.totalWeight = orderDetails.totalWeight - (invent.weightPerItem * (orderDetails.orderedQuantity[invent.inventoryItemsID]));

            orderDetails.itemTotal.map(item => {
              if (Math.floor(item % match) !== 0) {
                return filterItem.push(item);
              }
            });
            return filterOrderRate, filterItem;
          }
          return filterOrderRate, filterItem;
        });
      });

      // filtering Items

      setOrderDetails({
        ...orderDetails,
        orderedItemRate: orderDetails.orderedItemRate,
        inventoryItemsID: orderDetails.inventoryItemsID,
        itemTotal: orderDetails.itemTotal,
      });
    }
    // filtering inventoryItems ID
  };

  useEffect(() => {
    if (orderQuantityChanged) {
      setOrderDetails({
        ...orderDetails,
        orderedQuantity: orderDetails.orderedQuantity,
      });
    }
  }, [orderQuantityChanged]);

  const uniqueDatas = () => {
    //get unique data
    unique.uniqueInventoryID = orderDetails.inventoryItemsID.filter((item, i, ar) => {
      return ar.indexOf(item) === i;
    });
    unique.uniqueOrderRate = orderDetails.orderedItemRate.filter((item, i, ar) => {
      return ar.indexOf(item) === i;
    });

    orderDetails.inventoryItemsID = unique.uniqueInventoryID;
    orderDetails.orderedItemRate = unique.uniqueOrderRate;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const isMissingItem = item => {
    const hasVariation = item.variations.length > 0;
    const hasSelectedVariation =
      !!orderDetails.orderedVariation.size[item.inventoryItemsID] ||
      !!orderDetails.orderedVariation.numofcopies[item.inventoryItemsID];
    const hasQuantity = !!orderDetails.orderedQuantity[item.inventoryItemsID];

    if (hasVariation) {
      return (hasSelectedVariation && !hasQuantity) || (!hasSelectedVariation && hasQuantity);
    } else {
      return false;
    }
  };

  const missingItems = inventoryData
    .filter(
      inventory =>
        orderDetails.inventoryCategories && orderDetails.inventoryCategories.includes(inventory.inventory_category_id),
    )
    .map(inventory =>
      inventory.inventory.filter(inventoryItem => inventoryItem.inventoryItemsActive !== 0).filter(isMissingItem),
    );

  const modifiedInventoryItemsID = orderDetails.inventoryItemsID.filter(value => value !== null);
  const modifiedOrderedItemRate = orderDetails.orderedItemRate.filter(value => value !== null);
  const modifiedItemTotal = orderDetails.itemTotal.filter(value => value !== null);

  const getAllErrorMessages = errorObject => {
    const errorMessages = [];
    for (const field in errorObject) {
      if (errorObject.hasOwnProperty(field)) {
        // Concatenate all messages of the field to errorMessages
        errorMessages.push(...errorObject[field]);
      }
    }

    return errorMessages;
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    if (Object.keys(orderDetails.orderedQuantity).length < 1) {
      setOpen(true);
      setMessage(`Please select at least one item.`);
      setMessageState('error');
      setLoading(false);
      return;
    }

    // Handle case when order quantity or variation is missing for the selected item.
    if (missingItems[0].length > 0) {
      missingItems[0].forEach(item => {
        if (
          !(
            orderDetails.orderedVariation.size[item.inventoryItemsID] ||
            orderDetails.orderedVariation.numofcopies[item.inventoryItemsID]
          )
        ) {
          setOpen(true);
          setMessage(
            `${orderDetails.orderType.join(',') === 'Uniform' ? 'Size' : 'No of Copies'} is missing for ${
              item.inventoryItemName
            }`,
          );
          setMessageState('error');
          setLoading(false);
          return;
        }
        if (!orderDetails.orderedQuantity[item.inventoryItemsID]) {
          setOpen(true);
          setMessage(`Order quantity is missing for ${item.inventoryItemName}`);
          setMessageState('error');
          setLoading(false);
          return;
        }
      });
    } else {
      if (!orderID) {
        httpClient
          .post(
            'order',
            qs.stringify({
              companyID: orderDetails.companyID,
              memberID: orderDetails.memberID,
              // orderEditable: orderDetails.orderEditable,
              surchargeAmount: orderDetails.surchargeAmount,
              pickUpFee: orderDetails.pickUpFee,
              shippingCost: orderDetails.shippingCost,
              orderedDate: new Date(),
              onlinePaid: orderDetails.onlinePaid === true ? 1 : 0,
              orderReference: orderDetails.orderReference,
              orderStatus: orderDetails.orderStatus,
              shippedDate: orderDetails.shippedDate,
              // orderCompletedDate: orderDetails.orderCompletedDate,
              shippingCompanyID: orderDetails.shippingCompanyID,
              TrackingCode: orderDetails.TrackingCode,
              // deliveryDate: orderDetails.deliveryDate,
              inventoryItemsID: modifiedInventoryItemsID,
              orderedQuantity: orderDetails.orderedQuantity,
              orderedItemRate: modifiedOrderedItemRate,
              itemTotal: modifiedItemTotal,
              orderDeliver: orderDetails.delivered == true ? '1' : '0',
              orderedVariation: orderDetails.orderedVariation,
              orderType: mappedOrderType.length > 0 ? mappedOrderType.join(',') : mappedOrderType,
              discount: orderDetails.discount === true ? '1' : '0',
              discountAmount: orderDetails.discountAmount,
              discountReason: orderDetails.discountReason,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                window.location = '/orders/listRecentOrders';
              }, 1000);
              setTimeout(() => {
                setLoading(false);
              }, 1500);
              // setOrderDetails({
              //   companyID: '',
              //   memberID: '',
              //   // orderEditable: '',
              //   surchargeAmount: 0,
              //   pickUpFee: 0,
              //   shippingCost: 0,
              //   totalWeight: 0,
              //   orderedDate: '',
              //   onlinePaid: false,
              //   orderReference: '',
              //   orderStatus: '',
              //   shippedDate: '',
              //   // orderCompletedDate: '',
              //   shippingCompanyID: '',
              //   TrackingCode: '',
              //   // deliveryDate: '',
              //   inventoryItemsID: [],
              //   orderedQuantity: [],
              //   orderedItemRate: [],
              //   itemTotal: [],
              //   delivered: false,
              //   discount: false,
              //   discountAmount: '',
              //   discountReason: '',
              // });
            } else {
              setLoading(false);
              setOpen(true);
              !data.error ? setMessage(getAllErrorMessages(data.message)) : setMessage(getAllErrorMessages(data.error));
              setMessageState('error');
            }
          });
      } else {
        httpClient
          .put(
            `order/${orderID}`,
            qs.stringify({
              companyID: orderDetails.companyID,
              memberID: orderDetails.memberID,
              // orderEditable: orderDetails.orderEditable,
              surchargeAmount: orderDetails.surchargeAmount,
              pickUpFee: orderDetails.pickUpFee,
              shippingCost: orderDetails.shippingCost,
              orderedDate: orderDetails.orderedDate,
              onlinePaid: orderDetails.onlinePaid === true ? 1 : 0,
              orderReference: orderDetails.orderReference,
              orderStatus: orderDetails.orderStatus,
              shippedDate: orderDetails.shippedDate,
              // orderCompletedDate: orderDetails.orderCompletedDate,
              shippingCompanyID: orderDetails.shippingCompanyID,
              TrackingCode: orderDetails.TrackingCode,
              // deliveryDate: orderDetails.deliveryDate,
              inventoryItemsID: modifiedInventoryItemsID,
              orderedQuantity: orderDetails.orderedQuantity,
              orderedItemRate: modifiedOrderedItemRate,
              itemTotal: modifiedItemTotal,
              orderDeliver: orderDetails.delivered == true ? '1' : '0',
              orderedVariation: orderDetails.orderedVariation,
              orderType: mappedOrderType.length > 0 ? mappedOrderType.join(',') : mappedOrderType,
              discount: orderDetails.discountAmount ? '1' : '0',
              discountAmount: orderDetails.discountAmount,
              discountReasion: orderDetails.discountReason,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                window.location = '/orders/listRecentOrders';
              }, 1000);
              setTimeout(() => {
                setLoading(false);
              }, 1500);
              // setOrderDetails({
              //   companyID: '',
              //   memberID: '',
              //   // orderEditable: '',
              //   surchargeAmount: 0,
              //   pickUpFee: 0,
              //   shippingCost: 0,
              //   totalWeight: 0,
              //   orderedDate: '',
              //   onlinePaid: false,
              //   orderReference: '',
              //   orderStatus: '',
              //   shippedDate: '',
              //   // orderCompletedDate: '',
              //   shippingCompanyID: '',
              //   TrackingCode: '',
              //   // deliveryDate: '',
              //   inventoryItemsID: [],
              //   orderedQuantity: [],
              //   orderedItemRate: [],
              //   itemTotal: [],
              //   delivered: false,
              //   discount: false,
              //   discountAmount: '',
              //   discountReason: '',
              // });
            } else {
              setLoading(false);
              setOpen(true);
              !data.error ? setMessage(getAllErrorMessages(data.message)) : setMessage(getAllErrorMessages(data.error));
              setMessageState('error');
            }
          });
      }
    }
  };

  const getAllMember = () => {
    httpClient.get('member/all').then(({ data }) => {
      if (data.success === true) {
        setMembers(data.data);
      }
    });
  };

  const getAllCompany = () => {
    httpClient.get('member-company/all').then(({ data }) => {
      if (data.success === true) {
        setCompany(data.data);
      }
    });
  };

  const getShipping = () => {
    httpClient.get('shipping/company').then(({ data }) => {
      if (data.success === true) {
        setShipping(data.data);
      }
    });
  };

  const getInventoryUseage = () => {
    httpClient.get('inventory/category').then(({ data }) => {
      setInventoryData(data.data);
    });
  };

  const handleCalculate = () => {
    setShipLoading(true);

    if (Object.keys(orderDetails.orderedQuantity).length < 1) {
      setOpen(true);
      setMessage(`Please select at least one item.`);
      setMessageState('error');
      setShipLoading(false);
      return;
    }

    let weight = [];
    inventoryData &&
      inventoryData.map((inventory, i) => {
        inventory.inventory.map(invent => {
          orderDetails.orderedQuantity.map((order, i) => {
            if (i === invent.inventoryItemsID) {
              weight.push(order * invent.weightPerItem);
            }
          });
        });
      });

    let totalKg = weight && weight.length > 0 && weight.reduce((a, b) => a + b, 0);

    // Handle case when order quantity or variation is missing for the selected item.
    if (missingItems[0].length > 0) {
      missingItems[0].forEach(item => {
        if (
          !(
            orderDetails.orderedVariation.size[item.inventoryItemsID] ||
            orderDetails.orderedVariation.numofcopies[item.inventoryItemsID]
          )
        ) {
          setOpen(true);
          setMessage(
            `${orderDetails.orderType.join(',') === 'Uniform' ? 'Size' : 'No of Copies'} is missing for ${
              item.inventoryItemName
            }`,
          );
          setMessageState('error');
          setShipLoading(false);
          return;
        }
        if (!orderDetails.orderedQuantity[item.inventoryItemsID]) {
          setOpen(true);
          setMessage(`Order quantity is missing for ${item.inventoryItemName}`);
          setMessageState('error');
          setShipLoading(false);
          return;
        }
      });
    } else {
      if (orderDetails.memberID) {
        httpClient
          .post(
            `/calculate/shipping`,
            qs.stringify({
              memberID: orderDetails.memberID,
              orderedQuantity: totalKg,
              orderType: orderDetails.orderType.join(','),
            }),
          )
          .then(({ data }) => {
            if (data) {
              setOrderDetails({
                ...orderDetails,
                shippingCost:
                  orderDetails.orderType.join(',') === 'Uniform' || orderDetails.orderType.join(',') === 'Flyers'
                    ? 15.4
                    : data,
                totalWeight: totalKg,
              });
              setTimeout(() => {
                setShipLoading(false);
              }, 500);
            } else {
              setShipLoading(false);
            }
          });
      } else {
        setOpen(true);
        setMessage('Please choose the member');
        setMessageState('error');
        setShipLoading(false);
      }
    }
  };

  const handleApplyDiscount = () => {
    setOpenDiscountModal(true);
  };

  const getSingleOrder = () => {
    orderID &&
      httpClient.get(`/order/detail/${orderID}`).then(({ data }) => {
        if (data.success === true) {
          var inventoryItemsID = [];
          var orderedQuantity = [];
          var orderedItemRate = [];
          var itemTotal = [];
          var inventoryCategories = [];
          let orderedVariation = {
            size: [],
            numofcopies: [],
          };

          data.data.orderDetail.map((item, i) => {
            inventoryItemsID[item.inventoryItems.inventoryItemID] = item.inventoryItems.inventoryItemID;
            return inventoryItemsID;
          });
          data.data.orderDetail.map((item, i) => {
            return inventoryCategories.push(item.inventoryItems.inventory_category_id);
          });
          data.data.orderDetail.map((item, i) => {
            //  return orderedQuantity.push(item.orderedQuantity);
            // console.log('index',item.inventoryItems.inventoryItemID);
            // console.log('iemt',item.orderedQuantity);
            //  orderedQuantity.splice(item.inventoryItems.inventoryItemID, 0 ,item.orderedQuantity)
            orderedQuantity[item.inventoryItems.inventoryItemID] = item.orderedQuantity;
          });
          data.data.orderDetail.map((item, i) => {
            orderedItemRate[item.inventoryItems.inventoryItemID] = item.orderedItemRate;
            return orderedItemRate;
          });
          data.data.orderDetail.map((item, i) => {
            itemTotal[item.inventoryItems.inventoryItemID] = item.itemTotal;
            return itemTotal;
          });

          data.data.orderDetail.map((item, i) => {
            if (item.inventoryItems.inventory_category_id === 5 && !!item.orderAttrs) {
              orderedVariation.size[item.inventoryItems.inventoryItemID] = item.orderAttrs.size;
            }

            if (item.inventoryItems.inventory_category_id === 6 && !!item.orderAttrs) {
              orderedVariation.numofcopies[item.inventoryItems.inventoryItemID] = item.orderAttrs.numofcopies;
            }

            return orderedVariation;
          });

          // To map string or CSV string into corresponding number because we are using category number to filter orderType.
          const splittedOrderType = data.data.orderType.split(',');
          const reverseCategoryMapping = [];

          for (const [key, value] of Object.entries(categoryMapping)) {
            reverseCategoryMapping[value] = Number(key);
          }

          const reverseMappedOrderType =
            splittedOrderType.length > 0 && splittedOrderType.map(order => reverseCategoryMapping[order]);

          setOrderDetails({
            // ...orderDetails,
            companyID: data.data.company.companyID,
            companyName: data.data.company.companyName,
            memberID: data.data.Member.memberID,
            memberName: data.data.Member.memberName,
            // orderEditable: data.data.orderEditable,
            surchargeAmount: data.data.surchargeAmount,
            pickUpFee: data.data.pickUpFee,
            shippingCost:
              data?.data?.orderType === 'Uniform' || data?.data?.orderType === 'Flyers' ? 15.4 : data?.data?.shippingCost,
            orderedDate: data.data.orderedDate.substring(0, 10),
            onlinePaid: data.data.onlinePaid === 1 ? true : 0,
            orderReference: data.data.orderReference,
            orderStatus: data.data.orderStatus,
            shippedDate: data.data.shippedDate,
            // orderCompletedDate: data.data.orderCompletedDate.substring(0, 10),
            shippingCompanyID: data.data.shippingCompany.shippingCompanyID,
            TrackingCode: data.data.trackingCode,
            // deliveryDate: data.data.deliveryDate.substring(0, 10),
            inventoryItemsID: inventoryItemsID,
            inventoryCategories: inventoryCategories,
            orderedQuantity: orderedQuantity,
            orderedItemRate: orderedItemRate,
            orderedVariation: {
              size: orderedVariation.size,
              numofcopies: orderedVariation.numofcopies,
            },
            orderType: data.data.orderType.split(','),
            itemTotal: itemTotal,
            delivered: data.data.orderDeliver == '1' ? true : false,
            discount: data.data.discount === 1 ? true : false,
            discountAmount: data.data.discountAmount,
            discountReason: data.data.discountReasion,
          });
          setOrderTypes(reverseMappedOrderType);
        } else {
          setOpen(true);
          setMessage(data.message || 'Order not found');
          setMessageState('error');
        }
      });
  };

  var subTotal = orderDetails.itemTotal
    .filter(item => !Number.isNaN(item))
    .reduce((prev, next) => prev + next, 0)
    .toFixed(2);

  var total = (
    parseFloat(subTotal) +
    parseFloat(orderDetails.pickUpFee) +
    parseFloat(orderDetails.shippingCost) +
    parseFloat(orderDetails.surchargeAmount)
  ).toFixed(2);

  //checking for pick up fee
  // let treatArr = [];
  // inventoryData.map((inventory, i) => {
  //   if (inventory.inventory_category_id === 2) {
  //     inventory.inventory.map(item => {
  //       return treatArr.push(item.inventoryItemsID);
  //     });
  //   }
  // });

  // console.log('treat arr ', treatArr);

  // if (treatArr.some(arr => orderDetails.inventoryItemsID.includes(arr)) === true) {
  //   orderDetails.pickUpFee = 4.4;
  // } else {
  //   orderDetails.pickUpFee = 0;
  // }

  let catID = [];
  inventoryData.map((inventory, i) => {
    inventory.inventory.map(item => {
      orderDetails.inventoryItemsID &&
        orderDetails.inventoryItemsID.map(id => {
          if (id == item.inventoryItemsID) {
            catID.push(item.inventory_category_id);
          }
        });
    });
  });

  let uniqueID = catID && catID.filter((val, i, a) => a.indexOf(val) === i);

  let treatArr = [];
  uniqueID.length > 0 &&
    uniqueID.map(id => {
      inventoryData &&
        inventoryData.map((inventory, i) => {
          if (id == inventory.inventory_category_id) {
            var currentInventory = [];
            var finalPick = 0;
            var sumArr = [];

            currentInventory.push(inventory?.inventory.map(item => item.inventoryItemsID));

            orderDetails.orderedQuantity.some((item, index) => {
              if (currentInventory[0].includes(index)) {
                sumArr.push(item);
              }
            });
            var finalSum = sumArr.reduce((a, b) => parseInt(a) + parseInt(b), 0);

            if (finalSum <= inventory.minOrderQty) {
              finalPick = inventory.orderPickupFee;
            } else {
              finalPick = 0;
            }

            // treatArr.push(inventory.orderPickupFee);
            treatArr.push(finalPick);
          }
        });
    });

  var orderPickup = treatArr.length > 0 && treatArr.reduce((a, b) => a + b, 0);

  // if (orderPickup.length) {
  orderDetails.pickUpFee = orderPickup;
  // }

  const getUniformProductAttributes = () => {
    setLoading(true);
    httpClient.get('product-attribute?groupBy=uniform').then(({ data }) => {
      if (data.success === true) {
        setUniformAttribute(data.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getUniformProductAttributes();
  }, []);

  const getFlyersProductAttributes = () => {
    setLoading(true);
    httpClient.get('product-attribute?groupBy=flyers').then(({ data }) => {
      if (data.success === true) {
        setFlyersAttribute(data.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getFlyersProductAttributes();
  }, []);

  const matchAndReplaceProductAttribute = (attributes, variations, categoryId) => {
    const modifiedList = [];

    variations.length > 0 &&
      variations.forEach(obj2 => {
        let attributeID = '';

        switch (categoryId) {
          case 5:
            attributeID = obj2['3'];
            break;
          case 6:
            attributeID = obj2['6'];
            break;
          default:
            return '';
        }

        const matchingAttribute = attributes.find(attr => attr.attributeID === Number(attributeID));

        if (matchingAttribute) {
          const modifiedObj = { ...obj2 };

          switch (categoryId) {
            case 5:
              modifiedObj['3'] = {
                attributeID: matchingAttribute.attributeID,
                attributeName: matchingAttribute.attributeName,
                price: obj2['1'],
              };
              break;
            case 6:
              modifiedObj['6'] = {
                attributeID: matchingAttribute.attributeID,
                attributeName: matchingAttribute.attributeName,
                price: obj2['7'],
              };
              break;
            default:
              return '';
          }

          modifiedList.push(modifiedObj);
        }
      });

    return modifiedList;
  };

  return (
    <PageContainer heading={orderID === '' ? 'Add New Order' : 'Edit Order'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* <Grid item sm={12} md={12}>
              <TextField
                required
                label="Order Editable"
                variant="outlined"
                name="orderEditable"
                value={orderDetails.orderEditable}
                className={classes.text}
                onChange={handleChange}
                type="number"
              />
            </Grid> */}
            {/* <Grid item sm={12} md={12}>
              <TextField
                required
                label="Surcharge Amount"
                variant="outlined"
                name="surchargeAmount"
                value={orderDetails.surchargeAmount}
                className={classes.text}
                onChange={handleChange}
                type="number"
              />
            </Grid> */}

            {/* <Grid item sm={12} md={12}>
              <TextField
                required
                label="Pick Up Fee"
                variant="outlined"
                name="pickUpFee"
                value={orderDetails.pickUpFee}
                className={classes.text}
                onChange={handleChange}
                type="number"
              />
            </Grid> */}

            {/* <Grid item sm={12} md={12}>
              <TextField
                required
                label="Shipping Cost"
                variant="outlined"
                name="shippingCost"
                value={orderDetails.shippingCost}
                className={classes.text}
                onChange={handleChange}
                type="number"
              />
            </Grid> */}

            <Grid item sm={12} md={12}>
              <Autocomplete
                disablePortal
                id="order_company_id"
                options={company}
                onChange={(event, newValue) => {
                  handleChangeCompany(newValue);
                }}
                inputValue={orderDetails.companyName}
                disabled={orderID ? true : false}
                getOptionLabel={option => (option.tradingName !== null ? option.tradingName : '')}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={handleChange}
                    value={orderDetails.companyName}
                    variant="outlined"
                    name="companyID"
                    label="Company"
                    disabled={orderID ? true : false}
                  />
                )}
              />

              {/* <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Company</InputLabel>
                <Select required name="companyID" label="Company" value={orderDetails.companyID} onChange={handleChange}>
                  <MenuItem value="">
                    <em>Select Company</em>
                  </MenuItem>

                  {company.length > 0 &&
                    company.map(com => (
                      <MenuItem key={com && com.companyID} value={com && com.companyID}>
                        {com && com.tradingName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            </Grid>

            <Grid item sm={12} md={12}>
              <Autocomplete
                disablePortal
                id="order_member_auto"
                options={members}
                onChange={(event, newValue) => {
                  handleChangeMember(newValue);
                }}
                style={{ margin: '20px 0' }}
                disabled={orderID ? true : false}
                inputValue={orderDetails.memberName}
                getOptionLabel={option => option.firstName + ' ' + option.lastName}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={handleChange}
                    value={orderDetails.memberName}
                    variant="outlined"
                    name="memberID"
                    label="Member"
                    disabled={orderID ? true : false}
                  />
                )}
              />

              {/* <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Member</InputLabel>
                <Select required name="memberID" label="Member" value={orderDetails.memberID} onChange={handleChange}>
                  <MenuItem value={0}>
                    <em>Super Admin</em>
                  </MenuItem>

                  {members.length > 0 &&
                    members.map(mem => (
                      <MenuItem key={mem && mem.memberID} value={mem && mem.memberID}>
                        {mem && mem.firstName + ' ' + mem.lastName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                // required
                disabled
                label="Ordered Date"
                variant="outlined"
                type="date"
                name="orderedDate"
                value={orderDetails.orderedDate}
                className={classes.text}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                // required
                label="Order Reference"
                variant="outlined"
                name="orderReference"
                value={orderDetails.orderReference}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            {/* <Grid item sm={12} md={12}>
              <TextField
                // required
                label="Ordered Completed Date"
                variant="outlined"
                type="date"
                name="orderCompletedDate"
                value={orderDetails.orderCompletedDate}
                className={classes.text}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}

            {/* <Grid item sm={12} md={12}>
              <TextField
                // required
                label="Delivery Date"
                variant="outlined"
                type="date"
                name="deliveryDate"
                value={orderDetails.deliveryDate}
                className={classes.text}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}

            <Grid item sm={12} md={12}>
              <h2>Inventory Items</h2>
            </Grid>

            {inventoryData && inventoryData.length > 0 ? (
              inventoryData.map(
                (inventory, i) =>
                  inventory.inventory.length > 0 && (
                    <Accordion
                      className={classes.accordionMargin}
                      key={i}
                      disabled={
                        orderDetails.inventoryCategories &&
                        !orderDetails.inventoryCategories.includes(inventory.inventory_category_id)
                      }>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{
                          background:
                            orderDetails.inventoryCategories &&
                            orderDetails.inventoryCategories.includes(inventory.inventory_category_id)
                              ? '#e6ffdf'
                              : '#fff',
                        }}>
                        <Typography className={classes.heading}>{inventory.inventory_category_name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordion}>
                        <Grid container spacing={4}>
                          <Grid item sm={4} md={4}>
                            <h3>Item Name</h3>
                          </Grid>
                          <Grid
                            item
                            sm={3}
                            md={inventory.inventory_category_id === 5 || inventory.inventory_category_id === 6 ? 3 : 4}>
                            <h3>Order Quantity</h3>
                          </Grid>
                          {inventory.inventory_category_id === 5 || inventory.inventory_category_id === 6 ? (
                            <Grid item sm={3} md={3}>
                              <h3 style={{ marginLeft: '20px' }}>
                                {' '}
                                {inventory.inventory_category_id === 5 ? 'Sizes' : 'No. of Copies'}
                              </h3>
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid
                            item
                            sm={2}
                            md={inventory.inventory_category_id === 5 || inventory.inventory_category_id === 6 ? 2 : 4}>
                            <h3 style={{ textAlign: 'center' }}>Cost Per Item</h3>
                          </Grid>
                          {inventory.inventory &&
                            inventory.inventory
                              .filter(invent => invent.inventoryItemsActive !== 0)
                              .map((invent, index) => {
                                const modifiedUniformAttributes = matchAndReplaceProductAttribute(
                                  uniformAttribute,
                                  invent.variations && invent.variations,
                                  5,
                                );

                                const modifiedFlyersAttributes = matchAndReplaceProductAttribute(
                                  flyersAttribute,
                                  invent.variations,
                                  6,
                                );

                                return (
                                  <Grid key={index} container spacing={4}>
                                    <Grid item sm={4} md={4} className={classes.flexInput}>
                                      <InputLabel>
                                        {/* {(invent.inventoryItemsActive === 0)
                                    ? "OUT OF STOCK - "+ " " +invent.inventoryItemName
                                    : invent.inventoryItemName
                                  } */}
                                        {invent.inventoryItemName + '[' + invent.itemCode + ']'}
                                      </InputLabel>
                                    </Grid>
                                    <Grid
                                      item
                                      sm={3}
                                      md={
                                        inventory.inventory_category_id === 5 || inventory.inventory_category_id === 6
                                          ? 3
                                          : 4
                                      }>
                                      <TextField
                                        type="number"
                                        variant="outlined"
                                        name="orderedQuantity"
                                        value={parseInt(orderDetails.orderedQuantity[invent.inventoryItemsID])}
                                        className={classes.text}
                                        onChange={e => handleOrderedQuantity(e, invent.inventoryItemsID, index)}
                                      />
                                    </Grid>
                                    {inventory.inventory_category_id === 5 || inventory.inventory_category_id === 6 ? (
                                      <Grid
                                        item
                                        sm={3}
                                        md={3}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}>
                                        {invent.variations && invent.variations.length > 0 && (
                                          <>
                                            {modifiedUniformAttributes.length > 0 && (
                                              <Select
                                                name="variationUniform"
                                                style={{
                                                  width: '80%',
                                                  marginLeft: '20px',
                                                }}
                                                label="Select Size"
                                                value={
                                                  orderDetails?.orderedVariation?.size[invent.inventoryItemsID]
                                                    ?.attributeName
                                                    ? JSON.stringify(
                                                        orderDetails?.orderedVariation?.size[invent.inventoryItemsID],
                                                      )
                                                    : ''
                                                }
                                                onChange={e => handleChangeVariation(e, invent.inventoryItemsID)}
                                                renderValue={selected => {
                                                  if (selected) {
                                                    const selectedOption = JSON.parse(selected);
                                                    return selectedOption.attributeName;
                                                  }
                                                  return <em>Select Size</em>;
                                                }}
                                                // disabled={invent.inventoryStatus === 2}
                                              >
                                                <MenuItem value={JSON.stringify('')}>
                                                  <em>Select Size</em>
                                                </MenuItem>

                                                {modifiedUniformAttributes.length > 0 &&
                                                  modifiedUniformAttributes.map((listItem, index) => (
                                                    <MenuItem
                                                      key={listItem['3'].attributeID}
                                                      value={JSON.stringify(listItem['3'])}>
                                                      {listItem['3'].attributeName}
                                                    </MenuItem>
                                                  ))}
                                              </Select>
                                            )}

                                            {modifiedFlyersAttributes.length > 0 && (
                                              <Select
                                                name="variationFlyers"
                                                style={{
                                                  width: '80%',
                                                  marginLeft: '20px',
                                                }}
                                                label="Select No of copies"
                                                value={
                                                  orderDetails.orderedVariation.numofcopies[invent.inventoryItemsID]
                                                    ?.attributeName
                                                    ? JSON.stringify(
                                                        orderDetails.orderedVariation.numofcopies[invent.inventoryItemsID],
                                                      )
                                                    : ''
                                                }
                                                onChange={e => handleChangeVariation(e, invent.inventoryItemsID)}
                                                renderValue={selected => {
                                                  if (selected) {
                                                    const selectedOption = JSON.parse(selected);
                                                    return selectedOption.attributeName;
                                                  }
                                                  return <em>Select Size</em>;
                                                }}
                                                // disabled={invent.inventoryStatus === 2}
                                              >
                                                <MenuItem value={JSON.stringify('')}>
                                                  <em>Select No of Copies</em>
                                                </MenuItem>

                                                {modifiedFlyersAttributes.length > 0 &&
                                                  modifiedFlyersAttributes.map(listItem => (
                                                    <MenuItem
                                                      key={listItem['6'].attributeID}
                                                      value={JSON.stringify(listItem['6'])}>
                                                      {listItem['6'].attributeName}
                                                    </MenuItem>
                                                  ))}
                                              </Select>
                                            )}
                                          </>
                                        )}
                                      </Grid>
                                    ) : (
                                      ''
                                    )}

                                    <Grid
                                      item
                                      sm={2}
                                      md={
                                        inventory.inventory_category_id === 5 || inventory.inventory_category_id === 6
                                          ? 2
                                          : 4
                                      }
                                      className={classes.flexAmount}>
                                      {variationCost[invent.inventoryItemsID]
                                        ? `$${variationCost[invent.inventoryItemsID]}`
                                        : inventory.inventory_category_id === 5 || inventory.inventory_category_id === 6
                                        ? `$${(orderDetails && orderDetails.orderedItemRate[invent.inventoryItemsID]) ||
                                            invent.pricePerBox}`
                                        : `$${invent.pricePerBox + '/' + invent.weightPerItem + 'L'}`}
                                    </Grid>
                                  </Grid>
                                );
                              })}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ),
              )
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <CircularProgress size={30} />
              </Box>
            )}

            <Grid item sm={12} md={12} style={{ marginTop: '30px' }}>
              <TextField
                // required
                label="Shipped Date"
                variant="outlined"
                type="date"
                name="shippedDate"
                value={orderDetails.shippedDate}
                className={classes.text}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Shipping Company</InputLabel>
                <Select
                  // required
                  name="shippingCompanyID"
                  label="Shipping Company"
                  value={orderDetails.shippingCompanyID}
                  onChange={handleChange}>
                  <MenuItem value="">
                    <em>Select Shipping</em>
                  </MenuItem>

                  {shipping.length > 0 &&
                    shipping.map(ship => (
                      <MenuItem key={ship.shippingCompanyID} value={ship.shippingCompanyID}>
                        {ship.shippingCompanyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                // required
                label="Tracking Code"
                variant="outlined"
                name="TrackingCode"
                value={orderDetails.TrackingCode}
                className={classes.text}
                onChange={handleChange}
                helperText='Enter Multiple tracking code separated by Comma “trackCode1, trackCode2, trackCode3…"'
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Order Status</InputLabel>
                <Select
                  required
                  name="orderStatus"
                  label="Order Status"
                  disabled={orderDetails.shippingCompanyID ? (orderID ? false : true) : false}
                  value={orderDetails.orderStatus}
                  onChange={handleChange}>
                  <MenuItem value="">
                    <em>Select Order Status</em>
                  </MenuItem>
                  <MenuItem value={0}>Pending</MenuItem>
                  <MenuItem value={7}>Order Locked</MenuItem>
                  <MenuItem value={5}>Shipped</MenuItem>
                  <MenuItem value={1}>Completed</MenuItem>
                  <MenuItem value={8}>Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="onlinePaid"
                    checked={orderDetails.onlinePaid}
                    onChange={handleCheck}
                    value={orderDetails.onlinePaid}
                    color="primary"
                  />
                }
                label="Payment Received Online?"
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="delivered"
                    checked={orderDetails.delivered}
                    onChange={handleCheck}
                    value={orderDetails.delivered}
                    color="primary"
                  />
                }
                label="Order Delivered to Customer?"
              />
            </Grid>

            {/* <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="discount"
                    checked={orderDetails.discount}
                    onChange={handleCheck}
                    value={orderDetails.discount}
                    color="primary"
                  />
                }
                label="Discount?"
              />
            </Grid>

            {orderDetails.discount && (
              <Grid item sm={12} md={12}>
                <TextField
                  // required
                  label="Discount Amount ($)"
                  variant="outlined"
                  name="discountAmount"
                  value={orderDetails.discountAmount}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {orderDetails.discount && (
              <Grid item sm={12} md={12}>
                <TextField
                  // required
                  label="Discount Reason"
                  variant="outlined"
                  name="discountReason"
                  value={orderDetails.discountReason}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )} */}

            <Grid item sm={12} md={12}>
              <Button
                style={{ marginRight: '10px' }}
                color="secondary"
                variant="contained"
                onClick={handleCalculate}
                disabled={shipLoading}>
                Calculate Shipping
              </Button>
              {shipLoading && <CircularProgress size={20} />}
            </Grid>

            <Grid container spacing={4} className={classes.shippingPart}>
              <Grid item sm={6} md={6}>
                Shipping Cost
              </Grid>
              <Grid item sm={6} md={6}>
                ${orderDetails.shippingCost}
              </Grid>
              <Grid item sm={6} md={6}>
                Total Weight
              </Grid>
              <Grid item sm={6} md={6}>
                {orderDetails.totalWeight}Kg
              </Grid>
              <Grid item sm={6} md={6}>
                Sub Total
              </Grid>
              <Grid item sm={6} md={6}>
                ${subTotal}
              </Grid>
              <Grid item sm={6} md={6}>
                Pick and Pack Fee
              </Grid>
              <Grid item sm={6} md={6}>
                ${orderDetails.pickUpFee}
              </Grid>
              <Grid item sm={6} md={6}>
                Surcharge
              </Grid>
              <Grid item sm={6} md={6}>
                ${orderDetails.surchargeAmount}
              </Grid>
              <Grid item sm={6} md={6}>
                Total Cost
              </Grid>
              <Grid item sm={6} md={6}>
                ${isNaN(total) ? 0 : total}
              </Grid>
              <Grid item sm={6} md={6}>
                Discount
              </Grid>
              <Grid item sm={6} md={6}>
                ${orderDetails.discountAmount ? orderDetails.discountAmount : 0}
              </Grid>
              <Grid item sm={6} md={6}>
                Final Cost
              </Grid>
              <Grid item sm={6} md={6}>
                ${orderDetails.discountAmount ? parseFloat(total - orderDetails.discountAmount).toFixed(2) : total}
              </Grid>
            </Grid>

            <Grid item sm={12}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
                <Button color="secondary" variant="contained" onClick={handleApplyDiscount} size="small">
                  {orderDetails.discountAmount ? 'Edit/Remove Discount' : 'Apply Discount'}
                </Button>
                <Box textAlign="right" pt={4}>
                  <Button style={{ marginRight: '10px' }} color="secondary" variant="contained" onClick={handleBack}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit" disabled={loading}>
                    {orderID === '' ? 'Add Order' : 'Update Order'}
                  </Button>
                  {loading && (
                    <CircularProgress
                      fontSize={'small'}
                      style={{
                        position: 'relative',
                        top: '13px',
                        right: '-13px',
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <ApplyDiscountDialog
        open={openDiscountModal}
        setOpen={setOpenDiscountModal}
        orderDetails={orderDetails}
        setOrderDetails={setOrderDetails}
      />

      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddOrder;
