import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddPaymentType from './AddPaymentType';
import ListPaymentType from './ListPaymentType';

const PaymentType = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listPaymentType`} />
        <Route path={`${requestedUrl}/listPaymentType`} component={ListPaymentType} />
        <Route path={`${requestedUrl}/addPaymentType`} component={AddPaymentType} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default PaymentType;
