import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import { useEffect } from 'react';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  text: {
    width: '100%',
  },
  form: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AddAutoReply(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [replyID, setReplyID] = useState(props && props.location.pathname.slice(24));

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [replyDetails, setReplyDetails] = useState({
    emailInternalName: '',
    subject: '',
    body: '',
    active: false,
    emailType: '',
  });

  useEffect(() => {
    getSingleAutoReply();
  }, []);

  // console.log('reply id', replyID);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    let newVal;
    if (name === 'emailInternalName') {
      newVal = value.replace(/[&\/\\#, +()$~%.'":*?<>{}@!^]/g, '_');
    }
    setReplyDetails({
      ...replyDetails,
      [name]: name === 'emailInternalName' ? newVal : value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setReplyDetails({
      ...replyDetails,
      [name]: checked,
    });
  };

  const handleBack = () => {
    history.push('/emailTemplate/listEmails');
  };

  const getSingleAutoReply = () => {
    replyID !== '' &&
      httpClient.get(`email-template/${replyID}`).then(({ data }) => {
        if (data.success) {
          setReplyDetails({
            emailInternalName: data.data.emailInternalName,
            subject: data.data.emailSubject,
            body: data.data.emailBody,
            active: data.data.emailActive === 'Active' ? true : false,
            emailType: data.data.emailtype == 'System' ? 0 : 1,
          });
        }
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    replyID === ''
      ? httpClient
          .post(
            'email-template',
            qs.stringify({
              emailInternalName: replyDetails.emailInternalName,
              emailSubject: replyDetails.subject,
              emailBody: replyDetails.body,
              emailActive: replyDetails.active === true ? '1' : '0',
              emailType: replyDetails.emailType,
            }),
          )
          .then(({ data }) => {
            if (data.success) {
              setLoading(false);

              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                history.push('/emailTemplate/listEmails');
              }, 2000);
            } else {
              setLoading(false);

              setOpen(true);
              setMessage('Failed to add Email Template');
              setMessageState('error');
            }
          })
      : httpClient
          .post(
            `email-template/${replyID}`,
            qs.stringify({
              emailInternalName: replyDetails.emailInternalName,
              emailSubject: replyDetails.subject,
              emailBody: replyDetails.body,
              emailActive: replyDetails.active === true ? '1' : '0',
              emailType: replyDetails.emailType,
            }),
          )
          .then(({ data }) => {
            if (data.success) {
              setLoading(false);

              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                history.push('/emailTemplate/listEmails');
              }, 2000);
            } else {
              setLoading(false);

              setOpen(true);
              setMessage('Failed to update Email template');
              setMessageState('error');
            }
          });
  };

  return (
    <PageContainer heading={replyID === '' ? 'Add Email' : 'Edit Email'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Email Name"
                variant="outlined"
                name="emailInternalName"
                type="text"
                value={replyDetails.emailInternalName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Subject"
                variant="outlined"
                name="subject"
                type="text"
                value={replyDetails.subject}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={10}
                label="Body Content"
                variant="outlined"
                name="body"
                type="text"
                value={replyDetails.body}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Email Type</InputLabel>
                <Select
                  required
                  name="emailType"
                  label="Email Type"
                  value={replyDetails.emailType}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value="">
                    <em>Select Business</em>
                  </MenuItem>
                  <MenuItem value={0}>System</MenuItem>
                  <MenuItem value={1}>Auto Reply</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    checked={replyDetails.active}
                    onChange={handleCheck}
                    value={replyDetails.active}
                    color="primary"
                  />
                }
                label="Active ?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button onClick={handleBack} variant="contained" color="primary" style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button disabled={loading} type="submit" variant="contained" color="primary" style={{ marginRight: '10px' }}>
                {replyID === '' ? 'Add Email' : 'Edit Email'}
              </Button>
              {loading && <CircularProgress size={20} />}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
}

export default AddAutoReply;
