import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Card, Divider, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import { Autocomplete } from '@material-ui/lab';
import { Add, Clear } from '@material-ui/icons';
import { useEffect } from 'react';

const AddSuburbDialog = props => {
  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
    suburbs: props.suburbData.length > 0 ? props.suburbData.map(sub => sub.suburbName) : [''],
    suburbIDs: props.suburbData.length > 0 ? props.suburbData.map(sub => sub.suburbID) : [''],
    postCode: props.suburbData.length > 0 ? props.suburbData.map(sub => sub.suburbID) : [''],
  });

  console.log('props', props);

  const [suburbLists, setSuburbLists] = useState([]);

  const handleKeyEnter = e => {
    if (e.key == 'Enter') {
      httpClient.get(`suburb/suburb-name/${e.target.value}`).then(({ data }) => {
        if (data.success) {
          setSuburbLists(data.data);
        }
      });
    }
  };

  const handleChangeSuburbValue = (index, value) => {
    if (value === null) {
      alertData.suburbs[index] = '';
    } else {
      alertData.suburbs[index] = value.suburbName;
      alertData.suburbIDs[index] = value.suburbID;
      alertData.postCode[index] = value.PostCode;
    }
    setAlertData({
      ...alertData,
      suburbs: alertData.suburbs,
    });
  };

  const handleChangeSuburb = (e, index) => {
    alertData.suburbs[index] = e.target.value;
    setAlertData({
      ...alertData,
      suburbs: alertData.suburbs,
    });
  };

  const handleAddMoreSuburb = () => {
    setAlertData({
      ...alertData,
      suburbs: [...alertData.suburbs, ''],
      suburbIDs: [...alertData.suburbIDs, ''],
      postCode: [...alertData.postCode, ''],
    });
  };

  const handleCancelSuburb = (e, index) => {
    var array = alertData.suburbs;
    var array2 = alertData.suburbIDs;
    var array3 = alertData.postCode;

    var cancel = array.splice(index, 1);
    var cancel2 = array2.splice(index, 1);
    var cancel3 = array3.splice(index, 1);

    setAlertData({
      ...alertData,
      suburbs: array,
      suburbIDs: array2,
      postCode: array3,
    });
  };

  console.log('alertData', alertData);

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleSubmit = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: true,
    });
  };

  props.sendSuburbCallback(alertData);

  return (
    <Dialog open={alertData.open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Additional Suburb</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid item xs={12}>
            <Card
              style={{
                padding: '30px',
              }}>
              <Grid container spacing={2}>
                <Grid item md={2} xs={2}>
                  <h4 style={{ marginBottom: '0px' }}>S.No.</h4>
                </Grid>
                <Grid item md={6} xs={8}>
                  <h4 style={{ marginBottom: '0px', textAlign: 'center' }}>Suburb</h4>
                </Grid>
                <Grid item md={2} xs={2}>
                  <h4 style={{ marginBottom: '0px', textAlign: 'center' }}>Action</h4>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Divider />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  md={2}
                  xs={2}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    justifyContent: 'space-around',
                  }}>
                  {alertData.suburbs.map((group, index) => (
                    <div style={{ marginTop: '20px' }}>{index + 1}</div>
                  ))}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={8}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    justifyContent: 'space-around',
                  }}>
                  {alertData.suburbs.map((sub, index1) => (
                    <Autocomplete
                      // key={Math.random()}
                      //  {...defaultProps}
                      style={{ width: '100%', marginTop: '20px' }}
                      //   id="clear-on-escape"
                      clearOnEscape
                      inputValue={sub}
                      options={suburbLists}
                      getOptionLabel={option => option.suburbName + '-' + option.PostCode}
                      onChange={(event, newValue) => {
                        handleChangeSuburbValue(index1, newValue);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="suburbs"
                          label="Suburb"
                          variant="outlined"
                          // inputProps={{
                          //   ...params.inputProps
                          // }}
                          value={sub + '-' + alertData.postCode[index1]}
                          onChange={e => handleChangeSuburb(e, index1)}
                          onKeyDown={e => handleKeyEnter(e, index1)}
                        />
                      )}
                    />
                  ))}
                </Grid>

                <Grid
                  item
                  md={2}
                  xs={2}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    justifyContent: 'space-around',
                  }}>
                  {alertData.suburbs.map((sub, index1) => (
                    <div style={{ marginTop: '20px' }}>
                      {alertData.suburbs.length > 1 && (
                        <Tooltip title="Cancel Suburb">
                          <IconButton onClick={e => handleCancelSuburb(e, index1)}>
                            <Clear color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Add Suburb">
                        <IconButton onClick={e => handleAddMoreSuburb(e)}>
                          <Add color="Primary" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="secondary" autoFocus>
          Confirm Suburbs
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSuburbDialog;
