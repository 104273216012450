import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Card,
  Grid,
  makeStyles,
  TextField,
  InputLabel,
  Button,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
    width: '50%',
    margin: '0 auto',
  },
  textField: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '25px',
  },
  textSelect: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  resetBtn: {
    marginTop: '10px',
    float: 'right',
  },
  closeBtn: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  content: {
    padding: '35px',
  },
}));

const TopUpCredit = props => {
  const classes = useStyles();
  const [memberID, setMemberID] = useState(props && props.topID);
  const [topUpDetails, setTopUpDetails] = useState({
    companyName: '',
    companyID: '',
    creditModule: '',
    creditAmount: '',
    open: true,
    loading: false,
    submit: false,
  });

  const [response, setResponse] = useState({
    open: false,
    message: '',
    messageState: '',
  });

  const handleClose = (event, reason) => {
    setTopUpDetails({
      ...topUpDetails,
      open: false,
    });
  };

  useEffect(() => {
    getSingleMember();
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setTopUpDetails({
      ...topUpDetails,
      [name]: value,
    });
  };

  const getSingleMember = () => {
    memberID &&
      httpClient.get(`/member/${memberID}`).then(({ data }) => {
        if (data.success === true) {
          setTopUpDetails({
            ...topUpDetails,
            companyName: data.data.companyID.contactPersonFirstName + ' ' + data.data.companyID.contactPersonLastName,
            companyID: data.data.companyID.companyID,
            creditModule: '',
            creditAmount: '',
          });
        }
      });
  };

  const handleTopUp = () => {
    setTopUpDetails({
      ...topUpDetails,
      loading: true,
    });
    httpClient
      .post(
        `/credit`,
        qs.stringify({
          companyName: topUpDetails.companyName,
          companyID: topUpDetails.companyID,
          creditModule: topUpDetails.creditModule,
          creditAmount: topUpDetails.creditAmount,
        }),
      )
      .then(({ data }) => {
        if (data.success === true) {
          setResponse({
            open: true,
            message: 'Top Up Credit Successful',
            messageState: 'success',
          });
          setTopUpDetails({
            ...topUpDetails,
            loading: false,
            open: false,
            submit: true,
          });
        } else {
          setResponse({
            open: true,
            message: 'Top Up Credit Failed',
            messageState: 'error',
          });
          setTopUpDetails({
            ...topUpDetails,
            loading: false,
            open: true,
            submit: true,
          });
        }
      });
  };

  props.sendResponse(response);
  props.sendTopUp(topUpDetails);

  return (
    <Dialog open={topUpDetails.open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <h2>Top-up Credit</h2>
      </DialogTitle>
      <IconButton onClick={handleClose} className={classes.closeBtn}>
        <Close />
      </IconButton>
      <DialogContent className={classes.content}>
        <Grid container spacing={4}>
          <form className={classes.formControl}>
            <Grid item sm={12} md={12}>
              <InputLabel> Member Name</InputLabel>
              <TextField
                variant="outlined"
                name="companyName"
                value={topUpDetails.companyName}
                className={classes.textField}
                onChange={handleChange}
                disabled
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <InputLabel>Credit for *</InputLabel>
              <FormControl variant="outlined" className={classes.textSelect}>
                <Select
                  name="creditModule"
                  value={topUpDetails.creditModule}
                  onChange={handleChange}
                  className={classes.textField}>
                  <MenuItem value="">
                    <em>Select Module</em>
                  </MenuItem>
                  <MenuItem value={'SMS'}>SMS</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <InputLabel>Credit Amount *</InputLabel>
              <FormControl variant="outlined" className={classes.textSelect}>
                <Select
                  name="creditAmount"
                  value={topUpDetails.creditAmount}
                  onChange={handleChange}
                  className={classes.textField}>
                  <MenuItem value="">
                    <em>Select Top up Amount</em>
                  </MenuItem>
                  <MenuItem value={100}>100.00</MenuItem>
                  <MenuItem value={180}>180.00</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button className={classes.resetBtn} variant="contained" color="primary" size="large" onClick={handleTopUp}>
                  Top up Credit
                </Button>
                {topUpDetails.loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
              </div>
            </Grid>
          </form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TopUpCredit;
