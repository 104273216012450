import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Card,
  CardContent,
  Grid,
  Collapse,
  Box,
  Tooltip,
  IconButton,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Delete, Edit, MoreVert, Settings } from '@material-ui/icons';
import EditPostDialog from '../EditPostDialog';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import qs from 'qs';
import { httpClient } from 'appUtility/Api';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SocialCommentDialog = props => {
  const actions = [
    {
      icon: <Edit color="secondary" fontSize="small" />,
      label: 'Edit',
      onClick: id => handleEdit(id),
    },
    // {
    //   icon: <Settings color="secondary" fontSize="small" />,
    //   label: 'Activate/Deactivate',
    //   onClick: id => handleActivate(id),
    // },
    // {
    //   icon: <Delete color="secondary" fontSize="small" />,
    //   label: 'Delete',
    //   onClick: id => handleDelete(id),
    // },
  ];

  const [postDetails, setPostDetails] = useState({
    open: true,
    success: '',
    title: props.rowDetails.commentText,
    postedBy: props.rowDetails.commentedByUserName,
    noComments: props.rowDetails.socialPostSubComment.length,
    postID: props.rowDetails.postID,
    commentedByUserID: props.rowDetails.commentedByUserID,
    commentActive: props.rowDetails.commentActive === 'Active' ? true : false,
  });

  const [openEditPostDialog, setOpenEditPostDialog] = useState(false);
  const [currentSocial, setCurrentSocial] = useState('');
  const [rowData, setRowData] = useState('');
  const [postID, setPostID] = useState('');
  const [loading, setLoading] = useState(false);
  const [commentList, setCommentList] = useState(props && props.rowDetails);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleEdit = data => {
    setOpenEditPostDialog(true);
    setCurrentSocial('SubComment');
    setRowData(data.memID);
  };

  const handleActivate = data => {};

  const handleDelete = () => {};

  const sendBack = call => {
    if (call.success === 'SubComment' && call.open === false) {
      setOpenEditPostDialog(false);
      setCurrentSocial('');
      setRowData('');
      commentList.socialPostSubComment.length > 0 &&
        commentList.socialPostSubComment.map(sub => {
          if (sub.subCommentsID === call.commentID) {
            return (sub.subCommentText = call.title), (sub.subCommentActive = call.active === true ? 'Active' : 'Inactive');
          }
        });
    } else if (call.open === false) {
      setOpenEditPostDialog(false);
      setCurrentSocial('');
      setRowData('');
    }
  };

  const handleClose = () => {
    setPostDetails({
      ...postDetails,
      open: false,
      success: false,
    });
  };

  const handleCloseSuccess = () => {
    setPostDetails({
      ...postDetails,
      open: false,
      success: true,
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setPostDetails({
      ...postDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setPostDetails({
      ...postDetails,
      [name]: checked,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    httpClient
      .post(
        `social-post-comment/${props.rowDetails.commentsID}`,
        qs.stringify({
          postID: postDetails.postID,
          parentCommentID: 0,
          commentedByUserID: postDetails.commentedByUserID,
          commentText: postDetails.title,
          commentActive: postDetails.commentActive === true ? 1 : 0,
        }),
      )
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          setTimeout(() => {
            handleCloseSuccess();
          }, 1500);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage(data.error);
          setMessageState('failure');
        }
      });
  };

  const handleViewComment = post => {
    setPostID(post.postID);
  };

  console.log('props', props);

  props.sendBackPost(postDetails);

  return (
    <Dialog open={postDetails.open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <h2>Comment Details</h2>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item md={12}>
                      <TextField
                        multiline
                        rows={4}
                        name="title"
                        value={postDetails.title}
                        label="Comment Title"
                        variant="outlined"
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        name="postedBy"
                        value={postDetails.postedBy}
                        label="Commented By"
                        variant="outlined"
                        onChange={handleChange}
                        disabled={true}
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        name="noComments"
                        value={postDetails.noComments}
                        label="No of Sub Comments"
                        variant="outlined"
                        onChange={handleChange}
                        disabled={true}
                        style={{ width: '100%' }}
                      />
                    </Grid>

                    <Grid item md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="commentActive"
                            checked={postDetails.commentActive}
                            onChange={handleCheck}
                            value={postDetails.commentActive}
                            color="primary"
                          />
                        }
                        label="Comment Active ?"
                      />
                    </Grid>

                    <Grid item md={12} style={{ marginTop: '10px' }}>
                      {props.rowDetails.socialPostSubComment.length > 0 && (
                        <Grid
                          container
                          spacing={2}
                          style={{
                            borderBottom: '1px solid #f1f1f1',
                            padding: '5px',
                          }}>
                          <Grid item md={7}>
                            <strong>Sub Comment Title </strong>
                          </Grid>
                          <Grid item md={2}>
                            <strong> UserName</strong>
                          </Grid>
                          <Grid item md={2}>
                            <strong> Status</strong>
                          </Grid>
                          <Grid item md={1}>
                            <strong> Actions</strong>
                          </Grid>
                        </Grid>
                      )}
                      {props.rowDetails.socialPostSubComment.length > 0 &&
                        props.rowDetails.socialPostSubComment.map(comment => (
                          <Grid container spacing={2} style={{ borderBottom: '1px solid #f1f1f1' }}>
                            <Grid item md={7}>
                              {comment.subCommentText}
                            </Grid>

                            <Grid item md={2}>
                              {comment.commentedByUserName}
                            </Grid>
                            <Grid item md={2}>
                              {comment.subCommentActive}
                            </Grid>
                            <Grid item md={1}>
                              <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <CmtDropdownMenu
                                  TriggerComponent={
                                    <Tooltip title="More">
                                      <IconButton size="small" style={{ marginLeft: 10 }}>
                                        <MoreVert />
                                      </IconButton>
                                    </Tooltip>
                                  }
                                  items={actions}
                                  memID={comment}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="secondary">
          Update
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {loading && <CircularProgress size={25} />}
      </DialogActions>

      {openEditPostDialog && <EditPostDialog rowData={rowData} currentSocial={currentSocial} sendBack={sendBack} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default SocialCommentDialog;
