import React from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles(theme => ({
  twoFAIntro: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '5px',
    padding: '24px',
  },
  twoFAFeatures: {
    display: 'flex',
    alignItems: 'flex-start',
    '& p': {
      marginLeft: '8px',
    },
  },
  titleRoot: {
    marginBottom: '14px',
    color: theme.palette.text.primary,
  },
  addIcon: {
    width: '15px',
    height: '15px',
  },
}));

const Features = () => {
  const classes = useStyles();

  return (
    <Box className={classes.twoFAIntro}>
      <GridContainer>
        <Grid item sm={12} md={5} mr={4}>
          <Typography variant="h3" className={classes.titleRoot}>
            Two Factor Authentication
          </Typography>
          <Typography variant="body2">
            Two-Factor Authentication (2FA) adds an extra layer of security to your account by requiring a second form of
            verification in addition to your password. This extra layer of security to your account is crucial in today's
            digital landscape.{' '}
            <Box mt={2} display="flex" alignItems="center">
              <Link
                href="https://en.wikipedia.org/wiki/Multi-factor_authentication"
                underline="none"
                target="_blank"
                rel="noreferrer">
                <Box display="flex" alignItems="center">
                  Know more <ArrowForwardIcon fontSize="small" color="primary" className={classes.addIcon} />
                </Box>
              </Link>
            </Box>
          </Typography>
        </Grid>
        <Grid item sm={12} md={5}>
          <Box className={classes.twoFAFeatures} mb={4}>
            <CheckIcon color="primary" />
            <Typography variant="body2">
              Prevent unauthorized access without the second factor even if your password is compromised.
            </Typography>
          </Box>
          <Box className={classes.twoFAFeatures} mb={4}>
            <CheckIcon color="primary" />
            <Typography variant="body2">
              Provides an additional deterrent for attackers, as they need both your password and the second factor.
            </Typography>
          </Box>
          <Box className={classes.twoFAFeatures}>
            <WarningIcon color="secondary" style={{ marginTop: '6px' }} />
            <Typography variant="body2">
              Failure to enable 2FA can leave your account vulnerable to different security risks.
            </Typography>
          </Box>
        </Grid>
      </GridContainer>
    </Box>
  );
};

export default Features;
