import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Grid, makeStyles, TextField, FormControlLabel, Checkbox, Button, Card } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { httpClient } from 'appUtility/Api';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddShippingZone = props => {
  const classes = useStyles();
  const [shippingID, setShippingID] = useState(props && props.location.pathname.slice(21));
  console.log('shipping id >>', shippingID);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [shippingDetails, setShippingDetails] = useState({
    shipping_zone_name: '',
    shipping_zone_description: '',
    shipping_zone_active: false,
  });

  useEffect(() => {
    getSingleShipping();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    shippingID === ''
      ? httpClient
          .post(
            'shipping',
            qs.stringify({
              shipping_zone_name: shippingDetails.shipping_zone_name,
              shipping_zone_description: shippingDetails.shipping_zone_description,
              shipping_zone_active: shippingDetails.shipping_zone_active === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setShippingDetails({
                shipping_zone_name: '',
                shipping_zone_description: '',
                shipping_zone_active: false,
              });
            } else {
              setOpen(true);
              setMessage('failed to add shipping zone');
              setMessageState('error');
            }
          })
      : httpClient
          .put(
            `shipping/${shippingID}`,
            qs.stringify({
              shipping_zone_name: shippingDetails.shipping_zone_name,
              shipping_zone_description: shippingDetails.shipping_zone_description,
              shipping_zone_active: shippingDetails.shipping_zone_active === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              history.push('/freight/listShipping');
            } else {
              setOpen(true);
              setMessage('failed to edit shipping zone');
              setMessageState('error');
            }
          });
  };

  const getSingleShipping = () => {
    shippingID &&
      httpClient.get(`shipping/${shippingID}`).then(({ data }) => {
        if (data.success === true) {
          setShippingDetails({
            shipping_zone_name: data.data.shipping_zone_name,
            shipping_zone_description: data.data.shipping_zone_description,
            shipping_zone_active: data.data.shipping_zone_active === 1 ? true : false,
          });
        }
      });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setShippingDetails({
      ...shippingDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setShippingDetails({
      ...shippingDetails,
      [name]: checked,
    });
  };

  const handleBack = () => {
    history.push('/financial/listShipping');
  };

  return (
    <PageContainer heading={shippingID === '' ? 'Add Shipping Zone' : 'Edit Shipping Zone'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Shipping Zone Name"
                variant="outlined"
                name="shipping_zone_name"
                type="text"
                value={shippingDetails.shipping_zone_name}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={4}
                label="Shipping Zone Short Name"
                variant="outlined"
                name="shipping_zone_description"
                type="text"
                value={shippingDetails.shipping_zone_description}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="shipping_zone_active"
                    checked={shippingDetails.shipping_zone_active}
                    onChange={handleCheck}
                    value={shippingDetails.shipping_zone_active}
                    color="primary"
                  />
                }
                label="Make Zone Active ?"
              />
            </Grid>
            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" type="submit">
                {shippingID === '' ? 'Add Shipping Zone' : 'Edit Shipping Zone'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddShippingZone;
