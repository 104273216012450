import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Button,
  Card,
  makeStyles,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import { Autocomplete } from '@material-ui/lab';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddIncomeCategory = props => {
  const classes = useStyles();
  const [members, setMembers] = useState([]);
  const [incomeID, setIncomeID] = useState(props && props.location.pathname.slice(29));

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  useEffect(() => {
    getMembers();
    getSingleIncome();
  }, []);

  const [incomeDetails, setIncomeDetails] = useState({
    addedBy: '',
    addedByName: '',
    incomeCategoryName: '',
    incomeCategoryDescription: '',
    incomeCategoryActive: false,
    categoryDeleted: false,
    v2_migration_required: false,
    incGst: '',
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    incomeID === ''
      ? httpClient
          .post(
            'income/category',
            qs.stringify({
              addedBy: incomeDetails.addedBy,
              incomeCategoryName: incomeDetails.incomeCategoryName,
              incomeCategoryDescription: incomeDetails.incomeCategoryDescription,
              incomeCategoryActive: incomeDetails.incomeCategoryActive === true ? 1 : 0,
              categoryDeleted: incomeDetails.categoryDeleted === true ? 1 : 0,
              v2_migration_required: incomeDetails.v2_migration_required === true ? 1 : 0,
              gstInclusive: incomeDetails.incGst,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setIncomeDetails({
                addedBy: '',
                incomeCategoryName: '',
                incomeCategoryDescription: '',
                incomeCategoryActive: false,
                categoryDeleted: false,
                v2_migration_required: false,
                incGst: '',
              });
              setTimeout(() => {
                history.push('/financial/listIncomeCategory');
              }, 1500);
            } else {
              setOpen(true);
              setMessage('failed to add income category');
              setMessageState('error');
            }
          })
      : httpClient
          .put(
            `income/category/${incomeID}`,
            qs.stringify({
              addedBy: incomeDetails.addedBy,
              incomeCategoryName: incomeDetails.incomeCategoryName,
              incomeCategoryDescription: incomeDetails.incomeCategoryDescription,
              incomeCategoryActive: incomeDetails.incomeCategoryActive === true ? 1 : 0,
              categoryDeleted: incomeDetails.categoryDeleted === true ? 1 : 0,
              v2_migration_required: incomeDetails.v2_migration_required === true ? 1 : 0,
              gstInclusive: incomeDetails.incGst,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                history.push('/financial/listIncomeCategory');
              }, 1500);
            } else {
              setOpen(true);
              setMessage('failed to add income category');
              setMessageState('error');
            }
          });
  };

  const getSingleIncome = () => {
    incomeID &&
      httpClient.get(`income/category/${incomeID}`).then(({ data }) => {
        setIncomeDetails({
          addedBy: data.data.companyAddedBy.CompanyID == null ? 0 : data.data.companyAddedBy.CompanyID,
          addedByName: data.data.companyAddedBy.CompanyName == null ? 'Super Admin' : data.data.companyAddedBy.CompanyName,
          incomeCategoryName: data.data.incomeCategoryName,
          incomeCategoryDescription: data.data.incomeCategoryDescription,
          incomeCategoryActive: data.data.incomeCategoryActive === 1 ? true : false,
          categoryDeleted: data.data.categoryDeleted === 1 ? true : false,
          v2_migration_required: data.data.v2_migration_required === 1 ? true : false,
          incGst: data.data.gstInclusive,
        });
      });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'addedBy') {
      incomeDetails.addedByName = value;
    }
    setIncomeDetails({
      ...incomeDetails,
      [name]: value,
    });
  };

  const handleChangeAdded = value => {
    setIncomeDetails({
      ...incomeDetails,
      addedBy: value !== null ? value.companyID : '',
      addedByName: value !== null ? value.tradingName : '',
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setIncomeDetails({
      ...incomeDetails,
      [name]: checked,
    });
  };

  const getMembers = () => {
    httpClient.get('member-company/all').then(({ data }) => {
      if (data.success === true) {
        let newData = [];
        newData = data.data;
        newData.unshift({
          companyID: 0,
          tradingName: 'Super Admin',
        });
        setMembers(data.data);
      }
    });
  };

  const handleBack = () => {
    history.push('/financial/listIncomeCategory');
  };

  return (
    <PageContainer heading={incomeID === '' ? 'Add Income Category' : 'Edit Income Category'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Income Category Name"
                variant="outlined"
                name="incomeCategoryName"
                type="text"
                value={incomeDetails.incomeCategoryName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={4}
                label="Income Category Description"
                variant="outlined"
                name="incomeCategoryDescription"
                type="text"
                value={incomeDetails.incomeCategoryDescription}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <Autocomplete
                disablePortal
                id="add_income_auto"
                options={members}
                onChange={(event, newValue) => {
                  handleChangeAdded(newValue);
                }}
                inputValue={incomeDetails.addedByName}
                // sx={{ width: 300 }}
                getOptionLabel={option => (option.tradingName !== null ? option.tradingName : '')}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={handleChange}
                    value={incomeDetails.addedByName}
                    variant="outlined"
                    name="addedBy"
                    label="Added By"
                  />
                )}
              />

              {/* <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Added By</InputLabel>
                <Select
                  required
                  name="addedBy"
                  label="Added By"
                  value={incomeDetails.addedBy}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Super Admin</em>
                  </MenuItem>

                  {members.length > 0 &&
                    members.map(mem => (
                      <MenuItem key={mem.member && mem.member.memberID} value={mem.member && mem.member.memberID}>
                        {mem.member && mem.tradingName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Gst</InputLabel>
                <Select
                  required
                  name="incGst"
                  label="GST"
                  value={incomeDetails.incGst}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={1}>Include Gst</MenuItem>
                  <MenuItem value={0}>Exclude Gst</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="incomeCategoryActive"
                    checked={incomeDetails.incomeCategoryActive}
                    onChange={handleCheck}
                    value={incomeDetails.incomeCategoryActive}
                    color="primary"
                  />
                }
                label="Make Income Category Active?"
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="categoryDeleted"
                    checked={incomeDetails.categoryDeleted}
                    onChange={handleCheck}
                    value={incomeDetails.categoryDeleted}
                    color="primary"
                  />
                }
                label="Income Category Delete?"
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="v2_migration_required"
                    checked={incomeDetails.v2_migration_required}
                    onChange={handleCheck}
                    value={incomeDetails.v2_migration_required}
                    color="primary"
                  />
                }
                label="Version 2 migration required?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ marginRight: '10px' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {incomeID === '' ? 'Add Income' : 'Edit Income'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddIncomeCategory;
