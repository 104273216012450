import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableCatsComponent from './../TableCatsComponent';

const column = [
  { id: 'expenseSuperCategoryName', label: 'Super Category' },
  { id: 'expenseSuperCategoryDescription', label: 'Description' },
  { id: 'expenseSuperCategoryActive', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListExpenseSuperCategory = () => {
  const [expenseSuperCategory, setExpenseSuperCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  // useEffect(() => {
  //   getExpenseSuperCategory();
  // }, []);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    active: '',
    superCategoryName: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    active: '',
    superCategoryName: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (filterData.superCategoryName === '' && filterData.active === '' && filterData.search === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.superCategoryName === ' ') filterData.superCategoryName = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let expenseStorage = JSON.parse(localStorage.getItem('expense_super_category_filter'));
    expenseStorage !== null && setFilterData(expenseStorage);

    expenseStorage == null
      ? getExpenseSuperCategory()
      : expenseStorage.active == '' &&
        expenseStorage.search == '' &&
        expenseStorage.superCategoryName == '' &&
        expenseStorage.removed == false
      ? getExpenseSuperCategory()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      active: filterData.active,
      superCategoryName: filterData.superCategoryName,
      search: filterData.search,
      submit: true,
    });

    filterData.removed = true;
    localStorage.setItem('expense_super_category_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.active || filterData.search || filterData.superCategoryName) {
      httpClient
        .get(
          `/expenses/category/super/filter?q=${filterData.search}&expenseSuperCategoryName=${filterData.superCategoryName}&expenseSuperCategoryActive=${filterData.active}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setExpenseSuperCategory(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getExpenseSuperCategory();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const getExpenseSuperCategory = () => {
    setLoading(true);
    httpClient.get(`expenses/category/super?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setExpenseSuperCategory(data.data);
        setLoading(false);
        setTotal(data.meta.total);
      }
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`expenses/category/super?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setExpenseSuperCategory(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `/expenses/category/super/filter?q=${filterData.search}&expenseSuperCategoryName=${filterData.superCategoryName}&expenseSuperCategoryActive=${filterData.active}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setExpenseSuperCategory(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`expenses/category/super?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setExpenseSuperCategory(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `/expenses/category/super/filter?q=${filterData.search}&expenseSuperCategoryName=${
              filterData.superCategoryName
            }&expenseSuperCategoryActive=${filterData.active}&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setExpenseSuperCategory(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`expenses/category/super?direction=desc&pagination=${+event.target.value}&page=${page}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setExpenseSuperCategory(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleEdit = id => {
    history.push(`/financial/addExpenseSuperCategory/${id}`);
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     let filter_financial = expenseSuperCategory.filter(
  //       item =>
  //         item.expenseSuperCategoryName.toLowerCase().includes(searchText.toLowerCase()) ||
  //         item.expenseSuperCategoryDescription.toLowerCase().includes(searchText.toLowerCase()),
  //     );

  //     setTimeout(() => {
  //       if (filter_financial && filter_financial.length === 0) {
  //         setExpenseSuperCategory(filter_financial);
  //         setSearchText('');
  //       } else {
  //         setExpenseSuperCategory(filter_financial);
  //       }
  //     }, 1000);
  //   }
  // };

  // const handleDefault = e => {
  //   if (e.key === 'Backspace' || e.key === 'Enter') {
  //     setSearchText(e.target.value);
  //     getExpenseSuperCategory();
  //   }
  // };

  return (
    <PageContainer heading="List Expense - Super Category">
      <TableCatsComponent
        columns={column}
        selectedProducts={expenseSuperCategory}
        title="List Expense Super Category"
        handleEdit={handleEdit}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        direction={direction}
        currentColumn={currentColumn}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
      />
    </PageContainer>
  );
};

export default ListExpenseSuperCategory;
