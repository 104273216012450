import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import TableServiceGroupComponent from '../TableServiceGroupComponent';

import moment from 'moment';

const columns = [
  { id: 'franchiseeName', label: 'Franchisee Name' },
  { id: 'serviceLocation', label: 'Service Area' },
  { id: 'serviceGroupName', label: 'Group Name' },
  { id: 'Amount', label: 'Total Amount' },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ServiceGroupReport = () => {
  const [serviceData, setServiceData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  var serviceStorage = JSON.parse(localStorage.getItem('service_group_report_filter'));

  const [total, setTotal] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    startDate:
      serviceStorage !== null
        ? serviceStorage.startDate
        : moment()
            .subtract(1, 'week')
            .format('MM-DD-YYYY'),
    endDate: serviceStorage !== null ? serviceStorage.endDate : moment().format('MM-DD-YYYY'),
    currStartDate:
      serviceStorage !== null
        ? serviceStorage.currStartDate
        : moment()
            .subtract(1, 'week')
            .format('DD-MM-YYYY'),
    currEndDate: serviceStorage !== null ? serviceStorage.currEndDate : moment().format('DD-MM-YYYY'),
    memberID: serviceStorage !== null ? serviceStorage.memberID : '',
    memberName: serviceStorage?.memberName ? serviceStorage.memberName : '',
    groupID: serviceStorage !== null ? serviceStorage.groupID : '',
    groupName: serviceStorage?.groupName ? serviceStorage.groupName : '',
  });

  const [filterData, setFilterData] = useState({
    startDate:
      serviceStorage !== null
        ? serviceStorage.startDate
        : moment()
            .subtract(1, 'week')
            .format('MM-DD-YYYY'),
    endDate: serviceStorage !== null ? serviceStorage.endDate : moment().format('MM-DD-YYYY'),
    currStartDate:
      serviceStorage !== null
        ? serviceStorage.currStartDate
        : moment()
            .subtract(1, 'week')
            .format('DD-MM-YYYY'),
    currEndDate: serviceStorage !== null ? serviceStorage.currEndDate : moment().format('DD-MM-YYYY'),
    memberID: serviceStorage !== null ? serviceStorage.memberID : '',
    memberName: serviceStorage?.memberName ? serviceStorage.memberName : '',
    groupID: serviceStorage !== null ? serviceStorage.groupID : '',
    groupName: serviceStorage?.groupName ? serviceStorage.groupName : '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.startDate === '' &&
      filterData.endDate === '' &&
      filterData.memberID === '' &&
      filterData.memberName === '' &&
      filterData.groupID === '' &&
      filterData.groupName === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.startDate === ' ') filterData.startDate = '';
    if (filterData.endDate === ' ') filterData.endDate = '';
    if (filterData.memberID === ' ') filterData.memberID = '';
    if (filterData.memberName === ' ') filterData.memberName = '';
    if (filterData.groupID === ' ') filterData.groupID = '';
    if (filterData.groupName === ' ') filterData.groupName = '';

    filterData.removed && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let servStorage = JSON.parse(localStorage.getItem('service_group_report_filter'));
    servStorage !== null && setFilterData(servStorage);

    servStorage == null
      ? getServiceGroupReport()
      : servStorage.startDate == '' &&
        servStorage.endDate == '' &&
        servStorage.memberName == '' &&
        servStorage.removed == false &&
        servStorage.groupID == '' &&
        servStorage.groupName == '' &&
        servStorage.removed == false
      ? getServiceGroupReport()
      : console.log('service user report');
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmitData = () => {
    setSubmittedData({
      startDate: filterData.startDate,
      endDate: filterData.endDate,
      memberID: filterData.memberID,
      memberName: filterData.memberName,
      groupID: filterData.groupID,
      groupName: filterData.groupName,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('service_group_report_filter', JSON.stringify(filterData));
    if (filterData.startDate || filterData.endDate || filterData.memberID || filterData.groupID) {
      httpClient
        .get(
          `service-group/report?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&memberID=${filterData.memberID}&serviceGroupID=${filterData.groupID}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success) {
            setServiceData(data.data);
            setTotal(data.meta.total);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getServiceGroupReport();
    }
  };

  const handleFilter = e => {
    e.preventDefault();

    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'memberID') {
      filterData.memberName = value;
    }

    if (name === 'groupID') {
      filterData.groupName = value;
    }

    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleChangeMember = value => {
    setFilterData({
      ...filterData,
      memberID: value !== null ? value.memberID : '',
      memberName: value !== null ? value.firstName + ' ' + value.lastName : '',
    });
  };

  const handleChangeServiceGroup = value => {
    setFilterData({
      ...filterData,
      groupID: value !== null ? value.GroupID : '',
      groupName: value !== null ? value.GroupName : '',
    });
  };

  const handleStartDate = e => {
    setFilterData({
      ...filterData,

      startDate: moment(e).format('MM-DD-YYYY'),
      currStartDate: moment(e).format('DD-MM-YYYY'),
      removed: false,
    });
  };

  const handleEndDate = e => {
    setFilterData({
      ...filterData,
      endDate: moment(e).format('MM-DD-YYYY'),
      currEndDate: moment(e).format('DD-MM-YYYY'),
      removed: false,
    });
  };

  const handleRemove = data => {
    if (data === 'startDate') {
      filterData.currStartDate = '';
      submittedData.currStartDate = '';
    }
    if (data === 'endDate') {
      filterData.currEndDate = '';
      submittedData.currEndDate = '';
    }

    if (data === 'memberID') {
      filterData.memberName = '';
      submittedData.memberName = '';
    }

    if (data === 'groupID') {
      filterData.groupName = '';
      submittedData.groupName = '';
    }

    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpClient
          .get(
            `service-group/report?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&memberID=${
              filterData.memberID
            }&serviceGroupID=${filterData.groupID}&sort_by=${column.id}&direction=${
              !direction ? 'asc' : 'desc'
            }&pagination=${rowsPerPage}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setServiceData(data.data);
            }
          })
      : httpClient
          .get(
            `service-group/report?sort_by=${column.id}&direction=${!direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setServiceData(data.data);
            }
          });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `service-group/report?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&memberID=${
              filterData.memberID
            }&serviceGroupID=${filterData.groupID}&sort_by=${currentColumn.id || ''}&direction=${
              direction ? 'asc' : 'desc'
            }&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setServiceData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(
            `service-group/report?sort_by=${currentColumn.id || ''}&direction=${
              direction ? 'asc' : 'desc'
            }&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setServiceData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(
            `service-group/report?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&memberID=${
              filterData.memberID
            }&serviceGroupID=${filterData.groupID}&sort_by=${currentColumn.id || ''}&direction=${
              direction ? 'asc' : 'desc'
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setServiceData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(
            `service-group/report?sort_by=${currentColumn.id || ''}&direction=${
              direction ? 'asc' : 'desc'
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setServiceData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const getServiceGroupReport = () => {
    setLoading(true);
    httpClient.get(`service-group/report?&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
      if (data.success) {
        setServiceData(data.data);
        setTotal(data.meta.total);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
        setOpen(true);
        setMessage(data.message);
        setMessageState('error');
        setLoading(false);
        return;
      }
    });
  };

  return (
    <PageContainer heading="Service Report">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableServiceGroupComponent
              columns={columns}
              selectedProducts={serviceData}
              title="Service Group Report"
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total && total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleChangeMember={handleChangeMember}
              handleChangeServiceGroup={handleChangeServiceGroup}
              handleRemove={handleRemove}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>

      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ServiceGroupReport;
