import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableComponent from './../TableComponent';

const columns = [
  { id: 'paymentType', label: 'Payment Type' },
  { id: 'displayPosition', label: 'Display Position' },
  { id: 'paymentactive', label: 'Payment Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListPaymentType = () => {
  const [payment, setPayment] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [searchText, setSearchText] = useState('');
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  // useEffect(() => {
  //   getPayment();
  // }, []);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    active: '',
    paymentType: '',
    displayPosition: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    active: '',
    paymentType: '',
    displayPosition: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.paymentType === '' &&
      filterData.displayPosition === '' &&
      filterData.active === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.paymentType === ' ') filterData.paymentType = '';
    if (filterData.displayPosition === ' ') filterData.displayPosition = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let paymentStorage = JSON.parse(localStorage.getItem('payment_type_filter'));
    paymentStorage !== null && setFilterData(paymentStorage);

    paymentStorage == null
      ? getPayment()
      : paymentStorage.active == '' &&
        paymentStorage.search == '' &&
        paymentStorage.paymentType == '' &&
        paymentStorage.displayPosition == '' &&
        paymentStorage.removed == false
      ? getPayment()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      active: filterData.active,
      paymentType: filterData.paymentType,
      displayPosition: filterData.displayPosition,
      search: filterData.search,
      submit: true,
    });

    filterData.removed = true;
    localStorage.setItem('payment_type_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.active || filterData.search || filterData.paymentType || filterData.displayPosition) {
      httpClient
        .get(
          `payment/type/filter?q=${filterData.search}&paymentType=${filterData.paymentType}&displayPosition=${filterData.displayPosition}&paymentActive=${filterData.active}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setPayment(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getPayment();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const getPayment = () => {
    setLoading(true);
    httpClient.get(`payment/type?pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setPayment(data.data);
        setLoading(false);
      }
    });
  };

  const handleEdit = id => {
    history.push(`/paymentType/addPaymentType/${id}`);
  };

  const handleDelete = () => {};

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getPayment();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`payment/type/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setPayment(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="List Payment Type">
      <TableComponent
        columns={columns}
        selectedProducts={payment}
        title={'List Payment Type'}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
      />
    </PageContainer>
  );
};

export default ListPaymentType;
