import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddSocialGroup from './AddSocialGroup';
import ListSocialMediaGroup from './ListSocialMediaGroup';
import ListSystemGroups from './ListSystemGroups';
import ListUserGroups from './ListUserGroups';
import ManageSocialComment from './ManageSocialComment';
import ManageSocialPosts from './ManageSocialPosts';
import ManageSocialUsers from './ManageSocialUsers';

const SocialMedia = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listSystemGroups`} />
        <Route path={`${requestedUrl}/listSystemGroups`} component={ListSystemGroups} />
        <Route path={`${requestedUrl}/listUserGroups`} component={ListUserGroups} />
        <Route path={`${requestedUrl}/addSocialGroup`} component={AddSocialGroup} />
        <Route path={`${requestedUrl}/listSocialMediaGroups`} component={ListSocialMediaGroup} />
        <Route path={`${requestedUrl}/manageSocialPosts`} component={ManageSocialPosts} />
        <Route path={`${requestedUrl}/manageSocialUsers`} component={ManageSocialUsers} />
        <Route path={`${requestedUrl}/manageSocialComments`} component={ManageSocialComment} />
        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default SocialMedia;
