import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useRef, useState } from 'react';
import { history } from 'redux/store';
import ViewDialog from '../ViewDialog';
import TableIncomeComponent from './../TableIncomeComponent';
import moment from 'moment';
const qs = require('qs');

const columns = [
  { id: 'memberName', label: 'Name' },
  { id: 'stateID', label: 'State' },
  { id: 'serviceLocation', label: 'Location' },
  { id: 'startData', label: 'Start Date' },
  { id: 'endDate', label: 'End Date' },
  { id: 'TotalAmount', label: 'Total Income' },
  { id: 'totalPendingIncome', label: 'Pending Income Total' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const IncomeUserReport = () => {
  const [incomeData, setIncomeData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );

  var incomeStorage = JSON.parse(localStorage.getItem('income_report_filter'));
  // console.log('income storage', incomeStorage);

  const [total, setTotal] = useState('');
  //   const [dialogOpen, setDialogOpen] = useState(false);
  //   const [viewID, setViewID] = useState('');
  //   const [viewName, setViewName] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: true,
    startDate:
      incomeStorage !== null
        ? incomeStorage.startDate
        : moment()
            .subtract(1, 'week')
            .format('MM-DD-YYYY'),
    endDate: incomeStorage !== null ? incomeStorage.endDate : moment().format('MM-DD-YYYY'),
    currStartDate:
      incomeStorage !== null
        ? incomeStorage.currStartDate
        : moment()
            .subtract(1, 'week')
            .format('DD-MM-YYYY'),
    currEndDate: incomeStorage !== null ? incomeStorage.currEndDate : moment().format('DD-MM-YYYY'),
    memberActive: '',
    incomeAmount: '',
    incomeAmountOperator:'',
    outstandingIncome: '',
    // search: '',
  });
  const [filterData, setFilterData] = useState({
    startDate:
      incomeStorage !== null
        ? incomeStorage.startDate
        : moment()
            .subtract(1, 'week')
            .format('MM-DD-YYYY'),
    endDate: incomeStorage !== null ? incomeStorage.endDate : moment().format('MM-DD-YYYY'),
    currStartDate:
      incomeStorage !== null
        ? incomeStorage.currStartDate
        : moment()
            .subtract(1, 'week')
            .format('DD-MM-YYYY'),
    currEndDate: incomeStorage !== null ? incomeStorage.currEndDate : moment().format('DD-MM-YYYY'),
    memberActive: '',
    incomeAmount: '',
    incomeAmountOperator:'',
    outstandingIncome: '',
    // search: '',
    removed: false,
  });

  console.log('filter data is', filterData);

  useEffect(() => {
    if (
      filterData.startDate === '' &&
      filterData.memberActive === '' &&
      filterData.incomeAmount === '' &&
      filterData.incomeAmountOperator === '' &&
      filterData.outstandingIncome === '' &&
      filterData.endDate === ''
      //   && filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.startDate === ' ') filterData.startDate = '';
    if (filterData.endDate === ' ') filterData.endDate = '';
    if (filterData.memberActive === ' ') filterData.memberActive = '';
    if (filterData.outstandingIncome === ' ') filterData.outstandingIncome = '';
    if (filterData.incomeAmount === ' ') filterData.incomeAmount = '';
    if (filterData.incomeAmountOperator === ' ') filterData.incomeAmountOperator = '';
    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    // getIncomeUser();
    // handleSubmitData();
    let logsStorage = JSON.parse(localStorage.getItem('income_report_filter'));
    logsStorage !== null && setFilterData(logsStorage);

    logsStorage == null
      ? getIncomeUser()
      : logsStorage.startDate == '' &&
        logsStorage.currStartDate == '' &&
        logsStorage.endDate == '' &&
        logsStorage.currEndDate == '' &&
        logsStorage.memberActive == '' &&
        logsStorage.outstandingIncome == '' &&
        logsStorage.incomeAmount == '' &&
        logsStorage.incomeAmountOperator == '' &&
        logsStorage.removed == false
      ? getIncomeUser()
      : console.log('income report');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      startDate: filterData.startDate,
      endDate: filterData.endDate,
      memberActive: filterData.memberActive,
      incomeAmount: filterData.incomeAmount,
      incomeAmountOperator: filterData.incomeAmountOperator,
      outstandingIncome: filterData.outstandingIncome,
      //   search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('income_report_filter', JSON.stringify(filterData));
    if (
      filterData.startDate ||
      filterData.memberActive ||
      filterData.incomeAmount ||
      filterData.incomeAmountOperator ||
      filterData.outstandingIncome ||
      filterData.endDate
      //   || filterData.search
    ) {
      httpClient
        .get(
          `income/user?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&outStandingBooking=${filterData.outstandingIncome}&memberActive=${filterData.memberActive}&incomeAmount=${filterData.incomeAmount}&incomeAmountOperator=${filterData.incomeAmountOperator}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setIncomeData(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getIncomeUser();
    }
  };

  const handleFilter = e => {
    e.preventDefault();

    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleStartDate = e => {
    // const { name, value } = e.target;
    setFilterData({
      ...filterData,
      // startDate: moment(e).format('DD-MM-YYYY'),
      startDate: moment(e).format('MM-DD-YYYY'),
      currStartDate: moment(e).format('DD-MM-YYYY'),
      removed: false,
    });
  };

  const handleEndDate = e => {
    // const { name, value } = e.target;
    setFilterData({
      ...filterData,
      // endDate: moment(e).format('DD-MM-YYYY'),
      endDate: moment(e).format('MM-DD-YYYY'),
      currEndDate: moment(e).format('DD-MM-YYYY'),
      removed: false,
    });
  };

  const handleRemove = data => {
    if(data === "startDate"){
      filterData.currStartDate = "";
      submittedData.currStartDate = "";
    }
    if(data === "endDate"){
      filterData.currEndDate = "";
      submittedData.currEndDate = "";
    }
    if(data === "incomeAmount"){
      filterData.incomeAmountOperator = "";
      submittedData.incomeAmountOperator = "";
    }

    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpClient
          .get(
            `income/user?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&sort_by=${
              column.id
            }&outStandingBooking=${filterData.outstandingIncome}&memberActive=${filterData.memberActive}&incomeAmount=${
              filterData.incomeAmount
            }&incomeAmountOperator=${filterData.incomeAmountOperator}&sort_by=${column.id}&direction=${!direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setIncomeData(data.data);
            }
          })
      : httpClient
          .get(`income/user?sort_by=${column.id}&direction=${!direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
          .then(({ data }) => {
            if (data.success === true) {
              setIncomeData(data.data);
            }
          });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `income/user?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&outStandingBooking=${filterData.outstandingIncome}&memberActive=${filterData.memberActive}&incomeAmount=${filterData.incomeAmount}&incomeAmountOperator=${filterData.incomeAmountOperator}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setIncomeData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`income/user?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setIncomeData(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };
  // console.log('submitted data', submittedData);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(
            `income/user?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&outStandingBooking=${
              filterData.outstandingIncome
            }&memberActive=${filterData.memberActive}&incomeAmount=${filterData.incomeAmount}&incomeAmountOperator=${filterData.incomeAmountOperator}&direction=${direction ? 'asc' : 'desc'}&pagination=${+event.target
              .value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setIncomeData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`income/user?&direction=${direction ? 'asc' : 'desc'}&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setIncomeData(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const getIncomeUser = () => {
    setLoading(true);
    httpClient.get(`income/user?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data) {
        setIncomeData(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
      }
    });
  };

  // const handleView = val => {
  //   setViewID(val.id);
  //   setViewName(val.name);
  //   setDialogOpen(true);
  // };

  // const sendTo = child => {
  //   child == false && setDialogOpen(false);
  // };

  return (
    <PageContainer heading="User Report">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableIncomeComponent
              columns={columns}
              selectedProducts={incomeData}
              title="Income User Report"
              //   handleView={handleView}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total && total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>

      {/* {dialogOpen && <ViewDialog sendTo={sendTo} viewID={viewID} title={viewName} />} */}
    </PageContainer>
  );
};

export default IncomeUserReport;
