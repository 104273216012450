import React, { useState } from 'react';
import { Box, Button, TextField, Typography, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: '14px',
    color: theme.palette.text.primary,
  },
  dialogTitle: {
    marginTop: '24px',
  },
  dialogContent: {
    color: theme.palette.text.primary,
  },
  dialogActions: {
    marginRight: '24px',
    marginBottom: '24px',
  },
}));

const DisableModal = ({ openModal, handleCloseModal, handleDisable }) => {
  const [verificationCode, setVerificationCode] = useState('');

  const classes = useStyles();

  return (
    <Dialog open={openModal} onClose={handleCloseModal}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1" className={classes.titleRoot}>
          Are you sure you want to disable Two Factor Authentication?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body2" mb={4} className={classes.dialogContent}>
            Disabling Two Factor Authentication (2FA) will remove an additional layer of security from your account. Without
            2FA, your account will be more susceptible to unauthorized access and potential security threats.
          </Typography>
          <Box mt={4}>
            <Typography variant="body1" className={classes.titleRoot}>
              Enter the verification OTP here:
            </Typography>
            <TextField
              aria-label="2FA OTP input"
              placeholder="Enter OTP"
              variant="outlined"
              inputProps={{ type: 'number' }}
              value={verificationCode}
              onChange={e => setVerificationCode(e.target.value)}
              margin="normal"
            />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleCloseModal} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={() => handleDisable('disable', verificationCode)} color="primary" variant="contained">
          <IntlMessages id="security.disable2FA" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableModal;
