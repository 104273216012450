import { useEffect, useState } from 'react';
import { httpClient } from 'appUtility/Api';

const useGetProductAttributesList = () => {
  const [productAttributesList, setProductAttributesList] = useState([]);

  const getProductAttributesList = () => {
    httpClient.get('product-attribute?groupBy=flyers').then(res => {
      setProductAttributesList(res.data.data);
    });
  };

  useEffect(() => getProductAttributesList(), []);

  return { productAttributesList };
};

export default useGetProductAttributesList;
