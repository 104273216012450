import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade, IconButton, Tooltip } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  scrollbarRoot: {
    // height: 251,
    marginLeft: -24,
    marginRight: -24,
    [theme.breakpoints.up('xl')]: {
      // height: 269,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      // textAlign: 'right',
      // paddingRight: 24,
    },
  },
  tableRowCellRoot: {
    fontSize: 12,
    '&:last-child': {
      paddingRight: 64,
    },
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
}));

const actions = [
  {
    label: 'View Profile',
  },
  {
    label: 'More',
  },
];

const ProductsTable = ({ columns, selectedProducts }) => {
  const classes = useStyles();

  return (
    <PerfectScrollbar className={classes.scrollbarRoot}>
      <Box className="Cmt-table-responsive">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} className={clsx(classes.tableCellRoot, classes.tableRowCellRoot)}>
                  {' '}
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedProducts &&
              selectedProducts.map((row, index) => {
                // const isInflationPositive = row.sales_data.sales_inflation > 0;
                return (
                  <TableRow className={classes.tableRowRoot} key={Math.random()}>
                    {/* <TableCell className={classes.tableCellRoot}>{row.activeName}</TableCell> */}
                    <TableCell className={classes.tableCellRoot}>{row.service.serviceID}</TableCell>
                    <TableCell className={classes.tableCellRoot}>{row.service.serviceName}</TableCell>
                    <TableCell className={classes.tableCellRoot}>{row.total}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

export default ProductsTable;
