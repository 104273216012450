import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Card,
  CardContent,
  Grid,
  Collapse,
  FormControlLabel,
  Checkbox,
  TextField,
  Tooltip,
  IconButton,
  Box,
  CircularProgress,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Cancel, Delete, Edit, MoreVert, Settings } from '@material-ui/icons';
import EditPostDialog from '../EditPostDialog';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { httpClient } from 'appUtility/Api';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import qs from 'qs';
import ShowImageDialog from '../ShowImageDialog';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  imageListClass: {
    display: 'inline',
    listStyle: 'none',
    margin: '10px',
    position: 'relative',
  },

  imgClass: {
    borderRadius: '10px',
    height: '80px',
    width: '80px',
    objectFit: 'cover',
  },
  imageCancel: {
    position: 'absolute',
    right: '0',
    cursor: 'pointer',
  },
}));

const SocialPostDialog = props => {
  const classes = useStyles();

  console.log('props >>', props);

  const actions = [
    {
      icon: <Edit color="secondary" fontSize="small" />,
      label: 'Edit',
      onClick: id => handleEdit(id),
    },
    // {
    //   icon: <Settings color="secondary" fontSize="small" />,
    //   label: 'Activate/Deactivate',
    //   onClick: id => handleActivate(id),
    // },
    // {
    //   icon: <Delete color="secondary" fontSize="small" />,
    //   label: 'Delete',
    //   onClick: id => handleDelete(id),
    // },
  ];

  const actionsSub = [
    {
      icon: <Edit color="secondary" fontSize="small" />,
      label: 'Edit',
      onClick: id => handleEditSub(id),
    },
    // {
    //   icon: <Settings color="secondary" fontSize="small" />,
    //   label: 'Activate/Deactivate',
    //   onClick: id => handleActivateSub(id),
    // },
    // {
    //   icon: <Delete color="secondary" fontSize="small" />,
    //   label: 'Delete',
    //   onClick: id => handleDeleteSub(id),
    // },
  ];

  const [postDetails, setPostDetails] = useState({
    open: true,
    success: '',
    title: props.rowDetails.postTitle,
    active: props.rowDetails.postActive === 'Active' ? true : false,
    postedBy: props.rowDetails.postByUserID.postByUserName,
    postByUserID: props.rowDetails.postByUserID.postByUserID,
    noComments: props.rowDetails.socialPostComment.length,
    socialGroupID: props.rowDetails.socialGroup.socialGroupID,
  });

  const [openEditPostDialog, setOpenEditPostDialog] = useState(false);
  const [currentSocial, setCurrentSocial] = useState('');
  const [rowData, setRowData] = useState('');
  const [postID, setPostID] = useState('');
  const [loading, setLoading] = useState(false);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [allSocialGroups, setAllSocialGroups] = useState([]);

  const [commentList, setCommentList] = useState(props && props.rowDetails);

  const [imageList, setImageList] = useState(props && props.rowDetails.postImage);

  useEffect(() => {
    getAllGroups();
  }, []);

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleEdit = data => {
    setOpenEditPostDialog(true);
    setCurrentSocial('Comment');
    setRowData(data.memID);
  };

  const handleActivate = data => {};

  const handleDelete = () => {};

  const handleEditSub = data => {
    setOpenEditPostDialog(true);
    setCurrentSocial('SubComment');
    setRowData(data.memID);
  };

  const handleActivateSub = data => {};

  const handleDeleteSub = () => {};

  const sendBack = call => {
    // console.log('call', call);
    if (call.success === 'Comment' && call.open === false) {
      setOpenEditPostDialog(false);
      setCurrentSocial('');
      setRowData('');
      commentList.socialPostComment.length &&
        commentList.socialPostComment.map(com => {
          if (com.commentsID === call.commentID) {
            return (com.commentText = call.title), (com.commentActive = call.active === true ? 'Active' : 'Inactive');
          }
        });
    } else if (call.success === 'SubComment' && call.open === false) {
      setOpenEditPostDialog(false);
      setCurrentSocial('');
      setRowData('');
      commentList.socialPostComment.length &&
        commentList.socialPostComment.map(com => {
          if (com.commentsID === call.parentCommentID) {
            com.socialPostSubComment.length > 0 &&
              com.socialPostSubComment.map(sub => {
                if (sub.subCommentsID === call.commentID) {
                  return (
                    (sub.subCommentText = call.title), (sub.subCommentActive = call.active === true ? 'Active' : 'Inactive')
                  );
                }
              });
          }
        });
    } else if (call.open === false) {
      setOpenEditPostDialog(false);
      setCurrentSocial('');
      setRowData('');
    }
  };

  // console.log('commentList', commentList);

  const handleClose = () => {
    setPostDetails({
      ...postDetails,
      open: false,
      success: false,
    });
  };

  const handleCloseSuccess = () => {
    setPostDetails({
      ...postDetails,
      open: false,
      success: true,
    });
  };

  const getAllGroups = () => {
    httpClient.get('social-group/all').then(({ data }) => {
      if (data) {
        setAllSocialGroups(data.data);
      }
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    httpClient
      .post(
        `social-post/${props.rowDetails.postID}`,
        qs.stringify({
          socialGroupID: postDetails.socialGroupID,
          postByUserID: postDetails.postByUserID,
          postTitle: postDetails.title,
          postActive: postDetails.active === true ? 1 : 0,
          postImage: imageList.length > 0 ? imageList.map(img => img.imageURL) : [],
        }),
      )
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          setTimeout(() => {
            handleCloseSuccess();
          }, 1500);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage('Failed to update post');
          setMessageState('failure');
        }
      });
  };

  const handleViewComment = post => {
    console.log('comment', post);
    setPostID(post.postID);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setPostDetails({
      ...postDetails,
      [name]: value,
    });
  };
  const handleCheck = e => {
    const { name, check } = e.target;
    setPostDetails({
      ...postDetails,
      [name]: check,
    });
  };

  const handleShowImg = img => {
    setShowImageDialog(true);
    setCurrentImage(img);
  };

  const returnImageBack = call => {
    if (call.open === false) {
      setShowImageDialog(false);
      setCurrentImage('');
    }
  };

  const handleRemoveImg = id => {
    var newList =
      imageList.length > 0 &&
      imageList.filter(img => {
        return img.imageID !== id;
      });

    setImageList(newList);
  };
  // console.log('image list', imageList);
  // console.log('props', props);

  props.sendBackPost(postDetails);

  return (
    <Dialog open={postDetails.open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <h2>{props.rowDetails.socialGroup.socialGroupName} post details</h2>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item md={12}>
                      {/* <strong>Comment text: </strong> {props.rowDetails.postTitle} */}
                      <TextField
                        multiline
                        rows={4}
                        name="title"
                        value={postDetails.title}
                        label="Post Title"
                        variant="outlined"
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                    </Grid>

                    <Grid item md={12}>
                      <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel>Social Group</InputLabel>
                        <Select
                          name="socialGroupID"
                          label="Social Group"
                          value={postDetails.socialGroupID}
                          onChange={handleChange}>
                          <MenuItem value="">
                            <em>Select Social Group</em>
                          </MenuItem>
                          {allSocialGroups &&
                            allSocialGroups.map(grp => (
                              <MenuItem key={grp.socialGroupID} value={grp.socialGroupID}>
                                {grp.socialGroupName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={12}>
                      {/* <strong>Posted By: </strong> */}
                      {/* {props.rowDetails.postByUserID.postByUserName} */}
                      <TextField
                        name="postedBy"
                        value={postDetails.postedBy}
                        label="Posted By"
                        variant="outlined"
                        onChange={handleChange}
                        disabled={true}
                        style={{ width: '100%' }}
                      />
                    </Grid>

                    <Grid item md={12}>
                      {/* <strong>Number of Comments: </strong> */}
                      {/* {props.rowDetails.socialPostComment.length} */}
                      <TextField
                        name="noComments"
                        value={postDetails.noComments}
                        label="No of Comments"
                        variant="outlined"
                        onChange={handleChange}
                        disabled={true}
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    {imageList.length > 0 && (
                      <Grid item md={12}>
                        <ul>
                          {imageList.map(image => (
                            <li key={image.imageID} className={classes.imageListClass}>
                              <img
                                src={image.imageURL}
                                // src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80"
                                alt="..."
                                className={classes.imgClass}
                                onClick={() => handleShowImg(image.imageURL)}
                              />
                              <span className={classes.imageCancel} onClick={() => handleRemoveImg(image.imageID)}>
                                <Cancel />
                              </span>
                            </li>
                          ))}
                        </ul>
                      </Grid>
                    )}
                    <Grid item md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="active"
                            checked={postDetails.active}
                            onChange={handleCheck}
                            value={postDetails.active}
                            color="primary"
                          />
                        }
                        label="Post Active ?"
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={12} style={{ marginTop: '10px' }}>
                    {props.rowDetails.socialPostComment.length > 0 && (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          borderBottom: '1px solid #f1f1f1',
                          padding: '5px',
                        }}>
                        <Grid item md={7}>
                          <strong>Comment Title </strong>
                        </Grid>
                        <Grid item md={2}>
                          <strong> UserName</strong>
                        </Grid>
                        <Grid item md={2}>
                          <strong> Status</strong>
                        </Grid>
                        <Grid item md={1}>
                          <strong> Actions</strong>
                        </Grid>
                      </Grid>
                    )}
                    {props.rowDetails.socialPostComment.length > 0 &&
                      props.rowDetails.socialPostComment.map(comment => (
                        <Grid
                          container
                          spacing={2}
                          style={{
                            borderBottom: '1px solid #f1f1f1',
                            padding: '5px',
                          }}>
                          <Grid item md={7}>
                            {comment.commentText}
                          </Grid>

                          <Grid item md={2}>
                            {comment.commentedByUserName}
                          </Grid>
                          <Grid item md={2}>
                            {comment.commentActive}
                          </Grid>
                          <Grid item md={1}>
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                              <CmtDropdownMenu
                                TriggerComponent={
                                  <Tooltip title="More">
                                    <IconButton size="small" style={{ marginLeft: 10 }}>
                                      <MoreVert />
                                    </IconButton>
                                  </Tooltip>
                                }
                                items={actions}
                                memID={comment}
                              />
                            </Box>
                          </Grid>

                          {comment.socialPostSubComment.length > 0 && (
                            <Grid
                              container
                              spacing={2}
                              style={{
                                borderBottom: '1px solid #f1f1f1',
                                marginTop: '20px',
                              }}>
                              <Grid item sm={2} />
                              <Grid item md={5}>
                                <strong>Sub Comment Title </strong>
                              </Grid>
                              <Grid item md={2}>
                                <strong> UserName</strong>
                              </Grid>
                              <Grid item md={2}>
                                <strong> Status</strong>
                              </Grid>
                              <Grid item md={1}>
                                <strong> Actions</strong>
                              </Grid>
                            </Grid>
                          )}
                          {comment.socialPostSubComment.length > 0 &&
                            comment.socialPostSubComment.map((sub, index) => (
                              <Grid
                                container
                                spacing={2}
                                style={{
                                  borderBottom:
                                    comment.socialPostSubComment.length - 1 === index ? '0' : '1px solid #f1f1f1',
                                  marginBottom: comment.socialPostSubComment.length - 1 === index ? '20px' : '0',
                                  padding: '5px',
                                }}>
                                <Grid item sm={2} />
                                <Grid item sm={5}>
                                  {sub.subCommentText}
                                </Grid>
                                <Grid item sm={2}>
                                  {sub.commentedByUserName}
                                </Grid>
                                <Grid item sm={2}>
                                  {sub.subCommentActive}
                                </Grid>
                                <Grid item sm={1}>
                                  <Box display="flex" alignItems="center" justifyContent="flex-end">
                                    <CmtDropdownMenu
                                      TriggerComponent={
                                        <Tooltip title="More">
                                          <IconButton size="small" style={{ marginLeft: 10 }}>
                                            <MoreVert />
                                          </IconButton>
                                        </Tooltip>
                                      }
                                      items={actionsSub}
                                      memID={sub}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="secondary">
          Update
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {loading && <CircularProgress size={25} />}
      </DialogActions>

      {openEditPostDialog && <EditPostDialog rowData={rowData} currentSocial={currentSocial} sendBack={sendBack} />}

      {showImageDialog && <ShowImageDialog returnImageBack={returnImageBack} currentImage={currentImage} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default SocialPostDialog;
