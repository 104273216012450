import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import IntlMessages from '../../../../utils/IntlMessages';

import {
  ArrowForward,
  DonutSmall,
  Group,
  ImportContacts,
  Widgets,
  Fireplace,
  EmojiFlags,
  Forum,
  Dashboard,
  LocalShipping,
  AttachMoney,
  LocalAtm,
  Description,
  InsertComment,
  Iso,
  Ballot,
  Assessment,
  Book,
  ChatBubble,
  ChromeReaderMode,
  Extension,
  Note as NoteIcon,
} from '@material-ui/icons';
// import SidebarButtons from './SIdebarButtons';
import MoveToInbox from '@material-ui/icons/MoveToInbox';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const navigationMenus = [
  {
    name: <IntlMessages id={'sidebar.dashboard'} />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id={'sidebar.dashboard'} />,
        icon: <Widgets />,
        type: 'item',
        link: '/dashboard/dashboard',
      },
      {
        name: <IntlMessages id={'sidebar.versions'} />,
        icon: <Fireplace />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.versions.listVersions'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/versions/listVersion',
          },
          {
            name: <IntlMessages id={'sidebar.versions.addVersions'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/versions/addVersion',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.members'} />,
        icon: <Group />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.members.listMembers'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/members/listMembers',
          },
          {
            name: <IntlMessages id={'sidebar.members.addMembers'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/members/addMembers',
          },
          {
            name: <IntlMessages id={'sidebar.members.manageAdmins'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/members/manageAdmins',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.bookings'} />,
        icon: <Book />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.bookings.listActiveBookings'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/bookings/listActiveBookings',
          },
          {
            name: <IntlMessages id={'sidebar.bookings.listCompletedBookings'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/bookings/listCompletedBookings',
          },
          {
            name: <IntlMessages id={'sidebar.bookings.listCancelledBookings'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/bookings/listCancelledBookings',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.customers'} />,
        icon: <DonutSmall />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.customers.listCustomers'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/customers/listCustomers',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.suburb'} />,
        icon: <EmojiFlags />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.suburb.listSuburb'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/suburb/listSuburb',
          },
          {
            name: <IntlMessages id={'sidebar.suburb.addSuburb'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/suburb/addSuburb',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.services'} />,
        icon: <ImportContacts />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.services.listServices'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/services/listServices',
          },
          {
            name: <IntlMessages id={'sidebar.services.addServices'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/services/addServices',
          },
          {
            name: <IntlMessages id={'sidebar.services.listGroups'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/services/listGroups',
          },
          {
            name: <IntlMessages id={'sidebar.services.addGroups'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/services/addGroups',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.forum'} />,
        icon: <Forum />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.forum.listCategories'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/forum/listCategories',
          },
          {
            name: <IntlMessages id={'sidebar.forum.addCategories'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/forum/addCategories',
          },
          {
            name: <IntlMessages id={'sidebar.forum.listPosts'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/forum/listPosts',
          },
          {
            name: <IntlMessages id={'sidebar.forum.addPosts'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/forum/addPosts',
          },

          {
            name: <IntlMessages id={'sidebar.forum.listTopics'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/forum/listTopics',
          },
          {
            name: <IntlMessages id={'sidebar.forum.addTopics'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/forum/addTopics',
          },
          {
            name: <IntlMessages id={'sidebar.forum.listGroups'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/forum/listGroups',
          },
          {
            name: <IntlMessages id={'sidebar.forum.addGroups'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/forum/addGroups',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.newForum'} />,
        icon: <Ballot />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.newForum.listSystemGroups'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/new-forum/listSystemGroups',
          },
          {
            name: <IntlMessages id={'sidebar.newForum.listUserGroups'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/new-forum/listUserGroups',
          },
          // {
          //   name: <IntlMessages id={'sidebar.newForum.socialMediaGroup'} />,
          //   type: 'item',
          //   icon: <ArrowForward />,
          //   link: '/new-forum/listSocialMediaGroups',
          // },
          {
            name: <IntlMessages id={'sidebar.newForum.manageSocialPosts'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/new-forum/manageSocialPosts',
          },
          {
            name: <IntlMessages id={'sidebar.newForum.manageSocialUsers'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/new-forum/manageSocialUsers',
          },
          {
            name: <IntlMessages id={'sidebar.newForum.manageSocialComments'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/new-forum/manageSocialComments',
          },
          {
            name: <IntlMessages id={'sidebar.newForum.addSocialGroup'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/new-forum/addSocialGroup',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.inventory'} />,
        icon: <Dashboard />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.inventory.listItem'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/inventory/listItem',
          },
          {
            name: <IntlMessages id={'sidebar.inventory.addItem'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/inventory/addItem',
          },

          {
            name: <IntlMessages id={'sidebar.inventory.listInventoryCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/inventory/listInventoryCategory',
          },
          {
            name: <IntlMessages id={'sidebar.inventory.addInventoryCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/inventory/addInventoryCategory',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.uniform'} />,
        icon: <Extension />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.uniform.listUniform'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/uniform/listUniform',
          },
          {
            name: <IntlMessages id={'sidebar.uniform.addUniform'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/uniform/addUniform',
          },

          {
            name: <IntlMessages id={'sidebar.uniform.listProductAttribute'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/uniform/listProductAttribute',
          },
          {
            name: <IntlMessages id={'sidebar.uniform.addProductAttribute'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/uniform/addProductAttribute',
          },

          {
            name: <IntlMessages id={'sidebar.uniform.listAttributeType'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/uniform/listAttributeType',
          },
          {
            name: <IntlMessages id={'sidebar.uniform.addAttributeType'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/uniform/addAttributeType',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.flyers'} />,
        icon: <NoteIcon />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.flyers.listFlyers'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/flyers/listFlyers',
          },
          {
            name: <IntlMessages id={'sidebar.flyers.addFlyers'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/flyers/addFlyers',
          },

          {
            name: <IntlMessages id={'sidebar.flyers.listProductAttribute'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/flyers/listProductAttribute',
          },
          {
            name: <IntlMessages id={'sidebar.flyers.addProductAttribute'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/flyers/addProductAttribute',
          },

          {
            name: <IntlMessages id={'sidebar.flyers.listAttributeType'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/flyers/listAttributeType',
          },
          {
            name: <IntlMessages id={'sidebar.flyers.addAttributeType'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/flyers/addAttributeType',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.leads'} />,
        icon: <Iso />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.leads.issueLeads'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/leads/issueLeads',
          },
          {
            name: <IntlMessages id={'sidebar.leads.activeLeads'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/leads/activeLeads',
          },
          {
            name: <IntlMessages id={'sidebar.leads.snoozedLeads'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/leads/snoozedLeads',
          },
          {
            name: <IntlMessages id={'sidebar.leads.completedLeads'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/leads/completedLeads',
          },
          {
            name: <IntlMessages id={'sidebar.leads.forwardedLeads'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/leads/forwardedLeads',
          },
          {
            name: <IntlMessages id={'sidebar.leads.hoCompletedLeads'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/leads/hoCompletedLeads',
          },
          {
            name: <IntlMessages id={'sidebar.leads.leadsReport'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/leads/leadsReport',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.leadSuspendReason'} />,
        icon: <ChromeReaderMode />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.leadSuspendReason.leadsReason'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/leadSuspendReason/leadsReason',
          },
          {
            name: <IntlMessages id={'sidebar.leadSuspendReason.addReason'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/leadSuspendReason/addReason',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.autoReply'} />,
        icon: <ChatBubble />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.autoReply.listAutoReply'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/emailTemplate/listEmails',
          },
          {
            name: <IntlMessages id={'sidebar.autoReply.addAutoReply'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/emailTemplate/addEmail',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.freight'} />,
        icon: <LocalShipping />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.freight.listFreights'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/freight/listFreights',
          },
          {
            name: <IntlMessages id={'sidebar.freight.addFreight'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/freight/addFreight',
          },
          {
            name: <IntlMessages id={'sidebar.freight.listShipping'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/freight/listShipping',
          },
          {
            name: <IntlMessages id={'sidebar.freight.addShipping'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/freight/addShipping',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.orders'} />,
        icon: <MoveToInbox />,
        type: 'collapse',
        children: [
          // {
          //   name: <IntlMessages id={'sidebar.orders.addOrder'} />,
          //   type: 'item',
          //   icon: <ArrowForward />,
          //   link: '/orders/addOrder',
          // },
          {
            name: <IntlMessages id={'sidebar.orders.listRecentOrders'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/orders/listRecentOrders',
          },
          {
            name: <IntlMessages id={'sidebar.orders.listConfirmedOrders'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/orders/listConfirmedOrders',
          },
          {
            name: <IntlMessages id={'sidebar.orders.listShippedOrders'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/orders/listShippedOrders',
          },
          {
            name: <IntlMessages id={'sidebar.orders.listUnpaidOrders'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/orders/listUnpaidOrders',
          },
          {
            name: <IntlMessages id={'sidebar.orders.listPastOrders'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/orders/listPastOrders',
          },
          {
            name: <IntlMessages id={'sidebar.orders.listUndeliveredOrders'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/orders/listUndeliveredOrders',
          },
          {
            name: <IntlMessages id={'sidebar.orders.listCancelledOrders'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/orders/listCancelledOrders',
          },
          {
            name: <IntlMessages id={'sidebar.orders.listArchivedOrders'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/orders/listArchivedOrders',
          },
          {
            name: <IntlMessages id={'sidebar.orders.listShippingCompany'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/orders/listShippingCompany',
          },
          {
            name: <IntlMessages id={'sidebar.orders.addShippingCompany'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/orders/addShippingCompany',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.financial'} />,
        icon: <AttachMoney />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.financial.listExpenseCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/financial/listExpenseCategory',
          },
          {
            name: <IntlMessages id={'sidebar.financial.addExpenseCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/financial/addExpenseCategory',
          },
          {
            name: <IntlMessages id={'sidebar.financial.listExpenseSuperCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/financial/listExpenseSuperCategory',
          },
          {
            name: <IntlMessages id={'sidebar.financial.addExpenseSuperCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/financial/addExpenseSuperCategory',
          },
          {
            name: <IntlMessages id={'sidebar.financial.listIncomeCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/financial/listIncomeCategory',
          },
          {
            name: <IntlMessages id={'sidebar.financial.addIncomeCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/financial/addIncomeCategory',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.paymentType'} />,
        icon: <LocalAtm />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.paymentType.listPaymentType'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/paymentType/listPaymentType',
          },
          {
            name: <IntlMessages id={'sidebar.paymentType.addPaymentType'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/paymentType/addPaymentType',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.documents'} />,
        icon: <Description />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.documents.listDocuments'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/documents/listDocuments',
          },
          {
            name: <IntlMessages id={'sidebar.documents.addDocument'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/documents/addDocument',
          },
          {
            name: <IntlMessages id={'sidebar.documents.listDocumentType'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/documents/listDocumentType',
          },
          {
            name: <IntlMessages id={'sidebar.documents.addDocumentType'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/documents/addDocumentType',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.news'} />,
        icon: <InsertComment />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.news.listNews'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/news/listNews',
          },
          {
            name: <IntlMessages id={'sidebar.news.addNews'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/news/addNews',
          },
          {
            name: <IntlMessages id={'sidebar.news.listNewsCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/news/listNewsCategory',
          },
          {
            name: <IntlMessages id={'sidebar.news.addNewsCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/news/addNewsCategory',
          },
        ],
      },

      {
        name: <IntlMessages id={'sidebar.reports'} />,
        icon: <Assessment />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.reports.dashboard'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reports/dashboard',
          },
          {
            name: <IntlMessages id={'sidebar.reports.incomeReport'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reports/incomeReport',
          },
          {
            name: <IntlMessages id={'sidebar.reports.serviceGroupReport'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reports/serviceGroupReport',
          },
          {
            name: <IntlMessages id={'sidebar.reports.loginlog'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reports/loginlog',
          },
          {
            name: <IntlMessages id={'sidebar.reports.ipadlog'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reports/ipadlog',
          },
          {
            name: <IntlMessages id={'sidebar.reports.serviceReport'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/reports/serviceReport',
          },
        ],
      },
    ],
  },
];

const adminMenus = [
  {
    name: <IntlMessages id={'sidebar.dashboard'} />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id={'sidebar.dashboard'} />,
        icon: <Widgets />,
        type: 'item',
        link: '/dashboard/dashboard',
      },
      {
        name: <IntlMessages id={'sidebar.members'} />,
        icon: <Group />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.members.listMembers'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/members/listMembers',
          },
          {
            name: <IntlMessages id={'sidebar.members.addMembers'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/members/addMembers',
          },
          {
            name: <IntlMessages id={'sidebar.members.manageAdmins'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/members/manageAdmins',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.bookings'} />,
        icon: <Book />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.bookings.listActiveBookings'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/bookings/listActiveBookings',
          },
          {
            name: <IntlMessages id={'sidebar.bookings.listCompletedBookings'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/bookings/listCompletedBookings',
          },
          {
            name: <IntlMessages id={'sidebar.bookings.listCancelledBookings'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/bookings/listCancelledBookings',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.customers'} />,
        icon: <DonutSmall />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.customers.listCustomers'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/customers/listCustomers',
          },
        ],
      },
      {
        name: <IntlMessages id={'sidebar.news'} />,
        icon: <InsertComment />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.news.listNews'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/news/listNews',
          },
          {
            name: <IntlMessages id={'sidebar.news.addNews'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/news/addNews',
          },
          {
            name: <IntlMessages id={'sidebar.news.listNewsCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/news/listNewsCategory',
          },
          {
            name: <IntlMessages id={'sidebar.news.addNewsCategory'} />,
            type: 'item',
            icon: <ArrowForward />,
            link: '/news/addNewsCategory',
          },
        ],
      },
    ],
  },
];

// const navigationMenus = [
//   {
//     name: <IntlMessages id={'sidebar.dashboards'} />,
//     type: 'section',
//     children: [
//       {
//         name: <IntlMessages id={'sidebar.dashboard.crypto'} />,
//         icon: <MonetizationOn />,
//         type: 'item',
//         link: '/dashboard/crypto',
//       },
//       {
//         name: <IntlMessages id={'sidebar.dashboard.listing'} />,
//         icon: <Domain />,
//         type: 'item',
//         link: '/dashboard/listing',
//       },
//       {
//         name: <IntlMessages id={'sidebar.dashboard.crm'} />,
//         icon: <Group />,
//         type: 'item',
//         link: '/dashboard/crm',
//       },
//       {
//         name: <IntlMessages id={'sidebar.dashboard.intranet'} />,
//         icon: <DonutSmall />,
//         type: 'item',
//         link: '/dashboard/intranet',
//       },
//       {
//         name: <IntlMessages id={'sidebar.dashboard.ecommerce'} />,
//         icon: <LocalGroceryStore />,
//         type: 'item',
//         link: '/dashboard/eCommerce',
//       },
//       {
//         name: <IntlMessages id={'sidebar.dashboard.news'} />,
//         icon: <ImportContacts />,
//         type: 'item',
//         link: '/dashboard/news',
//       },
//       {
//         name: <IntlMessages id={'sidebar.dashboard.misc'} />,
//         icon: <LibraryBooks />,
//         type: 'item',
//         link: '/dashboard/misc',
//       },
//     ],
//   },
//   {
//     name: <IntlMessages id={'sidebar.components'} />,
//     type: 'section',
//     children: [
//       {
//         name: <IntlMessages id={'sidebar.components.muiComponents'} />,
//         icon: <Widgets />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.components.muiComponents.inputs'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.buttons'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/buttons',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.buttonGroup'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/button-group',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.checkbox'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/checkbox',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.fab'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/fab',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.dateTime'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/date-time',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.radio'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/radio',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.select'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/select',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.slider'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/slider',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.switch'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/switch',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.textField'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/text-field',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.inputs.transferList'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/transfer-list',
//               },
//             ],
//           },
//           {
//             name: <IntlMessages id={'sidebar.components.muiComponents.navigation'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.navigation.bottomNavigation'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/bottom-navigation',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.navigation.breadcrumb'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/breadcrumb',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.navigation.drawer'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/drawer',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.navigation.links'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/links',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.navigation.menu'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/menu',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.navigation.stepper'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/stepper',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.navigation.tabs'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/tabs',
//               },
//             ],
//           },
//           {
//             name: <IntlMessages id={'sidebar.components.muiComponents.surfaces'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.surfaces.appBar'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/appbar',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.surfaces.paper'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/paper',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.surfaces.card'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/card',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.surfaces.accordion'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/accordion',
//               },
//             ],
//           },
//           {
//             name: <IntlMessages id={'sidebar.components.muiComponents.feedback'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.feedback.progress'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/progress',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.feedback.dialog'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/dialog',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.feedback.snackbar'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/snackbar',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.feedback.backdrop'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/backdrop',
//               },
//             ],
//           },
//           {
//             name: <IntlMessages id={'sidebar.components.muiComponents.dataDisplay'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.dataDisplay.avatar'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/avatar',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.dataDisplay.badge'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/badge',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.dataDisplay.chip'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/chip',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.dataDisplay.divider'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/divider',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.dataDisplay.list'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/list',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.dataDisplay.table'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/table',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.dataDisplay.tooltip'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/tooltip',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.dataDisplay.typography'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/typography',
//               },
//             ],
//           },
//           {
//             name: <IntlMessages id={'sidebar.components.muiComponents.utils'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.utils.clickAwayListener'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/click-away-listener',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.utils.modal'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/modal',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.utils.noSsr'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/no-ssr',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.utils.popover'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/popover',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.utils.popper'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/popper',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.utils.portal'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/portal',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.utils.textareaAutosize'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/textarea-autosize',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.utils.transitions'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/transitions',
//               },
//             ],
//           },
//           {
//             name: <IntlMessages id={'sidebar.components.muiComponents.muiLab'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.muiLab.alert'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/alert',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.muiLab.autoComplete'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/auto-complete',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.muiLab.pagination'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/pagination',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.muiLab.rating'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/rating',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.muiLab.skeleton'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/skeleton',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.muiLab.speedDial'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/speed-dial',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.muiLab.timeline'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/timeline',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.muiLab.toggleButton'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/toggle-button',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.muiComponents.muiLab.treeView'} />,
//                 type: 'item',
//                 icon: <ArrowForward />,
//                 link: '/components/mui/tree-view',
//               },
//             ],
//           },
//         ],
//       },
//       {
//         name: <IntlMessages id={'sidebar.components.cmtComponents'} />,
//         icon: <Widgets />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.components.cmtComponents.inputs'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.inputs.buttons'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/buttons',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.inputs.search'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/search',
//               },
//             ],
//           },
//           {
//             name: <IntlMessages id={'sidebar.components.cmtComponents.display'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.display.carousel'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/carousel',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.display.avatar'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/avatar',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.display.avatarGroup'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/avatar-group',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.display.list'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/list',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.display.groupList'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/group-list',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.display.gridView'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/grid-view',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.display.mediaObject'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/media-object',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.display.objectSummary'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/object-summary',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.display.timeline'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/timeline',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.display.drawer'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/drawer',
//               },
//             ],
//           },
//           {
//             name: <IntlMessages id={'sidebar.components.cmtComponents.surface'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.surface.basicCard'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/basic-card',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.surface.expendableCard'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/expendable-card',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.surface.advancedCard'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/advanced-card',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.surface.revealCard'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/reveal-card',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.surface.backDrop'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/back-drop',
//               },
//             ],
//           },
//           {
//             name: <IntlMessages id={'sidebar.components.cmtComponents.feedback'} />,
//             type: 'section',
//             children: [
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.feedback.progressBar'} />,
//                 icon: <ArrowForward />,
//                 type: 'item',
//                 link: '/components/coremat/progressbar',
//               },
//               {
//                 name: <IntlMessages id={'sidebar.components.cmtComponents.feedback.notifications'} />,
//                 icon: <Notifications />,
//                 type: 'item',
//                 link: '/components/coremat/notifications',
//               },
//             ],
//           },
//         ],
//       },
//       {
//         name: <IntlMessages id={'sidebar.widgets'} />,
//         icon: <Category />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.classic'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/widgets/classic',
//           },
//           {
//             name: <IntlMessages id={'sidebar.modern'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/widgets/modern',
//           },
//         ],
//       },
//       {
//         name: <IntlMessages id={'sidebar.metrics'} />,
//         icon: <ShowChart />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.classic'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/metrics/classic',
//           },
//           {
//             name: <IntlMessages id={'sidebar.modern'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/metrics/modern',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     name: <IntlMessages id={'sidebar.Apps'} />,
//     type: 'section',
//     children: [
//       {
//         name: <IntlMessages id={'sidebar.appModule.mail'} />,
//         type: 'item',
//         icon: <Email />,
//         link: '/apps/mail',
//       },
//       {
//         name: <IntlMessages id={'sidebar.appModule.toDo'} />,
//         type: 'item',
//         icon: <CheckCircle />,
//         link: '/apps/to-do',
//       },
//       {
//         name: <IntlMessages id={'sidebar.appModule.contact'} />,
//         type: 'item',
//         icon: <ContactMail />,
//         link: '/apps/contact',
//       },
//       {
//         name: <IntlMessages id={'sidebar.appModule.chat'} />,
//         type: 'item',
//         icon: <Chat />,
//         link: '/apps/chat',
//       },
//     ],
//   },
//   {
//     name: <IntlMessages id={'sidebar.social'} />,
//     type: 'section',
//     children: [
//       {
//         name: <IntlMessages id={'sidebar.profile'} />,
//         type: 'item',
//         icon: <AccountCircle />,
//         link: '/apps/social-apps/profile',
//       },
//       {
//         name: <IntlMessages id={'sidebar.wall'} />,
//         type: 'item',
//         icon: <Contacts />,
//         link: '/apps/social-apps/wall',
//       },
//     ],
//   },
//   {
//     name: <IntlMessages id={'sidebar.extensions'} />,
//     type: 'section',
//     children: [
//       {
//         name: <IntlMessages id={'sidebar.extensions.editors'} />,
//         icon: <Edit />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.extensions.editors.CKEditor'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extensions/editors/ck',
//           },
//           {
//             name: <IntlMessages id={'sidebar.extensions.editors.WYSISWYGEditor'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extensions/editors/wysiswyg',
//           },
//         ],
//       },
//       {
//         name: <IntlMessages id={'sidebar.extensions.pickers'} />,
//         icon: <Colorize />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.extensions.pickers.date'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extensions/pickers/date',
//           },
//           {
//             name: <IntlMessages id={'sidebar.extensions.pickers.time'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extensions/pickers/time',
//           },
//           {
//             name: <IntlMessages id={'sidebar.extensions.pickers.dateTimePickers'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extensions/pickers/date-time',
//           },
//           {
//             name: <IntlMessages id={'sidebar.extensions.pickers.colorPickers'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extensions/pickers/color',
//           },
//         ],
//       },
//       {
//         name: <IntlMessages id={'sidebar.extensions.dragNDrop'} />,
//         icon: <DragIndicator />,
//         type: 'item',
//         link: '/extensions/dnd',
//       },
//       {
//         name: <IntlMessages id={'sidebar.extensions.dropzone'} />,
//         icon: <CloudUpload />,
//         type: 'item',
//         link: '/extensions/dropzone',
//       },
//       {
//         name: <IntlMessages id={'sidebar.extensions.sweetAlert'} />,
//         icon: <NotificationImportant />,
//         type: 'item',
//         link: '/extensions/sweet-alert',
//       },
//       {
//         name: <IntlMessages id={'sidebar.extensions.notification'} />,
//         icon: <Notifications />,
//         type: 'item',
//         link: '/extensions/notification',
//       },
//     ],
//   },
//   {
//     name: <IntlMessages id={'sidebar.visualization'} />,
//     type: 'section',
//     children: [
//       {
//         name: <IntlMessages id={'sidebar.visualization.chart'} />,
//         icon: <InsertChart />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.visualization.chart.line'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/chart/line',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.chart.bar'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/chart/bar',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.chart.area'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/chart/area',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.chart.composed'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/chart/composed',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.chart.scatter'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/chart/scatter',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.chart.pie'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/chart/pie',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.chart.radial'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/chart/radial',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.chart.radar'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/chart/radar',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.chart.tree'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/chart/treemap',
//           },
//         ],
//       },
//       {
//         name: <IntlMessages id={'sidebar.visualization.map'} />,
//         icon: <Map />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.visualization.map.simple'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/map/simple',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.map.styled'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/map/styled',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.map.geoLocation'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/map/geo-location',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.map.mapDirection'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/map/directions',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.map.overlay'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/map/overlay',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.map.kmLayer'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/map/kml',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.map.popupInfo'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/map/popup-info',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.map.streetView'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/map/street-view',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.map.mapDrawing'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/map/drawing',
//           },
//           {
//             name: <IntlMessages id={'sidebar.visualization.map.mapClustering'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/visualization/map/clustering',
//           },
//         ],
//       },
//     ],
//   },
//   {
//     name: <IntlMessages id={'sidebar.extraPages'} />,
//     type: 'section',
//     children: [
//       {
//         name: <IntlMessages id={'sidebar.appModule.login'} />,
//         icon: <LockOutlined />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.appModule.login1'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extra-pages/login',
//           },
//           {
//             name: <IntlMessages id={'sidebar.appModule.login2'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extra-pages/login-standard',
//           },
//         ],
//       },
//       {
//         name: <IntlMessages id={'sidebar.appModule.signup'} />,
//         icon: <Edit />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.appModule.signup1'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extra-pages/sign-up',
//           },
//           {
//             name: <IntlMessages id={'sidebar.appModule.signup2'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extra-pages/sign-up-standard',
//           },
//         ],
//       },
//       {
//         name: <IntlMessages id={'sidebar.appModule.forgotPassword'} />,
//         icon: <EditAttributes />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.appModule.forgotPassword1'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extra-pages/forgot-password',
//           },
//           {
//             name: <IntlMessages id={'sidebar.appModule.forgotPassword2'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extra-pages/forgot-password-standard',
//           },
//         ],
//       },
//       {
//         name: <IntlMessages id={'sidebar.appModule.error'} />,
//         icon: <Error />,
//         type: 'collapse',
//         children: [
//           {
//             name: <IntlMessages id={'sidebar.extraPages.404'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extra-pages/error-404',
//           },
//           {
//             name: <IntlMessages id={'sidebar.extraPages.500'} />,
//             icon: <ArrowForward />,
//             type: 'item',
//             link: '/extra-pages/error-500',
//           },
//         ],
//       },
//     ],
//   },
// ];

const SideBar = () => {
  const classes = useStyles();

  var login = localStorage.getItem('login');
  var loginData = JSON.parse(login);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={loginData && loginData.user.SuperAdmin === '0' ? adminMenus : navigationMenus} />
      {/* <SidebarButtons /> */}
    </PerfectScrollbar>
  );
};

export default SideBar;
