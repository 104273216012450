import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  makeStyles,
  InputLabel,
  Button,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
    width: '50%',
    margin: '0 auto',
  },
  textField: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
  },
  textSelect: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  // submitBtn: {
  //   marginTop: '10px',
  //   float: 'right',
  // },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ChangeStatus = props => {
  const classes = useStyles();
  const [inventoryID, setInventoryID] = useState(props && props.location.pathname.slice(24));
  const [loading, setLoading] = useState(false);
  const [statusDetails, setStatusDetails] = useState({
    inventoryStatus: '',
  });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  useEffect(() => {
    getInventoryStatus();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setStatusDetails({
      ...statusDetails,
      [name]: value,
    });
  };

  const getInventoryStatus = () => {
    inventoryID &&
      httpClient.get(`/inventory/${inventoryID}`).then(({ data }) => {
        if (data.success === true) {
          setStatusDetails({
            ...statusDetails,
            inventoryStatus: data.data.inventoryStatus == 'InStock' ? 1 : 2,
          });
        }
      });
  };

  const handleChangeStatus = () => {
    setLoading(true);
    httpClient.get(`/inventory/stock/${inventoryID}/${statusDetails.inventoryStatus}`).then(({ data }) => {
      if (data.success === true) {
        setOpen(true);
        setMessage('Inventory Status successfully changed');
        setMessageState('success');
        setTimeout(() => {
          history.push('/inventory/listItem');
        }, 1000);
        setLoading(false);
      } else {
        setOpen(true);
        setMessage('Failed to change Inventory Status');
        setMessageState('error');
        setLoading(false);
      }
    });
  };

  return (
    <PageContainer heading="Change Inventory Status">
      <Card className={classes.root}>
        <Grid container spacing={2}>
          <form className={classes.formControl}>
            <Grid item sm={12} md={12}>
              <InputLabel>Change Inventory Status</InputLabel>
              <FormControl variant="outlined" className={classes.textSelect}>
                <Select
                  name="inventoryStatus"
                  value={statusDetails.inventoryStatus}
                  onChange={handleChange}
                  className={classes.textField}>
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={1}>In Stock</MenuItem>
                  <MenuItem value={2}>Out of Stock</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={12} mt={2}>
              <Button variant="contained" color="primary" onClick={handleChangeStatus}>
                Change Status
              </Button>
              {loading ? (
                <span style={{ marginLeft: '10px', position: 'absolute' }}>
                  <CircularProgress size={25} />
                </span>
              ) : (
                ''
              )}
            </Grid>
          </form>
        </Grid>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ChangeStatus;
