import React, { createContext, useContext, useState } from 'react';

const initialState = {
  flyersListPageNum: 1,
  attributeTypeListPageNum: 0,
  productAttributeListPageNum: 0,
};

const FlyersContext = createContext();

const FlyersContextProvider = ({ children }) => {
  const [flyersPagination, setFlyersPagination] = useState(initialState);

  return <FlyersContext.Provider value={{ flyersPagination, setFlyersPagination }}>{children}</FlyersContext.Provider>;
};

const useFlyersContext = () => useContext(FlyersContext);

export { useFlyersContext, FlyersContextProvider };
