import React, { useEffect, useState } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
// import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { httpClient } from 'appUtility/Api';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);
  const [shipping, setShipping] = useState([]);
  const [inventoryCategory, setInventoryCategory] = useState([]);

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  useEffect(() => {
    getShipping();
    getInventoryCats();
  }, []);

  const getShipping = () => {
    httpClient.get('/shipping').then(({ data }) => {
      if (data) {
        setShipping(data.data);
      } else {
      }
    });
  };

  const getInventoryCats = () => {
    httpClient.get('/inventory/category').then(({ data }) => {
      if (data) {
        setInventoryCategory(data.data);
      } else {
      }
    });
  };

  const sendTo = () => {
    history.push('/freight/addFreight');
  };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '10px' }}>
            +Add
          </Button>
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Search </InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Shipping Zone</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="shipping_zone_id"
                      value={props.filterData.shipping_zone_id}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      {shipping &&
                        shipping.map(ship => (
                          <MenuItem value={ship.shipping_zone_id} key={ship.shipping_zone_id}>
                            {ship.shipping_zone_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Inventory Category</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="inventory_category_id"
                      value={props.filterData.inventory_category_id}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      {inventoryCategory &&
                        inventoryCategory.map(cat => (
                          <MenuItem value={cat.inventory_category_id} key={cat.inventory_category_id}>
                            {cat.inventory_category_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Freight Cost </InputLabel>
                  <TextField
                    variant="outlined"
                    name="freightCost"
                    value={props.filterData.freightCost}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Freight Per Kg</InputLabel>
                  <TextField
                    variant="outlined"
                    name="freightPerKG"
                    value={props.filterData.freightPerKG}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Freight Active</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="freightActive"
                      value={props.filterData.freightActive}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.freightActive ||
        props.submittedData.freightCost ||
        props.submittedData.freightPerKG ||
        props.submittedData.shipping_zone_id ||
        props.submittedData.inventory_category_id ||
        props.submittedData.search ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.shipping_zone_id && (
              <p>
                Shipping Zone:{' '}
                <span>
                  {shipping &&
                    shipping.map(ship => {
                      if (ship.shipping_zone_id === props.submittedData.shipping_zone_id) {
                        return ship.shipping_zone_name;
                      }
                    })}
                </span>
                <Close fontSize="small" onClick={() => props.handleRemove('shipping_zone_id')} />
              </p>
            )}
            {props.submittedData.inventory_category_id && (
              <p>
                Inventory Category:<span> {props.submittedData.inventory_category_id} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('inventory_category_id')} />
              </p>
            )}
            {props.submittedData.freightCost && (
              <p>
                Freight Cost:<span> {props.submittedData.freightCost} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('freightCost')} />
              </p>
            )}
            {props.submittedData.freightPerKG && (
              <p>
                Freight Per Kg:<span> {props.submittedData.freightPerKG} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('freightPerKG')} />
              </p>
            )}
            {props.submittedData.freightActive && (
              <p>
                Freight Active:<span> {props.submittedData.freightActive == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('freightActive')} />
              </p>
            )}
            {props.submittedData.search && (
              <p>
                Search:<span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleEdit={props.handleEdit}
          handleDelete={props.handleDelete}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
