import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Card,
  CardContent,
  Grid,
  Collapse,
  Box,
  Tooltip,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { Cancel, Delete, Edit, MoreVert, Settings } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { httpClient } from 'appUtility/Api';
import qs from 'qs';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  imageListClass: {
    display: 'inline',
    listStyle: 'none',
    margin: '10px',
    position: 'relative',
  },

  imgClass: {
    borderRadius: '10px',
    height: '80px',
    width: '80px',
    objectFit: 'cover',
  },
  imageCancel: {
    position: 'absolute',
    right: '0',
    cursor: 'pointer',
  },
}));

const EditPostDialog = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [loading, setLoading] = useState(false);

  const [showImageDialog, setShowImageDialog] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const [postDetails, setPostDetails] = useState({
    open: true,
    success: '',
    title: '',
    active: false,
    socialGroupID: '',
    postByUserID: '',
    parentCommentID: '',
    commentedByUserID: '',
    postID: '',
    commentID: '',
  });

  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    getSingle();
  }, []);

  const getSingle = () => {
    if (props.currentSocial === 'Post') {
      httpClient.get(`social-post/${props.rowData.postID}`).then(({ data }) => {
        if (data.success) {
          setPostDetails({
            ...postDetails,
            title: data.data.postTitle,
            active: data.data.postActive === 'Active' ? true : false,
            socialGroupID: data.data.socialGroup && data.data.socialGroup.socialGroupID,
            postByUserID: data.data.postByUserID && data.data.postByUserID.postByUserID,
            postID: data.data.postID,
          });
          setImageList(data.data.postImage);
        }
      });
    } else if (props.currentSocial === 'Comment') {
      httpClient.get(`social-post-comment/${props.rowData.commentsID}`).then(({ data }) => {
        if (data.success) {
          setPostDetails({
            ...postDetails,
            title: data.data.commentText,
            active: data.data.commentActive === 'Active' ? true : false,
            parentCommentID: data.data.parentCommentID,
            commentedByUserID: data.data.commentedByUserID,
            postID: data.data.postID,
            commentID: data.data.commentsID,
          });
        }
      });
    } else {
      httpClient.get(`social-post-comment/${props.rowData.subCommentsID}`).then(({ data }) => {
        if (data.success) {
          setPostDetails({
            ...postDetails,
            title: data.data.commentText,
            active: data.data.commentActive === 'Active' ? true : false,
            parentCommentID: data.data.parentCommentID,
            commentedByUserID: data.data.commentedByUserID,
            postID: data.data.postID,
            commentID: data.data.commentsID,
          });
        }
      });
    }
  };

  const handleClose = () => {
    setPostDetails({
      ...postDetails,
      open: false,
      success: false,
    });
  };

  const handleClosePostSuccess = () => {
    setPostDetails({
      ...postDetails,
      open: false,
      success: 'Post',
    });
  };

  const handleCloseCommentSuccess = () => {
    setPostDetails({
      ...postDetails,
      open: false,
      success: 'Comment',
    });
  };

  const handleCloseSubCommentSuccess = () => {
    setPostDetails({
      ...postDetails,
      open: false,
      success: 'SubComment',
    });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setPostDetails({
      ...postDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setPostDetails({
      ...postDetails,
      [name]: checked,
    });
  };

  const handleEditPost = () => {
    setLoading(true);
    if (props.currentSocial === 'Post') {
      httpClient
        .post(
          `social-post/${props.rowData.postID}`,
          qs.stringify({
            socialGroupID: postDetails.socialGroupID,
            postByUserID: postDetails.postByUserID,
            postTitle: postDetails.title,
            postActive: postDetails.active === true ? 1 : 0,
            postImage: imageList.length > 0 ? imageList.map(img => img.imageURL) : [],
          }),
        )
        .then(({ data }) => {
          if (data.success) {
            setLoading(false);
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            setPostDetails({
              ...postDetails,
              title: '',
              active: '',
              socialGroupID: '',
              postActive: '',
              postID: '',
            });
            setTimeout(() => {
              handleClosePostSuccess();
            }, 1500);
          } else {
            setLoading(false);
            setOpen(true);
            setMessage('Failed to update the post');
            setMessageState('failure');
          }
        });
    } else {
      httpClient
        .post(
          `social-post-comment/${props.rowData.commentsID || props.rowData.subCommentsID}`,
          qs.stringify({
            postID: postDetails.postID,
            parentCommentID: postDetails.parentCommentID,
            commentedByUserID: postDetails.commentedByUserID,
            commentText: postDetails.title,
            commentActive: postDetails.active === true ? 1 : 0,
          }),
        )
        .then(({ data }) => {
          if (data.success) {
            setLoading(false);
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            setPostDetails({
              ...postDetails,
              title: '',
              active: '',
              postID: '',
              parentCommentID: '',
              commentedByUserID: '',
              commentID: '',
            });
            setTimeout(() => {
              props.rowData.commentsID ? handleCloseCommentSuccess() : handleCloseSubCommentSuccess();
            }, 1500);
          } else {
            setLoading(false);
            setOpen(true);
            setMessage('Failed to update the comment');
            setMessageState('failure');
          }
        });
    }
  };

  const handleShowImg = img => {
    setShowImageDialog(true);
    setCurrentImage(img);
  };

  const returnImageBack = call => {
    if (call.open === false) {
      setShowImageDialog(false);
      setCurrentImage('');
    }
  };

  const handleRemoveImg = id => {
    var newList =
      imageList.length > 0 &&
      imageList.filter(img => {
        return img.imageID !== id;
      });

    setImageList(newList);
  };

  console.log('image list', imageList);

  props.sendBack(postDetails);

  return (
    <Dialog open={postDetails.open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <h2>
          {props.currentSocial === 'Post' && 'Update Post'}
          {props.currentSocial === 'Comment' && 'Update Comment'}
          {props.currentSocial === 'SubComment' && 'Update Sub Comment'}
        </h2>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                multiline
                rows={3}
                name="title"
                value={postDetails.title}
                label="Title"
                variant="outlined"
                onChange={handleChange}
                style={{ width: '100%' }}
              />
            </Grid>
            {imageList.length > 0 && (
              <Grid item md={12}>
                <ul>
                  {imageList.map(image => (
                    <li key={image.imageID} className={classes.imageListClass}>
                      <img
                        src={image.imageURL}
                        // src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80"
                        alt="..."
                        className={classes.imgClass}
                        onClick={() => handleShowImg(image.imageURL)}
                      />
                      <span className={classes.imageCancel} onClick={() => handleRemoveImg(image.imageID)}>
                        <Cancel />
                      </span>
                    </li>
                  ))}
                </ul>
              </Grid>
            )}
            <Grid item md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    checked={postDetails.active}
                    onChange={handleCheck}
                    value={postDetails.active}
                    color="primary"
                  />
                }
                label="Active ?"
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleEditPost} color="secondary">
          Update
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {loading && <CircularProgress size={25} />}
      </DialogActions>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default EditPostDialog;
