import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ShowImageDialog = props => {
  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  props.returnImageBack(alertData);

  return (
    <Dialog open={alertData.open} onClose={handleClose} fullWidth maxWidth="lg">
      <img src={props.currentImage} alt=".." style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </Dialog>
  );
};

export default ShowImageDialog;
