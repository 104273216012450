import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, CircularProgress, Fade, Grid, IconButton, Menu, MenuItem, styled } from '@material-ui/core';
import { Close, MoreVert } from '@material-ui/icons';
import { httpClient } from 'appUtility/Api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ConfirmationAlert from '../ConfirmationAlert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MenuStyle = styled(Menu)(({ theme }) => ({
  background: 'transparent',
  '& .MuiMenu-paper': {
    background: '#fff',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    backdropFilter: 'blur(10px) !important',
  },
  '& .MuiPaper-elevation8': {
    boxShadow: 'none',
    border: '1px solid #f1f1f1',
  },
}));

const MemberDialog = props => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [loading, setLoading] = useState(false);
  const [currID, setCurrID] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [action, setAction] = useState('');

  const [memberDetails, setMemberDetails] = useState({
    open: true,
    success: '',
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    setAllUsers(props && props.rowDetails.socialUser);
  }, []);

  const handleClick = (event, user) => {
    // console.log('event', event.currentTarget);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrID(user.userID);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrID('');
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setMemberDetails({
      ...memberDetails,
      open: false,
      success: false,
    });
  };

  const handleDeactivate = () => {
    setOpenAlert(true);
    setAction('deactivate');
  };

  const handleDelete = () => {
    setOpenAlert(true);
    setAction('delete');
  };

  const sendCallback = call => {
    if (call.open === false) {
      setOpenAlert(false);
      setAction('');
    }
    if (call.success === true) {
      if (action === 'delete') {
        handleRemove();
      }
      if (action === 'deactivate') {
        handleStatus();
      }
    }
  };

  const handleRemove = e => {
    handleClose();
    setLoading(true);
    httpClient
      .get(`social-group/subscription/user/delete?socialGroupID=${props.rowDetails.socialGroupID}&userID=${currID}`)
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');

          var updated = allUsers.filter(user => user.userID !== currID);
          setAllUsers(updated);

          setTimeout(() => {
            // handleCloseDialog();
            props.handleRefetch();
          }, 1000);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage(data.message);
          setMessageState('error');
        }
      });
  };

  const handleStatus = e => {
    // setCurrID(id);
    handleClose();
    setLoading(true);
    httpClient
      .get(
        `social-group/subscription/user/activate-deactivate?socialGroupID=${props.rowDetails.socialGroupID}&userID=${currID}`,
      )
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');

          var updated = allUsers.filter(user => user.userID !== currID);
          setAllUsers(updated);

          setTimeout(() => {
            // handleCloseDialog();
            props.handleRefetch();
          }, 1000);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage(data.message);
          setMessageState('error');
        }
      });
  };

  // console.log('all', allUsers);

  // console.log('current id', currID);

  props.sendBackMember(memberDetails);

  return (
    <>
      <Dialog open={memberDetails.open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2>{props.rowDetails.socialGroupName} Member List </h2>
            <IconButton onClick={handleCloseDialog}>
              <Close fontSize="small" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={3}>
              {loading ? (
                <Box
                  style={{
                    height: '300px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <CircularProgress />
                </Box>
              ) : (
                allUsers.length > 0 &&
                allUsers.map((user, index) => (
                  // (currID == user.userID) ? (
                  <Grid item md={6}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <div>{index + 1 + '.' + ' ' + user.userName}</div>
                      {props.social && (
                        <div>
                          <IconButton
                            aria-label="more"
                            aria-haspopup="true"
                            // aria-label="more"
                            // id="long-button"
                            // aria-controls={open ? 'long-menu' : undefined}
                            // aria-expanded={open ? 'true' : undefined}
                            // aria-haspopup="true"
                            onClick={e => handleClick(e, user)}>
                            <MoreVert />
                          </IconButton>
                          <MenuStyle
                            id="menu-popup"
                            keepMounted
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleClose}
                            TransitionComponent={Fade}>
                            <MenuItem onClick={e => handleDelete(e)}>Remove</MenuItem>
                            <MenuItem onClick={e => handleDeactivate(e)}>
                              {user.subscriptionActive === 'Active' ? 'Deactivate' : 'Activate'}
                            </MenuItem>
                          </MenuStyle>
                        </div>
                      )}
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {openAlert && <ConfirmationAlert action={action} sendCallback={sendCallback} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MemberDialog;
