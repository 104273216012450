import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useRef, useState } from 'react';
import { history } from 'redux/store';
import TableReportComponent from '../TableReportComponent';
import moment from 'moment';
const qs = require('qs');

const columns = [
  { id: 'franchiseeName', label: 'Name' },
  { id: 'startDate', label: 'Start Date' },
  { id: 'endDate', label: 'End Date' },

  { id: 'Leadreceived', label: 'Received' },
  { id: 'LeadCompleted', label: 'Completed' },
  { id: 'Leadignored', label: 'Ignored' },
  { id: 'LeadForwardedToHeadOffice', label: 'Forwarded HO' },
  { id: 'charge', label: 'Invoice/Charge' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const LeadsReport = () => {
  const [leadsData, setLeadsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );

  var leadsStorage = JSON.parse(localStorage.getItem('income_report_filter'));
  // console.log('income storage', leadsStorage);

  const [total, setTotal] = useState('');
  //   const [dialogOpen, setDialogOpen] = useState(false);
  //   const [viewID, setViewID] = useState('');
  //   const [viewName, setViewName] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    startDate:
      leadsStorage !== null
        ? leadsStorage.startDate
        : // : moment().subtract(1, 'month').format('MM-DD-YYYY'),
          moment()
            .startOf('month')
            .format('MM-DD-YYYY'),

    endDate:
      leadsStorage !== null
        ? leadsStorage.endDate
        : moment()
            // .endOf('month')
            .format('MM-DD-YYYY'),
    currStartDate:
      leadsStorage !== null
        ? leadsStorage.currStartDate
        : // : moment().subtract(1, 'month').format('DD-MM-YYYY'),
          moment()
            .startOf('month')
            .format('DD-MM-YYYY'),

    currEndDate:
      leadsStorage !== null
        ? leadsStorage.currEndDate
        : moment()
            // .endOf('month')
            .format('DD-MM-YYYY'),
    franchiseID: '',
    franchiseName: '',
    // search: '',
  });
  const [filterData, setFilterData] = useState({
    startDate:
      leadsStorage !== null
        ? leadsStorage.startDate
        : // : moment().subtract(1, 'month').format('MM-DD-YYYY'),
          moment()
            .startOf('month')
            .format('MM-DD-YYYY'),

    endDate:
      leadsStorage !== null
        ? leadsStorage.endDate
        : moment()
            // .endOf('month')
            .format('MM-DD-YYYY'),
    currStartDate:
      leadsStorage !== null
        ? leadsStorage.currStartDate
        : // : moment().subtract(1, 'month').format('DD-MM-YYYY'),
          moment()
            .startOf('month')
            .format('DD-MM-YYYY'),

    currEndDate:
      leadsStorage !== null
        ? leadsStorage.currEndDate
        : moment()
            // .endOf('month')
            .format('DD-MM-YYYY'),
    franchiseID: leadsStorage !== null ? leadsStorage.franchiseID : '',
    franchiseName: leadsStorage !== null ? leadsStorage.franchiseName : '',
    // search: '',
    removed: false,
  });

  useEffect(() => {
    if (filterData.startDate === '' && filterData.endDate === '' && filterData.franchiseID === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.startDate === ' ') filterData.startDate = '';
    if (filterData.endDate === ' ') filterData.endDate = '';
    if (filterData.franchiseID === ' ') filterData.franchiseID = '';
    if (filterData.franchiseName === ' ') filterData.franchiseName = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    // getLeadsReport();
    // handleSubmitData();
    let logsStorage = JSON.parse(localStorage.getItem('leads_report_filter'));
    logsStorage !== null && setFilterData(logsStorage);

    logsStorage == null
      ? getLeadsReport()
      : logsStorage.startDate == '' &&
        logsStorage.endDate == '' &&
        logsStorage.currEndDate == '' &&
        logsStorage.currStartDate == '' &&
        logsStorage.franchiseID == '' &&
        logsStorage.franchiseName == ''
      ? getLeadsReport()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      startDate: filterData.startDate,
      endDate: filterData.endDate,
      franchiseID: filterData.franchiseID,
      franchiseName: filterData.franchiseName,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('leads_report_filter', JSON.stringify(filterData));
    if (filterData.startDate || filterData.endDate || filterData.franchiseID || filterData.franchiseName) {
      httpClient
        .get(
          `lead/filter/count?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&franchiseeID=${filterData.franchiseID}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setLeadsData(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getLeadsReport();
    }
  };

  const handleFilter = e => {
    e.preventDefault();

    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'franchiseID') {
      filterData.franchiseName = value;
    }
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleChangeFranchise = value => {
    setFilterData({
      ...filterData,
      franchiseID: value !== null ? value.memberID : '',
      franchiseName: value !== null ? value.firstName + ' ' + value.lastName : '',
    });
  };

  const handleStartDate = e => {
    // const { name, value } = e.target;
    setFilterData({
      ...filterData,
      // startDate: moment(e).format('DD-MM-YYYY'),
      startDate: moment(e).format('MM-DD-YYYY'),
      currStartDate: moment(e).format('DD-MM-YYYY'),
      removed: false,
    });
  };

  const handleEndDate = e => {
    // const { name, value } = e.target;
    setFilterData({
      ...filterData,
      // endDate: moment(e).format('DD-MM-YYYY'),
      endDate: moment(e).format('MM-DD-YYYY'),
      currEndDate: moment(e).format('DD-MM-YYYY'),
      removed: false,
    });
  };

  const handleRemove = data => {
    if (data === 'franchiseID') {
      filterData.franchiseID = '';
      filterData.franchiseName = '';
    }
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpClient
          .get(
            `lead/filter/count?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&franchiseeID=${
              filterData.franchiseID
            }&sort_by=${column.id}&direction=${!direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setLeadsData(data.data);
            }
          })
      : httpClient
          .get(`lead/filter/count?sort_by=${column.id}&direction=${!direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
          .then(({ data }) => {
            if (data.success === true) {
              setLeadsData(data.data);
            }
          });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `lead/filter/count?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&franchiseeID=${filterData.franchiseID}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setLeadsData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`lead/filter/count/?&direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setLeadsData(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(
            `lead/filter/count?startDate=${filterData.currStartDate}&endDate=${filterData.currEndDate}&franchiseeID=${
              filterData.franchiseID
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setLeadsData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`lead/filter/count?&direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setLeadsData(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const getLeadsReport = () => {
    setLoading(true);
    httpClient.get(`lead/filter/count?&${rowsPerPage}`).then(({ data }) => {
      if (data) {
        setLeadsData(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
      }
    });
  };

  // const handleView = val => {
  //   setViewID(val.id);
  //   setViewName(val.name);
  //   setDialogOpen(true);
  // };

  // const sendTo = child => {
  //   child == false && setDialogOpen(false);
  // };

  return (
    <PageContainer heading="Report">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableReportComponent
              columns={columns}
              selectedProducts={leadsData}
              title="Leads Report"
              //   handleView={handleView}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total && total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleChangeFranchise={handleChangeFranchise}
              handleRemove={handleRemove}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>

      {/* {dialogOpen && <ViewDialog sendTo={sendTo} viewID={viewID} title={viewName} />} */}
    </PageContainer>
  );
};

export default LeadsReport;
