import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = props => {
  const [alertData, setAlertData] = useState({
    open: true,
    lock: props.lock ? props.lock : '',
    unlock: props.unlock ? props.unlock : '',
    success: '',
  });

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleLock = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: true,
    });
  };

  props.sendLocked(alertData);

  return (
    <Dialog open={alertData.open} onClose={handleClose}>
      <DialogTitle>
        {props.lock && 'Lock the Order?'}
        {props.unlock && 'Un-lock the Order?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.lock && 'This will lock the order. Are you sure?'}
          {props.unlock && 'This will un-lock the order. Are you sure?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleLock} color="secondary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
