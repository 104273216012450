import React from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { httpClient } from 'appUtility/Api';
import CircularProgress from '@material-ui/core/CircularProgress';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  twoFAContent: {
    minHeight: '200px',
  },
  qrCode: {
    height: '250px',
    width: '250px',
  },
  listIcon: {
    width: '10px',
    height: '10px',
    marginRight: '10px',
  },
  addIcon: {
    width: '15px',
    height: '15px',
  },
  scanText: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const SuperTwoFactor = props => {
  const {
    classes,
    verificationCode,
    setVerificationCode,
    loading,
    setLoading,
    setOpen,
    setMessage,
    setMessageState,
    superAdmin,
    sysAdminID,
    token,
    user,
  } = props;

  const TwoFAclasses = useStyles();

  const headerConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleVerifyCode = () => {
    if (!verificationCode) {
      setOpen(true);
      setMessage('Please enter the authentication code');
      setMessageState('error');
      return;
    }

    setLoading(true);
    httpClient
      .post(
        'twofa/verify',
        qs.stringify({
          otp: verificationCode,
          secret: '',
          superAdmin,
          sysAdminID,
        }),
        headerConfig,
      )
      .then(({ data }) => {
        if (data.success) {
          localStorage.setItem('token', JSON.stringify(token));
          localStorage.setItem('login', JSON.stringify(user));

          setTimeout(function() {
            setOpen(true);
            setMessage('Successfully Logged in');
            setMessageState('success');
          }, 1000);
          setTimeout(function() {
            window.location = '/dashboard/dashboard';
          }, 2000);
          setTimeout(function() {
            setLoading(false);
          }, 2500);
        } else {
          setTimeout(function() {
            setOpen(true);
            setMessage(data.message || 'OTP verification failed.');
            setMessageState('error');
            setLoading(false);
          }, 1000);
        }
      });
  };

  return (
    <Box className={TwoFAclasses.twoFAContent} mt={10}>
      <Typography variant="body1" className={classes.titleRoot}>
        Enter the Google Autheticator verification OTP here:
      </Typography>
      <Box mb={2}>
        <TextField
          aria-label="2FA OTP input"
          placeholder="Enter OTP"
          variant="outlined"
          inputProps={{ type: 'number' }}
          value={verificationCode}
          onChange={e => setVerificationCode(e.target.value)}
          margin="normal"
        />
      </Box>
      <Button onClick={handleVerifyCode} variant="contained" color="primary" disabled={loading}>
        <IntlMessages id="signIn.verify2FAOTP" />
      </Button>
      {loading ? <CircularProgress size={20} style={{ marginLeft: '10px' }} /> : ''}
    </Box>
  );
};

export default SuperTwoFactor;
