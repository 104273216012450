import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableUserComponent from '../TableUserComponent';

const columns = [
  { id: 'firstName', label: 'Name' },
  { id: 'username', label: 'Username' },
  { id: 'totalSocialPost', label: 'Posts' },
  { id: 'totalSocialComment', label: 'Comments' },
  { id: 'Actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ManageSocialUsers = () => {
  const [socialUsers, setSocialUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    firstName: '',
    lastName: '',
    userName: '',
    // active: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    // active: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.firstName === '' &&
      filterData.lastName === '' &&
      filterData.userName === '' &&
      // filterData.active === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.firstName === ' ') filterData.firstName = '';
    if (filterData.lastName === ' ') filterData.lastName = '';
    if (filterData.userName === ' ') filterData.userName = '';
    // if (filterData.active === ' ') filterData.active = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let social_users = JSON.parse(localStorage.getItem('social_users'));
    social_users !== null && setFilterData(social_users);

    social_users == null
      ? getSocial()
      : social_users.firstName == '' &&
        social_users.lastName == '' &&
        social_users.userName == '' &&
        // social_users.active == '' &&
        social_users.search == '' &&
        social_users.removed == false
      ? getSocial()
      : console.log('social_users');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      userName: filterData.userName,
      // active: filterData.active,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('social_users', JSON.stringify(filterData));
    if (
      filterData.firstName ||
      filterData.lastName ||
      filterData.userName ||
      // filterData.active ||
      filterData.search
    ) {
      httpClient
        .get(
          `social-user/filter?q=${filterData.search}&firstName=${filterData.firstName}&lastName=${filterData.lastName}&userName=${filterData.userName}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setSocialUsers(data.data);
            setTotal(data.meta.total);
            setPage(data.meta.current_page);
            setRowsPerPage(data.meta.per_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getSocial();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  // useEffect(() => {
  //   getSocial();
  // }, []);

  const getSocial = () => {
    setLoading(true);
    httpClient.get(`social-user?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setSocialUsers(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(data.meta.per_page);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`social-user?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setSocialUsers(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `social-user/filter?q=${filterData.search}&firstName=${filterData.firstName}&lastName=${filterData.lastName}&userName=${filterData.userName}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setSocialUsers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`social-user?&direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setSocialUsers(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `social-user/filter?q=${filterData.search}&firstName=${filterData.firstName}&lastName=${
              filterData.lastName
            }&userName=${filterData.userName}&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setSocialUsers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`social-user?sort_by=companyID&direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setSocialUsers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  //   const handleEdit = id => {
  //     history.push(`/new-forum/addSocialGroup/${id}`);
  //   };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getSocial();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`social-user/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setSocialUsers(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  const viewDetails = () => {
    console.log('Activate or deactivate users');
  };

  return (
    <PageContainer heading="Manage Social Users">
      <TableUserComponent
        columns={columns}
        selectedProducts={socialUsers}
        title={'Social Users'}
        viewDetails={viewDetails}
        // handleEdit={handleEdit}
        // handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        fromTable={from}
        toTable={to}
        direction={direction}
        currentColumn={currentColumn}
      />
    </PageContainer>
  );
};

export default ManageSocialUsers;
