import React from 'react';
import { Box, List, ListItem, TextField, Typography, makeStyles } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: '14px',
    color: theme.palette.text.primary,
  },
  qrCode: {
    height: '220px',
    width: '220px',
  },
  listIcon: {
    width: '10px',
    height: '10px',
    marginRight: '10px',
  },
  addIcon: {
    width: '15px',
    height: '15px',
  },
  scanText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  styledText: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const ScanQR = ({ qrCode, verificationCode, setVerificationCode }) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="body1" className={classes.titleRoot}>
        Please scan the QR code below with Google Authenticator:
      </Typography>
      <List>
        <ListItem>
          <StopIcon className={classes.listIcon} />
          <Typography variant="body2">
            Tap the <AddIcon className={classes.addIcon} /> icon in the Google Autheticator
          </Typography>
        </ListItem>
        <ListItem>
          <StopIcon className={classes.listIcon} />
          <Typography variant="body2">
            Select <span className={classes.scanText}>Scan a QR Code</span>
          </Typography>
        </ListItem>
      </List>
      <Box dangerouslySetInnerHTML={{ __html: qrCode }} className={classes.qrCode} />
      {/* <Typography variant="body2" className={classes.titleRoot}>
        Unable to scan above QR?{' '}
        <span onClick={handleOpen} className={classes.styledText}>
          Use Setup key
        </span>
      </Typography> */}
      <Typography variant="body1" className={classes.titleRoot}>
        Enter the verification OTP here:
      </Typography>
      <Box mb={2}>
        <TextField
          aria-label="2FA OTP input"
          placeholder="Enter OTP"
          variant="outlined"
          inputProps={{ type: 'number' }}
          value={verificationCode}
          onChange={e => setVerificationCode(e.target.value)}
          margin="normal"
        />
      </Box>
    </Box>
  );
};

export default ScanQR;
