import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ApplyDiscountDialog = props => {
  const { open, setOpen, orderDetails, setOrderDetails } = props;

  const { discountAmount, discountReason } = orderDetails;

  const [discountData, setDiscountData] = useState({
    amount: '',
    reason: '',
  });

  useEffect(() => {
    setDiscountData({
      ...discountData,
      amount: discountAmount || '',
      reason: discountReason || '',
    });
  }, [orderDetails]);

  const handleAmountInput = event => {
    const value = event.target.value;
    // Allow positive numbers with up to two decimal places
    if (/^\d*\.?\d{0,2}$/.test(value) && (value === '' || parseFloat(value) > 0)) {
      setDiscountData(prevState => ({
        ...prevState,
        amount: value,
      }));
    }
  };

  const handleReasonInput = event => {
    const value = event.target.value;
    setDiscountData(prevState => ({
      ...prevState,
      reason: value,
    }));
  };

  const handleApply = () => {
    setOrderDetails({
      ...orderDetails,
      discountAmount: discountData.amount || '',
      discountReason: discountData.reason || '',
    });

    setOpen(false);
  };

  const handleRemove = () => {
    setOrderDetails({
      ...orderDetails,
      discountAmount: '',
      discountReason: '',
    });

    setOpen(false);
  };

  const isAmountValid =
    /^\d*\.?\d{0,2}$/.test(discountData.amount) && (discountData.amount === '' || parseFloat(discountData.amount) > 0);
  const isReasonValid = !/^\d+$/.test(discountData.reason) || discountData.reason === '';

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>
          <h2>Please enter discount details</h2>
        </DialogTitle>
        <Tooltip title="Close" aria-label="close">
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}>
            <CloseIcon color="error" />
          </IconButton>
        </Tooltip>
      </Box>
      <DialogContent>
        <Box
          style={{
            borderBottom: '1px solid  #e0e0e0',
            paddingTop: '16px',
            paddingBottom: '24px',
          }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Amount"
                variant="outlined"
                value={discountData.amount}
                onChange={handleAmountInput}
                error={!isAmountValid}
                helperText={!isAmountValid ? 'Enter a positive number with up to two decimal places' : ''}
                inputProps={{
                  inputMode: 'decimal',
                  pattern: '^\\d*\\.?\\d{0,2}$',
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                required
                multiline
                minRows={4}
                label="Reason"
                variant="outlined"
                value={discountData.reason}
                onChange={handleReasonInput}
                error={!isReasonValid}
                helperText={!isReasonValid ? 'Reason cannot contain only numbers' : ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{ paddingRight: '20px', marginTop: '20px', marginBottom: '20px' }}>
        <Button variant="contained" onClick={discountAmount ? handleRemove : () => setOpen(false)} color="secondary">
          {discountAmount ? 'Remove Discount' : 'Cancel'}
        </Button>
        <Button
          variant="contained"
          onClick={handleApply}
          color="primary"
          style={{ paddingLeft: '16px' }}
          disabled={!(discountData.amount && discountData.reason)}
          autoFocus>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyDiscountDialog;
