import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableAdminComponent from './../TableAdminComponent';

const columns = [
  { id: 'firstName', label: 'Name' },
  { id: 'memberID', label: 'Company Name' },
  { id: 'lastName', label: 'Service Location' },
  { id: 'appLevelAdminID', label: 'Type' },
  { id: 'lastActive', label: 'Last Active' },
  { id: 'iPadActive', label: 'iPAD' },
  { id: 'memberActive', label: 'Member Active' },
  // { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ManageAdmins = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    // serviceLocations: '',
    active: '',
    social: '',
    migrated: '',
    adminMembers: '',
    state: '',
    memberType: '',
    ipad: '',
    bookingNumber: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    // serviceLocations: '',
    active: '',
    social: '',
    migrated: '',
    adminMembers: '',
    state: '',
    memberType: '',
    ipad: '',
    bookingNumber: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      // filterData.serviceLocations === '' &&
      filterData.active === '' &&
      filterData.bookingNumber === '' &&
      filterData.social === '' &&
      filterData.migrated === '' &&
      filterData.adminMembers === '' &&
      filterData.state === '' &&
      filterData.ipad === '' &&
      filterData.memberType === '' &&
      // filterData.credits === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.bookingNumber === ' ') filterData.bookingNumber = '';
    if (filterData.social === ' ') filterData.social = '';
    if (filterData.migrated === ' ') filterData.migrated = '';
    if (filterData.adminMembers === ' ') filterData.adminMembers = '';
    if (filterData.state === ' ') filterData.state = '';
    if (filterData.ipad === ' ') filterData.ipad = '';
    if (filterData.memberType === ' ') filterData.memberType = '';
    // if (filterData.credits === ' ') filterData.credits = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();

    console.log('filter data is>>>', filterData);
  }, [filterData]);

  useEffect(() => {
    let manageAdmins = JSON.parse(localStorage.getItem('manageAdmins_filter'));
    manageAdmins !== null && setFilterData(manageAdmins);

    manageAdmins == null
      ? getManageAdmins()
      : manageAdmins.suburbActive == '' &&
        manageAdmins.state == '' &&
        manageAdmins.search == '' &&
        manageAdmins.removed == false
      ? getManageAdmins()
      : console.log('manage admins');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      // serviceLocations: filterData.serviceLocations ,
      active: filterData.active,
      social: filterData.social,
      migrated: filterData.migrated,
      adminMembers: filterData.adminMembers,
      state: filterData.state,
      memberType: filterData.memberType,
      ipad: filterData.ipad,
      // credits: filterData.credits,
      search: filterData.search,
      bookingNumber: filterData.bookingNumber,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);

    localStorage.setItem('manageAdmins_filter', JSON.stringify(filterData));

    if (
      filterData.active ||
      filterData.bookingNumber ||
      filterData.social ||
      filterData.migrated ||
      filterData.adminMembers ||
      filterData.state ||
      filterData.memberType ||
      filterData.ipad ||
      filterData.search
      // filterData.credits
    ) {
      httpClient
        .get(
          `member/type/members-with-admin-access/filter?q=${filterData.search}&numberOfOutstandingBooking=${filterData.bookingNumber}&memberActive=${filterData.active}&memberSocialActive=${filterData.social}&memberMigrated=${filterData.migrated}&loginToAdmin=${filterData.adminMembers}&memStateID=${filterData.state}&appLevelAdminID=${filterData.memberType}&iPadActive=${filterData.ipad}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setAdmins(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getManageAdmins();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const getManageAdmins = () => {
    setLoading(true);
    httpClient.get(`member/type/members-with-admin-access?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setAdmins(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(
        `member/type/members-with-admin-access?sort_by=${column.id}&direction=${
          direction ? 'asc' : 'desc'
        }&pagination=${rowsPerPage}`,
      )
      .then(({ data }) => {
        if (data.success === true) {
          setAdmins(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `member/type/members-with-admin-access/filter?q=${filterData.search}&numberOfOutstandingBooking=${filterData.bookingNumber}&memberActive=${filterData.active}&memberSocialActive=${filterData.social}&memberMigrated=${filterData.migrated}&loginToAdmin=${filterData.adminMembers}&memStateID=${filterData.state}&appLevelAdminID=${filterData.memberType}&iPadActive=${filterData.ipad}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setAdmins(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(
            `member/type/members-with-admin-access?sort_by=companyID&direction=desc&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setAdmins(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `member/type/members-with-admin-access/filter?q=${filterData.search}&numberOfOutstandingBooking=${
              filterData.bookingNumber
            }&memberActive=${filterData.active}&memberSocialActive=${filterData.social}&memberMigrated=${
              filterData.migrated
            }&loginToAdmin=${filterData.adminMembers}&memStateID=${filterData.state}&appLevelAdminID=${
              filterData.memberType
            }&iPadActive=${filterData.ipad}&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setAdmins(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          })
      : httpClient
          .get(
            `member/type/members-with-admin-access?sort_by=companyID&direction=desc&pagination=${+event.target
              .value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setAdmins(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          });
  };

  // const handleEdit = id => {
  //   history.push(`/suburb/addSuburb/${id}`);
  // };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getManageAdmins();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`suburb/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setAdmins(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="Manage Admins">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableAdminComponent
              columns={columns}
              selectedProducts={admins}
              title="List Admin Members"
              // handleEdit={handleEdit}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default ManageAdmins;
