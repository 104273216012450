import React, { useState, useEffect } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
// import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { httpClient } from 'appUtility/Api';
import { Autocomplete } from '@material-ui/lab';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  const getMembers = () => {
    httpClient.get('/member/all').then(({ data }) => {
      if (data.success === true) {
        setMembers(data.data);
      }
    });
  };

  const sendTo = () => {
    history.push('/orders/addOrder');
  };

  const setFilterButton = () => {
    setFilter(prev => !prev);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* {props.confirmed && (
            <div className={classes.filterForm}>
              <label for="filter" style={{ marginRight: '5px' }}>
                Filter
              </label>
              <select
                id="filter"
                name="filter"
                value={props.filter}
                className={classes.filterSelect}
                onChange={props.filterChange}>
                <option value="">Select</option>
                <option value="paid">Paid</option>
                <option value="unpaid">Unpaid</option>
                <option value="shipped">Shipped</option>
                <option value="notShipped">Not Shipped</option>
              </select>
            </div>
          )}
          <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={setFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* Disabled this add orders button as we can't add new orders from BE now. Enable if required.
           */}
          {/* <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '10px' }}>
            +Add
          </Button> */}
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Search </InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Item Numbers </InputLabel>
                  <TextField
                    variant="outlined"
                    name="ItemNumber"
                    value={props.filterData.ItemNumber}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Online Paid</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="onlinePaid"
                      value={props.filterData.onlinePaid}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Shipped</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="shipped"
                      value={props.filterData.shipped}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Order Status</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="orderStatus"
                      value={props.filterData.orderStatus}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="0">Pending</MenuItem>
                      <MenuItem value="1">Completed</MenuItem>
                      <MenuItem value="8">Cancelled</MenuItem>
                      <MenuItem value="7">Order Locked</MenuItem>
                      <MenuItem value="5">Shipped</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  {/* <InputLabel className={classes.inputLabel}>Member</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="memberID"
                      value={props.filterData.memberID}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      {members &&
                        members.map(st => (
                          <MenuItem key={st.memberID} value={st.memberID}>
                            {st.firstName + ' ' + st.lastName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                  <InputLabel className={classes.inputLabel}>Member</InputLabel>

                  <Autocomplete
                    disablePortal
                    id="order_id"
                    options={members}
                    onChange={(event, newValue) => {
                      props.handleChangeMember(newValue);
                    }}
                    // value={memberDetails.leadsForwardToMemberName}
                    inputValue={props.filterData.memberName}
                    // sx={{ width: 300 }}
                    getOptionLabel={option => option.firstName + ' ' + option.lastName}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={props.handleChange}
                        value={props.filterData.memberName}
                        variant="outlined"
                        name="memberID"
                        // label="Member"
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.memberID ||
        props.submittedData.ItemNumber ||
        props.submittedData.onlinePaid ||
        props.submittedData.shipped ||
        props.submittedData.orderStatus ||
        props.submittedData.search ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.memberID && (
              <p>
                Member: <span>{props.submittedData.memberName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('memberID')} />
              </p>
            )}
            {props.submittedData.ItemNumber && (
              <p>
                Number of Items &gt; <span> {props.submittedData.ItemNumber} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('ItemNumber')} />
              </p>
            )}
            {props.submittedData.onlinePaid && (
              <p>
                Online Paid: <span>{props.submittedData.onlinePaid == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('onlinePaid')} />
              </p>
            )}
            {props.submittedData.shipped && (
              <p>
                Shipped:<span> {props.submittedData.shipped} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('shipped')} />
              </p>
            )}
            {props.submittedData.orderStatus && (
              <p>
                Order Status:
                <span>
                  {props.submittedData.orderStatus == 0 && 'pending'}
                  {props.submittedData.orderStatus == 1 && 'completed'}
                  {props.submittedData.orderStatus == 8 && 'cancelled'}
                  {props.submittedData.orderStatus == 7 && 'order locked'}
                  {props.submittedData.orderStatus == 5 && 'shipped'}
                </span>
                <Close fontSize="small" onClick={() => props.handleRemove('orderStatus')} />
              </p>
            )}
            {props.submittedData.search && (
              <p>
                Search:<span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          recent={props.recent}
          past={props.past}
          shipped={props.shipped}
          confirmed={props.confirmed}
          cancelled={props.cancelled}
          archived={props.archived}
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleEdit={props.handleEdit}
          handleUnlock={props.handleUnlock}
          handleEdit={props.handleEdit}
          handleNotShipped={props.handleNotShipped}
          handlePaymentReceived={props.handlePaymentReceived}
          handlePaymentNotReceived={props.handlePaymentNotReceived}
          handleArchive={props.handleArchive}
          handleCancel={props.handleCancel}
          handleReactivate={props.handleReactivate}
          handleLock={props.handleLock}
          handleShipped={props.handleShipped}
          loading={props.loading}
          filter={props.filter}
          filterChange={props.filterChange}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          rowsPerPage={props.rowsPerPage}
          fromTable={props.fromTable}
          toTable={props.toTable}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
