import React from 'react';

import { CryptoCard } from '../../../../@jumbo/components/Common';
import RippleGraph from './RippleGraph';

const RipplePurchaseHistory = props => {
  return (
    // <CryptoCard title="Ripple Price" amount="$9,626" progress={{ value: '-8%', icon: 'info', color: '#E00930' }}>
    <CryptoCard title={props.title} amount={props.amount} progress={{ color: `${props.color}` }}>
      <RippleGraph color={props.color} colorID={props.colorID} />
    </CryptoCard>
  );
};

export default RipplePurchaseHistory;
