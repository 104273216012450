import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableComponent from './../TableComponent';

const columns = [
  { id: 'newsTitle', label: 'Title' },
  { id: 'highPriority', label: 'Category' },
  { id: 'newsDescription', label: 'Description' },
  { id: 'addedDate', label: 'Added Date' },
  { id: 'newsLastUpdated', label: 'Last Updated' },
  { id: 'newsActive', label: 'Active' },
  { id: 'Actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListNews = () => {
  const [news, setNews] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  // useEffect(() => {
  //   getNews();
  // }, []);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    active: '',
    newsTitle: '',
    newsCategoryID: '',
    highPriority: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    active: '',
    newsTitle: '',
    newsCategoryID: '',
    search: '',
    highPriority: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.newsTitle === '' &&
      filterData.newsCategoryID === '' &&
      filterData.active === '' &&
      filterData.highPriority === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.newsTitle === ' ') filterData.newsTitle = '';
    if (filterData.newsCategoryID === ' ') filterData.newsCategoryID = '';
    if (filterData.highPriority === ' ') filterData.highPriority = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let newsFilter = JSON.parse(localStorage.getItem('news_filter'));
    newsFilter !== null && setFilterData(newsFilter);

    newsFilter == null
      ? getNews()
      : newsFilter.active == '' &&
        newsFilter.search == '' &&
        newsFilter.newsTitle == '' &&
        newsFilter.newsCategoryID == '' &&
        newsFilter.highPriority == '' &&
        newsFilter.removed == false
      ? getNews()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      active: filterData.active,
      newsTitle: filterData.newsTitle,
      newsCategoryID: filterData.newsCategoryID,
      search: filterData.search,
      submit: true,
    });

    filterData.removed = true;
    localStorage.setItem('news_filter', JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.active ||
      filterData.search ||
      filterData.newsTitle ||
      filterData.highPriority ||
      filterData.newsCategoryID
    ) {
      httpClient
        .get(
          `news/filter?q=${filterData.search}&newsTitle=${filterData.newsTitle}&newsCategoryID=${filterData.newsCategoryID}&highPriority=${filterData.highPriority}&newsActive=${filterData.active}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setNews(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getNews();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const getNews = () => {
    setLoading(true);
    httpClient.get(`news?&pagination=${rowsPerPage}`).then(({ data }) => {
      setNews(data.data);
      setPage(data.meta.current_page);
      setFrom(data.meta.from);
      setTo(data.meta.to);
      setLoading(false);
      setTotal(data.meta.total);
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`news?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setNews(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `news/filter?q=${filterData.search}&newsTitle=${filterData.newsTitle}&newsCategoryID=${filterData.newsCategoryID}&highPriority=${filterData.highPriority}&newsActive=${filterData.active}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setNews(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`news?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setNews(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(
            `news/filter?q=${filterData.search}&newsTitle=${filterData.newsTitle}&newsCategoryID=${
              filterData.newsCategoryID
            }&highPriority=${filterData.highPriority}&newsActive=${filterData.active}&pagination=${+event.target
              .value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setNews(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`news?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setNews(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getNews();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`news/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setNews(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  const handleEdit = id => {
    history.push(`/news/addNews/${id}`);
  };

  const handleDelete = () => {};

  return (
    <PageContainer heading="List News">
      <TableComponent
        columns={columns}
        selectedProducts={news}
        title={'List News'}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        fromTable={from}
        toTable={to}
        direction={direction}
        currentColumn={currentColumn}
      />
    </PageContainer>
  );
};

export default ListNews;
