import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import ActiveLeads from './ActiveLeads';
import CompletedLeads from './CompletedLeads';
import ForwardedLeads from './ForwardedLeads';
import HoCompleted from './HoCompleted';
import IssueLeads from './IssueLeads';
import LeadsReport from './LeadsReport';
import SnoozedLeads from './SnoozedLeads';

const Leads = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/issueLeads`} />
        <Route path={`${requestedUrl}/issueLeads`} component={IssueLeads} />
        <Route path={`${requestedUrl}/activeLeads`} component={ActiveLeads} />
        <Route path={`${requestedUrl}/snoozedLeads`} component={SnoozedLeads} />
        <Route path={`${requestedUrl}/completedLeads`} component={CompletedLeads} />
        <Route path={`${requestedUrl}/forwardedLeads`} component={ForwardedLeads} />
        <Route path={`${requestedUrl}/hoCompletedLeads`} component={HoCompleted} />
        <Route path={`${requestedUrl}/leadsReport`} component={LeadsReport} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Leads;
