import React, { useState, useEffect } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
// import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { httpClient } from 'appUtility/Api';
// import { setFilterData } from 'redux/actions/TaskList';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);
  const [state, setState] = useState([]);
  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  const sendTo = () => {
    history.push('/members/manageAdmins');
  };

  const getState = () => {
    httpClient.get('/state').then(({ data }) => {
      if (data) {
        setState(data.data);
      } else {
      }
    });
  };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  // console.log('props filterData >>', props.submittedData);

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div>  */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '15px' }}>
            +Add
          </Button> */}
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Search </InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Outstanding Bookings </InputLabel>
                  <TextField
                    variant="outlined"
                    name="bookingNumber"
                    value={props.filterData.bookingNumber}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Active Members</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="active"
                      // label="Active Members"
                      value={props.filterData.active}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Migrated Members</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="migrated"
                      // label="Migrated Members"
                      value={props.filterData.migrated}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Social Members</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="social"
                      // label="Social Members"
                      value={props.filterData.social}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Admin Members</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="adminMembers"
                      // label="Admin Members"
                      value={props.filterData.adminMembers}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>State</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="state"
                      // label="State"
                      value={props.filterData.state}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {state &&
                        state.map(st => (
                          <MenuItem key={st.stateID} value={st.stateID}>
                            {st.stateName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Member Type</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="memberType"
                      // label="Member Type"
                      value={props.filterData.memberType}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={'4'}>Franchisor</MenuItem>
                      <MenuItem value={'3'}>Master Franchisee</MenuItem>
                      <MenuItem value={'1'}>Franchisee</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>IPad</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="ipad"
                      // label="IPad"
                      value={props.filterData.ipad}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Active</MenuItem>
                      <MenuItem value="0">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Service Location</InputLabel>
                  <TextField
                    // label="Service Location"
                    variant="outlined"
                    name="serviceLocations"
                    value={props.filterData.serviceLocations}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Credits ($)</InputLabel>
                  <TextField
                    // label="Credits($)"
                    variant="outlined"
                    name="credits"
                    type="number"
                    value={props.filterData.credits}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid> */}
                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.active ||
        props.submittedData.social ||
        props.submittedData.migrated ||
        props.submittedData.adminMembers ||
        props.submittedData.state ||
        props.submittedData.memberType ||
        props.submittedData.bookingNumber ||
        props.submittedData.search ||
        props.submittedData.ipad ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.active && (
              <p>
                Active Members: <span>{props.submittedData.active == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('active')} />
              </p>
            )}
            {props.submittedData.social && (
              <p>
                Social Members:<span> {props.submittedData.social == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('social')} />
              </p>
            )}
            {props.submittedData.migrated && (
              <p>
                Migrated Members: <span>{props.submittedData.migrated == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('migrated')} />
              </p>
            )}

            {props.submittedData.adminMembers && (
              <p>
                Admin Members: <span>{props.submittedData.adminMembers == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('adminMembers')} />
              </p>
            )}
            {props.submittedData.state && (
              <p>
                State:
                <span>
                  {state &&
                    state.map(st => {
                      if (st.stateID === props.filterData.state) return st.stateName;
                    })}
                </span>
                <Close fontSize="small" onClick={() => props.handleRemove('state')} />
              </p>
            )}

            {props.submittedData.memberType && (
              <p>
                Member Type:{' '}
                <span>
                  {(props.submittedData.memberType == '4' && 'Franchisor') ||
                    (props.submittedData.memberType == '1' && 'Franchisee') ||
                    (props.submittedData.memberType == '3' && 'Master Franchisee')}{' '}
                </span>
                <Close fontSize="small" onClick={() => props.handleRemove('memberType')} />
              </p>
            )}

            {props.submittedData.ipad && (
              <p>
                Ipad: <span>{props.submittedData.ipad == 1 ? 'Active' : 'Inactive'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('ipad')} />
              </p>
            )}

            {props.submittedData.bookingNumber && (
              <p>
                Outstanding Bookings &gt; <span> {props.submittedData.bookingNumber} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('bookingNumber')} />
              </p>
            )}
            {/* {props.submittedData.credits && (
              <p>
                Credits:<span> {props.submittedData.credits} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('credits')} />
              </p>
            )} */}
            {/* {props.submittedData.serviceLocations && (
              <p>
                Service Locations:<span> {props.submittedData.serviceLocations} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('serviceLocations')} />
              </p>
            )} */}
            {props.submittedData.search && (
              <p>
                Search:<span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleEdit={props.handleEdit}
          handleDelete={props.handleDelete}
          handleActivate={props.handleActivate}
          handleResetPassword={props.handleResetPassword}
          handleEnableDisableIpad={props.handleEnableDisableIpad}
          handleSocialInactive={props.handleSocialInactive}
          handleSocialActive={props.handleSocialActive}
          handleForumMgmt={props.handleForumMgmt}
          handleBuySell={props.handleBuySell}
          loading={props.loading}
          handleMonetization={props.handleMonetization}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
