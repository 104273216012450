import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Button, Card, Checkbox, FormControlLabel, Grid, InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const loginData = JSON.parse(localStorage.getItem('login'));

const useStyles = makeStyles(theme => ({
  text: {
    width: '100%',
    marginBottom: '30px',
  },
  name: {
    marginBottom: '20px',
  },
  textArea: {
    width: '100%',
    marginBottom: '30px',
  },
  root: {
    padding: theme.spacing(8),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddVersion = props => {
  const classes = useStyles();
  const [versionDetails, setVersionDetails] = useState({
    versionNumber: '',
    versionTitle: '',
    versionDescription: '',
    logoutRequired: false,
    refreshRequired: false,
  });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [versionID, getVersionID] = useState(props.location.pathname.slice(21));

  useEffect(() => {
    getSingleVersion();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setVersionDetails({
      ...versionDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setVersionDetails({
      ...versionDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    versionID === ''
      ? httpClient
          .post(
            'version',
            qs.stringify({
              appID: loginData && loginData.appID,
              version_number: versionDetails.versionNumber,
              logoutRequired: versionDetails.logoutRequired === true ? 1 : 0,
              refreshRequired: versionDetails.refreshRequired === true ? 1 : 0,
              versionTitle: versionDetails.versionTitle,
              versionDescription: versionDetails.versionDescription,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              // setVersionDetails({
              //   versionNumber: '',
              //   versionTitle: '',
              //   versionDescription: '',
              //   logoutRequired: false,
              //   refreshRequired: false,
              // });
              history.push('/versions/listVersion');
            } else {
              setOpen(true);
              setMessage('Failed to add version');
              setMessageState('error');
            }
          })
      : httpClient
          .put(
            `version/${versionID}`,
            qs.stringify({
              appID: loginData && loginData.appID,
              version_number: versionDetails.versionNumber,
              logoutRequired: versionDetails.logoutRequired === true ? 1 : 0,
              refreshRequired: versionDetails.refreshRequired === true ? 1 : 0,
              versionTitle: versionDetails.versionTitle,
              versionDescription: versionDetails.versionDescription,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              history.push('/versions/listVersion');
            } else {
              setOpen(true);
              setMessage('Failed to edit version');
              setMessageState('error');
            }
          });
  };

  const getSingleVersion = () => {
    versionID &&
      httpClient.get(`version/${versionID}`).then(({ data }) => {
        if (data.success === true) {
          setVersionDetails({
            versionNumber: data.data.version_number,
            versionTitle: data.data.versionTitle,
            versionDescription: data.data.versionDescription,
            logoutRequired: data.data.logoutRequired === 'yes' ? true : false,
            refreshRequired: data.data.refreshRequired === 'yes' ? true : false,
          });
        }
      });
  };

  const handleBack = () => {
    history.push('/versions/listVersion');
  };

  return (
    <PageContainer heading={versionID === '' ? 'Add Version' : 'Edit Version'}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <InputLabel className={classes.name}>Version Number</InputLabel>
              <TextField
                required
                label="Version Number"
                variant="outlined"
                name="versionNumber"
                value={versionDetails.versionNumber}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <InputLabel className={classes.name}>Version Title</InputLabel>

              <TextField
                required
                label="Version Title"
                variant="outlined"
                name="versionTitle"
                value={versionDetails.versionTitle}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <InputLabel className={classes.name}>Version Description</InputLabel>

              <TextField
                required
                multiline
                rows={4}
                label="Version Description"
                variant="outlined"
                name="versionDescription"
                value={versionDetails.versionDescription}
                className={classes.textArea}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="logoutRequired"
                    checked={versionDetails.logoutRequired}
                    value={versionDetails.logoutRequired}
                    color="primary"
                    onChange={handleCheck}
                  />
                }
                label="Logout/Login Required"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="refreshRequired"
                    checked={versionDetails.refreshRequired}
                    onChange={handleCheck}
                    value={versionDetails.refreshRequired}
                    color="primary"
                  />
                }
                label="Refresh Required"
              />
            </Grid>
            <Grid item sm={12}>
              <Box textAlign="right" pt={4}>
                <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  {versionID ? 'Update Version' : 'Add Version'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddVersion;
