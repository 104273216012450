import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableShipComponent from './../TableShipComponent';

const column = [
  { id: 'shippingZoneName', label: 'Shipping Zone Name' },
  { id: 'shipping_zone_description', label: 'Short Name' },
  { id: 'shipping_zone_active', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListShipping = () => {
  const [shipping, setShipping] = useState([]);
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  // const [searchText, setSearchText] = useState('');

  // useEffect(() => {
  //   getAllShipping();
  // }, []);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    active: '',
    shippingZoneName: '',
    shippingZoneDescription: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    active: '',
    shippingZoneName: '',
    shippingZoneDescription: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.shippingZoneName === '' &&
      filterData.shippingZoneDescription === '' &&
      filterData.active === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.shippingZoneName === ' ') filterData.shippingZoneName = '';
    if (filterData.shippingZoneDescription === ' ') filterData.shippingZoneDescription = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let shippingStorage = JSON.parse(localStorage.getItem('shipping_zone_filter'));
    shippingStorage !== null && setFilterData(shippingStorage);

    shippingStorage == null
      ? getAllShipping()
      : shippingStorage.active == '' &&
        shippingStorage.search == '' &&
        shippingStorage.shippingZoneName == '' &&
        shippingStorage.shippingZoneDescription == '' &&
        shippingStorage.removed == false
      ? getAllShipping()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      active: filterData.active,
      shippingZoneName: filterData.shippingZoneName,
      shippingZoneDescription: filterData.shippingZoneDescription,
      search: filterData.search,
      submit: true,
    });

    filterData.removed = true;
    localStorage.setItem('shipping_zone_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.active || filterData.search || filterData.shippingZoneName || filterData.shippingZoneDescription) {
      httpClient
        .get(
          `shipping/filter?q=${filterData.search}&shipping_zone_name=${filterData.shippingZoneName}&shipping_zone_description=${filterData.shippingZoneDescription}&shipping_zone_active=${filterData.active}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setShipping(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllShipping();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleEdit = id => {
    history.push(`/freight/addShipping/${id}`);
  };

  // const handleDelete = () => {};

  const getAllShipping = () => {
    httpClient.get(`shipping?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setShipping(data.data);
        setTotal(data.meta.total);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getAllShipping();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`shipping/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setShipping(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="List Shipping">
      <TableShipComponent
        columns={column}
        selectedProducts={shipping}
        title="List Shipping"
        handleEdit={handleEdit}
        // handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
      />
    </PageContainer>
  );
};

export default ListShipping;
