import React, { useState } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, fade, TableFooter, TablePagination, CircularProgress } from '@material-ui/core';
// import { alpha } from '@material-ui/core/styles';

import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  scrollbarRoot: {
    // height: 251,
    marginLeft: -24,
    marginRight: -24,
    [theme.breakpoints.up('xl')]: {
      // height: 269,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      // textAlign: 'right',
      // paddingRight: 24,
    },
  },
  tableCellWidth: {
    minWidth: '200px',
  },
  tableRowCellRoot: {
    fontSize: 12,
    '&:last-child': {
      paddingRight: 64,
    },
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  loader: {
    position: 'relative',
    height: '300px',
  },
  loadingSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const ProductsTable = ({ columns, selectedProducts, handleEdit, handleDelete, loading }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <PerfectScrollbar className={classes.scrollbarRoot}>
      <Box className="Cmt-table-responsive">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} className={clsx(classes.tableCellRoot, classes.tableRowCellRoot)}>
                  {' '}
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {selectedProducts &&
              selectedProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                // const isInflationPositive = row.sales_data.sales_inflation > 0;
                return (
                  <TableRow className={classes.tableRowRoot} key={Math.random()}>
                    <TableCell className={classes.tableCellRoot}>{row.shipping_zone_name}</TableCell>
                    <TableCell className={classes.tableCellRoot}>{row.shipping_zone_description}</TableCell>
                    <TableCell className={classes.tableCellRoot}>
                      {row.shipping_zone_active === 1 ? (
                        <CheckIcon fontSize="small" color="primary" />
                      ) : (
                        <ClearIcon fontSize="small" color="secondary" />
                      )}
                    </TableCell>

                    <TableCell className={classes.tableCellWidth}>
                      <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => handleEdit(row.shipping_zone_id)}>
                        Edit
                      </Button>
                      {/* <Button
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={() => handleDelete(row.shipping_zone_id)}
                        style={{ marginLeft: '10px' }}>
                        Delete
                      </Button> */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, 250, 1000]}
                count={selectedProducts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
        {loading && (
          <Box textAlign="center" style={{ height: '50px' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </PerfectScrollbar>
  );
};

export default ProductsTable;
