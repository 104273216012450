import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { httpClient } from 'appUtility/Api';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    display: 'flex',
    position: 'relative',
    '& span': {
      fontSize: '22px',
    },
  },
  btnClose: {
    position: 'absolute',
    right: '10px',
    top: '8px',
  },
  body: {
    padding: '25px 40px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  head: {
    marginRight: '10px',
  },
  tail: {
    fontSize: '20px',
  },
  main: {
    padding: '10px 0',
  },
  close: {
    color: '#fff',
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    width: '100%',
  },
}));

const ViewDialog = props => {
  const classes = useStyles();
  const [customerData, setCustomerData] = useState([]);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSingleCustomer();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const getSingleCustomer = () => {
    setLoading(true);
    httpClient.get(`customer/${props.customerID}`).then(({ data }) => {
      if (data) {
        setCustomerData(data.date);
        setLoading(false);
      }
    });
  };

  props.sendBackView(open);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} className={classes.root} fullWidth maxWidth="md">
        <DialogTitle className={classes.header}>
          <span>View Customer Detail </span>
          <IconButton className={classes.btnClose} onClick={handleClose}>
            <Close className={classes.close} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.body}>
          {loading ? (
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div className={classes.main}>
                <span className={classes.head}>Name: </span>
                <span className={classes.tail}>
                  {(customerData.firstName ? customerData.firstName : '') +
                    ' ' +
                    (customerData.lastName ? customerData.lastName : '')}
                </span>
              </div>
              <div className={classes.main}>
                <span className={classes.head}>Franchisee Name: </span>
                <span className={classes.tail}>
                  {customerData.franchisee && customerData.franchisee.franchiseeName
                    ? customerData.franchisee && customerData.franchisee.franchiseeName
                    : '-'}
                </span>
              </div>
              <div className={classes.main}>
                <span className={classes.head}>Suburb: </span>
                <span className={classes.tail}>{customerData.suburb ? customerData.suburb : '-'}</span>
              </div>
              <div className={classes.main}>
                <span className={classes.head}>State: </span>
                <span className={classes.tail}>
                  {customerData.state && customerData.state.stateName
                    ? customerData.state && customerData.state.stateName
                    : '-'}
                </span>
              </div>
              <div className={classes.main}>
                <span className={classes.head}>Address: </span>
                <span className={classes.tail}>{customerData.address ? customerData.address : '-'}</span>
              </div>

              <div className={classes.main}>
                <span className={classes.head}>Phone Number: </span>
                <span className={classes.tail}>{customerData.v2_PhoneMobile ? customerData.v2_PhoneMobile : '-'}</span>
              </div>

              <div className={classes.main}>
                <span className={classes.head}>Email: </span>
                <span className={classes.tail}>{customerData.v2_OtherEmail ? customerData.v2_OtherEmail : '-'}</span>
              </div>

              <div className={classes.main}>
                <span className={classes.head}>Registered Date: </span>
                <span className={classes.tail}>
                  {customerData.registeredDate ? moment(customerData.registeredDate).format('MMMM DD YYYY, hh:mm a') : '-'}
                </span>
              </div>
              <div className={classes.main}>
                <span className={classes.head}>Customer Active: </span>
                <span className={classes.tail}>{customerData.customerActive == '1' ? 'Yes' : 'No'}</span>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewDialog;
