import React, { useState, useEffect } from 'react';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button } from '@material-ui/core';
import FilterList from '@material-ui/icons/FilterList';
import Filter from './Filter';
import ServiceTable from './ServiceTable';

const WeeklySales = props => {
  const classes = useStyles();

  const [filter, setFilter] = useState(false);

  function checkUrlType(url) {
    const urlObject = new URL(url);

    // Extract the hostname
    const hostname = urlObject.hostname;

    // Use a regular expression to check for 'admin' or 'backadmin'
    if (/^admin\./.test(hostname)) {
      return 'admin';
    } else if (/^backadmin\./.test(hostname)) {
      return 'backadmin';
    } else {
      return 'none';
    }
  }

  const currentUrl = window.location.href;
  const urlType = checkUrlType(currentUrl);
  const isAdminUrl = urlType === 'admin';

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" />
            Filter
          </Button>
          <a
            download="service_user_report.csv"
            className={classes.exportButton}
            href={`https://${
              isAdminUrl ? 'admin' : 'backadmin'
            }.franchise.care/php/api/app/1.0/service/report/export?startDate=${props.filterData.currStartDate}&endDate=${
              props.filterData.currEndDate
            }&serviceID=${props.filterData.serviceID}&serviceActive=${props.filterData.serviceActive}`}
            target="_blank"
            rel="noreferrer">
            + Export
          </a>
        </div>
      </div>
      <CmtCardContent>
        <Filter
          classes={classes}
          filter={filter}
          handleFilter={props.handleFilter}
          services={props.selectedProducts}
          filterData={props.filterData}
          handleStartDate={props.handleStartDate}
          handleEndDate={props.handleEndDate}
          setFilter={setFilter}
          submittedData={props.submittedData}
          handleChangeService={props.handleChangeService}
          handleChange={props.handleChange}
          handleRemove={props.handleRemove}
        />
        <ServiceTable
          selectedProducts={props.selectedProducts}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
