import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddGroups from './AddGroups';
import AddServices from './AddServices';
import InventoryUseage from './InventoryUseage';
import ListGroups from './ListGroups';
import ListServices from './ListServices';

const Services = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listServices`} />
        <Route path={`${requestedUrl}/listServices`} component={ListServices} />
        <Route path={`${requestedUrl}/listGroups`} component={ListGroups} />
        <Route path={`${requestedUrl}/addServices`} component={AddServices} />
        <Route path={`${requestedUrl}/addGroups`} component={AddGroups} />
        <Route path={`${requestedUrl}/inventoryUsage`} component={InventoryUseage} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Services;
