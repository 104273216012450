import React, { useEffect, useState } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Button,
  fade,
  IconButton,
  MenuItem,
  TableFooter,
  TablePagination,
  Tooltip,
  Box,
  CircularProgress,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import MoreVert from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LoopIcon from '@material-ui/icons/Loop';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PeopleIcon from '@material-ui/icons/People';
import ForumIcon from '@material-ui/icons/Forum';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import StoreIcon from '@material-ui/icons/Store';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShopIcon from '@material-ui/icons/Shop';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { DoneOutline, ExpandLess, ExpandMore, UnfoldMore } from '@material-ui/icons';
// import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  scrollbarRoot: {
    // height: 251,
    marginLeft: -24,
    marginRight: -24,
    [theme.breakpoints.up('xl')]: {
      // height: 269,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    position: 'relative',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      paddingRight: 24,
    },
  },
  tableRowCellRoot: {
    fontSize: 12,
    '&:last-child': {
      // paddingRight: 64,
      '& .MuiSvgIcon-root': {
        display: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: '13px',
      position: 'relative',
      top: '2px',
      cursor: 'pointer',
    },
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  menuBox: {
    '& .MuiPaper-root': {
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.1)}`,
    },
  },
  loader: {
    position: 'relative',
    height: '100px',
  },
  loadingSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  flexBtn: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  return (
    <div className={classes.flexBtn}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage)} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ProductsTable = ({
  columns,
  selectedProducts,
  handleResetPassword,
  handleEdit,
  handleDelete,
  handleActivate,
  handleLoginTBA,
  handleCustomers,
  handleMigrationCheck,
  handleMonetization,
  handleBuySell,
  handleEnableDisableIpad,
  handleSocialInactive,
  handleSocialActive,
  handleForumMgmt,
  handleInventoryUsage,
  handleStockTake,
  handleStockOnHand,
  loading,
  handleSort,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  total,
  toTable,
  fromTable,
  direction,
  currentColumn,
}) => {
  const classes = useStyles();
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(20);
  const [socialActive, setSocialActive] = useState('');
  const [custNumber, setCustNumber] = useState('');
  const [custName, setCustName] = useState('');

  const [memActive, setMemActive] = useState('');
  var [rowID, setRowID] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    selectedProducts &&
      selectedProducts.map(item => {
        if (item.memberID === rowID) {
          setCustName(item.firstName);
          item.memberSocialActive === 1 ? setSocialActive(true) : setSocialActive(false);
          setCustNumber(item.totalCustomers);

          item.memberActive === 1 ? setMemActive(true) : setMemActive(false);
        }
      });
  }, [rowID]);

  const handleRowID = (e, id) => {
    e.preventDefault();
    setRowID(id);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actions = [
    {
      icon: <EditIcon color="secondary" fontSize="small" />,
      label: 'Edit',
      onClick: handleEdit,
    },
    {
      icon: memActive ? (
        <DeleteIcon color="secondary" fontSize="small" />
      ) : (
        <DoneOutline color="secondary" fontSize="small" />
      ),
      label: memActive ? 'Deactivate' : 'Activate',
      onClick: memActive ? handleDelete : handleActivate,
    },
    {
      icon: <SettingsIcon color="secondary" fontSize="small" />,
      label: 'Reset Password',
      onClick: handleResetPassword,
    },
    {
      icon: <MonetizationOnIcon color="secondary" fontSize="small" />,
      label: 'Top up Credit',
      onClick: handleMonetization,
    },
    {
      icon: <LoopIcon color="secondary" fontSize="small" />,
      label: 'Start Buy/Sell Process',
      onClick: handleBuySell,
    },
    {
      icon: <SmartphoneIcon color="secondary" fontSize="small" />,
      label: 'Enable/Disable iPad',
      onClick: handleEnableDisableIpad,
    },
    {
      icon: socialActive ? (
        <PeopleIcon color="secondary" fontSize="small" />
      ) : (
        <PeopleIcon style={{ color: '#BC544B' }} fontSize="small" />
      ),
      label: socialActive ? 'Mark Social InActive' : 'Mark Social Active',
      onClick: socialActive ? handleSocialInactive : handleSocialActive,
    },

    {
      icon: <ForumIcon color="secondary" fontSize="small" />,
      label: 'Forum Mgmt',
      onClick: handleForumMgmt,
    },
    {
      icon: <SupervisorAccountIcon color="secondary" fontSize="small" />,
      label: `Customers${' '} (${custNumber})`,
      onClick: handleCustomers,
    },

    {
      icon: <VisibilityIcon color="secondary" fontSize="small" />,
      label: `Login as ${custName}`,
      onClick: handleLoginTBA,
    },
    {
      icon: <StoreIcon color="secondary" fontSize="small" />,
      label: `Inventory Top Up`,
      onClick: handleInventoryUsage,
    },
    {
      icon: <StorefrontIcon color="secondary" fontSize="small" />,
      label: `View Stock Take`,
      onClick: handleStockTake,
    },
    {
      icon: <ShopIcon color="secondary" fontSize="small" />,
      label: `Stock on Hand`,
      onClick: handleStockOnHand,
    },
    // {
    //   icon: <PeopleAltIcon color="secondary" fontSize="small" />,
    //   label: 'Migration Check',
    //   onClick: handleMigrationCheck,
    // },
  ];

  // const handleChangePage = (event, page) => {
  //   // onChangePage(page, rowsPerPage)
  // };
  // const handleChangeRowsPerPage = event => {
  //   // setRowsPerPage(+event.target.value);
  //   // setPage(0);
  //   // onChangePage(page, parseInt(event.target.value))

  // };

  return (
    <PerfectScrollbar className={classes.scrollbarRoot}>
      <Box className="Cmt-table-responsive">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  onClick={() => handleSort(column)}
                  key={column.id}
                  className={clsx(classes.tableCellRoot, classes.tableRowCellRoot)}>
                  {' '}
                  {currentColumn === column ? <span style={{ fontWeight: '900' }}>{column.label}</span> : column.label}
                  {currentColumn === column ? direction ? <ExpandLess /> : <ExpandMore /> : <UnfoldMore />}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody className={classes.loader}>
              <Box textAlign="center" className={classes.loadingSection}>
                <CircularProgress />
              </Box>
            </TableBody>
          ) : (
            <TableBody>
              {selectedProducts &&
                // selectedProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                selectedProducts.map((row, index) => {
                  // const isInflationPositive = row.sales_data.sales_inflation > 0;
                  return (
                    <TableRow className={classes.tableRowRoot} key={Math.random()}>
                      <TableCell className={classes.tableCellRoot}>{row.firstName + ' ' + row.lastName}</TableCell>
                      <TableCell className={classes.tableCellRoot}>{row.companyID && row.companyID.tradingName}</TableCell>
                      <TableCell className={classes.tableCellRoot}>
                        {row.companyID && row.companyID.serviceLocation}
                      </TableCell>
                      <TableCell className={classes.tableCellRoot}>{row.appLevelAdminID}</TableCell>

                      <TableCell className={classes.tableCellRoot}>
                        {row.lastActive == '0000-00-00 00:00:00'
                          ? '-'
                          : moment(row.lastActive).format('MMMM DD YYYY, hh:mm a')}
                      </TableCell>

                      <TableCell className={classes.tableCellRoot} style={{ minWidth: '80px' }}>
                        {row.iPadActive === 1 ? (
                          <CheckIcon fontSize="small" color="primary" />
                        ) : (
                          <ClearIcon fontSize="small" color="secondary" />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCellRoot} style={{ minWidth: '130px' }}>
                        {row.memberActive === 1 ? (
                          <CheckIcon fontSize="small" color="primary" />
                        ) : (
                          <ClearIcon fontSize="small" color="secondary" />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCellRoot} style={{ minWidth: '150px' }}>
                        {row.TsandCs === 1 ? (
                          <CheckIcon fontSize="small" color="primary" />
                        ) : (
                          <ClearIcon fontSize="small" color="secondary" />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCellRoot} onMouseOver={e => handleRowID(e, row.memberID)}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <CmtDropdownMenu
                            TriggerComponent={
                              <Tooltip title="More">
                                <IconButton size="small" style={{ marginLeft: 10 }}>
                                  <MoreVert />
                                </IconButton>
                              </Tooltip>
                            }
                            items={actions}
                            // memID={row.memberID}
                            memID={row}

                            // onItemClick={() => {actions(row.memberID)}}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {selectedProducts.length === 0 && (
                <TableRow
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    position: 'absolute',
                  }}>
                  No Members Found
                </TableRow>
              )}
            </TableBody>
          )}

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[20, 50, 70, 100]}
                rowsPerPage={rowsPerPage}
                page={page}
                count={total && total}
                SelectProps={{
                  native: true,
                }}
                labelDisplayedRows={() =>
                  `${fromTable !== null ? fromTable : '0'} - ${toTable !== null ? toTable : '0'} to ${total}`
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

export default ProductsTable;
