import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Card,
  Grid,
  makeStyles,
  TextField,
  InputLabel,
  Button,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
    width: '50%',
    margin: '0 auto',
  },
  textField: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '25px',
  },
  textSelect: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  resetBtn: {
    marginTop: '10px',
    float: 'right',
  },
  closeBtn: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  content: {
    padding: '35px',
  },
}));

const ForumMgmt = props => {
  const classes = useStyles();
  const [memberID, setMemberID] = useState(props && props.forumID);
  const [forumDetails, setForumDetails] = useState({
    forumAccess: false,
    memberName: '',
    forumState: '',
    open: true,
    loading: false,
    submit: false,
  });

  const [response, setResponse] = useState({
    open: false,
    message: '',
    messageState: '',
  });

  const handleClose = (event, reason) => {
    setForumDetails({
      ...forumDetails,
      open: false,
    });
  };

  useEffect(() => {
    getSingleMember();
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setForumDetails({
      ...forumDetails,
      [name]: value,
    });
  };

  const getSingleMember = () => {
    memberID &&
      httpClient.get(`/member/${memberID}`).then(({ data }) => {
        if (data.success === true) {
          setForumDetails({
            ...forumDetails,
            memberName: data.data.firstName + ' ' + data.data.lastName,
            forumState: data.data.memberActive === 1 ? 'Enable' : 'Disable',
            forumAccess: data.data.memberActive,
          });
        }
      });
  };

  const handleChangeStatus = () => {
    setForumDetails({
      ...forumDetails,
      loading: true,
    });
    httpClient
      .post(
        `/member/forum-access/${memberID}`,
        qs.stringify({
          forumAccess: forumDetails.forumAccess === true ? 1 : 0,
        }),
      )
      .then(({ data }) => {
        if (data.success === true) {
          setResponse({
            open: true,
            message: data.message,
            messageState: 'success',
          });
          setForumDetails({
            ...forumDetails,
            loading: false,
            submit: true,
            open: false,
          });
        } else {
          setResponse({
            open: true,
            message: 'Failed to change forum status',
            messageState: 'error',
          });
          setForumDetails({
            ...forumDetails,
            loading: false,
            submit: true,
            open: true,
          });
        }
      });
  };

  props.sendResponse(response);
  props.sendForumData(forumDetails);

  return (
    <Dialog open={forumDetails.open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <h2>Forum Management</h2>
      </DialogTitle>
      <IconButton onClick={handleClose} className={classes.closeBtn}>
        <Close />
      </IconButton>
      <DialogContent className={classes.content}>
        <Grid container spacing={4}>
          <form className={classes.formControl}>
            <Grid item sm={12} md={12}>
              <InputLabel> Member Name</InputLabel>
              <TextField
                variant="outlined"
                name="memberName"
                value={forumDetails.memberName}
                className={classes.textField}
                onChange={handleChange}
                disabled
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <InputLabel> Current state</InputLabel>
              <TextField
                variant="outlined"
                name="forumState"
                value={forumDetails.forumState}
                className={classes.textField}
                onChange={handleChange}
                disabled
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <InputLabel>Change State</InputLabel>
              <FormControl variant="outlined" className={classes.textSelect}>
                <Select
                  name="forumAccess"
                  value={forumDetails.forumAccess}
                  onChange={handleChange}
                  className={classes.textField}>
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={1}>Enable</MenuItem>
                  <MenuItem value={0}>Disable</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  className={classes.resetBtn}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleChangeStatus}>
                  Change Status
                </Button>
                {forumDetails.loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
              </div>
            </Grid>
          </form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ForumMgmt;
