import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableShipComponent from './../TableShipComponent';

const columns = [
  { id: 'name', label: 'Name' },
  { id: 'tracking', label: 'Tracking Url' },
  { id: 'active', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

const ListShippingCompany = () => {
  const [shipping, setShipping] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getShippingCompany();
  }, []);

  const getShippingCompany = () => {
    setLoading(true);
    httpClient.get('/shipping/company').then(({ data }) => {
      if (data.success === true) {
        setShipping(data.data);
        setLoading(false);
      }
    });
  };

  const handleEdit = id => {
    history.push(`/orders/addShippingCompany/${id}`);
  };

  const handleDelete = () => {};

  const onChangeSearch = e => {
    setSearchText(e.target.value);
    e.target.value === '' && getShippingCompany();
  };

  const handleSearch = e => {
    if (e.key === 'Enter') {
      httpClient.get(`shipping/company/search?q=${searchText}`).then(({ data }) => {
        if (data.success === true) {
          setShipping(data.data);
        } else {
          console.log('search failed');
        }
      });
    }
  };

  const handleDefault = e => {
    setSearchText(e.target.value);
  };

  return (
    <PageContainer heading="Shipping Company">
      <TableShipComponent
        columns={columns}
        selectedProducts={shipping}
        title={'List Shipping Company'}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        onChangeSearch={e => onChangeSearch(e)}
        searchValue={searchText}
        onKeyPress={e => handleSearch(e)}
        onKeyDown={e => handleDefault(e)}
        loading={loading}
      />
    </PageContainer>
  );
};

export default ListShippingCompany;
