import React, { useEffect, useState } from 'react';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { httpClient } from 'appUtility/Api';
import Features from './Features';
import ScanQR from './ScanQR';
import DisableModal from './DisableModal';

const qs = require('qs');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TwoFASettings = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [qrCode, setQRCode] = useState('');
  const [qrSecret, setQRSecret] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const token = JSON.parse(localStorage.getItem('token'));
  const userData = JSON.parse(localStorage.getItem('login'));
  const {
    sysAdminFirstName: firstName,
    sysAdminLastName: lastName,
    sysAdminUserName: userName,
    sysAdminID,
    SuperAdmin: superAdmin,
    enableTwoFactor,
  } = !!userData && userData.user;

  const [localStorageValueChanged, setLocalStorageValueChanged] = useState(false);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(enableTwoFactor === 1);

  const getQRCode = () => {
    httpClient
      .get(`twofa/image?firstName=${firstName}&lastName=${lastName}&userName=${userName}&sysAdminID=${sysAdminID}`)
      .then(({ data }) => {
        if (data.success) {
          const { qr_code_url, secret_key } = data;
          setQRCode(qr_code_url);
          setQRSecret(secret_key);
        } else {
          setTimeout(function() {
            setOpen(true);
            setMessage('Error loading QR code');
            setMessageState('error');
            setLoading(false);
          }, 1000);
        }
      });
  };

  useEffect(() => {
    const localStorageValue = JSON.parse(localStorage.getItem('enableTwoFactor'));

    if (localStorageValue) {
      setTwoFactorEnabled(localStorageValue === 1);
    }
  }, [localStorageValueChanged]);

  useEffect(() => {
    if (!twoFactorEnabled) {
      getQRCode();
    }
  }, [twoFactorEnabled]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const headerConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleEnable = () => {
    if (!verificationCode) {
      setOpen(true);
      setMessage('Please enter OTP');
      setMessageState('error');
      return;
    }

    setLoading(true);
    httpClient
      .post(
        'twofa/verify',
        qs.stringify({
          otp: verificationCode,
          secret: qrSecret,
          superAdmin,
          sysAdminID,
        }),
        headerConfig,
      )
      .then(({ data }) => {
        if (data.success) {
          localStorage.setItem('enableTwoFactor', JSON.stringify(data.enableTwoFactor));
          localStorage.setItem('2FALocalValueChanged', true);
          setLocalStorageValueChanged(true);
          setTwoFactorEnabled(true);
          setTimeout(function() {
            setOpen(true);
            setMessage(data.message || 'OTP verification successful.');
            setMessageState('success');
          }, 1000);
          setTimeout(function() {
            setLoading(false);
          }, 1500);
        } else {
          setTimeout(function() {
            setOpen(true);
            setMessage(data.message || 'OTP verification failed.');
            setMessageState('error');
            setLoading(false);
          }, 1000);
        }
      });
  };

  const handleDisable = (type, otp) => {
    if (type === 'openModal') {
      handleOpenModal();
      return;
    }

    if (type === 'disable') {
      handleCloseModal();
    }

    if (!otp) {
      setOpen(true);
      setMessage('Please enter OTP');
      setMessageState('error');
      return;
    }

    let formData = new FormData();
    formData.append('otp', otp);
    formData.append('enableTwoFactor', 0);

    setLoading(true);
    httpClient.post('profile/update-twofa', formData).then(({ data }) => {
      if (data.success) {
        localStorage.setItem('enableTwoFactor', JSON.stringify(data.enableTwoFactor));
        localStorage.setItem('2FALocalValueChanged', true);
        setLocalStorageValueChanged(true);
        setTwoFactorEnabled(false);
        setTimeout(function() {
          setOpen(true);
          setMessage(data.message || 'Successfully updated settings.');
          setMessageState('success');
        }, 1000);

        setTimeout(function() {
          setLoading(false);
        }, 1500);
      } else {
        setTimeout(function() {
          setOpen(true);
          setMessage(data.message || 'OTP verification failed.');
          setMessageState('error');
          setLoading(false);
        }, 1000);
      }
    });
  };

  return (
    <PageContainer heading="Security">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Features />
          <Box pt={2} pb={2}>
            <Box mt={10}>
              {!twoFactorEnabled ? (
                <ScanQR qrCode={qrCode} verificationCode={verificationCode} setVerificationCode={setVerificationCode} />
              ) : (
                ''
              )}
              <Box display="flex" alignItems="center">
                <Button
                  onClick={() => (twoFactorEnabled ? handleDisable('openModal', '') : handleEnable(''))}
                  variant="contained"
                  color="primary"
                  disabled={loading}>
                  <IntlMessages id={twoFactorEnabled ? 'security.disable2FA' : 'security.enable2FA'} />
                </Button>
                {loading ? <CircularProgress size={20} style={{ marginLeft: '10px' }} /> : ''}
              </Box>
            </Box>
          </Box>
        </Grid>
      </GridContainer>
      <DisableModal openModal={openModal} handleCloseModal={handleCloseModal} handleDisable={handleDisable} />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default TwoFASettings;
