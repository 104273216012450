import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useRef, useState } from 'react';
import { history } from 'redux/store';
import AlertDialog from '../AlertDialog';
import TableComponent from './../TableComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
const qs = require('qs');

const columns = [
  { id: 'vid', label: 'ID' },
  { id: 'version_number', label: 'Version' },
  { id: 'versionTitle', label: 'Title' },
  { id: 'logoutRequired', label: 'Logout' },
  { id: 'refreshRequired', label: 'Refresh' },
  { id: 'addedDateTime', label: 'Date' },
  { id: 'Actions', label: 'Actions' },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListVersion = () => {
  const ref = useRef();

  const [versions, setVersions] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteID, setDeleteID] = useState('');
  const [open, setOpen] = useState(false);

  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  var [initialLoad, setInitailLoad] = useState('false');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    logoutRequired: '',
    refreshRequired: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    logoutRequired: '',
    refreshRequired: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (filterData.logoutRequired === '' && filterData.refreshRequired === '' && filterData.search === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.logoutRequired === ' ') filterData.logoutRequired = '';
    if (filterData.refreshRequired === ' ') filterData.refreshRequired = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();

    console.log('filterdata', filterData);
  }, [filterData]);

  useEffect(() => {
    let versionStorage = JSON.parse(localStorage.getItem('version_filter'));
    versionStorage !== null && setFilterData(versionStorage);
    // console.log('initial load ??', initialLoad);
    // initialLoad === 'true' && getAllVersions();
    versionStorage == null
      ? getAllVersions()
      : versionStorage.logoutRequired == '' &&
        versionStorage.refreshRequired == '' &&
        versionStorage.search == '' &&
        versionStorage.removed == false
      ? getAllVersions()
      : console.log('version');
  }, []);

  const getAllVersions = () => {
    setLoading(true);
    httpClient.get(`version?&${rowsPerPage}`).then(({ data }) => {
      if (data) {
        setVersions(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
      }
    });
  };

  const handleSubmitData = () => {
    setSubmittedData({
      logoutRequired: filterData.logoutRequired,
      refreshRequired: filterData.refreshRequired,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('version_filter', JSON.stringify(filterData));
    if (filterData.logoutRequired || filterData.refreshRequired || filterData.search) {
      httpClient
        .get(
          `version/filter?q=${filterData.search}&logoutRequired=${filterData.logoutRequired}&refreshRequired=${filterData.refreshRequired}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setVersions(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllVersions();
    }
  };

  const handleFilter = e => {
    e.preventDefault();

    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`version?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setVersions(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `version/filter?q=${filterData.search}&logoutRequired=${filterData.logoutRequired}&refreshRequired=${filterData.refreshRequired}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setVersions(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`version?&direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setVersions(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(
            `version/filter?q=${filterData.search}&logoutRequired=${filterData.logoutRequired}&refreshRequired=${
              filterData.refreshRequired
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setVersions(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`version?&direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setVersions(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleEdit = id => {
    // console.log('edit now', id);
    history.push(`/versions/addVersion/${id}`);
  };
  const handleDelete = id => {
    setDeleteID(id);
    setDialogOpen(true);
  };

  const deleteCallBack = callback => {
    callback.open === false && setDialogOpen(false);

    callback.delete === true &&
      httpClient.delete(`version/${deleteID}`).then(({ data }) => {
        if (data.success === true) {
          getAllVersions();
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
        } else {
          setOpen(true);
          setMessage('Version failed to be deleted');
          setMessageState('error');
        }
      });
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getAllVersions();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`version/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setVersions(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });

  // let filter_version = versions.filter(
  //   item =>
  //     item.versionTitle.toLowerCase().includes(searchText.toLowerCase()) ||
  //     item.versionDescription.toLowerCase().includes(searchText.toLowerCase()),
  // );

  // setTimeout(() => {
  //   if (filter_version && filter_version.length === 0) {
  //     setVersions(filter_version);
  //     setSearchText('');
  //   } else {
  //     setVersions(filter_version);
  //   }
  // }, 1000);
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="List Versions">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableComponent
              columns={columns}
              selectedProducts={versions}
              title="List Versions"
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total && total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>

      {dialogOpen && <AlertDialog deleteCallBack={deleteCallBack} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListVersion;
