import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  text: {
    margin: '10px 0',
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddInventoryCategory = props => {
  const classes = useStyles();
  const [inventoryCatDetails, setInventoryCatDetails] = useState({
    inventory_category_name: '',
    inventory_category_description: '',
    minOrderQty: 0,
    orderPickupFee: 0,
    inventory_category_active: false,
  });
  const [categoryID, setCategoryID] = useState(props && props.location.pathname.slice(32));
  console.log('category id is', categoryID);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getSingleInventoryCategory();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setInventoryCatDetails({
      ...inventoryCatDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setInventoryCatDetails({
      ...inventoryCatDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    categoryID == ''
      ? httpClient
          .post(
            'inventory/category',
            qs.stringify({
              inventory_category_name: inventoryCatDetails.inventory_category_name,
              inventory_category_description: inventoryCatDetails.inventory_category_description,
              minOrderQty: inventoryCatDetails.minOrderQty,
              orderPickupFee: inventoryCatDetails.orderPickupFee,
              inventory_category_active: inventoryCatDetails.inventory_category_active == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setInventoryCatDetails({
                inventory_category_name: '',
                inventory_category_description: '',
                minOrderQty: 0,
                orderPickupFee: 0,
                inventory_category_active: false,
              });
              setTimeout(() => {
                history.push('/inventory/listInventoryCategory');
              }, 1500);
            } else {
              setOpen(true);
              setMessage('Inventory Category Failed to be added');
              setMessageState('error');
              // !data.error ? setMessage(data.message[0]) : setMessage(data.error);
            }
          })
      : httpClient
          .post(
            `inventory/category/${categoryID}`,
            qs.stringify({
              inventory_category_name: inventoryCatDetails.inventory_category_name,
              inventory_category_description: inventoryCatDetails.inventory_category_description,
              minOrderQty: inventoryCatDetails.minOrderQty,
              orderPickupFee: inventoryCatDetails.orderPickupFee,
              inventory_category_active: inventoryCatDetails.inventory_category_active == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                history.push('/inventory/listInventoryCategory');
              }, 1500);
            } else {
              setOpen(true);
              setMessage('Inventory Category Failed to be added');
              setMessageState('error');
              // !data.error ? setMessage(data.message[0]) : setMessage(data.error);

            }
          });
  };

  const getSingleInventoryCategory = () => {
    categoryID &&
      httpClient.get(`inventory/category/${categoryID}`).then(({ data }) => {
        if (data.success == true) {
          setInventoryCatDetails({
            inventory_category_name: data.data.inventory_category_name,
            inventory_category_description: data.data.inventory_category_description,
            minOrderQty: data.data.minOrderQty,
            orderPickupFee: data.data.orderPickupFee,
            inventory_category_active: data.data.inventory_category_active == 'Active' ? true : false,
          });
        }
      });
  };

  const handleBack = () => {
    history.push('/inventory/listInventoryCategory');
  };

  return (
    <PageContainer heading={categoryID == '' ? 'Add Inventory Category' : 'Edit Inventory Category'}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Inventory Category Name"
                variant="outlined"
                name="inventory_category_name"
                value={inventoryCatDetails.inventory_category_name}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={4}
                label="Inventory Category Description"
                variant="outlined"
                name="inventory_category_description"
                value={inventoryCatDetails.inventory_category_description}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Minimum Order Quantity"
                variant="outlined"
                type="number"
                name="minOrderQty"
                value={inventoryCatDetails.minOrderQty}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Order Pickup Fee"
                variant="outlined"
                name="orderPickupFee"
                value={inventoryCatDetails.orderPickupFee}
                className={classes.text}
                onChange={handleChange}
                type="number"
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="inventory_category_active"
                    checked={inventoryCatDetails.inventory_category_active}
                    onChange={handleCheck}
                    value={inventoryCatDetails.inventory_category_active}
                    color="primary"
                  />
                }
                label="Make Inventory Category Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Box textAlign="right" pt={4}>
                <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  {categoryID === '' ? 'Add Inventory Category' : 'Edit Inventory Category'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddInventoryCategory;
