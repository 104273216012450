import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { httpClient } from 'appUtility/Api';
import moment from 'moment';
import { AddPhotoAlternateOutlined } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dropzone from 'react-dropzone';
import { history } from 'redux/store';
import AddSuburbDialog from '../AddSuburbDialog';
import { Autocomplete } from '@material-ui/lab';
import SocialGroupsDialog from '../SocialGroupsDialog';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const qs = require('qs');

const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles(theme => ({
  text: {
    width: '100%',
  },

  textArea: {
    width: '100%',
  },
  root: {
    padding: theme.spacing(8),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
    padding: '20px',
  },
  normal: {
    padding: '20px 0',
  },
  accordionMargin: {
    marginTop: '20px',
  },
  input: {
    marginBottom: '10px',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
}));

const Option = styled('option')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const stateValues = [
  {
    stateID: 9,
    stateName: 'Australian Capital Territory',
    stateShortName: 'ACT',
  },
  { stateID: 8, stateName: 'Tasmania', stateShortName: 'TAS' },
  { stateID: 7, stateName: 'Victoria', stateShortName: 'VIC' },
  { stateID: 6, stateName: 'South Australia', stateShortName: 'SA' },
  { stateID: 5, stateName: 'Western Australia', stateShortName: 'WA' },
  { stateID: 4, stateName: 'Northern Territory', stateShortName: 'NT' },
  { stateID: 3, stateName: 'Queensland', stateShortName: 'QLD' },
  { stateID: 2, stateName: 'New South Wales', stateShortName: 'NSW' },
  { stateID: 0, stateName: 'All States', stateShortName: '' },
];

const initialMemberDetailValue = {
  tradingName: '',
  companyABN: '',
  affiliatedToBusiness: '',
  FirstName: '',
  LastName: '',
  EmailAddress: '',
  password: '',
  TelephonePhone: '',
  Mobile: '',
  Address1: '',
  Address2: '',
  Suburb: '',
  CountryID: '',
  StateID: '',
  TimeZone: '',
  ShippingZone: '',
  PostCode: '',
  ServiceLocation: '',
  Image: '',
  warningSet: false,
  registeredForTax: false,
  deRegisteredTax: false,
  enableTwoFactor: false,
  companyActive: false,
  ticketAdmin: false,
  loginToAdmin: false,
  DOB: moment(),
  warningSuspensionTill: moment(),
  memberSocialActive: false,
  memberLeadsActive: false,
  reactivateInventoryCategory: false,
  inventory_category_id: [],
  inventory_category_value: [],
  appLevelAdminID: '',
  serviceID: [],
  companiesID: [],
  // socialGroupID: [],
  memberSocialStateIDs: new Array(10).fill(false),
  comID: '',
  suspendLead: '',
  suspendLeadStartDate: moment(),
  suspendLeadEndDate: moment(),
  forwardLeads: '',
  emailTemplateID: '',
  leadsForwardToMemberID: '',
  leadsForwardToMemberName: '',
  suspendLeadReasonID: '',
  AdditionalServiceLocation: '',
  // changeTax: '',
  RegisterDate: moment(),
  deRegisteredDate: moment(),
  // aaa: false,
  // col: false,
};

const AddMembers = props => {
  const classes = useStyles();
  const location = useLocation();
  // const [memberID, setMemberID] = useState(props && props.location.pathname.slice(20));
  const [memberID, setMemberID] = useState(location.pathname.slice(20));
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [company, setCompany] = useState([]);
  const [allCompanyActive, setAllCompanyActive] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [suspendReasonList, setSuspendReasonList] = useState([]);

  const [systemGroup, setSystemGroup] = useState([]);
  const [services, setServices] = useState([]);
  const [formInvalid, setFormInvalid] = useState(false);
  const [companyUpdatedData, setCompanyUpdatedData] = useState([]);
  const [serviceUpdatedData, setServiceUpdatedData] = useState([]);
  const [socialUpdatedData, setSocialUpdatedData] = useState([]);
  const [stateIDs, setStateIDs] = useState('');
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [suburbDialog, setSuburbDialog] = useState(false);
  const [suburbData, setSuburbData] = useState([]);
  const [openSocialGroup, setOpenSocialGroup] = useState(false);
  const [inventoryCatLists, setInventoryCatList] = useState([]);
  // const [stateValues, setStateValues] = useState([]);
  const [currCompanyID, setCurrCompanyID] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [memberDetails, setMemberDetails] = useState(initialMemberDetailValue);
  const [isTwoFactorChangable, setIsTwoFactorChangable] = useState(false);

  useEffect(() => {
    getCountry();
    // getState();
    getShipping();
    getCompany();
    getFranchiseCompany();
    getServices();
    getSystemGroups();
    getSingleMember();
    getStateSuburb();
    getEmailTemplate();
    getSuspendReason();
    getInventoryCategory();
    // getAllState();
  }, []);

  // to avoid field initializing issue while adding member after editing
  useEffect(() => {
    setMemberID(location.pathname.slice(20));
  }, [location]);

  useEffect(() => {
    if (memberID === '') {
      setMemberDetails(() => initialMemberDetailValue);
      setMemberDetails(prev => ({
        ...prev,
        memberSocialStateIDs: Array(10).fill(false),
        companiesID: [],
      }));
    }
  }, [memberID]);

  useEffect(() => {
    getServicesDistribution();
    getCompanyDistribution();
    // getSocialSubscription();
  }, [memberDetails.comID]);

  useEffect(() => {
    allCompanyActive.length > 0 &&
      allCompanyActive.map(company => {
        if (company.memberID == memberDetails.leadsForwardToMemberID) {
          memberDetails.leadsForwardToMemberName = company.firstName + ' ' + company.lastName;
        }
      });
  }, [allCompanyActive, memberDetails.leadsForwardToMemberID]);

  useEffect(() => {
    serviceUpdatedData &&
      serviceUpdatedData.map((ser, index) => {
        memberDetails.serviceID[ser.systemService.ServiceID] = ser.memServicePrice;
      });
    companyUpdatedData &&
      companyUpdatedData.map((com, index) => {
        company.map(comp => {
          if (comp.companyID == com.company.companyID) {
            memberDetails.companiesID[com.company.companyID] = true;
          }
        });
      });

    // stateValues &&
    // stateValues.map((social, index) => {
    //   memberDetails.memberSocialStateIDs[social.stateID] = true;
    // });
    stateValues.map(state => {
      stateIDs &&
        stateIDs.map(ids => {
          if (state.stateID == ids) {
            memberDetails.memberSocialStateIDs[state.stateID] = true;
          }
        });
    });
  }, [serviceUpdatedData, companyUpdatedData, stateIDs]);

  const getServicesDistribution = () => {
    memberDetails.comID !== '' &&
      httpClient.get(`/member/service/${memberDetails.comID !== '' && memberDetails.comID}`).then(({ data }) => {
        setServiceUpdatedData(data.data);
      });
  };

  // serviceUpdatedData &&
  //   serviceUpdatedData.map((ser, index) => {
  //     memberDetails.serviceID[ser.systemService.ServiceID] = ser.memServicePrice;
  //   });

  const getCompanyDistribution = () => {
    memberDetails.comID !== '' &&
      httpClient.get(`/member/distribution/${memberDetails.comID !== '' && memberDetails.comID}`).then(({ data }) => {
        setCompanyUpdatedData(data.data);
      });
  };
  // companyUpdatedData &&
  //   companyUpdatedData.map((com, index) => {
  //     memberDetails.companiesID[com.company.companyID] = true;
  //   });

  // const getSocialSubscription = () => {
  //   memberID !== '' &&
  //     httpClient.get(`/social-group/subscription/member/${memberID !== '' && memberID} `).then(({ data }) => {
  //       setSocialUpdatedData(data.data);
  //     });
  // };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'leadsForwardToMemberID') {
      memberDetails.leadsForwardToMemberName = value;
    }
    setMemberDetails({
      ...memberDetails,
      [name]: value,
    });
  };

  const handleChangeForwardLeads = value => {
    setMemberDetails({
      ...memberDetails,
      leadsForwardToMemberID: value !== null ? value.memberID : '',
      leadsForwardToMemberName: value !== null ? value.firstName + ' ' + value.lastName : '',
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setMemberDetails({
      ...memberDetails,
      [name]: checked,
    });
  };

  const handleMemberCheck = (e, id) => {
    const { checked } = e.target;
    memberDetails.companiesID[id] = checked;

    setMemberDetails({
      ...memberDetails,
      companiesID: memberDetails.companiesID,
    });
  };

  const handleSocialCheck = (e, id) => {
    const { checked } = e.target;

    if (id === 0) {
      stateValues.map(st => {
        setMemberDetails(prev => ({
          ...prev,
          memberSocialStateIDs: Array(10).fill(checked ? true : false),
        }));
      });
    } else {
      if (!checked) memberDetails.memberSocialStateIDs[0] = false;
      if (checked) {
        const otherElememtsToCheck = memberDetails.memberSocialStateIDs.filter((bool, index) => {
          if (index !== 0 && index !== 1 && index !== id) return true;
        });

        if (!otherElememtsToCheck.includes(false)) memberDetails.memberSocialStateIDs[0] = true;
      }

      memberDetails.memberSocialStateIDs[id] = checked;
      setMemberDetails({
        ...memberDetails,
        memberSocialStateIDs: memberDetails.memberSocialStateIDs,
      });
    }
  };

  const handleInventoryCheck = (e, id) => {
    const { checked } = e.target;
    memberDetails.inventory_category_value[id] = checked;

    if (checked === true) {
      setMemberDetails({
        ...memberDetails,
        inventory_category_value: memberDetails.inventory_category_value,
        inventory_category_id: [...memberDetails.inventory_category_id, id],
      });
    } else {
      memberDetails.inventory_category_id = memberDetails.inventory_category_id.filter(cat => cat !== id);
      setMemberDetails({
        ...memberDetails,
        inventory_category_value: memberDetails.inventory_category_value,
        inventory_category_id: memberDetails.inventory_category_id,
      });
    }
  };

  const handleServices = (e, id) => {
    const { name, value } = e.target;
    memberDetails.serviceID[id] = value;
    // console.log('value is >>>', value);
    setMemberDetails({
      ...memberDetails,
      serviceID: memberDetails.serviceID,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getEmailTemplate = () => {
    httpClient.get('email-template/all').then(({ data }) => {
      if (data.success) {
        setTemplateList(data.data);
      }
    });
  };

  const getSuspendReason = () => {
    httpClient.get('suspend-lead/all').then(({ data }) => {
      if (data.success) {
        setSuspendReasonList(data.data);
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (
      memberDetails.tradingName === '' ||
      memberDetails.companyABN === '' ||
      memberDetails.affiliatedToBusiness === '' ||
      memberDetails.FirstName === '' ||
      memberDetails.LastName === '' ||
      memberDetails.EmailAddress === '' ||
      // memberDetails.password === '' ||
      // memberDetails.TelephonePhone === '' ||
      memberDetails.Mobile === '' ||
      memberDetails.Address1 === '' ||
      // memberDetails.Address2 === '' ||
      memberDetails.Suburb === '' ||
      memberDetails.CountryID === '' ||
      memberDetails.StateID === '' ||
      memberDetails.TimeZone === '' ||
      memberDetails.ShippingZone === '' ||
      memberDetails.PostCode === '' ||
      // memberDetails.ServiceLocation === '' ||
      // memberDetails.DOB === '' ||
      memberDetails.appLevelAdminID === ''
    ) {
      setOpen(true);
      setMessage('Mandatory Fields are left empty!');
      setMessageState('error');
    } else {
      // console.log('submitted data >>', memberDetails);
      var NewData = new FormData();
      NewData.append('tradingName', memberDetails.tradingName);
      NewData.append('companyABN', memberDetails.companyABN);
      NewData.append('affiliatedToBusiness', memberDetails.affiliatedToBusiness);
      NewData.append('FirstName', memberDetails.FirstName);
      NewData.append('LastName', memberDetails.LastName);
      NewData.append('EmailAddress', memberDetails.EmailAddress);
      NewData.append('password', memberDetails.password);
      NewData.append('TelephonePhone', memberDetails.TelephonePhone);
      NewData.append('Mobile', memberDetails.Mobile);
      NewData.append('Address1', memberDetails.Address1);
      NewData.append('Address2', memberDetails.Address2);
      NewData.append('Suburb', memberDetails.Suburb);
      NewData.append('CountryID', memberDetails.CountryID);
      NewData.append('StateID', memberDetails.StateID);
      NewData.append('TimeZone', memberDetails.TimeZone);
      NewData.append('ShippingZone', memberDetails.ShippingZone);
      NewData.append('PostCode', memberDetails.PostCode);
      NewData.append('ServiceLocation', memberDetails.ServiceLocation);
      NewData.append('warningSet', memberDetails.warningSet === true ? 1 : 0);
      NewData.append('registeredForTax', memberDetails.registeredForTax === true ? 1 : 0);
      NewData.append('deRegisteredTax', memberDetails.deRegisteredTax && memberDetails.registeredForTax === true ? 1 : 0);
      NewData.append('enableTwoFactor', memberDetails.enableTwoFactor === true ? 1 : 0);
      NewData.append('companyActive', memberDetails.companyActive === true ? 1 : 0);
      NewData.append('ticketAdmin', memberDetails.ticketAdmin === true ? 1 : 0);
      NewData.append('loginToAdmin', memberDetails.loginToAdmin === true ? 1 : 0);
      NewData.append('DOB', memberDetails.DOB);
      NewData.append('suspendLead', memberDetails.suspendLead);
      NewData.append('suspendLeadStartDate', memberDetails.suspendLeadStartDate);
      NewData.append('suspendLeadEndDate', memberDetails.suspendLeadEndDate);
      NewData.append('leadsForwardToMemberID', memberDetails.leadsForwardToMemberID);

      // NewData.append('changeTax', memberDetails.changeTax);
      NewData.append('RegisterDate', memberDetails.RegisterDate ? memberDetails.RegisterDate : '');
      NewData.append(
        'deRegisteredDate',
        memberDetails.deRegisteredDate && memberDetails.registeredForTax ? memberDetails.deRegisteredDate : '',
      );

      NewData.append('emailTemplateID', memberDetails.emailTemplateID);
      NewData.append('suspendLeadReasonID', memberDetails.suspendLeadReasonID);
      // NewData.append('AdditionalServiceLocation', memberDetails.AdditionalServiceLocation);

      NewData.append('warningSuspensionTill', memberDetails.warningSuspensionTill);
      NewData.append('memberSocialActive', memberDetails.memberSocialActive === true ? 1 : 0);
      NewData.append('memberLeadsActive', memberDetails.memberLeadsActive === true ? 1 : 0);

      NewData.append('appLevelAdminID', memberDetails.appLevelAdminID);
      NewData.append('Image', memberDetails.Image);

      if (memberDetails.AdditionalServiceLocation !== '') {
        memberDetails.AdditionalServiceLocation.map((serv, index) => {
          NewData.append(`AdditionalServiceLocation[${index}]`, serv);
        });
      }

      memberDetails.serviceID.map((ser, index) => {
        NewData.append(`serviceID[${index}]`, ser);
      });

      memberDetails.companiesID.map((com, index) => {
        if (com == true) {
          NewData.append(`companiesID[${index}]`, index);
        }
      });

      memberDetails.memberSocialStateIDs.map((social, index) => {
        if (social == true) {
          NewData.append(`memberSocialStateIDs[${index}]`, index);
        }
      });

      var updatedData = new FormData();
      updatedData.append('tradingName', memberDetails.tradingName);
      updatedData.append('companyABN', memberDetails.companyABN);
      updatedData.append('affiliatedToBusiness', memberDetails.affiliatedToBusiness);
      updatedData.append('FirstName', memberDetails.FirstName);
      updatedData.append('LastName', memberDetails.LastName);
      updatedData.append('EmailAddress', memberDetails.EmailAddress);
      updatedData.append('TelephonePhone', memberDetails.TelephonePhone);
      updatedData.append('Mobile', memberDetails.Mobile);
      updatedData.append('Address1', memberDetails.Address1);
      updatedData.append('Address2', memberDetails.Address2);
      updatedData.append('Suburb', memberDetails.Suburb);
      updatedData.append('CountryID', memberDetails.CountryID);
      updatedData.append('StateID', memberDetails.StateID);
      updatedData.append('TimeZone', memberDetails.TimeZone);
      updatedData.append('ShippingZone', memberDetails.ShippingZone);
      updatedData.append('PostCode', memberDetails.PostCode);
      updatedData.append('ServiceLocation', memberDetails.ServiceLocation);
      updatedData.append('warningSet', memberDetails.warningSet === true ? 1 : 0);
      updatedData.append('registeredForTax', memberDetails.registeredForTax === true ? 1 : 0);
      updatedData.append(
        'deRegisteredTax',
        memberDetails.deRegisteredTax && memberDetails.registeredForTax === true ? 1 : 0,
      );
      updatedData.append('enableTwoFactor', memberDetails.enableTwoFactor === true ? 1 : 0);
      updatedData.append('companyActive', memberDetails.companyActive === true ? 1 : 0);
      updatedData.append('ticketAdmin', memberDetails.ticketAdmin === true ? 1 : 0);
      updatedData.append('loginToAdmin', memberDetails.loginToAdmin === true ? 1 : 0);
      updatedData.append('DOB', memberDetails.DOB);
      updatedData.append('suspendLead', memberDetails.suspendLead);
      updatedData.append('suspendLeadStartDate', memberDetails.suspendLeadStartDate);
      updatedData.append('suspendLeadEndDate', memberDetails.suspendLeadEndDate);
      updatedData.append('leadsForwardToMemberID', memberDetails.leadsForwardToMemberID);

      // updatedData.append('changeTax', memberDetails.changeTax);
      updatedData.append('RegisterDate', memberDetails.RegisterDate ? memberDetails.RegisterDate : '');
      updatedData.append(
        'deRegisteredDate',
        memberDetails.deRegisteredDate && memberDetails.registeredForTax ? memberDetails.deRegisteredDate : '',
      );

      updatedData.append('emailTemplateID', memberDetails.emailTemplateID);
      updatedData.append('suspendLeadReasonID', memberDetails.suspendLeadReasonID);
      // updatedData.append('AdditionalServiceLocation', memberDetails.AdditionalServiceLocation);

      updatedData.append('warningSuspensionTill', memberDetails.warningSuspensionTill);
      updatedData.append('memberSocialActive', memberDetails.memberSocialActive === true ? 1 : 0);
      updatedData.append('memberLeadsActive', memberDetails.memberLeadsActive === true ? 1 : 0);
      updatedData.append('appLevelAdminID', memberDetails.appLevelAdminID);
      updatedData.append('Image', memberDetails.Image.path ? memberDetails.Image : ' ');

      if (memberDetails.AdditionalServiceLocation !== '') {
        memberDetails.AdditionalServiceLocation.map((serv, index) => {
          updatedData.append(`AdditionalServiceLocation[${index}]`, serv);
        });
      }

      memberDetails.serviceID.map((ser, index) => {
        updatedData.append(`serviceID[${index}]`, ser);
      });
      memberDetails.companiesID.map((com, index) => {
        if (com == true) {
          updatedData.append(`companiesID[${index}]`, index);
        }
      });

      memberDetails.memberSocialStateIDs.map((social, index) => {
        if (social == true) {
          updatedData.append(`memberSocialStateIDs[${index}]`, index);
        }
      });

      setButtonLoader(true);
      memberID === ''
        ? httpClient.post('member', NewData).then(({ data }) => {
            if (data.success === true) {
              // console.log('submited data', data.data);
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setMemberDetails(initialMemberDetailValue);
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setButtonLoader(false);

              setTimeout(() => {
                history.push('/members/listMembers');
              }, 2000);
            } else {
              setOpen(true);
              // setMessage(data.message);
              !data.error ? setMessage(data.message[0]) : setMessage(data.error);

              setMessageState('error');
              setButtonLoader(false);
            }
          })
        : httpClient.post(`member/${memberID}`, updatedData).then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setButtonLoader(false);
              setTimeout(() => {
                history.push('/members/listMembers');
              }, 2000);
            } else {
              setOpen(true);
              // setMessage('* Required field is missing');
              !data.error ? setMessage(data.message[0]) : setMessage(data.error);
              setMessageState('error');
              setButtonLoader(false);
            }
          });

      if (memberID !== '' && memberDetails.inventory_category_id.length) {
        httpClient
          .post(
            `inventory/category/re-activate`,
            qs.stringify({
              companyID: currCompanyID,
              inventory_category_id: memberDetails.inventory_category_id,
            }),
          )
          .then(({ data }) => {
            if (data.success) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
            } else {
              setOpen(true);
              // setMessage('* Required field is missing');
              !data.error ? setMessage(data.message[0]) : setMessage(data.error);
              setMessageState('error');
            }
          });
      }
    }
  };

  const getSingleMember = () => {
    memberID && setLoading(true);
    memberID &&
      httpClient.get(`/member/${memberID}`).then(({ data }) => {
        if (data) {
          setLoading(false);
          let stIds = data.data.memberSocialStateIDs.map(ids => parseInt(ids));
          let suburbIDList = [];
          data.data.companyID !== null &&
            data.data.companyID.additional_service_location.map(serv => {
              suburbIDList.push(serv.suburbID);
            });
          setStateIDs(stIds);
          setSuburbData(data.data.companyID && data.data.companyID.additional_service_location);

          setCurrCompanyID(data.data.companyID.companyID);
          if (data.data.enableTwoFactor === 1) setIsTwoFactorChangable(true);
          setMemberDetails({
            ...memberDetails,
            comID: data.data.companyID.companyID,
            tradingName: data.data.companyID.tradingName,
            companyABN: data.data.companyID.companyABN,
            affiliatedToBusiness: data.data.companyID.affiliatedToBusiness,
            FirstName: data.data.companyID.contactPersonFirstName,
            LastName: data.data.companyID.contactPersonLastName,
            EmailAddress: data.data.companyID.contactPersonEmailAddress,
            // password: '',
            TelephonePhone: data.data.companyID.companyTelephone,
            Mobile: data.data.companyID.companyMobile,
            Address1: data.data.companyID.companyAddress1,
            Address2: data.data.companyID.companyAddress1,
            Suburb: data.data.companyID.companySuburb,
            CountryID: data.data.memCountryID.countryID,
            StateID: data.data.memStateID.stateID,
            TimeZone: data.data.companyID.companyTimeZone,
            ShippingZone: data.data.shippingZoneID,
            PostCode: data.data.companyID.companyPostcode,
            ServiceLocation: data.data.companyID.serviceLocation,
            Image: data.data.memImage,
            // memberSocialStateIDs: stateData,
            warningSet: data.data.companyID.warningSet === 1 ? true : false,
            registeredForTax: data.data.companyID.registeredForTax === 1 ? true : false,
            deRegisteredTax: data.data.companyID.deRegisteredTax === 1 ? true : false,
            enableTwoFactor: data.data.enableTwoFactor === 1 ? true : false,
            companyActive: data.data.companyID.companyActive === 1 ? true : false,
            ticketAdmin: data.data.ticketAdmin === 1 ? true : false,
            loginToAdmin: data.data.loginToAdmin == '1' ? true : false,
            DOB: data.data.memDOB,
            warningSuspensionTill: data.data.warningSuspensionTill,
            memberSocialActive: data.data.memberSocialActive === 1 ? true : false,
            memberLeadsActive: data.data.memberLeadsActive === 1 ? true : false,
            suspendLead: data.data.suspendLead === 'yes' ? '1' : '0',
            suspendLeadStartDate: data.data.suspendLeadStartDate,
            suspendLeadEndDate: data.data.suspendLeadEndDate,
            leadsForwardToMemberID: data.data.leadsForwardToMemberID,
            // changeTax: data.data.companyID.changeTax,
            RegisterDate:
              data.data.companyID.RegisterDate === null ||
              data.data.companyID.RegisterDate === '0000-00-00 00:00:00' ||
              data.data.companyID.RegisterDate === 'Invalid date'
                ? null
                : moment(data.data.companyID.RegisterDate).format('YYYY-MM-DD'),

            deRegisteredDate:
              data.data.companyID.deRegisteredDate === null ||
              data.data.companyID.deRegisteredDate === '0000-00-00 00:00:00' ||
              data.data.companyID.deRegisteredDate === 'Invalid date'
                ? null
                : moment(data.data.companyID.deRegisteredDate).format('YYYY-MM-DD'),

            forwardLeads: data.data.leadsForwardToMemberID != 0 ? '1' : '0',
            emailTemplateID: data.data.emailTemplateID,
            suspendLeadReasonID: data.data.suspendLeadReason !== null && data.data.suspendLeadReason.ID,
            AdditionalServiceLocation: suburbIDList,
            appLevelAdminID:
              (data.data.appLevelAdminID === 'Franchisee' && '1') ||
              (data.data.appLevelAdminID === 'Master Franchisee' && '3') ||
              (data.data.appLevelAdminID === 'Franchisor' && '4'),
          });
        } else {
        }
      });
  };

  const getCountry = () => {
    httpClient.get('/country').then(({ data }) => {
      if (data) {
        setCountry(data.data);
      } else {
      }
    });
  };

  // const getAllState = () => {
  //   httpClient.get('/state').then(({ data }) => {
  //     if (data) {
  //       // setState(data.data);
  //       setStateValues(data.data);
  //     } else {
  //     }
  //   });
  // };

  // console.log('al state', stateValues);

  const getStateSuburb = () => {
    httpClient.get('/state/suburb').then(({ data }) => {
      if (data) {
        setState(data.data);
      }
    });
  };

  const getShipping = () => {
    httpClient.get('/shipping').then(({ data }) => {
      if (data) {
        setShipping(data.data);
      } else {
      }
    });
  };

  const getInventoryCategory = () => {
    httpClient.get('inventory/category').then(({ data }) => {
      if (data.success) {
        setInventoryCatList(data.data);
      } else {
      }
    });
  };

  const getCompany = () => {
    setLoading(true);
    httpClient.get('member/all').then(({ data }) => {
      if (data) {
        let companyData = [];
        let newData = [];
        companyData = data.data;
        companyData.map(item => {
          if (item.appLevelAdminID === 'Master Franchisee' && item.memberActive === 1) {
            return newData.push(item);
          }
        });
        setCompany(newData);
        setLoading(false);
      } else {
      }
    });
  };

  const getFranchiseCompany = () => {
    setLoading(true);
    httpClient.get('member/franchisee').then(({ data }) => {
      if (data) {
        setAllCompanyActive(data.data);
      }
    });
  };

  const getSystemGroups = () => {
    setLoading(true);
    httpClient.get('social-group/all').then(({ data }) => {
      if (data.success === true) {
        setSystemGroup(data.data);
        setLoading(false);
      }
    });
  };

  const getServices = () => {
    setLoading(true);
    httpClient.get('service').then(({ data }) => {
      if (data) {
        setLoading(false);
        setServices(data.data);
      } else {
      }
    });
  };

  const handleOpenAddSuburb = () => {
    setSuburbDialog(true);
  };

  // console.log('suburbData', suburbData);

  const sendSuburbCallback = call => {
    if (call.success === true) {
      setMemberDetails({
        ...memberDetails,
        AdditionalServiceLocation: call.suburbIDs,
      });

      let newSubs = call.suburbIDs.map((sub, index) => {
        return {
          suburbName: call.suburbs[index],
          suburbID: sub,
        };
      });

      setSuburbData(newSubs);
      setSuburbDialog(false);
    }
    if (call.open === false) {
      setSuburbDialog(false);
    }
  };

  const sendSocialGroup = callback => {
    if (callback.open === false) {
      setOpenSocialGroup(false);
    }
    if (callback.message !== '') {
      setOpen(true);
      setMessage(callback.message);
      setMessageState('success');
    }
  };

  const handleBack = () => {
    history.push('/members/listMembers');
  };

  const onDrop = file => {
    // return file[0];
    setMemberDetails({
      ...memberDetails,
      // Image: (URL.createObjectURL(file[0]))
      Image: file[0],
    });
  };

  let suburbValue = [];

  state &&
    state.map(stateVal => {
      if (memberDetails.StateID === stateVal.stateID) {
        stateVal.suburb.map(sub => {
          return suburbValue.push(sub);
        });
      }
    });

  if (memberDetails.suspendLead == '0') {
    memberDetails.suspendLeadEndDate = moment();
    memberDetails.suspendLeadStartDate = moment();
  }

  // console.log('member_details', memberDetails);
  // console.log('member_details', memberDetails.memberSocialStateIDs);1
  // console.log('company id is', currCompanyID);
  // console.log('company >>>', company);

  return (
    <PageContainer heading={memberID ? 'Edit Member Company' : 'Add Member Company'}>
      {loading ? (
        <Card className={classes.root}>
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        </Card>
      ) : (
        <Card className={classes.root}>
          <form onSubmit={handleSubmit}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{memberID ? 'Edit' : 'Create'} New Company Account</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordion}>
                <Grid container spacing={4}>
                  <Grid item sm={12} md={6}>
                    <TextField
                      required
                      label="Company Name"
                      variant="outlined"
                      name="tradingName"
                      value={memberDetails.tradingName}
                      className={classes.text}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <TextField
                      required
                      label="ABN Number"
                      variant="outlined"
                      name="companyABN"
                      type="number"
                      value={memberDetails.companyABN}
                      className={classes.text}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <TextField
                      required
                      select
                      name="affiliatedToBusiness"
                      label="Affiliated To Business"
                      value={memberDetails.affiliatedToBusiness}
                      onChange={handleChange}
                      className={classes.text}
                      variant="outlined"
                      SelectProps={{ native: true }}>
                      <Option value="" hidden></Option>
                      <Option value="none" style={{ fontStyle: 'italic' }} disabled>
                        Select
                      </Option>
                      <Option value="1">Blue Wheelers</Option>
                      <Option value="2">Dash Dog</Option>
                    </TextField>
                  </Grid>

                  <Grid item sm={12} md={6}>
                    <TextField
                      required
                      label="First Name"
                      variant="outlined"
                      name="FirstName"
                      value={memberDetails.FirstName}
                      className={classes.text}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <TextField
                      required
                      label="Last Name"
                      variant="outlined"
                      name="LastName"
                      value={memberDetails.LastName}
                      className={classes.text}
                      onChange={handleChange}
                    />
                  </Grid>
                  {memberID !== '' ? (
                    <Grid item sm={12} md={12}>
                      <TextField
                        required
                        // error={emailPattern.test(memberDetails.EmailAddress) === true ? false : true}
                        helperText={
                          emailPattern.test(memberDetails.EmailAddress) === true ? '' : 'Please enter a valid Email'
                        }
                        label="Email Address"
                        variant="outlined"
                        name="EmailAddress"
                        value={memberDetails.EmailAddress}
                        className={classes.text}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : (
                    <Grid item sm={12} md={12}>
                      <Grid container spacing={4}>
                        <Grid item sm={12} md={6}>
                          <TextField
                            required
                            // error={emailPattern.test(memberDetails.EmailAddress) === true ? false : true}
                            helperText={
                              emailPattern.test(memberDetails.EmailAddress) === true ? '' : 'Please enter a valid Email'
                            }
                            label="Email Address"
                            variant="outlined"
                            name="EmailAddress"
                            value={memberDetails.EmailAddress}
                            className={classes.text}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <TextField
                            required
                            label="Password"
                            type="password"
                            variant="outlined"
                            name="password"
                            value={memberDetails.password}
                            className={classes.text}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item sm={12} md={6}>
                    <TextField
                      // required
                      label="Personal Phone"
                      variant="outlined"
                      name="TelephonePhone"
                      value={memberDetails.TelephonePhone}
                      className={classes.text}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <TextField
                      label="Mobile"
                      variant="outlined"
                      name="Mobile"
                      value={memberDetails.Mobile}
                      className={classes.text}
                      onChange={handleChange}
                      type="text"
                      required
                    />
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <TextField
                      required
                      select
                      SelectProps={{ native: true }}
                      name="CountryID"
                      label="Country"
                      value={memberDetails.CountryID}
                      onChange={handleChange}
                      className={classes.text}
                      variant="outlined">
                      <Option value="" hidden></Option>
                      <Option style={{ fontStyle: 'italic' }} disabled>
                        Select Country
                      </Option>
                      <Option value={13}>Australia</Option>
                    </TextField>
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <TextField
                      required
                      select
                      SelectProps={{ native: true }}
                      name="StateID"
                      label="State"
                      value={memberDetails.StateID}
                      onChange={handleChange}
                      className={classes.text}
                      variant="outlined">
                      <Option value="" hidden></Option>
                      <Option style={{ fontStyle: 'italic' }} disabled>
                        Select State
                      </Option>
                      {state && state.map(st => <Option value={st.stateID}>{st.stateName}</Option>)}
                    </TextField>
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <TextField
                      required
                      label="Address 1"
                      variant="outlined"
                      name="Address1"
                      value={memberDetails.Address1}
                      className={classes.text}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <TextField
                      label="Address 2"
                      variant="outlined"
                      name="Address2"
                      value={memberDetails.Address2}
                      className={classes.text}
                      onChange={handleChange}
                    />
                  </Grid>
                  {/* <Grid item sm={12} md={12}>
                  <FormControl variant="outlined" className={classes.text}>
                    <InputLabel>Suburb</InputLabel>
                    <Select
                      required
                      name="Suburb"
                      label="Suburb"
                      value={memberDetails.Suburb}
                      onChange={handleChange}
                      className={classes.text}>
                      <MenuItem value="">
                        <em>Select Suburb</em>
                      </MenuItem>
                      {suburbValue &&
                        suburbValue.map(sub => (
                          // console.log('sububr is >>', sub.suburbName)
                          <MenuItem value={sub.suburbName}>{sub.suburbName}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid> */}
                  <Grid item sm={12} md={12}>
                    <TextField
                      label="Suburb *"
                      variant="outlined"
                      name="Suburb"
                      value={memberDetails.Suburb}
                      className={classes.text}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <TextField
                      required
                      select
                      SelectProps={{
                        native: true,
                      }}
                      name="TimeZone"
                      label="TimeZone"
                      value={memberDetails.TimeZone}
                      onChange={handleChange}
                      className={classes.text}
                      variant="outlined">
                      <Option value="" hidden></Option>
                      <Option value="none" style={{ fontStyle: 'italic' }} disabled>
                        Select TimeZone
                      </Option>
                      <Option value="Australia/Australian Capital Territory">Australia/Australian Capital Territory</Option>
                      <Option value="Australia/Adelaide">Australia/Adelaide</Option>
                      <Option value="Australia/Adeliade">Australia/Adeliade</Option>
                      <Option value="Australia/Victoria">Australia/Victoria</Option>
                      <Option value="Australia/Tasmania">Australia/Tasmania</Option>
                      <Option value="Australia/Queensland">Australia/Queensland</Option>
                      <Option value="Australia/Sydney">Australia/Sydney</Option>
                      <Option value="Australia/Perth">Australia/Perth</Option>
                      <Option value="Australia/Darwin">Australia/Darwin</Option>
                      <Option value="Australia/Melbourne">Australia/Melbourne</Option>
                      <Option value="Australia/Brisbane">Australia/Brisbane</Option>
                    </TextField>
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <TextField
                      required
                      select
                      SelectProps={{ native: true }}
                      name="ShippingZone"
                      label="Shipping Zone"
                      value={memberDetails.ShippingZone}
                      onChange={handleChange}
                      className={classes.text}
                      variant="outlined">
                      <Option value="" hidden></Option>
                      <Option value="none" style={{ fontStyle: 'italic' }}>
                        Select Shipping Zone
                      </Option>
                      {shipping &&
                        shipping.map(ship => <Option value={ship.shipping_zone_id}>{ship.shipping_zone_name}</Option>)}
                    </TextField>
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <TextField
                      required
                      label="Post Code"
                      variant="outlined"
                      name="PostCode"
                      value={memberDetails.PostCode}
                      className={classes.text}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={12} md={8}>
                    <TextField
                      // required
                      label="Service Location"
                      variant="outlined"
                      name="ServiceLocation"
                      value={memberDetails.ServiceLocation}
                      className={classes.text}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={12} md={4} style={{ display: 'flex' }} alignItems="center" justifyContent="center">
                    <Button fullWidth color="secondary" variant="contained" onClick={handleOpenAddSuburb}>
                      Add Additional Suburb
                    </Button>
                  </Grid>

                  <Grid item sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      name="DOB"
                      label="Date of Birth"
                      type="date"
                      onChange={handleChange}
                      // defaultValue={memberDetails.DOB}
                      value={memberDetails.DOB}
                      className={classes.text}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      name="warningSuspensionTill"
                      label="Warning Suspension"
                      type="date"
                      // defaultValue={memberDetails.warningSuspensionTill}
                      value={memberDetails.warningSuspensionTill}
                      className={classes.text}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <TextField
                      required
                      select
                      SelectProps={{ native: true }}
                      name="appLevelAdminID"
                      label="App Level Admin"
                      value={memberDetails.appLevelAdminID}
                      onChange={handleChange}
                      className={classes.text}
                      variant="outlined">
                      <Option value="" hidden></Option>
                      <Option value="none" style={{ fontStyle: 'italic' }}>
                        Select
                      </Option>
                      <Option value="1">Franchisee</Option>
                      <Option value="3">Master Franchisee</Option>
                      <Option value="4">Franchisor</Option>
                    </TextField>
                  </Grid>

                  <Grid item sm={12} md={2}>
                    <div>
                      <Dropzone
                        onDrop={onDrop}
                        // accept="image/png, image/gif image/jpg" //whatever the file type needed
                      >
                        {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                          return (
                            <div>
                              {memberDetails.Image ? (
                                memberDetails.Image.type ? (
                                  <img
                                    src={URL.createObjectURL(memberDetails.Image)}
                                    className={classes.imgClass}
                                    alt={memberDetails.Image.name}
                                  />
                                ) : (
                                  <img
                                    src={memberDetails.Image}
                                    className={classes.imgClass}
                                    alt={memberDetails.FirstName}
                                  />
                                )
                              ) : (
                                <div {...getRootProps()} className={classes.uploadImage}>
                                  <input {...getInputProps()} />
                                  <AddPhotoAlternateOutlined color="primary" />
                                  <p>Upload Image here *</p>
                                </div>
                              )}
                              <div style={{ textAlign: 'center', marginTop: '5px' }}>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    setMemberDetails({
                                      ...memberDetails,
                                      Image: '',
                                    })
                                  }>
                                  Reset Image
                                </Button>
                              </div>
                            </div>
                          );
                        }}
                      </Dropzone>
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.accordionMargin}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Francise Owned By</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordion}>
                <Grid container spacing={4}>
                  {company &&
                    company.map(com => (
                      <Grid item sm={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={memberDetails.companiesID}
                              // checked={memberDetails.aaa}
                              // checked={memberDetails.companiesID[com.memberID] ? true : false}
                              checked={memberDetails.companiesID[com.companyID] ? true : false}
                              value={memberDetails.companiesID}
                              color="primary"
                              onChange={e => handleMemberCheck(e, com.companyID)}
                            />
                          }
                          label={
                            com.firstName +
                            ' ' +
                            com.lastName +
                            ' ' +
                            '(' +
                            (com.memCountryID && com.memCountryID.stateName) +
                            ')'
                          }
                        />
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.accordionMargin}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {memberID == '' ? 'Add' : 'Edit'} User to Social System Groups
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordion}>
                <Grid container spacing={4}>
                  {/* {systemGroup &&
                  systemGroup.map(system => (
                    <Grid item sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={memberDetails.socialGroupID}
                            // checked={memberDetails.aaa}
                            checked={memberDetails.socialGroupID[system.socialGroupID]}
                            value={memberDetails.socialGroupID}
                            color="primary"
                            onChange={e => handleSocialCheck(e, system.socialGroupID)}
                          />
                        }
                        label={system.socialGroupName}
                      />
                    </Grid>
                  ))} */}
                  {stateValues &&
                    stateValues.map(state => (
                      <Grid item sm={12} md={6}>
                        {memberID == '' ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={memberDetails.memberSocialStateIDs}
                                // checked={memberDetails.aaa}
                                checked={memberDetails.memberSocialStateIDs[state.stateID] ? true : false}
                                value={memberDetails.memberSocialStateIDs}
                                color="primary"
                                onChange={e => handleSocialCheck(e, state.stateID)}
                              />
                            }
                            label={state.stateName}
                          />
                        ) : (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={memberDetails.memberSocialStateIDs}
                                // checked={memberDetails.aaa}
                                checked={memberDetails.memberSocialStateIDs[state.stateID] ? true : false}
                                value={memberDetails.memberSocialStateIDs}
                                color="primary"
                                // disabled={memberDetails.loginToAdmin == 1 ? false : true}
                                onChange={e => handleSocialCheck(e, state.stateID)}
                              />
                            }
                            label={state.stateName}
                          />
                        )}
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>

            {memberID !== '' && (
              <Box mt={5} textAlign={'right'}>
                <Button color="primary" variant="outlined" onClick={() => setOpenSocialGroup(true)}>
                  Edit Social Group
                </Button>
              </Box>
            )}

            {memberID !== '' && (
              <div>
                <FormControlLabel
                  style={{ marginTop: '20px' }}
                  control={
                    <Checkbox
                      name="reactivateInventoryCategory"
                      checked={memberDetails.reactivateInventoryCategory}
                      onChange={handleCheck}
                      value={memberDetails.reactivateInventoryCategory}
                      color="primary"
                    />
                  }
                  label="Reactivate Stock Take"
                />
              </div>
            )}

            <Accordion
              className={classes.accordionMargin}
              expanded={memberDetails.reactivateInventoryCategory ? true : false}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Reactivate Stock Take</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordion}>
                <Grid container spacing={4}>
                  {inventoryCatLists &&
                    inventoryCatLists.map(cats => (
                      <Grid item sm={12} md={6}>
                        {/* <FormControlLabel
                            control={
                              <Checkbox
                                name={memberDetails.memberSocialStateIDs}
                                // checked={memberDetails.aaa}
                                checked={memberDetails.memberSocialStateIDs[state.stateID] ? true : false}
                                value={memberDetails.memberSocialStateIDs}
                                color="primary"
                                onChange={e => handleSocialCheck(e, state.stateID)}
                              />
                            }
                            label={state.stateName}
                          /> */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={memberDetails.inventory_category_value}
                              // checked={memberDetails.aaa}
                              checked={memberDetails.inventory_category_value[cats.inventory_category_id] ? true : false}
                              value={memberDetails.inventory_category_value}
                              color="primary"
                              disabled={memberDetails.reactivateInventoryCategory == 1 ? false : true}
                              onChange={e => handleInventoryCheck(e, cats.inventory_category_id)}
                            />
                          }
                          label={cats.inventory_category_name}
                        />
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.accordionMargin}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Services</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordion}>
                <Grid container spacing={4}>
                  {services &&
                    services.map((ser, index) => (
                      <Grid item sm={12} md={6}>
                        <InputLabel className={classes.input}>{ser.serviceName}</InputLabel>
                        <TextField
                          variant="outlined"
                          type="number"
                          name={memberDetails.serviceID}
                          // value={memberDetails.serviceID[index] ? memberDetails.serviceID[index] : ser.servicePrice}
                          value={
                            memberDetails.serviceID[ser.serviceID]
                              ? memberDetails.serviceID[ser.serviceID]
                              : ser.servicePrice
                          }
                          className={classes.text}
                          onChange={e => handleServices(e, ser.serviceID)}
                        />
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <div className={classes.normal}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={12}>
                  <TextField
                    required
                    select
                    SelectProps={{ native: true }}
                    name="suspendLead"
                    label="Suspend Leads"
                    value={memberDetails.suspendLead}
                    onChange={handleChange}
                    className={classes.text}
                    variant="outlined">
                    <Option value="" hidden></Option>
                    <Option value="none" style={{ fontStyle: 'italic' }}>
                      Select
                    </Option>
                    <Option value="0">No</Option>
                    <Option value="1">Yes</Option>
                  </TextField>
                </Grid>

                {memberDetails.suspendLead == '1' && (
                  <Grid item sm={12} md={12}>
                    <FormControl variant="outlined" className={classes.text}>
                      <InputLabel>Suspend Lead Reason</InputLabel>
                      <Select
                        name="suspendLeadReasonID"
                        label="Suspend Lead Reason"
                        value={memberDetails.suspendLeadReasonID}
                        onChange={handleChange}
                        className={classes.text}>
                        <MenuItem value="">
                          <em>Select Reason</em>
                        </MenuItem>
                        {suspendReasonList &&
                          suspendReasonList.map(temp => (
                            <MenuItem value={temp.id} key={temp.id}>
                              {temp.reason}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {memberDetails.suspendLead == '1' && (
                  <Grid item sm={12} md={12}>
                    <FormControl variant="outlined" className={classes.text}>
                      <InputLabel>Forward Leads</InputLabel>
                      <Select
                        name="forwardLeads"
                        label="Forward Leads"
                        value={memberDetails.forwardLeads}
                        onChange={handleChange}
                        className={classes.text}>
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value="0">No</MenuItem>
                        <MenuItem value="1">Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {memberDetails.forwardLeads == '1' && memberDetails.suspendLead == '1' && (
                  <Grid item sm={12} md={12}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={allCompanyActive}
                      onChange={(event, newValue) => {
                        handleChangeForwardLeads(newValue);
                      }}
                      // value={memberDetails.leadsForwardToMemberName}
                      inputValue={memberDetails.leadsForwardToMemberName}
                      // sx={{ width: 300 }}
                      getOptionLabel={option => option.firstName + ' ' + option.lastName}
                      renderInput={params => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          value={memberDetails.leadsForwardToMemberName}
                          variant="outlined"
                          name="leadsForwardToMemberID"
                          label="Forward Leads To"
                        />
                      )}
                    />

                    {/* <FormControl variant="outlined" className={classes.text}>
                      <InputLabel>Forward Leads To</InputLabel>
                      <Select
                        name="leadsForwardToMemberID"
                        label="Forward Leads To"
                        value={memberDetails.leadsForwardToMemberID}
                        onChange={handleChange}
                        className={classes.text}>
                        <MenuItem value="">
                          <em>Select Franchisee</em>
                        </MenuItem>
                        {allCompanyActive &&
                          allCompanyActive.map(com => {
                            return (
                              <MenuItem value={com.memberID} key={com.memberID}>
                                {com.firstName + ' ' + com.lastName}{' '}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl> */}
                  </Grid>
                )}

                {memberDetails.suspendLead == '1' && (
                  <Grid item sm={12} md={12}>
                    <FormControl
                      disabled={memberID && memberDetails.leadsForwardToMemberID ? true : false}
                      variant="outlined"
                      className={classes.text}>
                      <InputLabel>Auto Response Email Template</InputLabel>
                      <Select
                        name="emailTemplateID"
                        label="Auto Response Email Template"
                        value={memberDetails.emailTemplateID}
                        onChange={handleChange}
                        className={classes.text}>
                        <MenuItem value="">
                          <em>Select Email Template</em>
                        </MenuItem>
                        {templateList &&
                          templateList.map(temp => (
                            <MenuItem value={temp.templateID} key={temp.templateID}>
                              {temp.emailSubject}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {memberDetails.forwardLeads && memberDetails.suspendLead == '1' && (
                  <Grid item sm={12} md={6}>
                    <TextField
                      // disabled={memberID && memberDetails.leadsForwardToMemberID ? true : false}
                      variant="outlined"
                      name="suspendLeadStartDate"
                      label="Suspend Lead Start Date"
                      type="date"
                      onChange={handleChange}
                      value={memberDetails.suspendLeadStartDate}
                      className={classes.text}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}
                {memberDetails.forwardLeads && memberDetails.suspendLead == '1' && (
                  <Grid item sm={12} md={6}>
                    <TextField
                      // disabled={memberID && memberDetails.leadsForwardToMemberID ? true : false}
                      variant="outlined"
                      name="suspendLeadEndDate"
                      label="Suspend Lead End Date"
                      type="date"
                      // defaultValue={memberDetails.suspendLeadStartDate}
                      value={memberDetails.suspendLeadEndDate}
                      className={classes.text}
                      onChange={handleChange}
                      inputProps={{ min: memberDetails.suspendLeadStartDate }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}

                {memberDetails.registeredForTax && (
                  <Grid item sm={12} md={12}>
                    <TextField
                      // disabled={memberID && memberDetails.leadsForwardToMemberID ? true : false}
                      variant="outlined"
                      name="RegisterDate"
                      label="Registered Date"
                      type="date"
                      // defaultValue={memberDetails.suspendLeadStartDate}
                      value={memberDetails.RegisterDate}
                      className={classes.text}
                      onChange={handleChange}
                      // inputProps={{ min: memberDetails.suspendLeadStartDate }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}

                {memberDetails.deRegisteredTax && memberDetails.registeredForTax && (
                  <Grid item sm={12} md={12}>
                    <TextField
                      // disabled={memberID && memberDetails.leadsForwardToMemberID ? true : false}
                      variant="outlined"
                      name="deRegisteredDate"
                      label="Deregistered Date"
                      type="date"
                      // defaultValue={memberDetails.suspendLeadStartDate}
                      value={memberDetails.deRegisteredDate}
                      className={classes.text}
                      onChange={handleChange}
                      // inputProps={{ min: memberDetails.suspendLeadStartDate }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}

                {/* {memberDetails.registeredForTax && (
                  <Grid item sm={12} md={6}>
                    <FormControl variant="outlined" className={classes.text}>
                      <InputLabel>Tax Impact ?</InputLabel>
                      <Select
                        name="changeTax"
                        label="Tax Impact ?"
                        value={memberDetails.changeTax}
                        onChange={handleChange}
                        className={classes.text}>
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value={'1'}>Yes</MenuItem>
                        <MenuItem value={'0'}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )} */}

                <Grid item sm={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="warningSet"
                        checked={memberDetails.warningSet}
                        onChange={handleCheck}
                        value={memberDetails.warningSet}
                        color="primary"
                      />
                    }
                    label="Warning Set"
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="registeredForTax"
                        checked={memberDetails.registeredForTax}
                        onChange={handleCheck}
                        value={memberDetails.registeredForTax}
                        color="primary"
                      />
                    }
                    label="Register for Tax"
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!memberDetails.registeredForTax}
                        name="deRegisteredTax"
                        checked={memberDetails.deRegisteredTax}
                        onChange={handleCheck}
                        value={memberDetails.deRegisteredTax}
                        color="primary"
                      />
                    }
                    label="Deregister for Tax"
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="companyActive"
                        checked={memberDetails.companyActive}
                        onChange={handleCheck}
                        value={memberDetails.companyActive}
                        color="primary"
                      />
                    }
                    label="Company is Active"
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="ticketAdmin"
                        checked={memberDetails.ticketAdmin}
                        onChange={handleCheck}
                        value={memberDetails.ticketAdmin}
                        color="primary"
                      />
                    }
                    label="Ticket Admin"
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="loginToAdmin"
                        checked={memberDetails.loginToAdmin}
                        onChange={handleCheck}
                        value={memberDetails.loginToAdmin}
                        color="primary"
                      />
                    }
                    label="Login to Admin"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="memberSocialActive"
                        checked={memberDetails.memberSocialActive}
                        onChange={handleCheck}
                        value={memberDetails.memberSocialActive}
                        color="primary"
                      />
                    }
                    label="Member Social Active"
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="memberLeadsActive"
                        checked={memberDetails.memberLeadsActive}
                        onChange={handleCheck}
                        value={memberDetails.memberLeadsActive}
                        color="primary"
                      />
                    }
                    label="Member Leads Active"
                  />
                </Grid>

                {memberID !== '' && (
                  <Grid item sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="reactivateInventoryCategory"
                          checked={memberDetails.reactivateInventoryCategory}
                          onChange={handleCheck}
                          value={memberDetails.reactivateInventoryCategory}
                          color="primary"
                        />
                      }
                      label="Reactivate Inventory Category"
                    />
                  </Grid>
                )}

                {/* {memberID !== '' && memberDetails.enableTwoFactor === true && ( */}
                {memberID !== '' && (
                  <Grid item sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="enableTwoFactor"
                          checked={!memberDetails.enableTwoFactor}
                          onChange={e => {
                            const { name, checked } = e.target;
                            if (isTwoFactorChangable)
                              setMemberDetails({
                                ...memberDetails,
                                [name]: !checked,
                              });
                          }}
                          value={!memberDetails.enableTwoFactor}
                          color="primary"
                        />
                      }
                      label="Disable Two-factor Authentication"
                    />
                  </Grid>
                )}
              </Grid>
            </div>

            <Grid container>
              <Grid item sm={12}>
                <Box pt={4} style={{ textAlign: 'right' }}>
                  <Button style={{ marginRight: '10px' }} color="secondary" variant="contained" onClick={handleBack}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit" disabled={buttonLoader}>
                    Submit
                  </Button>
                  {buttonLoader && <CircularProgress style={{ marginLeft: '10px' }} size={25} />}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Card>
      )}

      {suburbDialog && <AddSuburbDialog sendSuburbCallback={sendSuburbCallback} suburbData={suburbData} />}

      {openSocialGroup && <SocialGroupsDialog sendSocialGroup={sendSocialGroup} memberID={memberID} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddMembers;
