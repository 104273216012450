import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableComponent from '../TableComponent';

const columns = [
  { id: 'title', label: 'Document Type' },
  //   { id: 'shortDesc', label: 'Short Description' },
  //   { id: 'type', label: 'Type' },
  { id: 'active', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListDocumentType = () => {
  const [documents, setDocuments] = useState([]);
  // const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  // useEffect(() => {
  //   getAllDocumentType();
  // }, []);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    active: '',
    documentTypeName: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    active: '',
    documentTypeName: '',
    documentType: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (filterData.documentTypeName === '' && filterData.active === '' && filterData.search === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.documentTypeName === ' ') filterData.documentTypeName = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let documentStorage = JSON.parse(localStorage.getItem('document_type_filter'));
    documentStorage !== null && setFilterData(documentStorage);

    documentStorage == null
      ? getAllDocumentType()
      : documentStorage.active == '' &&
        documentStorage.search == '' &&
        documentStorage.documentTypeName == '' &&
        documentStorage.removed == false
      ? getAllDocumentType()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      active: filterData.active,
      documentTypeName: filterData.documentTypeName,
      search: filterData.search,
      submit: true,
    });

    filterData.removed = true;
    localStorage.setItem('document_type_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.active || filterData.search || filterData.documentTypeName) {
      httpClient
        .get(
          `document/type/filter?q=${filterData.search}&documentTypeName=${filterData.documentTypeName}&documentTypeActive=${filterData.active}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setDocuments(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllDocumentType();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleEdit = id => {
    history.push(`/documents/addDocumentType/${id}`);
  };

  const handleDelete = () => {};

  const getAllDocumentType = () => {
    httpClient.get(`document/type?pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setDocuments(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(data.meta.per_page);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getAllDocumentType();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`document/type/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setDocuments(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="List Document Type">
      <TableComponent
        columns={columns}
        selectedProducts={documents}
        title={'List Document Type'}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
      />
    </PageContainer>
  );
};

export default ListDocumentType;
