import React, { useState } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { Button } from '@material-ui/core';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  const [showChart, setShowChart] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const handleShow = () => {
    setShowTable(true);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader title={props.title} />
      <CmtCardContent>
        <Box className={classes.productView} />
        {!showTable ? (
          <Box pt={6} pb={2} textAlign="center">
            <Button color="primary" onClick={handleShow} variant="contained">
              View
            </Button>
          </Box>
        ) : (
          <div>
            <ProductsTable columns={props.columns} selectedProducts={props.selectedProducts && props.selectedProducts} />
          </div>
        )}
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
