import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddSuburb from './AddSuburb';
import ListSuburb from './ListSuburb';

const Suburb = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listSuburb`} />
        <Route path={`${requestedUrl}/listSuburb`} component={ListSuburb} />
        <Route path={`${requestedUrl}/addSuburb`} component={AddSuburb} />
        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Suburb;
