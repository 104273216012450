import React, { useCallback } from 'react';
import { Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

const ActiveAttributesFields = ({
  classes,
  sendVariationDetails,
  variationAttributesValue,
  activeAttributesTypesList,
  productAttributesList,
}) => {
  // const [variationAttributesValue, setVariationAttributesValue] = useState(initVariationAttributesValue);

  // const handleChange = typeID => e => {
  const handleChange = (value, typeID) => {
    const updatedList = variationAttributesValue.map(attr => {
      if (parseInt(attr.attrTypeID) === typeID) {
        return { ...attr, attrTypeValue: value.toString() }; // Update the matching item
      }
      return attr; // Return the original item if ID doesn't match
    });

    sendVariationDetails(updatedList);
  };

  const getFieldValue = useCallback(
    typeID => {
      let value = '';
      variationAttributesValue.map(attr => {
        if (parseInt(attr.attrTypeID) === typeID) value = attr.attrTypeValue;
      });

      // if (!isNaN(parseInt(value))) {
      //   value = parseInt(value);
      // }
      return value;
    },
    [variationAttributesValue],
  );

  return (
    <>
      {activeAttributesTypesList.map((attribute, index) => (
        <Grid item xs={3} md={3} key={index}>
          <div style={{ marginBottom: '10px' }}>
            <InputLabel className={classes.input}>{attribute.typeName}</InputLabel>
            {attribute.typeStyle === 'selectable' && (
              <Select
                value={getFieldValue(attribute.typeID)}
                onChange={e => handleChange(e.target.value, attribute.typeID)}
                variant="outlined"
                fullWidth>
                <MenuItem value={''}>Select attribute</MenuItem>
                {productAttributesList
                  .filter(productAttr => productAttr.typeID === attribute.typeID)
                  .map((attrSelect, index) => (
                    <MenuItem value={attrSelect.attributeID} key={index}>
                      {attrSelect.attributeName}
                    </MenuItem>
                  ))}
              </Select>
            )}

            {attribute.typeStyle === 'numberInput' && (
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                value={getFieldValue(attribute.typeID)}
                // className={classes.text}
                onChange={e => {
                  const { value } = e.target;
                  !isNaN(value) ? handleChange(value, attribute.typeID) : handleChange(0, attribute.typeID); // to handle NaN
                }}
              />
            )}

            {attribute.typeStyle !== 'selectable' && attribute.typeStyle !== 'numberInput' && (
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                value={getFieldValue(attribute.typeID)}
                // className={classes.text}
                onChange={e => handleChange(e.target.value, attribute.typeID)}
              />
            )}
          </div>
        </Grid>
      ))}
    </>
  );
};

export default ActiveAttributesFields;
