import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddFreight = props => {
  const classes = useStyles();
  const [shipping, setShipping] = useState([]);
  const [inventoryCategory, setInventoryCategory] = useState([]);
  const [freightID, setFreightID] = useState(props && props.location.pathname.slice(20));
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [freightDetails, setFreightDetails] = useState({
    inventory_category_id: '',
    shipping_zone_id: '',
    freightTitle: '',
    freightPerKG: '',
    freightCost: '',
    paidToEmail: '',
    confirmationEmail: '',
    ownedByFranchisor: false,
    freightActive: false,
  });

  useEffect(() => {
    getShippingZone();
    getSingleFreight();
    getAllInventoryCategory();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFreightDetails({
      ...freightDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setFreightDetails({
      ...freightDetails,
      [name]: checked,
    });
  };

  const getShippingZone = () => {
    httpClient.get('shipping').then(({ data }) => {
      if (data.success === true) {
        setShipping(data.data);
      } else {
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    freightID === ''
      ? httpClient
          .post(
            'inventory/freight',
            qs.stringify({
              inventory_category_id: freightDetails.inventory_category_id,
              shipping_zone_id: freightDetails.shipping_zone_id,
              freightTitle: freightDetails.freightTitle,
              freightPerKG: freightDetails.freightPerKG,
              freightCost: freightDetails.freightCost,
              paidToEmail: freightDetails.paidToEmail,
              confirmationEmail: freightDetails.confirmationEmail,
              ownedByFranchisor: freightDetails.ownedByFranchisor === true ? 1 : 0,
              freightActive: freightDetails.freightActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setFreightDetails({
                inventory_category_id: '',
                shipping_zone_id: '',
                freightTitle: '',
                freightPerKG: '',
                freightCost: '',
                paidToEmail: '',
                confirmationEmail: '',
                ownedByFranchisor: false,
                freightActive: false,
              });
            } else {
              setOpen(true);
              setMessage('Freight Failed to be added');
              setMessageState('error');
            }
          })
      : httpClient
          .put(
            `inventory/freight/${freightID}`,
            qs.stringify({
              inventory_category_id: freightDetails.inventory_category_id,
              shipping_zone_id: freightDetails.shipping_zone_id,
              freightTitle: freightDetails.freightTitle,
              freightPerKG: freightDetails.freightPerKG,
              freightCost: freightDetails.freightCost,
              paidToEmail: freightDetails.paidToEmail,
              confirmationEmail: freightDetails.confirmationEmail,
              ownedByFranchisor: freightDetails.ownedByFranchisor === true ? 1 : 0,
              freightActive: freightDetails.freightActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              history.push('/freight/listFreights');
            } else {
              setOpen(true);
              setMessage('Freight Failed to be updated');
              setMessageState('error');
            }
          });
  };

  const getSingleFreight = () => {
    freightID &&
      httpClient.get(`/inventory/freight/${freightID}`).then(({ data }) => {
        if (data.success === true) {
          setFreightDetails({
            inventory_category_id: data.data.inventory_category.inventory_category_id,
            shipping_zone_id: data.data.shipping_zone.shipping_zone_id,
            freightTitle: data.data.freightTitle,
            freightPerKG: data.data.freightPerKG,
            freightCost: data.data.freightCost,
            paidToEmail: data.data.paidToEmail,
            confirmationEmail: data.data.confirmationEmail,
            ownedByFranchisor: '1' ? true : false,
            freightActive: '1' ? true : false,
          });
        } else {
          console.log('failed');
        }
      });
  };

  const getAllInventoryCategory = () => {
    httpClient.get('inventory/category').then(({ data }) => {
      if (data.success === true) {
        setInventoryCategory(data.data);
      }
    });
  };

  const handleBack = () => {
    history.push('/freight/listFreights');
  };

  return (
    <PageContainer heading={freightID === '' ? 'Add Freight' : 'Edit Freight'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Freight Name"
                variant="outlined"
                name="freightTitle"
                type="text"
                value={freightDetails.freightTitle}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Inventory Category</InputLabel>
                <Select
                  required
                  name="inventory_category_id"
                  label="Inventory Category"
                  value={freightDetails.inventory_category_id}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value="">
                    <em>Select Inventory Category</em>
                  </MenuItem>
                  {inventoryCategory &&
                    inventoryCategory.map(invent => (
                      <MenuItem key={invent.inventory_category_id} value={invent.inventory_category_id}>
                        {invent.inventory_category_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Shipping Zone</InputLabel>
                <Select
                  required
                  name="shipping_zone_id"
                  label="Shipping Zone"
                  value={freightDetails.shipping_zone_id}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value="">
                    <em>Select Shipping Zone</em>
                  </MenuItem>
                  {shipping &&
                    shipping.map(ship => (
                      <MenuItem key={ship.shipping_zone_id} value={ship.shipping_zone_id}>
                        {ship.shipping_zone_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Price Per X Kg"
                variant="outlined"
                name="freightPerKG"
                type="number"
                value={freightDetails.freightPerKG}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Freight Cost Per X(kg)"
                variant="outlined"
                name="freightCost"
                type="number"
                value={freightDetails.freightCost}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                helperText={emailPattern.test(freightDetails.confirmationEmail) === true ? '' : 'Please enter a valid Email'}
                label="Payment Goes to (Paypal Address)"
                variant="outlined"
                name="paidToEmail"
                type="text"
                value={freightDetails.paidToEmail}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                helperText={emailPattern.test(freightDetails.confirmationEmail) === true ? '' : 'Please enter a valid Email'}
                label="Order Confirmation Email"
                variant="outlined"
                name="confirmationEmail"
                type="text"
                value={freightDetails.confirmationEmail}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="ownedByFranchisor"
                    checked={freightDetails.ownedByFranchisor}
                    onChange={handleCheck}
                    value={freightDetails.ownedByFranchisor}
                    color="primary"
                  />
                }
                label="Owner by Franchisor?"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="freightActive"
                    checked={freightDetails.freightActive}
                    onChange={handleCheck}
                    value={freightDetails.freightActive}
                    color="primary"
                  />
                }
                label="Make Rule Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                {freightID === '' ? 'Add Freight' : 'Edit Freight'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddFreight;
