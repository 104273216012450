import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import ViewLeads from '../ViewLeads';
import TableActiveComponent from '../TableActiveComponent';
import moment from 'moment';
import { history } from 'redux/store';

const columns = [
  { id: 'reason', label: 'Suspended Reason' },
  { id: 'status', label: 'Status' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LeadsReason = () => {
  const [suspendedLeads, setSuspendedLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [leadID, setLeadID] = useState('');
  const [viewDialog, setViewDialog] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    reason: '',
    status: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    reason: '',
    status: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (filterData.reason === '' && filterData.status === '' && filterData.search === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.reason === ' ') filterData.reason = '';
    if (filterData.status === ' ') filterData.status = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let leadStorage = JSON.parse(localStorage.getItem('suspend_reasons_filter'));
    leadStorage !== null && setFilterData(leadStorage);

    leadStorage == null
      ? getLeadsSuspendReason()
      : leadStorage.reason == '' && leadStorage.status == '' && leadStorage.search == '' && leadStorage.removed == false
      ? getLeadsSuspendReason()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      reason: filterData.reason,
      status: filterData.status,
      search: filterData.company,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('suspend_reasons_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.reason || filterData.status || filterData.search) {
      httpClient
        .get(
          `suspend-lead?search=${filterData.search}&reason=${filterData.reason}&status=${filterData.status}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setSuspendedLeads(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getLeadsSuspendReason();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  //   const handleDateFrom = e => {
  //     setFilterData({
  //       ...filterData,
  //       currentFrom: moment(e).format('DD-MM-YYYY'),
  //       from: moment(e).format('MM-DD-YYYY'),
  //       removed: false,
  //     });
  //   };

  //   const handleDateTo = e => {
  //     setFilterData({
  //       ...filterData,
  //       currentTo: moment(e).format('DD-MM-YYYY'),
  //       to: moment(e).format('MM-DD-YYYY'),
  //       removed: false,
  //     });
  //   };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    console.log('direction', direction);
    httpClient
      .get(`suspend-lead?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setSuspendedLeads(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `suspend-lead?search=${filterData.search}&reason=${filterData.reason}&status=${filterData.status}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setSuspendedLeads(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`suspend-lead?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setSuspendedLeads(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `suspend-lead?search=${filterData.search}&reason=${filterData.reason}&status=${
              filterData.status
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setSuspendedLeads(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          })
      : httpClient.get(`suspend-lead?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setSuspendedLeads(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setPage(data.meta.current_page);
            setLoading(false);
          }
        });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getLeadsSuspendReason = () => {
    setLoading(true);
    httpClient.get(`suspend-lead?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setSuspendedLeads(data.data);
        setTotal(data.meta.total);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
      }
    });
  };

  const handleEdit = id => {
    console.log('edit', id);
    history.push(`/leadSuspendReason/addReason/${id}`);
  };

  const handleDelete = id => {
    console.log('delete id ', id);
  };

  //   const handleView = id => {
  //     setLeadID(id);
  //     setViewDialog(true);
  //   };

  //   const sendData = callback => {
  //     console.log('callback', callback);
  //     if (callback.open === false) {
  //       setViewDialog(false);
  //     }
  //     if (callback.reflag === true) {
  //       httpClient.get(`lead/reFlagTocopy/${callback.leadID}`).then(({ data }) => {
  //         if (data.success === true) {
  //           setOpen(true);
  //           setMessage(data.message);
  //           setMessageState('success');
  //           getLeadsSuspendReason();
  //         } else {
  //           setOpen(true);
  //           setMessage('Failed Re-Flag to copy the current lead');
  //           setMessageState('error');
  //         }
  //       });
  //     }
  //     if (callback.deletion === true) {
  //       httpClient.get(`lead/flagForDeletion/${callback.leadID}`).then(({ data }) => {
  //         if (data.success === true) {
  //           setOpen(true);
  //           setMessage(data.message);
  //           setMessageState('success');
  //           getLeadsSuspendReason();
  //         } else {
  //           setOpen(true);
  //           setMessage('Failed to delete the current lead');
  //           setMessageState('error');
  //         }
  //       });
  //     }
  //   };

  return (
    <PageContainer heading="Suspend Reasons">
      <TableActiveComponent
        columns={columns}
        selectedProducts={suspendedLeads}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        title={'List Suspend Reasons'}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        fromTable={from}
        toTable={to}
        rowsPerPage={rowsPerPage}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        // handleDateFrom={handleDateFrom}
        // handleDateTo={handleDateTo}
        handleRemove={handleRemove}
        currentColumn={currentColumn}
        direction={direction}
      />

      {/* {viewDialog && <ViewLeads leadID={leadID} sendData={sendData} />} */}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default LeadsReason;
