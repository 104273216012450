export const capitalizeFLetter = string => {
  return string[0].toUpperCase() + string.slice(1);
};

export const isValidEmail = value => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const idGenerator = () => {
  return Math.floor(Math.random() * 100000);
};

export const linkify = inputText => {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z0-9\\-]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const geValidUrl = (url, ubSecureUrl = false) => {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    if (ubSecureUrl) {
      return 'http://' + url;
    }
    return 'https://' + url;
  }

  return url;
};

/**
 * Check if the given url can be found
 * in one of the given parent's children
 *
 * @param parent
 * @param url
 * @returns {boolean}
 */
export const isUrlInChildren = (parent, url) => {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i++) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (parent.children[i].link === url || url.includes(parent.children[i].link)) {
      return true;
    }
  }
  return false;
};

/**
 * Formats a percentage value according to specific rules.
 *
 * This function takes a string representation of a number and formats it as follows:
 * - If the number has no decimal places, it returns the integer.
 * - If the number has one decimal place, it keeps that one decimal place.
 * - If the number has two or more decimal places, it truncates to two decimal places without rounding.
 *
 * The function handles string inputs representing numbers and does not add a '%' symbol.
 *
 * @param {string} value - The percentage value to format, as a string.
 * @returns {string} The formatted percentage value.
 *
 * @example
 * formatPercentage("9")      // returns "9"
 * formatPercentage("23.5")   // returns "23.5"
 * formatPercentage("99.999") // returns "99.99"
 *
 * @throws {Error} Will return "Invalid number" if the input cannot be parsed as a number.
 */
export const formatPercentage = value => {
  // Parse the string to a number
  const num = parseFloat(value);

  // Check if it's a valid number
  if (isNaN(num)) {
    return 'Invalid number';
  }

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = num.toString().split('.');

  // Format based on the number of decimal places
  if (!decimalPart) {
    return integerPart;
  } else if (decimalPart.length === 1) {
    return `${integerPart}.${decimalPart}`;
  } else {
    return `${integerPart}.${decimalPart.slice(0, 2)}`;
  }
};
