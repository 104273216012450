import React, { useEffect, useState } from 'react';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { httpClient } from 'appUtility/Api';

const WeeklySales = props => {
  const classes = useStyles();

  const [filter, setFilter] = useState(false);

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  const sendTo = () => {
    history.push('/forum/addTopics');
  };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '10px' }}>
            +Add
          </Button>
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={12}>
                  <InputLabel className={classes.inputLabel}>Search </InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.search ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.search && (
              <p>
                Search:<span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleEdit={props.handleEdit}
          handleDelete={props.handleDelete}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
