import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, fade, IconButton, TableFooter, TablePagination, Tooltip, CircularProgress } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { ExpandLess, ExpandMore, UnfoldMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  scrollbarRoot: {
    // height: 251,
    marginLeft: -24,
    marginRight: -24,
    [theme.breakpoints.up('xl')]: {
      // height: 269,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      // textAlign: 'right',
      // paddingRight: 24,
    },
  },
  tableWidth: {
    minWidth: '200px',
  },
  tableRowCellRoot: {
    fontSize: 12,
    '&:last-child': {
      paddingRight: 64,
      '& .MuiSvgIcon-root': {
        display: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: '13px',
      position: 'relative',
      top: '2px',
      cursor: 'pointer',
    },
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  loader: {
    position: 'relative',
    height: '150px',
  },
  loadingSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  flexBtn: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const actions = [
  {
    label: 'View Profile',
  },
  {
    label: 'More',
  },
];

function TablePaginationActions(props) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  return (
    <div className={classes.flexBtn}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage)} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ProductsTable = ({
  columns,
  selectedProducts,
  handleEdit,
  handleDelete,
  loading,
  handleSort,
  rowsPerPage,
  page,
  total,
  handleChangePage,
  handleChangeRowsPerPage,
  toTable,
  fromTable,
  direction,
  currentColumn,
}) => {
  const classes = useStyles();
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  return (
    <PerfectScrollbar className={classes.scrollbarRoot}>
      <Box className="Cmt-table-responsive">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  onClick={() => handleSort(column)}
                  key={column.id}
                  className={clsx(classes.tableCellRoot, classes.tableRowCellRoot)}>
                  {' '}
                  {currentColumn === column ? <span style={{ fontWeight: '900' }}>{column.label}</span> : column.label}
                  {currentColumn === column ? direction ? <ExpandLess /> : <ExpandMore /> : <UnfoldMore />}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody className={classes.loader}>
              <Box textAlign="center" className={classes.loadingSection}>
                <CircularProgress />
              </Box>
            </TableBody>
          ) : (
            <TableBody>
              {selectedProducts &&
                // selectedProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                selectedProducts.map((row, index) => {
                  // const isInflationPositive = row.sales_data.sales_inflation > 0;
                  return (
                    <TableRow className={classes.tableRowRoot} key={Math.random()}>
                      <TableCell className={classes.tableCellRoot}>{row.forumGroupName}</TableCell>
                      <TableCell className={classes.tableCellRoot}>
                        {row.forumCategory && row.forumCategory.forumCategoryName}
                      </TableCell>
                      <TableCell className={classes.tableCellRoot}>{row.no_of_forum_topic}</TableCell>
                      <TableCell className={classes.tableCellRoot}>{row.no_of_forum_post}</TableCell>
                      <TableCell className={classes.tableCellRoot}>
                        {row.forumGroupActive === 'Active' ? (
                          <CheckIcon fontSize="small" color="primary" />
                        ) : (
                          <ClearIcon fontSize="small" color="secondary" />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableWidth}>
                        <Button color="primary" size="small" variant="outlined" onClick={() => handleEdit(row.forumGroupID)}>
                          Edit
                        </Button>
                        {/* <Button
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={() => handleDelete(row.freightID)}
                        style={{ marginLeft: '10px' }}>
                        Delete
                      </Button> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {selectedProducts.length === 0 && (
                <TableRow
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    position: 'absolute',
                  }}>
                  No Forum Group Found
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[20, 50, 70, 100]}
                rowsPerPage={rowsPerPage}
                page={page}
                count={total && total}
                SelectProps={{
                  native: true,
                }}
                labelDisplayedRows={() => `${fromTable} - ${toTable} to ${total}`}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

export default ProductsTable;
