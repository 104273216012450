import React, { useEffect, useState } from 'react';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { httpClient } from 'appUtility/Api';
import { CompassCalibration } from '@material-ui/icons';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';

const WeeklySales = props => {
  const classes = useStyles();

  const [filter, setFilter] = useState(false);
  const [company, setCompany] = useState([]);
  var [memberName, setMemberName] = useState('');
  var [page, setPage] = useState(1);

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  useEffect(() => {
    getCompany();
  }, []);

  // const loadMoreItems = () => {
  //   console.log('more');
  //   setPage(page + 1);
  //   getCompany();
  // }

  const getCompany = () => {
    httpClient.get(`member/all`).then(({ data }) => {
      if (data) {
        let companyData = [];
        let newData = [];
        companyData = data.data;
        companyData.map(item => {
          if (item.memberActive === 1) {
            return newData.push(item);
          }
        });
        setCompany(newData);
      } else {
      }
    });
  };

  company &&
    company.map(com => {
      if (com.memberID === props.submittedData.memberID) {
        memberName = com.firstName + ' ' + com.lastName;
      }
    });

  // const sendTo = () => {
  //   history.push('/forum/addTopics');
  // };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  console.log('props.submittedData', props.filterData);

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '10px' }}>
            +Add
          </Button> */}
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                  <DatePicker
                    // disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    label="From Date"
                    name="from"
                    helperText="Booking Date From"
                    // format="MM-DD-YYYY"
                    format="DD-MM-YYYY"
                    value={props.filterData.from ? props.filterData.from : null}
                    onChange={props.handleDateFrom}
                    className={classes.dateField}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <DatePicker
                    // disableToolbar
                    inputVariant="outlined"
                    name="to"
                    variant="inline"
                    label="To Date"
                    helperText="Booking Date To"
                    // format="MM-DD-YYYY"
                    format="DD-MM-YYYY"
                    value={props.filterData.to ? props.filterData.to : null}
                    onChange={props.handleDateTo}
                    className={classes.dateField}
                    minDate={props.filterData.from ? props.filterData.from : new Date()}
                  />
                </Grid>

                <Grid item sm={12} md={12}>
                  <InputLabel className={classes.inputLabel}>Member</InputLabel>
                  <Autocomplete
                    disablePortal
                    id="member_id"
                    options={company}
                    onChange={(event, newValue) => {
                      props.handleChangeMember(newValue);
                    }}
                    // value={memberDetails.leadsForwardToMemberName}
                    inputValue={props.filterData.memberName}
                    // sx={{ width: 300 }}
                    getOptionLabel={option => option.firstName + ' ' + option.lastName}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={props.handleChange}
                        value={props.filterData.memberName}
                        variant="outlined"
                        name="memberID"
                        // label="Member"
                      />
                    )}
                  />
                  {/* <FormControl variant="outlined" className={classes.text}>
                    <InputLabel>Member</InputLabel>
                    <Select
                      required
                      name="memberID"
                      label="Member"
                      value={props.filterData.memberID}
                      onChange={props.handleChange}
                      className={classes.text}
                      // MenuProps={{
                      //   onScroll: loadMoreItems
                      // }}
                    >
                      <MenuItem value="">
                        <em>Select Member</em>
                      </MenuItem>
                      {company &&
                        company.map(com => <MenuItem value={com.memberID}>{com.firstName + ' ' + com.lastName}</MenuItem>)}
                    </Select>
                  </FormControl> */}
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.from || props.submittedData.to || props.submittedData.memberID ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.from && (
              <p>
                From:<span> {moment(props.submittedData.from).format('MMMM DD , YYYY')} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('from')} />
              </p>
            )}
            {props.submittedData.to && (
              <p>
                To:<span> {moment(props.submittedData.to).format('MMMM DD , YYYY')} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('to')} />
              </p>
            )}
            {props.submittedData.memberID && (
              <p>
                Member:<span> {memberName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('memberID')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleEdit={props.handleEdit}
          handleDelete={props.handleDelete}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
