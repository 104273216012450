import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const ConfirmationAlert = props => {
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    success: false,
  });

  useEffect(() => {
    props.sendCallback(dialogDetails);
  }, [dialogDetails]);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleSubmit = () => {
    setDialogDetails({
      ...dialogDetails,
      success: true,
      open: false,
    });
  };

  return (
    <Dialog open={dialogDetails.open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <h2>{props.action === 'delete' ? 'Remove User' : 'Deactivate User'}</h2>
      </DialogTitle>
      <DialogContent>
        {props.action === 'delete'
          ? 'Are you sure you want to remove the user from this group?'
          : 'Are you sure you want to deactivate the user from this group?'}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          No
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="outlined">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationAlert;
