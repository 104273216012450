import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import { Autocomplete } from '@material-ui/lab';
import { Add, Clear } from '@material-ui/icons';
import { useEffect } from 'react';

const SocialGroupsDialog = props => {
  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  var [checkGroups, setCheckGroups] = useState([]);

  useEffect(() => {
    props.sendSocialGroup(alertData);
  }, [alertData]);

  useEffect(() => {
    listSocialGroups();
  }, []);

  const listSocialGroups = () => {
    setLoading(true);
    httpClient.get(`social-group/member/${props.memberID}`).then(({ data }) => {
      if (data.success) {
        setGroupList(data.data);
        setLoading(false);
        // var listValues = data.data.map(list => {
        //   return {
        //     groupName: list.socialGroupName,
        //     groupID: list.socialGroupID,
        //     check: false,
        //   };
        // });
        // setCheckGroups(listValues);
      } else {
        setLoading(false);
      }
    });
  };

  const handleCheck = (e, group) => {
    const { checked } = e.target;
    var newGroup = {
      socialGroupID: group.socialGroupID,
      socialGroupName: group.socialGroupName,
      regionalGroupID: group.regionalGroupID,
      isSubscribed: checked ? 1 : 0,
    };

    var res = groupList.map(check => {
      if (check.socialGroupID === group.socialGroupID) {
        return (check = newGroup);
      } else {
        return check;
      }
    });

    setGroupList(res);
  };

  //   console.log('props', props);

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleSubmit = () => {
    const login = localStorage.getItem('login');
    const loginData = JSON.parse(login);

    var formData = new FormData();
    formData.append('appID', loginData && loginData.user.appID);
    formData.append('companyID', loginData && loginData.user.companyID);
    formData.append('userID', props.memberID);
    groupList.map((grp, index) => {
      if (grp.isSubscribed === 1) {
        formData.append(`subscriptions[${index}][socialGroupID]`, grp.socialGroupID);
      }
    });

    httpClient.post(`social-group/subscription/save/${props.memberID}`, formData).then(({ data }) => {
      if (data.success) {
        setAlertData({
          ...alertData,
          open: false,
          success: true,
          message: data.message,
        });
      } else {
        setAlertData({
          ...alertData,
          open: true,
          success: false,
          message: data.message,
        });
      }
    });
  };

  // console.log('group list', groupList);
  //   console.log('cehck data', checkGroups);

  return (
    <Dialog open={alertData.open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Social Groups</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid item xs={12}>
            <Card style={{ padding: '30px' }}>
              {loading ? (
                <Box textAlign={'center'} p={3}>
                  <CircularProgress />
                </Box>
              ) : (
                <Grid container spacing={4}>
                  {groupList &&
                    groupList.map(group => (
                      <Grid item xs={12} md={6} key={group.socialGroupID}>
                        <FormControlLabel
                          label={group.socialGroupName}
                          control={
                            <Checkbox
                              checked={
                                groupList &&
                                groupList.some(ch => (ch.socialGroupID === group.socialGroupID ? ch.isSubscribed : false))
                              }
                              onChange={e => handleCheck(e, group)}
                            />
                          }
                        />
                      </Grid>
                    ))}
                </Grid>
              )}
            </Card>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="secondary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SocialGroupsDialog;
