import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Grid,
  TextField,
  FormControl,
  Card,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Autocomplete } from '@material-ui/lab';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddExpenseCategory = props => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [expenseID, setExpenseID] = useState(props && props.location.pathname.slice(30));
  const [superData, setSuperData] = useState([]);
  const [members, setMembers] = useState([]);

  const [expenseDetails, setExpenseDetails] = useState({
    addedBy: '',
    addedByName: '',
    expenseSuperCategoryID: '',
    expenseCategoryName: '',
    expenseCategoryDescription: '',
    expenseCategoryActive: false,
    migrationSuccessful: false,
    v2_migration_required: false,
    incGst: '',
  });

  useEffect(() => {
    getSingleExpenseCategpry();
    getMembers();
    getSuperCategory();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    expenseID === ''
      ? httpClient
          .post(
            '/expenses/category',
            qs.stringify({
              addedBy: expenseDetails.addedBy,
              expenseSuperCategoryID: expenseDetails.expenseSuperCategoryID,
              expenseCategoryName: expenseDetails.expenseCategoryName,
              expenseCategoryDescription: expenseDetails.expenseCategoryDescription,
              expenseCategoryActive: expenseDetails.expenseCategoryActive === true ? 1 : 0,
              migrationSuccessful: expenseDetails.migrationSuccessful === true ? 1 : 0,
              v2_migration_required: expenseDetails.v2_migration_required === true ? 1 : 0,
              gstInclusive: expenseDetails.incGst,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setExpenseDetails({
                addedBy: '',
                expenseSuperCategoryID: '',
                expenseCategoryName: '',
                expenseCategoryDescription: '',
                expenseCategoryActive: false,
                migrationSuccessful: false,
                v2_migration_required: false,
                incGst: '',
              });
              setTimeout(() => {
                history.push('/financial/listExpenseCategory');
              }, 1500);
            } else {
              setOpen(true);
              setMessage('failed to add expense super category!');
              setMessageState('error');
            }
          })
      : httpClient
          .put(
            `/expenses/category/${expenseID}`,
            qs.stringify({
              addedBy: expenseDetails.addedBy,
              expenseSuperCategoryID: expenseDetails.expenseSuperCategoryID,
              expenseCategoryName: expenseDetails.expenseCategoryName,
              expenseCategoryDescription: expenseDetails.expenseCategoryDescription,
              expenseCategoryActive: expenseDetails.expenseCategoryActive === true ? 1 : 0,
              migrationSuccessful: expenseDetails.migrationSuccessful === true ? 1 : 0,
              v2_migration_required: expenseDetails.v2_migration_required === true ? 1 : 0,
              gstInclusive: expenseDetails.incGst,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                history.push('/financial/listExpenseCategory');
              }, 1500);
            } else {
              setOpen(true);
              setMessage('failed to edit expense category!');
              setMessageState('error');
            }
          });
  };

  const getSingleExpenseCategpry = () => {
    expenseID &&
      httpClient.get(`/expenses/category/${expenseID}`).then(({ data }) => {
        if (data.success === true) {
          setExpenseDetails({
            addedBy: data.data.companyAddedBy.CompanyID == null ? 0 : data.data.companyAddedBy.CompanyID,
            addedByName: data.data.companyAddedBy.CompanyName == null ? 'Super Admin' : data.data.companyAddedBy.CompanyName,
            expenseSuperCategoryID: data.data.expenseSuperCategory.expenseSuperCategoryID,
            expenseCategoryName: data.data.expenseCategoryName,
            expenseCategoryDescription: data.data.expenseCategoryDescription,
            expenseCategoryActive: data.data.expenseCategoryActive === 1 ? true : false,
            migrationSuccessful: data.data.migrationSuccessful === 1 ? true : false,
            v2_migration_required: data.data.v2_migration_required === 1 ? true : false,
            incGst: data.data.gstInclusive,
          });
        }
      });
  };

  const getSuperCategory = () => {
    httpClient.get(`/expenses/category/super`).then(({ data }) => {
      if (data.success === true) {
        setSuperData(data.data);
      }
    });
  };

  const getMembers = () => {
    httpClient.get('member-company/all').then(({ data }) => {
      if (data.success === true) {
        let newData = [];
        newData = data.data;
        newData.unshift({
          companyID: 0,
          tradingName: 'Super Admin',
        });

        setMembers(newData);
      }
    });
  };

  const handleChangeAdded = value => {
    setExpenseDetails({
      ...expenseDetails,
      addedBy: value !== null ? value.companyID : '',
      addedByName: value !== null ? value.tradingName : '',
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'addedBy') {
      expenseDetails.addedByName = value;
    }
    setExpenseDetails({
      ...expenseDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setExpenseDetails({
      ...expenseDetails,
      [name]: checked,
    });
  };

  const handleBack = () => {
    history.push('/financial/listExpenseCategory');
  };

  return (
    <PageContainer heading={expenseID === '' ? 'Add Expense Category' : 'Edit Expense Category'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Category Name"
                variant="outlined"
                name="expenseCategoryName"
                type="text"
                value={expenseDetails.expenseCategoryName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={4}
                label="Category Description"
                variant="outlined"
                name="expenseCategoryDescription"
                type="text"
                value={expenseDetails.expenseCategoryDescription}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Expenses Super Category</InputLabel>
                <Select
                  required
                  name="expenseSuperCategoryID"
                  label="Expenses Super Category"
                  value={expenseDetails.expenseSuperCategoryID}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value="">
                    <em>Select Super Category</em>
                  </MenuItem>
                  {superData &&
                    superData.map(sup => (
                      <MenuItem key={sup.expenseSuperCategoryID} value={sup.expenseSuperCategoryID}>
                        {sup.expenseSuperCategoryName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={12}>
              {/* <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Added By</InputLabel>
                <Select
                  required
                  name="addedBy"
                  label="Added By"
                  value={expenseDetails.addedBy}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Super Admin</em>
                  </MenuItem>

                  {members.length > 0 &&
                    members.map(mem => (
                      <MenuItem key={mem.companyID} value={mem.companyID}>
                        {mem.member && mem.tradingName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}

              <Autocomplete
                disablePortal
                id="add_expense_auto"
                options={members}
                onChange={(event, newValue) => {
                  handleChangeAdded(newValue);
                }}
                inputValue={expenseDetails.addedByName}
                // sx={{ width: 300 }}
                getOptionLabel={option => (option.tradingName !== null ? option.tradingName : '')}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={handleChange}
                    value={expenseDetails.addedByName}
                    variant="outlined"
                    name="addedBy"
                    label="Added By"
                  />
                )}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Gst</InputLabel>
                <Select
                  required
                  name="incGst"
                  label="GST"
                  value={expenseDetails.incGst}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value={0}>
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={1}>Include Gst</MenuItem>
                  <MenuItem value={0}>Exclude Gst</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="expenseCategoryActive"
                    checked={expenseDetails.expenseCategoryActive}
                    onChange={handleCheck}
                    value={expenseDetails.expenseCategoryActive}
                    color="primary"
                  />
                }
                label="Make Category Active ?"
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="migrationSuccessful"
                    checked={expenseDetails.migrationSuccessful}
                    onChange={handleCheck}
                    value={expenseDetails.migrationSuccessful}
                    color="primary"
                  />
                }
                label="Is Migration Successful ?"
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="v2_migration_required"
                    checked={expenseDetails.v2_migration_required}
                    onChange={handleCheck}
                    value={expenseDetails.v2_migration_required}
                    color="primary"
                  />
                }
                label="Version 2 migration required ?"
              />
            </Grid>
            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" type="submit">
                {expenseID === '' ? 'Add Expense Category' : 'Edit Expense Category'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddExpenseCategory;
