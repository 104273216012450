import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddNews from './AddNews';
import AddNewsCategory from './AddNewsCategory';
import ListNews from './ListNews';
import ListNewsCategory from './ListNewsCategory';

const News = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listNewsCategory`} />
        <Route path={`${requestedUrl}/listNewsCategory`} component={ListNewsCategory} />
        <Route path={`${requestedUrl}/addNewsCategory`} component={AddNewsCategory} />
        <Route path={`${requestedUrl}/listNews`} component={ListNews} />
        <Route path={`${requestedUrl}/addNews`} component={AddNews} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default News;
