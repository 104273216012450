import React, { useEffect, useState } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import SearchIcon from '@material-ui/icons/Search';
import { httpClient } from 'appUtility/Api';
import { Close, FilterList } from '@material-ui/icons';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);

  const [documentTypeList, setDocumentTypeList] = useState([]);
  var docName;

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  useEffect(() => {
    getDocumentType();
  }, []);

  const getDocumentType = () => {
    httpClient.get('document/type').then(({ data }) => {
      if (data.success) {
        setDocumentTypeList(data.data);
      }
    });
  };

  documentTypeList.map(item => {
    if (props.filterData.documentType == item.documentTypeID) {
      docName = item.documentTypeName;
    }
  });

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  const linkTo = () => {
    history.push('/documents/addDocument');
  };

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
           
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          <Button color="primary" variant="contained" onClick={linkTo} style={{ marginRight: '10px' }}>
            +Add
          </Button>
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Search </InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Document Title</InputLabel>
                  <TextField
                    variant="outlined"
                    name="documentTypeName"
                    value={props.filterData.documentTypeName}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Document Type</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="documentType"
                      label="Document Type"
                      value={props.filterData.documentType}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">
                        <em>Select Document Type</em>
                      </MenuItem>
                      {documentTypeList &&
                        documentTypeList.map(doc => (
                          <MenuItem key={doc.documentTypeID} value={doc.documentTypeID}>
                            {doc.documentTypeName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Document Active</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="active"
                      value={props.filterData.active}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.documentTypeName ||
        props.submittedData.documentType ||
        props.submittedData.active ||
        props.submittedData.search ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>

            {props.submittedData.active && (
              <p>
                Active:<span> {props.submittedData.active == '1' ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('active')} />
              </p>
            )}
            {props.submittedData.documentType && (
              <p>
                Document Type:<span> {docName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('documentType')} />
              </p>
            )}
            {props.submittedData.documentTypeName && (
              <p>
                Document Name:<span> {props.submittedData.documentTypeName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('documentTypeName')} />
              </p>
            )}
            {props.submittedData.search && (
              <p>
                Search:<span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleEdit={props.handleEdit}
          handleDelete={props.handleDelete}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          rowsPerPage={props.rowsPerPage}
          fromTable={props.fromTable}
          toTable={props.toTable}
          currentColumn={props.currentColumn}
          direction={props.direction}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
