import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Card,
  Grid,
  makeStyles,
  TextField,
  InputLabel,
  Button,
  FormControl,
  Select,
  Box,
  MenuItem,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
    // width: '50%',
    // margin: '0 auto',
  },
  textField: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '25px',
  },
  textField2: {
    width: '90%',
    marginTop: '5px',
    marginBottom: '25px',
  },
  textSelect: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  resetBtn: {
    marginTop: '10px',
    float: 'right',
  },
  inputText: {
    fontSize: '12px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BuySellProcess = props => {
  const classes = useStyles();
  const [memberID, setMemberID] = useState(props && props.location.pathname.slice(24));
  const [step, setStep] = useState(1);
  const [sellMemberID, setSellMemberID] = useState('');
  const [buyMemberID, setBuyMemberID] = useState('');
  const [members, setMembers] = useState([]);
  const [buySellDetails, setBuysellDetails] = useState({
    sellerCompanyID: '',
    buyerCompanyID: '',
    firstName: '',
    lastName: '',
    tradingName: '',
    telephone: '',
    mobile: '',
    suburb: '',
    state: '',
    postCode: '',
    abn: '',
  });

  const [updatedDetails, setUpdatedDetails] = useState({
    firstName: '',
    lastName: '',
    tradingName: '',
    telephone: '',
    mobile: '',
    suburb: '',
    state: '',
    postCode: '',
    abn: '',
  });

  const [sellerDetails, setSellerDetails] = useState({
    fullName: '',
    phone: '',
    email: '',
    address: '',
    location: '',
    customers: '',
    pets: '',
    activeBookings: '',
    futureBookings: '',
    cancelledBookings: '',
    completedBookings: '',
    archivedBookings: '',
    incomes: '',
    expenses: '',
    credits: '',
  });

  const [buyerDetails, setBuyerDetails] = useState({
    fullName: '',
    phone: '',
    email: '',
    address: '',
    location: '',
    customers: '',
    pets: '',
    activeBookings: '',
    futureBookings: '',
    cancelledBookings: '',
    completedBookings: '',
    archivedBookings: '',
    incomes: '',
    expenses: '',
    credits: '',
  });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getAllCompany();
    getSingleMember();
  }, []);

  useEffect(() => {
    members.map(mem => {
      if (mem.companyID == buySellDetails.sellerCompanyID) {
        setSellMemberID(mem.member && mem.member.length > 0 && mem.member[0].memberID);
      }
    });
  }, [buySellDetails.sellerCompanyID]);
  useEffect(() => {
    members.map(mem => {
      if (mem.companyID == buySellDetails.buyerCompanyID) {
        setBuyMemberID(mem.member && mem.member.length > 0 && mem.member[0].memberID);
      }
    });
  }, [buySellDetails.buyerCompanyID]);

  useEffect(() => {
    getUpdatedMember();
  }, [sellMemberID]);

  useEffect(() => {
    getBuyMember();
  }, [buyMemberID]);

  const handleChange = e => {
    const { name, value } = e.target;
    setBuysellDetails({
      ...buySellDetails,
      [name]: value,
    });
  };

  const handleChangeUpdated = e => {
    const { name, value } = e.target;
    setUpdatedDetails({
      ...updatedDetails,
      [name]: value,
    });
  };

  const getSingleMember = () => {
    httpClient.get(`/member/${memberID}`).then(({ data }) => {
      if (data.success === true) {
        setBuysellDetails({
          ...buySellDetails,
          sellerCompanyID: data.data.companyID.companyID,
          firstName: data.data.firstName,
          lastName: data.data.lastName,
          tradingName: data.data.companyID.tradingName,
          telephone: data.data.companyID.companyTelephone,
          mobile: data.data.companyID.companyMobile,
          suburb: data.data.companyID.companySuburb,
          state: data.data.memStateID.stateName,
          postCode: data.data.companyID.companyPostCode,
          abn: data.data.companyID.companyABN,
        });
      }
    });
  };

  const getUpdatedMember = () => {
    sellMemberID &&
      httpClient.get(`/member/${sellMemberID}`).then(({ data }) => {
        if (data.success === true) {
          setBuysellDetails({
            ...buySellDetails,
            sellerCompanyID: data.data.companyID.companyID,
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            tradingName: data.data.companyID.tradingName,
            telephone: data.data.companyID.companyTelephone,
            mobile: data.data.companyID.companyMobile,
            suburb: data.data.companyID.companySuburb,
            state: data.data.memStateID.stateName,
            postCode: data.data.companyID.companyPostCode,
            abn: data.data.companyID.companyABN,
          });
        }
      });
  };

  const getBuyMember = () => {
    buyMemberID &&
      httpClient.get(`/member/${buyMemberID}`).then(({ data }) => {
        if (data.success === true) {
          setUpdatedDetails({
            ...updatedDetails,
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            tradingName: data.data.companyID.tradingName,
            telephone: data.data.companyID.companyTelephone,
            mobile: data.data.companyID.companyMobile,
            suburb: data.data.companyID.companySuburb,
            state: data.data.memStateID.stateName,
            postCode: data.data.companyID.companyPostCode,
            abn: data.data.companyID.companyABN,
          });
        }
      });
  };

  const getAllCompany = () => {
    memberID &&
      httpClient.get(`/member-company/all`).then(({ data }) => {
        if (data.success === true) {
          setMembers(data.data);
        }
      });
  };

  const handleNextStep = () => {
    httpClient
      .post(
        `/member/buyer-seller`,
        qs.stringify({
          sellerCompanyID: buySellDetails.sellerCompanyID,
          buyerCompanyID: buySellDetails.buyerCompanyID,
        }),
      )
      .then(({ data }) => {
        if (data.success === true) {
          setStep(2);
          setSellerDetails({
            ...sellerDetails,
            fullName: data.data.seller.firstName + ' ' + data.data.seller.lastName,
            phone: data.data.seller.memPhone,
            email: data.data.seller.username,
            address: data.data.seller.memAddress1,
            location: data.data.memSuburb,
            customers: data.data.seller.customer,
            pets: data.data.seller.pets,
            activeBookings: data.data.seller.activeBookings,
            futureBookings: data.data.seller.futureBookings,
            cancelledBookings: data.data.seller.cancelledBooking,
            completedBookings: data.data.seller.completed,
            archivedBookings: data.data.seller.archived,
            incomes: data.data.seller.income,
            expenses: data.data.seller.expense,
            credits: data.data.seller.credit,
          });
          setBuyerDetails({
            ...buyerDetails,
            fullName: data.data.buyer.firstName + ' ' + data.data.buyer.lastName,
            phone: data.data.buyer.memPhone,
            email: data.data.buyer.username,
            address: data.data.buyer.memAddress1,
            location: data.data.memSuburb,
            customers: data.data.buyer.customer,
            pets: data.data.buyer.pets,
            activeBookings: data.data.buyer.activeBookings,
            futureBookings: data.data.buyer.futureBookings,
            cancelledBookings: data.data.buyer.cancelledBooking,
            completedBookings: data.data.buyer.completed,
            archivedBookings: data.data.buyer.archived,
            incomes: data.data.buyer.income,
            expenses: data.data.buyer.expense,
            credits: data.data.buyer.credit,
          });
        } else {
        }
      });
  };

  const handleBuy = () => {
    httpClient
      .post(
        `/member/buy-sell`,
        qs.stringify({
          sellerCompanyID: buySellDetails.sellerCompanyID,
          buyerCompanyID: buySellDetails.buyerCompanyID,
        }),
      )
      .then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          setTimeout(() => {
            history.push('/members/listMembers');
          }, 1000);
        } else {
          setOpen(true);
          setMessage('Failed to Migrate to the seller.');
          setMessageState('error');
        }
      });
  };

  return (
    <PageContainer heading="Buy/Sell Process">
      <Card className={classes.root}>
        <form className={classes.formControl}>
          {step == 1 && (
            <Grid container spacing={4}>
              <Grid item sm={12} md={12}>
                <InputLabel>Select Seller</InputLabel>
                <FormControl variant="outlined" className={classes.textSelect}>
                  <Select
                    name="sellerCompanyID"
                    value={buySellDetails.sellerCompanyID}
                    onChange={handleChange}
                    className={classes.textField}>
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    {members &&
                      members.map(mem => {
                        if (mem.companyActive == 1) {
                          return (
                            <MenuItem key={mem.companyID} value={mem.companyID}>
                              {mem.member && mem.member[0]?.firstName} {mem.member && mem.member[0]?.lastName}
                            </MenuItem>
                          );
                        }
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={12}>
                <h2>Verify Seller Company Detail : {buySellDetails.firstName + ' ' + buySellDetails.lastName}</h2>
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel> First Name</InputLabel>
                <TextField
                  variant="outlined"
                  name="firstName"
                  value={buySellDetails.firstName}
                  className={classes.textField}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel> Last Name</InputLabel>
                <TextField
                  variant="outlined"
                  name="lastName"
                  value={buySellDetails.lastName}
                  className={classes.textField}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <InputLabel> Trading Name</InputLabel>
                <TextField
                  variant="outlined"
                  name="tradingName"
                  value={buySellDetails.tradingName}
                  className={classes.textField}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel> Telephone</InputLabel>
                <TextField
                  variant="outlined"
                  name="telephone"
                  value={buySellDetails.telephone}
                  className={classes.textField}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel> Mobile</InputLabel>
                <TextField
                  variant="outlined"
                  name="mobile"
                  value={buySellDetails.mobile}
                  className={classes.textField}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel> Suburb</InputLabel>
                <TextField
                  variant="outlined"
                  name="suburb"
                  value={buySellDetails.suburb}
                  className={classes.textField}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel> State</InputLabel>
                <TextField
                  variant="outlined"
                  name="state"
                  value={buySellDetails.state}
                  className={classes.textField}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel> Post Code</InputLabel>
                <TextField
                  variant="outlined"
                  name="postCode"
                  value={buySellDetails.postCode}
                  className={classes.textField}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <InputLabel> ABN</InputLabel>
                <TextField
                  variant="outlined"
                  name="abn"
                  value={buySellDetails.abn}
                  className={classes.textField}
                  onChange={handleChange}
                  disabled
                />
              </Grid>

              <Grid item sm={12} md={12}>
                <InputLabel>Select Buyer</InputLabel>
                <FormControl variant="outlined" className={classes.textSelect}>
                  <Select
                    name="buyerCompanyID"
                    value={buySellDetails.buyerCompanyID}
                    onChange={handleChange}
                    className={classes.textField}>
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    {members &&
                      members.map(mem => {
                        if (mem.companyActive == 1) {
                          return (
                            <MenuItem key={mem.companyID} value={mem.companyID}>
                              {mem.member && mem.member[0]?.firstName} {mem.member && mem.member[0]?.lastName}
                            </MenuItem>
                          );
                        }
                      })}
                  </Select>
                </FormControl>
              </Grid>
              {updatedDetails.firstName && (
                <Grid item sm={12} md={12}>
                  <h2>Verify Buyer Company Detail : {updatedDetails.firstName + ' ' + updatedDetails.lastName}</h2>
                </Grid>
              )}
              {updatedDetails.firstName && (
                <Grid item sm={12} md={6}>
                  <InputLabel> First Name</InputLabel>
                  <TextField
                    variant="outlined"
                    name="firstName"
                    value={updatedDetails.firstName}
                    className={classes.textField}
                    onChange={handleChangeUpdated}
                    disabled
                  />
                </Grid>
              )}
              {updatedDetails.firstName && (
                <Grid item sm={12} md={6}>
                  <InputLabel> Last Name</InputLabel>
                  <TextField
                    variant="outlined"
                    name="lastName"
                    value={updatedDetails.lastName}
                    className={classes.textField}
                    onChange={handleChangeUpdated}
                    disabled
                  />
                </Grid>
              )}
              {updatedDetails.firstName && (
                <Grid item sm={12} md={12}>
                  <InputLabel> Trading Name</InputLabel>
                  <TextField
                    variant="outlined"
                    name="tradingName"
                    value={updatedDetails.tradingName}
                    className={classes.textField}
                    onChange={handleChangeUpdated}
                    disabled
                  />
                </Grid>
              )}
              {updatedDetails.firstName && (
                <Grid item sm={12} md={6}>
                  <InputLabel> Telephone</InputLabel>
                  <TextField
                    variant="outlined"
                    name="telephone"
                    value={updatedDetails.telephone}
                    className={classes.textField}
                    onChange={handleChangeUpdated}
                    disabled
                  />
                </Grid>
              )}
              {updatedDetails.firstName && (
                <Grid item sm={12} md={6}>
                  <InputLabel> Mobile</InputLabel>
                  <TextField
                    variant="outlined"
                    name="mobile"
                    value={updatedDetails.mobile}
                    className={classes.textField}
                    onChange={handleChangeUpdated}
                    disabled
                  />
                </Grid>
              )}
              {updatedDetails.firstName && (
                <Grid item sm={12} md={6}>
                  <InputLabel> Suburb</InputLabel>
                  <TextField
                    variant="outlined"
                    name="suburb"
                    value={updatedDetails.suburb}
                    className={classes.textField}
                    onChange={handleChangeUpdated}
                    disabled
                  />
                </Grid>
              )}
              {updatedDetails.firstName && (
                <Grid item sm={12} md={6}>
                  <InputLabel> State</InputLabel>
                  <TextField
                    variant="outlined"
                    name="state"
                    value={updatedDetails.state}
                    className={classes.textField}
                    onChange={handleChangeUpdated}
                    disabled
                  />
                </Grid>
              )}
              {updatedDetails.firstName && (
                <Grid item sm={12} md={6}>
                  <InputLabel> Post Code</InputLabel>
                  <TextField
                    variant="outlined"
                    name="postCode"
                    value={updatedDetails.postCode}
                    className={classes.textField}
                    onChange={handleChangeUpdated}
                    disabled
                  />
                </Grid>
              )}
              {updatedDetails.firstName && (
                <Grid item sm={12} md={6}>
                  <InputLabel> ABN</InputLabel>
                  <TextField
                    variant="outlined"
                    name="abn"
                    value={updatedDetails.abn}
                    className={classes.textField}
                    onChange={handleChangeUpdated}
                    disabled
                  />
                </Grid>
              )}

              <Grid item sm={12} md={12}>
                <Button
                  className={classes.resetBtn}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleNextStep}
                  disabled={buySellDetails.buyerCompanyID ? false : true}>
                  Proceed to Next Step
                </Button>
              </Grid>
            </Grid>
          )}
          {step == 2 && (
            <Grid container spacing={8}>
              <Grid item sm={12} md={12}>
                <h2>Buyer/Seller Info</h2>
              </Grid>
              <Grid item sm={6} md={6}>
                <Box mb={5}>
                  <h4>Seller Information</h4>
                </Box>
                <div>
                  <InputLabel className={classes.inputText}> Full Name</InputLabel>
                  <TextField
                    variant="outlined"
                    name="fullName"
                    value={sellerDetails.fullName}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Phone</InputLabel>
                  <TextField
                    variant="outlined"
                    name="phone"
                    value={sellerDetails.phone}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Email</InputLabel>
                  <TextField
                    variant="outlined"
                    name="email"
                    value={sellerDetails.email}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Address</InputLabel>
                  <TextField
                    variant="outlined"
                    name="address"
                    value={sellerDetails.address}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Location</InputLabel>
                  <TextField
                    variant="outlined"
                    name="location"
                    value={sellerDetails.location}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Customers</InputLabel>
                  <TextField
                    variant="outlined"
                    name="customers"
                    value={sellerDetails.customers}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Pets</InputLabel>
                  <TextField
                    variant="outlined"
                    name="pets"
                    value={sellerDetails.pets}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Active Bookings</InputLabel>
                  <TextField
                    variant="outlined"
                    name="activeBookings"
                    value={sellerDetails.activeBookings}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Future Bookings</InputLabel>
                  <TextField
                    variant="outlined"
                    name="futureBookings"
                    value={sellerDetails.futureBookings}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Cancelled Bookings</InputLabel>
                  <TextField
                    variant="outlined"
                    name="cancelledBookings"
                    value={sellerDetails.cancelledBookings}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Completed Bookings</InputLabel>
                  <TextField
                    variant="outlined"
                    name="completedBookings"
                    value={sellerDetails.completedBookings}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Archived Bookings</InputLabel>
                  <TextField
                    variant="outlined"
                    name="archivedBookings"
                    value={sellerDetails.archivedBookings}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}>Income</InputLabel>
                  <TextField
                    variant="outlined"
                    name="income"
                    value={sellerDetails.income}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Expenses</InputLabel>
                  <TextField
                    variant="outlined"
                    name="expenses"
                    value={sellerDetails.expenses}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Credits</InputLabel>
                  <TextField
                    variant="outlined"
                    name="credits"
                    value={sellerDetails.credits}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </Grid>

              <Grid item sm={6} md={6}>
                <Box mb={5}>
                  <h4>Buyer Information</h4>
                </Box>
                <div>
                  <InputLabel className={classes.inputText}> Full Name</InputLabel>
                  <TextField
                    variant="outlined"
                    name="fullName"
                    value={buyerDetails.fullName}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Phone</InputLabel>
                  <TextField
                    variant="outlined"
                    name="phone"
                    value={buyerDetails.phone}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Email</InputLabel>
                  <TextField
                    variant="outlined"
                    name="email"
                    value={buyerDetails.email}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Address</InputLabel>
                  <TextField
                    variant="outlined"
                    name="address"
                    value={buyerDetails.address}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Location</InputLabel>
                  <TextField
                    variant="outlined"
                    name="location"
                    value={buyerDetails.location}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Customers</InputLabel>
                  <TextField
                    variant="outlined"
                    name="customers"
                    value={buyerDetails.customers}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Pets</InputLabel>
                  <TextField
                    variant="outlined"
                    name="pets"
                    value={buyerDetails.pets}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Active Bookings</InputLabel>
                  <TextField
                    variant="outlined"
                    name="activeBookings"
                    value={buyerDetails.activeBookings}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputText}> Future Bookings</InputLabel>
                  <TextField
                    variant="outlined"
                    name="futureBookings"
                    value={buyerDetails.futureBookings}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Cancelled Bookings</InputLabel>
                  <TextField
                    variant="outlined"
                    name="cancelledBookings"
                    value={buyerDetails.cancelledBookings}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Completed Bookings</InputLabel>
                  <TextField
                    variant="outlined"
                    name="completedBookings"
                    value={buyerDetails.completedBookings}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Archived Bookings</InputLabel>
                  <TextField
                    variant="outlined"
                    name="archivedBookings"
                    value={buyerDetails.archivedBookings}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}>Income</InputLabel>
                  <TextField
                    variant="outlined"
                    name="income"
                    value={buyerDetails.income}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Expenses</InputLabel>
                  <TextField
                    variant="outlined"
                    name="expenses"
                    value={buyerDetails.expenses}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div>
                  <InputLabel className={classes.inputText}> Credits</InputLabel>
                  <TextField
                    variant="outlined"
                    name="credits"
                    value={buyerDetails.credits}
                    className={classes.textField2}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={12}>
                <h3>Below Action Will Occur</h3>
                {/* <p>Delete all cancelled bookings of seller company</p> */}
                {/* <p>Delete all income of seller company</p> */}

                {/* <p>Delete all expenses of seller company</p> */}
                {/* <p>Delete all SMS history of seller company</p> */}
                <p>Migrate customers and pets to buyer company</p>
                <p>Migrate recurring bookings to buyer company</p>
                <p>Migrate all Archived, Completed, Future, Cancelled bookings to buyer company</p>
                <p>Set seller company as migrated</p>
              </Grid>
              <Grid item sm={12} md={12}>
                <Button className={classes.resetBtn} variant="contained" color="primary" size="large" onClick={handleBuy}>
                  Start Migration Process
                </Button>
              </Grid>
            </Grid>
          )}
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default BuySellProcess;
