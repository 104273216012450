import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import ViewIpadDialog from '../ViewIpadDialog';
import TableIpadComponent from './../TableIpadComponent';
const qs = require('qs');

const columns = [
  { id: 'loginDate', label: 'Last Login' },
  { id: 'memberName', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'no_of_access', label: 'No. of Access' },
  // { id: 'Actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const IpadLog = () => {
  const [ipadData, setIpadData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [viewID, setViewID] = useState('');
  const [viewName, setViewName] = useState('');

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    // name: '',
    // email: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    // name: '',
    // email: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      // filterData.name === '' && filterData.email === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    // if (filterData.name === ' ') filterData.name = '';
    // if (filterData.email === ' ') filterData.email = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    getAllIpadAccess();
    let logsStorage = JSON.parse(localStorage.getItem('ipad_filter'));
    logsStorage !== null && setFilterData(logsStorage);
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      // name: filterData.name,
      // email: filterData.email,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('ipad_filter', JSON.stringify(filterData));
    if (
      // filterData.name || filterData.email ||
      filterData.search
    ) {
      httpClient.get(`member/report/ipad-access/filter?q=${filterData.search}`).then(({ data }) => {
        if (data.success === true) {
          setIpadData(data.data);
          setTotal(data.meta.total);
          // setRowsPerPage(data.meta.per_page);
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      });
    } else {
      getAllIpadAccess();
    }
  };

  const handleFilter = e => {
    e.preventDefault();

    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(
        `member/report/ipad-access?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`,
      )
      .then(({ data }) => {
        if (data.success === true) {
          setIpadData(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(`member/report/ipad-access/filter?q=${filterData.search}&pagination=${rowsPerPage}&page=${page}`)
          .then(({ data }) => {
            if (data.success === true) {
              setIpadData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`member/report/ipad-access?&direction=desc&pagination=${rowsPerPage}&page=${page}`)
          .then(({ data }) => {
            if (data.success === true) {
              setIpadData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(`member/report/ipad-access/filter?q=${filterData.search}&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setIpadData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`member/report/ipad-access?&direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setIpadData(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const getAllIpadAccess = () => {
    setLoading(true);
    httpClient.get(`member/report/ipad-access?&${rowsPerPage}`).then(({ data }) => {
      if (data) {
        setIpadData(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
      }
    });
  };

  // const handleView = val => {
  //   setViewID(val.id);
  //   setViewName(val.name);
  //   setDialogOpen(true);
  // };

  const sendTo = child => {
    child == false && setDialogOpen(false);
  };

  return (
    <PageContainer heading="Ipad Access Log">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableIpadComponent
              columns={columns}
              selectedProducts={ipadData}
              title="Ipad Access Log"
              // handleView={handleView}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total && total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>

      {/* {dialogOpen && <ViewIpadDialog sendTo={sendTo} viewID={viewID} title={viewName} />} */}
    </PageContainer>
  );
};

export default IpadLog;
