import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles({
  text: {
    width: '100%',
    margin: '10px',
  },
  root: {
    padding: '40px',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddSuburb = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [members, setMembers] = useState([]);
  const [company, setCompany] = useState([]);
  const [suburbID, setSuburbID] = useState(props && props.location.pathname.slice(18));

  const [suburbDetails, setSuburbDetails] = useState({
    companyID: '',
    memberID: '',
    suburbName: '',
    PostCode: '',
    suburbActive: false,
  });

  useEffect(() => {
    getAllMember();
    getAllCompany();
    getSingleSuburb();
  }, []);

  const getAllMember = () => {
    httpClient.get('member/all').then(({ data }) => {
      if (data.success === true) {
        setMembers(data.data);
      }
    });
  };

  const getAllCompany = () => {
    httpClient.get('member-company/all').then(({ data }) => {
      if (data.success === true) {
        setCompany(data.data);
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setSuburbDetails({
      ...suburbDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setSuburbDetails({
      ...suburbDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    suburbID === ''
      ? httpClient
          .post(
            'suburb',
            qs.stringify({
              companyID: suburbDetails.companyID,
              memberID: suburbDetails.memberID,
              suburbName: suburbDetails.suburbName,
              PostCode: suburbDetails.PostCode,
              suburbActive: suburbDetails.suburbActive === true ? 1 : false,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setSuburbDetails({
                companyID: '',
                memberID: '',
                suburbName: '',
                PostCode: '',
                suburbActive: false,
              });
            } else {
              setOpen(true);
              setMessage('Failed to add Suburb!');
              setMessageState('error');
            }
          })
      : httpClient
          .put(
            `suburb/${suburbID}`,
            qs.stringify({
              companyID: suburbDetails.companyID,
              memberID: suburbDetails.memberID,
              suburbName: suburbDetails.suburbName,
              PostCode: suburbDetails.PostCode,
              suburbActive: suburbDetails.suburbActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              history.push('/suburb/listSuburb');
            } else {
              setOpen(true);
              setMessage('Failed to update suburb!');
              setMessageState('error');
            }
          });
  };

  const getSingleSuburb = () => {
    suburbID &&
      httpClient.get(`suburb/${suburbID}`).then(({ data }) => {
        if (data.success === true) {
          setSuburbDetails({
            companyID: data.data.company && data.data.company.companyID,
            memberID: data.data.member && data.data.member.memberID,
            suburbName: data.data.suburbName,
            PostCode: data.data.PostCode,
            suburbActive: data.data.suburbActive === 1 ? true : false,
          });
        }
      });
  };

  const handleBack = () => {
    history.push('/suburb/listSuburb');
  };

  return (
    <PageContainer heading={suburbID === '' ? 'Add Suburb' : 'Edit Suburb'}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                disabled={suburbID ? true : false}
                label="Suburb Name"
                variant="outlined"
                name="suburbName"
                value={suburbDetails.suburbName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Post Code"
                variant="outlined"
                name="PostCode"
                value={suburbDetails.PostCode}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Company</InputLabel>
                <Select
                  required
                  name="companyID"
                  label="Company"
                  value={suburbDetails.companyID}
                  onChange={handleChange}
                  disabled={suburbID ? true : false}>
                  <MenuItem value="">
                    <em>Select Company</em>
                  </MenuItem>

                  {company.length > 0 &&
                    company.map(com => (
                      <MenuItem key={com && com.companyID} value={com && com.companyID}>
                        {com && com.tradingName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Member</InputLabel>
                <Select
                  required
                  name="memberID"
                  label="Member"
                  value={suburbDetails.memberID}
                  onChange={handleChange}
                  disabled={suburbID ? true : false}>
                  <MenuItem value={0}>
                    <em>Super Admin</em>
                  </MenuItem>

                  {members.length > 0 &&
                    members.map(mem => (
                      <MenuItem key={mem && mem.memberID} value={mem && mem.memberID}>
                        {mem && mem.firstName + ' ' + mem.lastName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="suburbActive"
                    checked={suburbDetails.suburbActive}
                    onChange={handleCheck}
                    value={suburbDetails.suburbActive}
                    color="primary"
                  />
                }
                label="Make Suburb Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Box textAlign="right" pt={4}>
                <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  {suburbID === '' ? 'Add Suburb' : 'Edit Suburb'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddSuburb;
