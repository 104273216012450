import React, { useState, useEffect } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { DatePicker } from '@material-ui/pickers';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { httpClient } from 'appUtility/Api';
import Axios from 'axios';

const login = localStorage.getItem('login');

const WeeklySales = props => {
  // const { productsList } = intranet;

  let loginData = JSON.parse(login);

  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);

  const [filter, setFilter] = useState(false);
  const [csvData, setCsvData] = useState({});
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
    // props.submittedData.submit === true && exportData();
  }, [props.submittedData]);

  // const linkTo = () => {
  //   history.push('/versions/addVersion');
  // };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  const handleClickAnchor = e => {
    e.preventDefault();
    // window.open(
    //   `https://admin.franchise.care/php/api/app/1.0/income/user-export?startDate=${props.filterData.currStartDate}&endDate=${props.filterData.currEndDate}&token=bearer ${loginData.access_token}`,
    // );
    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        Authorization: 'Bearer ' + loginData.access_token,
      },
    };
    httpClient.get(
      // `income/user-export?startDate=${props.filterData.currStartDate}&endDate=${props.filterData.currEndDate}`
      `https://admin.franchise.care/php/api/app/1.0/income/user-export?startDate=${props.filterData.currStartDate}&endDate=${props.filterData.currEndDate}&outStandingBooking=${props.filterData.outstandingIncome}&memberActive=${props.filterData.memberActive}&incomeAmount=${props.filterData.incomeAmount}&incomeAmountOperator=${props.filterData.incomeAmountOperator}&token=${loginData.access_token}`,
    );
  };

  console.log('windos', window.location);

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* <Button color="primary" variant="contained" onClick={exportData} style={{ marginRight: '10px' }}>
            +Export
          </Button> */}
          <a
            // href={`data:text/csv;charset=utf-8,${escape(csvData)}`}
            download="income_user_report.csv"
            // onClick={handleClickAnchor}
            href={`${(window.location.origin === "https://backadmin.franchise.care" || window.location.origin === "http://localhost:3000") ? "https://backadmin.franchise.care" : "https://admin.franchise.care"}/php/api/app/1.0/income/user-export?startDate=${props.filterData.currStartDate}&endDate=${props.filterData.currEndDate}&outStandingBooking=${props.filterData.outstandingIncome}&memberActive=${props.filterData.memberActive}&incomeAmount=${props.filterData.incomeAmount}&incomeAmountOperator=${props.filterData.incomeAmountOperator}`}
            className={classes.exportButton}
            target="_blank"
            download
            rel="noreferrer">
            + Export
          </a>
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                  <DatePicker
                    // disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    label="Start Date"
                    name="startDate"
                    // helperText="Enter the start date"
                    // format="MM-DD-YYYY"
                    value={props.filterData.startDate ? props.filterData.startDate : null}
                    onChange={props.handleStartDate}
                    className={classes.dateField}
                    format="DD-MM-YYYY"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <DatePicker
                    // disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    label="End Date"
                    name="endDate"
                    // helperText="Enter the end date"
                    // format="MM-DD-YYYY"
                    value={props.filterData.endDate ? props.filterData.endDate : null}
                    onChange={props.handleEndDate}
                    format="DD-MM-YYYY"
                    className={classes.dateField}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Members Active</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="memberActive"
                      value={props.filterData.memberActive}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Income Amount</InputLabel>
                  <Box style={{ display:"grid", gridTemplateColumns:"1fr 3fr" }}>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="incomeAmountOperator"
                      value={props.filterData.incomeAmountOperator}
                      onChange={props.handleChange}
                      className={classes.text}
                      style={{ borderRadius:"4px 0px 0px 4px" }}
                      >
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="="> = </MenuItem>
                      <MenuItem value=">"> {">"} </MenuItem>
                      <MenuItem value="<"> {"<"} </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    name="incomeAmount"
                    value={props.filterData.incomeAmount}
                    className={classes.textIncome}
                    onChange={props.handleChange}

                  />
                  </Box>
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Outstanding Income</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="outstandingIncome"
                      value={props.filterData.outstandingIncome}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.startDate ||
        props.submittedData.endDate ||
        props.submittedData.memberActive ||
        props.submittedData.outstandingIncome ||
        props.submittedData.incomeAmount ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.startDate && (
              <p>
                Start Date: <span>{moment(props.submittedData.startDate).format('Do MMMM , YYYY')} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('startDate')} />
              </p>
            )}

            {props.submittedData.endDate && (
              <p>
                End Date:<span> {moment(props.submittedData.endDate).format('Do MMMM , YYYY')} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('endDate')} />
              </p>
            )}
            {props.submittedData.memberActive && (
              <p>
                Member Active:<span> {props.submittedData.memberActive === '1' ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('memberActive')} />
              </p>
            )}
            {props.submittedData.incomeAmount && (
              <p>
                Income Amount <span>{props.submittedData.incomeAmountOperator}</span> <span> {props.submittedData.incomeAmount} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('incomeAmount')} />
              </p>
            )}
            {props.submittedData.outstandingIncome && (
              <p>
                Outstanding Income:<span> {props.submittedData.outstandingIncome === '1' ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('outstandingIncome')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
