import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DialogBox = props => {
  const [alertDetails, setAlertDetails] = useState({
    open: true,
    reflag: props.reflag,
    deletion: props.deletion,
    success: false,
  });

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
    });
  };

  const handleSuccess = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      success: true,
    });
  };

  props.alertData(alertDetails);

  return (
    <Dialog open={alertDetails.open} onClose={handleClose}>
      <DialogTitle>
        {props.reflag && 'Re-Flag Lead'}
        {props.deletion && 'Delete Lead'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.reflag && 'Are you sure you want to Re-Flag to copy the current lead?'}
          {props.deletion && 'Are you sure you want to Delete the current lead?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleSuccess} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
