import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Card,
  Grid,
  makeStyles,
  TextField,
  InputLabel,
  Button,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import { Close } from '@material-ui/icons';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
    width: '50%',
    margin: '0 auto',
  },
  textField: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '25px',
  },
  textSelect: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  resetBtn: {
    marginTop: '10px',
    float: 'right',
  },
  closeBtn: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  content: {
    padding: '35px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EnableDisableIPad = props => {
  const classes = useStyles();
  const [memberID, setMemberID] = useState(props && props.ipadID);
  const [enableDetails, setEnableDetails] = useState({
    iPadActive: '',
    memberName: '',
    iPadState: '',
    iPadDeletedData: '',
    open: true,
    loading: false,
    submit: false,
  });

  const [response, setResponse] = useState({
    open: false,
    message: '',
    messageState: '',
  });

  const handleClose = (event, reason) => {
    setEnableDetails({
      ...enableDetails,
      open: false,
    });
  };

  useEffect(() => {
    getSingleMember();
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setEnableDetails({
      ...enableDetails,
      [name]: value,
    });
  };

  const getSingleMember = () => {
    memberID &&
      httpClient.get(`/member/${memberID}`).then(({ data }) => {
        if (data.success === true) {
          setEnableDetails({
            ...enableDetails,
            memberName: data.data.firstName + ' ' + data.data.lastName,
            iPadActive: data.data.iPadActive,
            iPadState: data.data.iPadActive === 1 ? 'Enable' : 'Disable',
            iPadDeletedData: data.data.iPadDataDelete,
          });
        }
      });
  };

  const handleChangeStatus = () => {
    setEnableDetails({
      ...enableDetails,
      loading: true,
    });
    httpClient
      .post(
        `/member/ipad-access`,
        qs.stringify({
          memberID: memberID,
          iPadAccess: enableDetails.iPadActive,
          deleteiPadData: enableDetails.iPadDeletedData,
        }),
      )
      .then(({ data }) => {
        if (data.success === true) {
          setResponse({
            open: true,
            message: data.message,
            messageState: 'success',
          });

          setEnableDetails({
            ...enableDetails,
            open: false,
            submit: true,
            loading: false,
          });
          //   history.push('/members/listMembers');
        } else {
          setResponse({
            open: true,
            message: 'Failed to Enale/Disable Ipad',
            messageState: 'error',
          });

          setEnableDetails({
            ...enableDetails,
            open: true,
            submit: true,
            loading: false,
          });
        }
      });
  };

  props.sendResponse(response);
  props.sendIpadData(enableDetails);

  return (
    <Dialog open={enableDetails.open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <h2>Enable/Disable IPad Access</h2>
      </DialogTitle>
      <IconButton onClick={handleClose} className={classes.closeBtn}>
        <Close />
      </IconButton>
      <DialogContent className={classes.content}>
        <Grid container spacing={4}>
          <form className={classes.formControl}>
            <Grid item sm={12} md={12}>
              <InputLabel> Member Name</InputLabel>
              <TextField
                variant="outlined"
                name="memberName"
                value={enableDetails.memberName}
                className={classes.textField}
                onChange={handleChange}
                disabled
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <InputLabel> Current IPad State</InputLabel>
              <TextField
                variant="outlined"
                name="iPadState"
                value={enableDetails.iPadState}
                className={classes.textField}
                onChange={handleChange}
                disabled
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <InputLabel>Change IPad State</InputLabel>
              <FormControl variant="outlined" className={classes.textSelect}>
                <Select
                  name="iPadActive"
                  value={enableDetails.iPadActive}
                  onChange={handleChange}
                  className={classes.textField}>
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={1}>Enable</MenuItem>
                  <MenuItem value={0}>Disable</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <InputLabel>Delete IPad Data</InputLabel>
              <FormControl variant="outlined" className={classes.textSelect}>
                <Select
                  name="iPadDeletedData"
                  value={enableDetails.iPadDeletedData}
                  onChange={handleChange}
                  className={classes.textField}>
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  className={classes.resetBtn}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleChangeStatus}>
                  Reset Status
                </Button>
                {enableDetails.loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
              </div>
            </Grid>
          </form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EnableDisableIPad;
