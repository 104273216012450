import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableIncomeComponent from './../TableIncomeComponent';

const columns = [
  { id: 'incomeCategoryName', label: 'Item Name' },
  { id: 'incomeCategoryDescription', label: 'Description' },
  { id: 'companyAddedBy', label: 'Added By' },
  { id: 'incGst', label: 'Gst Included' },
  { id: 'incomeCategoryActive', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListIncomeCategory = () => {
  const [incomeCategory, setIncomeCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    categoryName: '',
    addedBy: '',
    addedByName: '',
    gst: '',
    active: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    categoryName: '',
    addedBy: '',
    addedByName: '',
    gst: '',
    active: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (
      filterData.categoryName === '' &&
      filterData.addedBy === '' &&
      filterData.addedByName === '' &&
      filterData.gst === '' &&
      filterData.active === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.categoryName === ' ') filterData.categoryName = '';
    if (filterData.addedBy === ' ') filterData.addedBy = '';
    if (filterData.addedByName === ' ') filterData.addedByName = '';
    if (filterData.gst === ' ') filterData.gst = '';
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let income_category = JSON.parse(localStorage.getItem('income_category'));
    income_category !== null && setFilterData(income_category);

    income_category == null
      ? getIncomeCategory()
      : income_category.categoryName == '' &&
        income_category.addedBy == '' &&
        income_category.addedByName == '' &&
        income_category.gst == '' &&
        income_category.active == '' &&
        income_category.search == '' &&
        income_category.removed == false
      ? getIncomeCategory()
      : console.log('income_category');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      categoryName: filterData.categoryName,
      addedBy: filterData.addedBy,
      addedByName: filterData.addedByName,
      gst: filterData.gst,
      active: filterData.active,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('income_category', JSON.stringify(filterData));
    if (
      filterData.categoryName ||
      filterData.addedBy ||
      filterData.addedByName ||
      filterData.gst ||
      filterData.active ||
      filterData.search
    ) {
      httpClient
        .get(
          `income/category/filter?q=${filterData.search}&incomeCategoryName=${filterData.categoryName}&addedBy=${filterData.addedBy}&incomeCategoryActive=${filterData.active}&gstInclusive=${filterData.gst}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setIncomeCategory(data.data);
            setTotal(data.meta.total);
            setPage(data.meta.current_page);
            setRowsPerPage(data.meta.per_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getIncomeCategory();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'addedBy') {
      filterData.addedByName = value;
    }
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleChangeAdded = value => {
    setFilterData({
      ...filterData,
      addedBy: value !== null ? value.companyID : 0,
      addedByName: value !== null ? value.tradingName : 'Super Admin',
      removed: false,
    });
  };

  const handleRemove = data => {
    if (data === 'addedBy') {
      filterData.addedBy = '';
      filterData.addedByName = '';
    }
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  // useEffect(() => {
  //   getIncomeCategory();
  // }, []);

  const getIncomeCategory = () => {
    setLoading(true);
    httpClient.get(`income/category?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setIncomeCategory(data.data);
        setTotal(data.meta.total);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      } else {
      }
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    console.log('direction', direction);
    httpClient
      .get(`income/category?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setIncomeCategory(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `income/category/filter?q=${filterData.search}&incomeCategoryName=${filterData.categoryName}&addedBy=${filterData.addedBy}&incomeCategoryActive=${filterData.active}&gstInclusive=${filterData.gst}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setIncomeCategory(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`income/category?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setIncomeCategory(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(
            `income/category/filter?q=${filterData.search}&incomeCategoryName=${filterData.categoryName}&addedBy=${
              filterData.addedBy
            }&incomeCategoryActive=${filterData.active}&gstInclusive=${filterData.gst}&pagination=${+event.target
              .value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setIncomeCategory(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`income/category?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setIncomeCategory(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleEdit = id => {
    history.push(`/financial/addIncomeCategory/${id}`);
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getIncomeCategory();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`income/category/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setIncomeCategory(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  const handleDefault = e => {
    setSearchText(e.target.value);
  };

  return (
    <PageContainer heading="List Income Category">
      <TableIncomeComponent
        columns={columns}
        selectedProducts={incomeCategory !== undefined && incomeCategory}
        title={'List Income Category'}
        handleEdit={handleEdit}
        // handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        fromTable={from}
        toTable={to}
        rowsPerPage={rowsPerPage}
        direction={direction}
        currentColumn={currentColumn}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleChangeAdded={handleChangeAdded}
        handleRemove={handleRemove}
      />
    </PageContainer>
  );
};

export default ListIncomeCategory;
