import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddTopics = props => {
  const classes = useStyles();

  const [topicID, setTopicID] = useState(props && props.location.pathname.slice(17));
  const [forumGroup, setForumGroup] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [topicDetails, setTopicDetails] = useState({
    topicName: '',
    topicShortDescription: '',
    forumGroupID: '',
    topicReadOnly: false,
    topicActive: false,
  });

  useEffect(() => {
    getSingleTopic();
    getForumGroup();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setTopicDetails({
      ...topicDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setTopicDetails({
      ...topicDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    topicID == ''
      ? httpClient
          .post(
            'forum-topic',
            qs.stringify({
              topicName: topicDetails.topicName,
              topicShortDescription: topicDetails.topicShortDescription,
              forumGroupID: topicDetails.forumGroupID,
              topicReadOnly: topicDetails.topicReadOnly == true ? 1 : 0,
              topicActive: topicDetails.topicActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.mesage);
              setMessageState('success');
              setTopicDetails({
                topicName: '',
                topicShortDescription: '',
                forumGroupID: '',
                topicReadOnly: false,
                topicActive: false,
              });
            } else {
              setOpen(true);
              setMessage('Forum Topic Failed to be added');
              setMessageState('error');
            }
          })
      : httpClient
          .post(
            `forum-topic/${topicID}`,
            qs.stringify({
              topicName: topicDetails.topicName,
              topicShortDescription: topicDetails.topicShortDescription,
              forumGroupID: topicDetails.forumGroupID,
              topicReadOnly: topicDetails.topicReadOnly == true ? 1 : 0,
              topicActive: topicDetails.topicActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.mesage);
              setMessageState('success');
              setTopicDetails({
                topicName: '',
                topicShortDescription: '',
                forumGroupID: '',
                topicReadOnly: false,
                topicActive: false,
              });
              setTimeout(() => {
                history.push('/forum/listTopics');
              }, 1000);
            } else {
              setOpen(true);
              setMessage('Forum Topic Failed to be updated');
              setMessageState('error');
            }
          });
  };

  const getSingleTopic = () => {
    topicID &&
      httpClient.get(`/forum-topic/${topicID}`).then(({ data }) => {
        setTopicDetails({
          topicName: data.data.topicName,
          topicShortDescription: data.data.topicShortDescription,
          forumGroupID: parseInt(data.data.forumGroup.forumGroupID),
          topicReadOnly: data.data.topicReadOnly == 'Read only' ? true : false,
          topicActive: data.data.topicActive == 'Active' ? true : false,
        });
      });
  };

  const getForumGroup = () => {
    httpClient.get(`/forum-group`).then(({ data }) => {
      if (data) {
        setForumGroup(data.data);
      }
    });
  };

  const handleBack = () => {
    history.push('/forum/listTopics');
  };

  return (
    <PageContainer heading={topicID === '' ? 'Add Forum Topic' : 'Edit Forum Topic'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Forum Topics Name"
                variant="outlined"
                name="topicName"
                type="text"
                value={topicDetails.topicName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={3}
                label="Short Description"
                variant="outlined"
                name="topicShortDescription"
                type="text"
                value={topicDetails.topicShortDescription}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" className={classes.text}>
                <InputLabel>Category *</InputLabel>
                <Select
                  required
                  name="forumGroupID"
                  label="category"
                  value={topicDetails.forumGroupID}
                  onChange={handleChange}
                  className={classes.text}>
                  <MenuItem value="">Select Forum Group</MenuItem>
                  {forumGroup &&
                    forumGroup.map(group => (
                      <MenuItem key={group.forumGroupID} value={group.forumGroupID}>
                        {group.forumGroupName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="topicReadOnly"
                    checked={topicDetails.topicReadOnly}
                    onChange={handleCheck}
                    value={topicDetails.topicReadOnly}
                    color="primary"
                  />
                }
                label="Make Forum Topic Read Only?"
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="topicActive"
                    checked={topicDetails.topicActive}
                    onChange={handleCheck}
                    value={topicDetails.topicActive}
                    color="primary"
                  />
                }
                label="Make Forum Topic Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                {topicID === '' ? 'Add Forum Topic' : 'Edit Forum Topic'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddTopics;
