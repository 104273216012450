import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useState, useEffect } from 'react';
import RipplePurchaseHistory from 'routes/Dashboards/Crypto/RipplePurchaseHistory';
import Statistics from './../Statistics';
import DailyCountryIncome from '../DailyCountryIncome';
import StateIncome from '../StateIncome';

const columns = [
  // { id: 'none', label: ' ' },
  { id: 'serviceId', label: 'Service ID' },
  { id: 'ServiceName', label: 'Service Name' },
  { id: 'total', label: 'Total' },
];

const avgCountryColumns = [
  // { id: 'none', label: ' ' },
  { id: 'countryName', label: 'Country Name' },
  { id: 'avgIncome', label: 'Average Income' },
];

const stateColumns = [
  // { id: 'none', label: ' ' },
  { id: 'stateName', label: 'State Name' },
  { id: 'avgIncome', label: 'Average Income' },
];

const Dashboard = () => {
  const [socialActiveMembers, setSocialActiveMembers] = useState([]);
  const [notLogged, setNotLoggedIn] = useState([]);
  const [avgIncome, setAvgIncome] = useState([]);
  const [serviceReport, setServiceReport] = useState([]);
  const [income, setIncome] = useState([]);

  useEffect(() => {
    getSocialActiveMembers();
    getNotLoggedMembers();
    avgIncomeForcast();
    serviceTimelineReport();
    incomeReport();
  }, []);

  const getSocialActiveMembers = () => {
    httpClient.get('member/report/social-active').then(({ data }) => {
      if (data.success === true) {
        setSocialActiveMembers(data.data);
      }
    });
  };

  const getNotLoggedMembers = () => {
    httpClient.get('member/report/Not-logged-in').then(({ data }) => {
      if (data.success === true) {
        setNotLoggedIn(data.data);
      }
    });
  };

  const avgIncomeForcast = () => {
    httpClient.get('income/report/AvgIncome').then(({ data }) => {
      if (data) {
        setAvgIncome(data[0]);
      }
    });
  };

  const serviceTimelineReport = () => {
    httpClient.get('booking/report/service').then(({ data }) => {
      if (data.success === true) {
        setServiceReport(data.data);
      }
    });
  };

  const incomeReport = () => {
    httpClient.get('income/report/avgTurnover').then(({ data }) => {
      if (data.success === true) {
        setIncome(data.data);
      }
    });
  };

  return (
    <PageContainer heading="Report Dashboard">
      <GridContainer>
        <Grid item xs={12} sm={6} md={3}>
          <RipplePurchaseHistory
            title="Social Active Members"
            amount={socialActiveMembers}
            color="#B819D2"
            colorID="color10"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RipplePurchaseHistory title="Not Logged In Users" amount={notLogged} color="#F39711" colorID="color11" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RipplePurchaseHistory
            title="Average Income in 1 week"
            amount={avgIncome.IncomeIn1Week}
            color="#19A6D2"
            colorID="color12"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RipplePurchaseHistory
            title="Average Income in 4 week"
            amount={Math.round(avgIncome.IncomeIn4Week).toFixed(2)}
            color="#ADFF2F"
            colorID="color13"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Statistics columns={columns} selectedProducts={serviceReport} title="Service Timeline Report" />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DailyCountryIncome
            columns={avgCountryColumns}
            selectedProducts={income.dailyCountryIncome}
            title="Daily Country Income"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DailyCountryIncome
            columns={avgCountryColumns}
            selectedProducts={income.weeklyCountryIncome}
            title="Weekely Country Income"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <StateIncome columns={stateColumns} selectedProducts={income.dailyStateIncome} title="Daily State Income" />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <StateIncome columns={stateColumns} selectedProducts={income.weeklyStateIncome} title="Weekely State Income" />
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Dashboard;
