import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddOrder from './AddOrder';
import AddShippingCompany from './AddShippingCompany';
import ListArchivedOrders from './ListArchivedOrders';
import ListCancelledOrders from './ListCancelledOrders';
import ListConfirmedOrders from './ListConfirmedOrders';
import ListPastOrders from './ListPastOrders';
import ListRecentOrders from './ListRecentOrders';
import ListShippedOrders from './ListShippedOrders';
import ListShippingCompany from './ListShippingCompany';
import ListUndeliveredOrders from './ListUndeliveredOrders';
import ListUnpaidOrders from './ListUnpaidOrders';

const Orders = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listShippingCompany`} />
        <Route path={`${requestedUrl}/listShippingCompany`} component={ListShippingCompany} />
        <Route path={`${requestedUrl}/addShippingCompany`} component={AddShippingCompany} />
        <Route path={`${requestedUrl}/listConfirmedOrders`} component={ListConfirmedOrders} />
        <Route path={`${requestedUrl}/listRecentOrders`} component={ListRecentOrders} />
        <Route path={`${requestedUrl}/listPastOrders`} component={ListPastOrders} />
        <Route path={`${requestedUrl}/listUndeliveredOrders`} component={ListUndeliveredOrders} />
        <Route path={`${requestedUrl}/listUnpaidOrders`} component={ListUnpaidOrders} />
        <Route path={`${requestedUrl}/listShippedOrders`} component={ListShippedOrders} />
        <Route path={`${requestedUrl}/listCancelledOrders`} component={ListCancelledOrders} />
        <Route path={`${requestedUrl}/listArchivedOrders`} component={ListArchivedOrders} />
        <Route path={`${requestedUrl}/addOrder/:orderID`} component={AddOrder} />
        <Route path={`${requestedUrl}/addOrder`} component={ListRecentOrders} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Orders;
