import {
  Button,
  Card,
  FormControl,
  Grid,
  Tooltip,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { httpClient } from 'appUtility/Api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';

const useStyles = makeStyles(theme => ({
  text: {
    width: '100%',
  },
  root: {
    padding: '20px',
    minWidth: '800px',
  },
  content: {
    padding: '24px',
  },
  formControl: {
    width: '100%',
  },
  remove: {
    position: 'absolute',
    right: '-70px',
    top: '30px',
  },
  formMargin: {
    margin: '20px 0',
  },
  input: {
    marginBottom: '10px',
  },
}));

const shampooList = [
  'Herbal Deluxe Shampoo',
  'Whitening Deluxe Shampoo',
  'Flea & Tick Wash',
  'Vet Shampoo',
  'Medicated Wash',
  'DeShed 2LT Step 2',
  'Blue Wheelers NEW Deshed',
  'Cologne',
  'HydroClean Sanitiser',
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const InventoryUsageDialog = props => {
  const classes = useStyles();
  const [inventoryData, setInventoryData] = useState([]);

  const [rows, setRows] = useState([{ selectedItem: {}, numOfPumps: 0, selectedUsage: '' }]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInventoryUseage();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getInventoryUseage = () => {
    httpClient.get('inventory/all').then(({ data }) => {
      const filteredData = data.data && data.data.filter(item => shampooList.includes(item.inventoryItemName));

      setInventoryData(filteredData);
    });
  };

  const handleChangeService = (index, e) => {
    const selectedInventoryItem = inventoryData.find(item => item.inventoryItemsID === e.target.value);
    const newRows = [...rows];

    // Check for duplicates in existing rows
    // const duplicateIndex = newRows.findIndex(
    //   row => row.selectedItem.inventoryItemsID === selectedInventoryItem.inventoryItemsID && row !== newRows[index],
    // );
    // if (duplicateIndex !== -1) {
    //   alert(`Duplicate item detected: ${selectedInventoryItem.inventoryItemName}`);
    //   return;
    // }

    // Preserve the current selectedUsage if already set
    const previousSelectedUsage = newRows[index].selectedUsage;

    newRows[index].selectedItem = selectedInventoryItem;
    const pumpPerML = selectedInventoryItem.inventoryItemsID === 2 || selectedInventoryItem.inventoryItemsID === 6 ? 5 : 30;
    const numOfPumps = (selectedInventoryItem.weightPerItem * 1000 * (newRows[index].stockUsagePercent / 100)) / pumpPerML;

    newRows[index].numOfPumps = numOfPumps;
    newRows[index].selectedUsage = previousSelectedUsage || (numOfPumps < 1 ? '' : Math.round(numOfPumps).toString());

    setRows(newRows);
  };

  const handleUsageChange = (index, event) => {
    const newRows = [...rows];

    newRows[index].selectedUsage = event.target.value;
    setRows(newRows);
  };

  const addRow = () => {
    // Check if the last row has both fields populated
    const lastRow = rows[rows.length - 1];

    if (lastRow) {
      if (!lastRow.selectedItem.inventoryItemsID) {
        setOpen(true);
        setMessage(`Please select an inventory item before adding a new row.`);
        setMessageState('error');
        return;
      }
      if (!lastRow.selectedUsage || isNaN(lastRow.selectedUsage)) {
        setOpen(true);
        setMessage(`Please select top up quantity before adding a new row.`);
        setMessageState('error');
        return;
      }
    }

    // Check for duplicate items in all rows
    const selectedItems = rows.map(row => row.selectedItem.inventoryItemsID);
    const duplicates = selectedItems.filter((item, index) => selectedItems.indexOf(item) !== index);
    if (duplicates.length > 0) {
      const duplicateItem = inventoryData.find(item => item.inventoryItemsID === duplicates[0]);
      setOpen(true);
      setMessage(`Duplicate item detected: ${duplicateItem.inventoryItemName}`);
      setMessageState('error');
      return;
    }
    setRows([...rows, { selectedItem: {}, numOfPumps: 0, selectedUsage: '', stockUsagePercent: 0 }]);
  };

  const removeRow = index => {
    if (rows.length === 1) {
      setOpen(true);
      setMessage('Cannot remove the last remaining row.');
      setMessageState('error');
      return;
    }

    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleSubmit = e => {
    e.preventDefault();

    // Check if the last row has both fields populated
    const lastRow = rows[rows.length - 1];

    if (lastRow) {
      if (!lastRow.selectedItem.inventoryItemsID) {
        setOpen(true);
        setMessage(`Please select an inventory item before adding a new row.`);
        setMessageState('error');
        return;
      }
      if (!lastRow.selectedUsage || isNaN(lastRow.selectedUsage)) {
        setOpen(true);
        setMessage(`Please select top up quantity before adding a new row.`);
        setMessageState('error');
        return;
      }
    }

    // Check for duplicate items in all rows
    const selectedItems = rows.map(row => row.selectedItem.inventoryItemsID);
    const duplicates = selectedItems.filter((item, index) => selectedItems.indexOf(item) !== index);
    if (duplicates.length > 0) {
      const duplicateItem = inventoryData.find(item => item.inventoryItemsID === duplicates[0]);
      setOpen(true);
      setMessage(`Duplicate item detected: ${duplicateItem.inventoryItemName}`);
      setMessageState('error');
      return;
    }

    setLoading(true);

    const receivedDate = new Date().toISOString().split('T')[0];

    const saveData = rows.reduce((acc, row, index) => {
      acc[`inventoryID[${index}]`] = row.selectedItem.inventoryItemsID;
      acc[`numofBottle[${index}]`] = row.selectedUsage;
      return acc;
    }, {});

    saveData.memberID = props?.memberID;
    saveData.companyID = props?.companyID;
    saveData.receivedDate = receivedDate;

    const formData = new FormData();
    Object.keys(saveData).forEach(key => {
      formData.append(key, saveData[key]);
    });

    httpClient.post(`member/${props.memberID}/inventory`, formData).then(({ data }) => {
      if (data.success) {
        setOpen(true);
        setMessage(data.message || 'Inventory added successfully');
        setMessageState('success');
        setTimeout(() => {
          props.setOpen(false);
          history.push('/members/listMembers');
        }, 1000);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(data.message || 'Failed to add inventory items');
        setMessageState('error');
      }
    });
  };

  return (
    <Dialog open={props.open} onClose={() => props.setOpen(false)} maxWidth="lg">
      <DialogTitle>
        <h2>Inventory Top Up</h2>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Card className={classes.root}>
          <form onSubmit={handleSubmit} className={classes.formControl}>
            <Grid container spacing={4}>
              {rows &&
                rows.map((row, index) => (
                  <>
                    <Grid item md={6}>
                      <FormControl variant="outlined" fullWidth style={{ marginRight: '1rem' }}>
                        <InputLabel id={`inventory-select-label-${index}`}>Select Inventory Item</InputLabel>
                        <Select
                          labelId={`inventory-select-label-${index}`}
                          value={row.selectedItem.inventoryItemsID || ''}
                          onChange={e => handleChangeService(index, e)}
                          label="Select Inventory Item">
                          {inventoryData.map(item => (
                            <MenuItem key={item.inventoryItemsID} value={item.inventoryItemsID}>
                              {item.inventoryItemName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={5}>
                      <div key={index} style={{ position: 'relative' }}>
                        <FormControl variant="outlined" fullWidth style={{ marginRight: '1rem' }}>
                          <InputLabel id={`usage-select-label-${index}`}>Top Up Quantity (in bottles)</InputLabel>
                          <Select
                            labelId={`usage-select-label-${index}`}
                            value={row.selectedUsage}
                            onChange={e => handleUsageChange(index, e)}
                            label="Inventory Usage (in pumps)">
                            <MenuItem value="">Select Top Up Quantity</MenuItem>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid md={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Tooltip title="Remove">
                        <IconButton onClick={() => removeRow(index)} color="secondary">
                          <Close color="error" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </>
                ))}
              <Grid item sm={12} md={12}>
                <Box sx={{ marginTop: '24px', display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Button variant="outlined" color="primary" onClick={() => props.setOpen(false)}>
                      cancel
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={addRow} style={{ marginLeft: '10px' }}>
                      Add More +
                    </Button>
                  </Box>

                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ marginRight: '24px' }}
                      disabled={rows.length < 1 || loading}>
                      Save
                    </Button>
                    {loading && <CircularProgress fontSize={24} />}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Card>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={messageState}>
            {message}
          </Alert>
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
};

export default InventoryUsageDialog;
