import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { makeStyles, Card, Grid, TextField, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import Dropzone from 'react-dropzone';
import { AddPhotoAlternateOutlined, History } from '@material-ui/icons';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddNewsCategory = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [categoryID, setCategoryID] = useState(props && props.location.pathname.slice(22));

  const [categoryDetails, setCategoryDetails] = useState({
    categoryTitle: '',
    categoryActive: false,
    // categoryImage: '',
  });

  useEffect(() => {
    getSingleCategory();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const catFormData = new FormData();
    catFormData.append('categoryTitle', categoryDetails.categoryTitle);
    catFormData.append('categoryActive', categoryDetails.categoryActive === true ? 1 : 0);
    // catFormData.append('categoryImage', categoryDetails.categoryImage);

    categoryID === ''
      ? httpClient.post('/news/category', catFormData).then(({ data }) => {
          if (data.success === true) {
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            setCategoryDetails({
              categoryTitle: '',
              categoryActive: false,
              categoryImage: '',
            });
          } else {
            setOpen(true);
            setMessage('failed to add news category');
            setMessageState('error');
          }
        })
      : httpClient.post(`/news/category/${categoryID}`, catFormData).then(({ data }) => {
          if (data.success === true) {
            setOpen(true);
            setMessage(data.message);
            setMessageState('success');
            history.push('/news/listNewsCategory');
          } else {
            setOpen(true);
            setMessage('failed to add news category');
            setMessageState('error');
          }
        });
  };

  const getSingleCategory = () => {
    categoryID &&
      httpClient.get(`/news/category/${categoryID}`).then(({ data }) => {
        if (data.success === true) {
          setCategoryDetails({
            categoryTitle: data.data.categoryTitle,
            categoryActive: data.data.categoryActive == 1 ? true : false,
            // categoryImage: '',
          });
        }
      });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setCategoryDetails({
      ...categoryDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setCategoryDetails({
      ...categoryDetails,
      [name]: checked,
    });
  };

  // const onDrop = file => {
  //   // return file[0];
  //   setCategoryDetails({
  //     ...categoryDetails,
  //     // Image: (URL.createObjectURL(file[0]))
  //     categoryImage: file[0],
  //   });
  // };

  const handleBack = () => {
    history.push('/news/listNewsCategory');
  };

  return (
    <PageContainer heading={categoryID === '' ? 'Add News Category' : 'Edit News Category'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Category Title"
                variant="outlined"
                name="categoryTitle"
                type="text"
                value={categoryDetails.categoryTitle}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            {/* <Grid item sm={12} md={3}>
              <div>
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                    return (
                      <div>
                        {categoryDetails.categoryImage ? (
                          <img
                            src={URL.createObjectURL(categoryDetails.categoryImage)}
                            className={classes.imgClass}
                            alt={categoryDetails.categoryImage.name}
                          />
                        ) : (
                          <div {...getRootProps()} className={classes.uploadImage}>
                            <input {...getInputProps()} />
                            <AddPhotoAlternateOutlined color="primary" />
                            <p>Upload Image here</p>
                          </div>
                        )}
                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              setCategoryDetails({
                                ...categoryDetails,
                                categoryImage: '',
                              })
                            }>
                            {' '}
                            Reset Image
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
              </div>
            </Grid> */}

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="categoryActive"
                    checked={categoryDetails.categoryActive}
                    onChange={handleCheck}
                    value={categoryDetails.categoryActive}
                    color="primary"
                  />
                }
                label="Active Category?"
              />
            </Grid>
            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {categoryID === '' ? 'Add Category' : 'Edit Category'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddNewsCategory;
