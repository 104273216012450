import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableCatComponent from './../TableCatComponent';

const columns = [
  { id: 'name', label: 'Title' },
  // {id: "news", label: "# of News"},
  { id: 'last', label: 'Last Updated' },
  { id: 'active', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];
const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListNewsCategory = () => {
  const [newsCategory, setNewsCategory] = useState([]);
  // const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  // useEffect(() => {
  //   getNewsCategory();
  // }, []);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    active: '',
    categoryTitle: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    active: '',
    categoryTitle: '',
    documentType: '',
    search: '',
    removed: false,
  });

  useEffect(() => {
    if (filterData.categoryTitle === '' && filterData.active === '' && filterData.search === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.categoryTitle === ' ') filterData.categoryTitle = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let newsCategoryFilter = JSON.parse(localStorage.getItem('news_category_filter'));
    newsCategoryFilter !== null && setFilterData(newsCategoryFilter);

    newsCategoryFilter == null
      ? getNewsCategory()
      : newsCategoryFilter.active == '' &&
        newsCategoryFilter.search == '' &&
        newsCategoryFilter.categoryTitle == '' &&
        newsCategoryFilter.removed == false
      ? getNewsCategory()
      : console.log('');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      active: filterData.active,
      categoryTitle: filterData.categoryTitle,
      search: filterData.search,
      submit: true,
    });

    filterData.removed = true;
    localStorage.setItem('news_category_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.active || filterData.search || filterData.categoryTitle) {
      httpClient
        .get(
          `news/category/filter?q=${filterData.search}&categoryTitle=${filterData.categoryTitle}&categoryActive=${filterData.active}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setNewsCategory(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getNewsCategory();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const getNewsCategory = () => {
    setLoading(true);
    httpClient.get(`news/category?pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setNewsCategory(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(data.meta.per_page);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleEdit = id => {
    history.push(`/news/addNewsCategory/${id}`);
  };

  const handleDelete = () => {};

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getNewsCategory();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`news/category/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setNewsCategory(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="List News Category">
      <TableCatComponent
        columns={columns}
        selectedProducts={newsCategory}
        title={'News Category'}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleRemove={handleRemove}
        loading={loading}
      />
    </PageContainer>
  );
};

export default ListNewsCategory;
