import React, { createContext, useContext, useState } from 'react';

const initialState = {
  uniformListPageNum: 1,
  attributeTypeListPageNum: 0,
  productAttributeListPageNum: 0,
};

const UniformContext = createContext();

const UniformContextProvider = ({ children }) => {
  const [uniformPagination, setUniformPagination] = useState(initialState);

  return <UniformContext.Provider value={{ uniformPagination, setUniformPagination }}>{children}</UniformContext.Provider>;
};

const useUniformContext = () => useContext(UniformContext);

export { useUniformContext, UniformContextProvider };
