import { httpClient } from 'appUtility/Api';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableComponent from './../TableComponent';
import moment from 'moment';
import { ContactSupportOutlined } from '@material-ui/icons';

const columns = [
  { id: 'customerName', label: 'Customer Name' },
  { id: 'bookingDate', label: 'Booking Date' },
  { id: 'address', label: 'Address' },
  { id: 'bookingMethod', label: 'Booking Method' },
  { id: 'bookingLastUpdated', label: 'Last Updated' },
  //   { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListCompletedBookings = props => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    from: '',
    to: '',
    currentFrom: '',
    currentTo: '',
    memberID: '',
    memberName: '',
  });
  const [filterData, setFilterData] = useState({
    from: '',
    to: '',
    memberID: '',
    memberName: '',
    currentFrom: '',
    currentTo: '',
    removed: false,
  });

  useEffect(() => {
    if ((filterData.from === '' && filterData.to === '' && filterData.memberID === '') || filterData.memberName === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.from === ' ') filterData.from = '';
    if (filterData.to === ' ') filterData.to = '';
    if (filterData.memberID === ' ') filterData.memberID = '';
    if (filterData.memberName === ' ') filterData.memberName = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let completed_bookings = JSON.parse(localStorage.getItem('completed_bookings'));
    completed_bookings !== null && setFilterData(completed_bookings);

    completed_bookings == null
      ? !(props.location.query && props.location.query.fromDate) && getCancelledBookings()
      : completed_bookings.from == '' &&
        completed_bookings.to == '' &&
        completed_bookings.currentFrom == '' &&
        completed_bookings.currentTo == '' &&
        completed_bookings.memberID == '' &&
        completed_bookings.memberName == '' &&
        completed_bookings.removed == false
      ? getCancelledBookings()
      : console.log('completed');

    props.location.query &&
      setFilterData({
        from: props.location.query && moment(props.location.query.fromDate).format('YYYY-MM-DD'),
        to: props.location.query && moment(props.location.query.toDate).format('YYYY-MM-DD'),
        memberID: '',
        memberName: '',
        removed: true,
      });
    setSubmittedData({
      from: props.location.query && moment(props.location.query.fromDate).format('YYYY-MM-DD'),
      to: props.location.query && moment(props.location.query.toDate).format('YYYY-MM-DD'),
      memberID: '',
      memberName: '',
      submit: true,
    });
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      from: filterData.from,
      to: filterData.to,
      currentFrom: filterData.currentFrom,
      currentTo: filterData.currentTo,
      memberID: filterData.memberID,
      memberName: filterData.memberName,
      submit: true,
    });
    filterData.removed = true;
    setLoading(true);
    localStorage.setItem('completed_bookings', JSON.stringify(filterData));
    if (filterData.from || filterData.to || filterData.memberID || filterData.memberName) {
      httpClient
        .get(
          `booking/completed/filter?q=&from=${filterData.currentFrom}&to=${filterData.currentTo}&franchiseeID=${filterData.memberID}&pagination=${rowsPerPage}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setBookings(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getCancelledBookings();
    }
  };

  const handleFilter = e => {
    e.preventDefault();

    handleSubmitData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'memberID') {
      filterData.memberName = value;
    }
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleChangeMember = value => {
    setFilterData({
      ...filterData,
      memberID: value !== null ? value.memberID : '',
      memberName: value !== null ? value.firstName + ' ' + value.lastName : '',
    });
  };

  const handleDateFrom = e => {
    // const { name, value } = e.target;
    setFilterData({
      ...filterData,
      currentFrom: moment(e).format('DD-MM-YYYY'),
      from: moment(e).format('MM-DD-YYYY'),
      removed: false,
    });
  };

  const handleDateTo = e => {
    // const { name, value } = e.target;
    setFilterData({
      ...filterData,
      currentTo: moment(e).format('DD-MM-YYYY'),
      to: moment(e).format('MM-DD-YYYY'),
      removed: false,
    });
  };

  const handleRemove = data => {
    if (data === 'memberID') {
      filterData.memberName = '';
      submittedData.memberName = '';
    }
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    setCurrentColumn(column);
    httpClient
      .get(`booking/completed?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setBookings(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `booking/completed/filter?q=&from=${filterData.currentFrom}&to=${filterData.currentTo}&franchiseeID=${filterData.memberID}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setBookings(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`booking/completed?&direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setBookings(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);

    submittedData.submit
      ? httpClient
          .get(
            `booking/completed/filter?q=&from=${filterData.currentFrom}&to=${filterData.currentTo}&franchiseeID=${
              filterData.memberID
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setBookings(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`booking/completed?&direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setBookings(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const getCancelledBookings = () => {
    setLoading(true);
    httpClient.get(`booking/completed?pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setBookings(data.data);
        setTotal(data.meta.total);
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  return (
    <PageContainer>
      <TableComponent
        columns={columns}
        selectedProducts={bookings}
        title={'List Completed Bookings'}
        // handleEdit={handleEdit}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        rowsPerPage={rowsPerPage}
        fromTable={from}
        toTable={to}
        handleFilter={handleFilter}
        filterData={filterData}
        submittedData={submittedData}
        handleChange={handleChange}
        handleChangeMember={handleChangeMember}
        handleDateFrom={handleDateFrom}
        handleDateTo={handleDateTo}
        handleRemove={handleRemove}
        currentColumn={currentColumn}
        direction={direction}
      />
    </PageContainer>
  );
};

export default ListCompletedBookings;
