import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';

const AlertDialog = props => {
  const [versionDialog, setVersionDialog] = useState({
    open: true,
    delete: false,
  });

  const handleClose = () => {
    setVersionDialog({ ...versionDialog, open: false, delete: false });
  };

  const handleDelete = () => {
    setVersionDialog({ ...versionDialog, open: false, delete: true });
  };

  props.deleteCallBack(versionDialog);

  return (
    <div>
      <Dialog open={versionDialog.open} onClose={handleClose}>
        <DialogTitle>Are you sure you want to delete version?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">This will delete the current version</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
