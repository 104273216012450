import React, { useState, useEffect } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { DatePicker } from '@material-ui/pickers';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { httpClient } from 'appUtility/Api';
import Axios from 'axios';
import { Autocomplete } from '@material-ui/lab';

const login = localStorage.getItem('login');

const WeeklySales = props => {
  // const { productsList } = intranet;

  let loginData = JSON.parse(login);

  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);

  const [filter, setFilter] = useState(false);
  const [csvData, setCsvData] = useState({});
  const [exportLoading, setExportLoading] = useState(false);
  const [franchiseList, setFranchiseList] = useState([]);
  let franchiseName;
  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
    // props.submittedData.submit === true && exportData();
  }, [props.submittedData]);

  useEffect(() => {
    getAllFranchise();
  }, []);

  const getAllFranchise = () => {
    httpClient.get('member/franchisee').then(({ data }) => {
      if (data.success) {
        setFranchiseList(data.data);
      }
    });
  };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  const handleClickAnchor = e => {
    e.preventDefault();
    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        Authorization: 'Bearer ' + loginData.access_token,
      },
    };
    httpClient.get(
      `https://admin.franchise.care/php/api/app/1.0/income/user-export?startDate=${props.filterData.currStartDate}&endDate=${props.filterData.currEndDate}&token=${loginData.access_token}`,
    );
  };

  franchiseList &&
    franchiseList.map(fran => {
      if (fran.memberID === props.filterData.franchiseID) {
        franchiseName = fran.firstName + ' ' + fran.lastName;
      }
    });

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* <Button color="primary" variant="contained" onClick={exportData} style={{ marginRight: '10px' }}>
            +Export
          </Button> */}
          {/* <a
            download="income_user_report.csv"
            href={`https://admin.franchise.care/php/api/app/1.0/income/user-export?startDate=${props.filterData.currStartDate}&endDate=${props.filterData.currEndDate}`}
            className={classes.exportButton}
            target="_blank"
            download
            rel="noreferrer">
            + Export
          </a> */}
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={12}>
                  {/* <InputLabel className={classes.inputLabel}>Franchisee Name</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="franchiseID"
                      value={props.filterData.franchiseID}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">
                        {' '}
                        <em>Select Franchise Name </em>
                      </MenuItem>
                      {franchiseList &&
                        franchiseList.map(fran => (
                          <MenuItem key={fran.memberID} value={fran.memberID}>
                            {fran.firstName + ' ' + fran.lastName}
                          </MenuItem>
                        ))}
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl> */}
                  <InputLabel className={classes.inputLabel}>Franchisee Name</InputLabel>
                  <Autocomplete
                    disablePortal
                    id="franchise__id"
                    options={franchiseList}
                    onChange={(event, newValue) => {
                      props.handleChangeFranchise(newValue);
                    }}
                    // value={memberDetails.leadsForwardToMemberName}
                    inputValue={props.filterData.franchiseName}
                    // sx={{ width: 300 }}
                    getOptionLabel={option => option.firstName + ' ' + option.lastName}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={props.handleChange}
                        value={props.filterData.franchiseName}
                        variant="outlined"
                        name="franchiseID"
                        // label="Franchisee Name"
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <DatePicker
                    // disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    label="Start Date"
                    name="startDate"
                    // helperText="Enter the start date"
                    // format="MM-DD-YYYY"
                    value={props.filterData.startDate ? props.filterData.startDate : null}
                    onChange={props.handleStartDate}
                    className={classes.dateField}
                    format="DD-MM-YYYY"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <DatePicker
                    // disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    label="End Date"
                    name="endDate"
                    // helperText="Enter the end date"
                    // format="MM-DD-YYYY"
                    value={props.filterData.endDate ? props.filterData.endDate : null}
                    onChange={props.handleEndDate}
                    format="DD-MM-YYYY"
                    className={classes.dateField}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.startDate || props.submittedData.endDate || props.submittedData.franchiseID ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.startDate && (
              <p>
                Start Date: <span>{moment(props.submittedData.startDate).format('Do MMMM , YYYY')} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('startDate')} />
              </p>
            )}

            {props.submittedData.endDate && (
              <p>
                End Date:<span> {moment(props.submittedData.endDate).format('Do MMMM , YYYY')} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('endDate')} />
              </p>
            )}
            {props.submittedData.franchiseID && (
              <p>
                Franchisee Name:<span> {franchiseName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('franchiseID')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          direction={props.direction}
          currentColumn={props.currentColumn}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
