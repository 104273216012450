import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddCategories from './AddCategories';
import AddGroups from './AddGroups';
import AddPosts from './AddPosts';
import AddTopics from './AddTopics';
import ListCategories from './ListCategories';
import ListGroups from './ListGroups';
import ListPosts from './ListPosts';
import ListTopics from './ListTopics';

const Forum = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listCategories`} />
        <Route path={`${requestedUrl}/listCategories`} component={ListCategories} />
        <Route path={`${requestedUrl}/listGroups`} component={ListGroups} />
        <Route path={`${requestedUrl}/listPosts`} component={ListPosts} />
        <Route path={`${requestedUrl}/listTopics`} component={ListTopics} />
        <Route path={`${requestedUrl}/addCategories`} component={AddCategories} />
        <Route path={`${requestedUrl}/addGroups`} component={AddGroups} />
        <Route path={`${requestedUrl}/addPosts`} component={AddPosts} />
        <Route path={`${requestedUrl}/addTopics`} component={AddTopics} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Forum;
