import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddCategories = props => {
  const classes = useStyles();

  const [categoryID, setCategoryID] = useState(props && props.location.pathname.slice(21));
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [categoryDetails, setCategoryDetails] = useState({
    forumCategoryName: '',
    forumCategoryActive: false,
  });

  useEffect(() => {
    getSingleForumCategory();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setCategoryDetails({
      ...categoryDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setCategoryDetails({
      ...categoryDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    categoryID == ''
      ? httpClient
          .post(
            'forum-category',
            qs.stringify({
              forumCategoryName: categoryDetails.forumCategoryName,
              forumCategoryActive: categoryDetails.forumCategoryActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setCategoryDetails({
                forumCategoryActive: false,
                forumCategoryName: '',
              });
            } else {
              setOpen(true);
              setMessage('Forum Category Failed to be added');
              setMessageState('error');
            }
          })
      : httpClient
          .post(
            `forum-category/${categoryID}`,
            qs.stringify({
              forumCategoryName: categoryDetails.forumCategoryName,
              forumCategoryActive: categoryDetails.forumCategoryActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setCategoryDetails({
                forumCategoryActive: false,
                forumCategoryName: '',
              });
              setTimeout(() => {
                history.push('/forum/listCategories');
              }, 1000);
            } else {
              setOpen(true);
              setMessage('Forum Category Failed to be updated');
              setMessageState('error');
            }
          });
  };

  const getSingleForumCategory = () => {
    categoryID &&
      httpClient.get(`/forum-category/${categoryID}`).then(({ data }) => {
        setCategoryDetails({
          forumCategoryName: data.data.forumCategoryName,
          forumCategoryActive: data.data.forumCategoryActive == 'Active' ? true : false,
        });
      });
  };

  const handleBack = () => {
    history.push('/forum/listCategories');
  };

  return (
    <PageContainer heading={categoryID === '' ? 'Add Forum Category' : 'Edit Forum Category'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Forum Category Name"
                variant="outlined"
                name="forumCategoryName"
                type="text"
                value={categoryDetails.forumCategoryName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="forumCategoryActive"
                    checked={categoryDetails.forumCategoryActive}
                    onChange={handleCheck}
                    value={categoryDetails.forumCategoryActive}
                    color="primary"
                  />
                }
                label="Make Forum Category Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                {categoryID === '' ? 'Add Forum Category' : 'Edit Forum Category'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddCategories;
