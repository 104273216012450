import React, { useState } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, CircularProgress, fade, IconButton, TableFooter, TablePagination, Tooltip } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  scrollbarRoot: {
    // height: 251,
    marginLeft: -24,
    marginRight: -24,
    [theme.breakpoints.up('xl')]: {
      // height: 269,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      // textAlign: 'right',
      // paddingRight: 24,
    },
  },
  tableRowCellRoot: {
    fontSize: 12,
    '&:last-child': {
      paddingRight: 64,
    },
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  tableWidth: {
    minWidth: '200px',
  },
  loader: {
    position: 'relative',
    height: '150px',
  },
  loadingSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const actions = [
  {
    label: 'View Profile',
  },
  {
    label: 'More',
  },
];

const ProductsTable = ({ columns, selectedProducts, handleEdit, handleDelete, loading }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <PerfectScrollbar className={classes.scrollbarRoot}>
      <Box className="Cmt-table-responsive">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} className={clsx(classes.tableCellRoot, classes.tableRowCellRoot)}>
                  {' '}
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody className={classes.loader}>
              <Box textAlign="center" className={classes.loadingSection}>
                <CircularProgress />
              </Box>
            </TableBody>
          ) : (
            <TableBody>
              {selectedProducts &&
                selectedProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  // const isInflationPositive = row.sales_data.sales_inflation > 0;
                  return (
                    <TableRow className={classes.tableRowRoot} key={Math.random()}>
                      <TableCell className={classes.tableCellRoot}>{row.emailInternalName}</TableCell>
                      <TableCell className={classes.tableCellRoot}>{row.emailSubject}</TableCell>
                      <TableCell className={classes.tableCellRoot}>
                        {row.emailBody.substring(0, 50) + (row.emailBody.length > 50 ? '...' : ' ')}
                      </TableCell>
                      <TableCell className={classes.tableCellRoot}>{row.emailtype}</TableCell>
                      <TableCell className={classes.tableCellRoot}>
                        {row.emailActive === 'Active' ? (
                          <CheckIcon fontSize="small" color="primary" />
                        ) : (
                          <ClearIcon fontSize="small" color="secondary" />
                        )}
                      </TableCell>

                      <TableCell className={classes.tableWidth}>
                        <Button color="primary" size="small" variant="outlined" onClick={() => handleEdit(row.templateID)}>
                          Edit
                        </Button>
                        {row.emailType !== 'System' && (
                          <Button
                            color="secondary"
                            size="small"
                            variant="outlined"
                            onClick={() => handleDelete(row.templateID)}
                            style={{ marginLeft: '10px' }}>
                            Delete
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {selectedProducts.length === 0 && (
                <TableRow
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    position: 'absolute',
                  }}>
                  No Auto Reply Found
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100, 250, 1000]}
                count={selectedProducts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

export default ProductsTable;
