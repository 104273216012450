import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddInventoryCategory from './AddInventoryCategory';
import AddItem from './AddItem';
import ChangeStatus from './ChangeStatus';
import ListInventoryCategory from './ListInventoryCategory';
import ListItem from './ListItem';
import AttributeType from './AttributeType';
import AddAttributeType from './AddAttributeType';
import ProductAttribute from './ProductAttribute';
import AddProductAttribute from './AddProductAttribute';

const Inventory = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listItem`} />
        <Route path={`${requestedUrl}/listItem`} component={ListItem} />
        <Route path={`${requestedUrl}/addItem`} component={AddItem} />
        <Route path={`${requestedUrl}/listInventoryCategory`} component={ListInventoryCategory} />
        <Route path={`${requestedUrl}/addInventoryCategory`} component={AddInventoryCategory} />
        <Route path={`${requestedUrl}/changeStatus`} component={ChangeStatus} />
        {/* <Route path={`${requestedUrl}/listAttributeType`} component={AttributeType} />
        <Route path={`${requestedUrl}/addAttributeType`} component={AddAttributeType} />
        <Route path={`${requestedUrl}/addProductAttribute`} component={AddProductAttribute} />
        <Route path={`${requestedUrl}/listProductAttribute`} component={ProductAttribute} /> */}

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Inventory;
