import React, { useState, useEffect } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
// import { history } from 'redux/store';
// import SearchIcon from '@material-ui/icons/Search';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { httpClient } from 'appUtility/Api';
import { Autocomplete } from '@material-ui/lab';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  const [filter, setFilter] = useState(false);
  const [state, setState] = useState([]);
  const [franchiseList, setFranchiseList] = useState([]);
  var franchiseName;

  useEffect(() => {
    getState();
    getFranchiseList();
  }, []);

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  // const sendTo = () => {
  //   console.log('add customers');
  // };

  const getState = () => {
    httpClient.get('/state').then(({ data }) => {
      if (data) {
        setState(data.data);
      } else {
      }
    });
  };

  const getFranchiseList = () => {
    httpClient.get('/member/franchisee').then(({ data }) => {
      if (data) {
        setFranchiseList(data.data);
      }
    });
  };

  franchiseList &&
    franchiseList.map(item => {
      if (props.filterData.franchiseeID == item.memberID) {
        franchiseName = item.firstName + ' ' + item.lastName;
      }
    });

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  console.log('customer >>', props.filterData);

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '15px' }}>
            +Add
          </Button> */}
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Search </InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Customer Active</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="customerActive"
                      // label="Active Members"
                      value={props.filterData.customerActive}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Franchisee</InputLabel>
                  <Autocomplete
                    disablePortal
                    id="franchise_id"
                    options={franchiseList}
                    onChange={(event, newValue) => {
                      props.handleChangeFranchise(newValue);
                    }}
                    // value={memberDetails.leadsForwardToMemberName}
                    inputValue={props.filterData.franchiseeName}
                    // sx={{ width: 300 }}
                    getOptionLabel={option => option.firstName + ' ' + option.lastName}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={props.handleChange}
                        value={props.filterData.franchiseeName}
                        variant="outlined"
                        name="franchiseeID"
                        // label="Franchisee"
                      />
                    )}
                  />

                  {/* <InputLabel className={classes.inputLabel}>Franchisee</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="franchiseeID"
                      // label="State"
                      value={props.filterData.franchiseeID}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {franchiseList &&
                        franchiseList.map(st => (
                          <MenuItem key={st.memberID} value={st.memberID}>
                            {st.firstName + ' ' + st.lastName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>State</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="officeStateID"
                      // label="State"
                      value={props.filterData.officeStateID}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {state &&
                        state.map(st => (
                          <MenuItem key={st.stateID} value={st.stateID}>
                            {st.stateName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Suburb</InputLabel>
                  <TextField
                    variant="outlined"
                    name="suburb"
                    value={props.filterData.suburb}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Pet Count</InputLabel>
                  <TextField
                    variant="outlined"
                    name="itemCount"
                    value={props.filterData.itemCount}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.customerActive ||
        props.submittedData.officeStateID ||
        props.submittedData.franchiseeID ||
        props.submittedData.memberType ||
        props.submittedData.itemCount ||
        props.submittedData.search ||
        props.submittedData.suburb ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.customerActive && (
              <p>
                Customer Active: <span>{props.submittedData.customerActive == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('customerActive')} />
              </p>
            )}
            {props.submittedData.officeStateID && (
              <p>
                State:
                <span>
                  {state &&
                    state.map(st => {
                      if (st.stateID === props.filterData.officeStateID) return st.stateName;
                    })}
                </span>
                <Close fontSize="small" onClick={() => props.handleRemove('officeStateID')} />
              </p>
            )}
            {props.submittedData.suburb && (
              <p>
                Suburb:<span> {props.submittedData.suburb} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('suburb')} />
              </p>
            )}
            {props.submittedData.franchiseeID && (
              <p>
                Franchisee Name:<span> {franchiseName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('franchiseeID')} />
              </p>
            )}
            {props.submittedData.itemCount && (
              <p>
                Pet Count:<span> {props.submittedData.itemCount} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('itemCount')} />
              </p>
            )}

            {props.submittedData.search && (
              <p>
                Search:<span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleView={props.handleView}
          handleDelete={props.handleDelete}
          handleReactive={props.handleReactive}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          currentColumn={props.currentColumn}
          direction={props.direction}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
