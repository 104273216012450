import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  text: {
    margin: '10px 0',
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddAttributeType = props => {
  const classes = useStyles();
  const [attributeTypeDetail, setAttributeTypeDetail] = useState({
    typeName: '',
    typeStyle: '',
    // minOrderQty: 0,
    // orderPickupFee: 0,
    typeActive: false,
  });
  const [typeID, setTypeID] = useState(props && props.location.pathname.slice(28));
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeID) {
      getSingleAttributeType();
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setAttributeTypeDetail({
      ...attributeTypeDetail,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setAttributeTypeDetail({
      ...attributeTypeDetail,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    typeID == ''
      ? httpClient
          .post(
            'attribute-type',
            qs.stringify({
              typeName: attributeTypeDetail.typeName,
              typeStyle: attributeTypeDetail.typeStyle,
              //   minOrderQty: attributeTypeDetail.minOrderQty,
              //   orderPickupFee: attributeTypeDetail.orderPickupFee,
              typeActive: attributeTypeDetail.typeActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setAttributeTypeDetail({
                typeName: '',
                typeStyle: '',
                // minOrderQty: 0,
                // orderPickupFee: 0,
                typeActive: false,
              });
              setTimeout(() => {
                history.push('/inventory/listAttributeType');
              }, 1500);
            } else {
              setOpen(true);
              setMessage(data.message);
              setMessageState('error');
              // !data.error ? setMessage(data.message[0]) : setMessage(data.error);
            }
          })
      : httpClient
          .post(
            `attribute-type/${typeID}`,
            qs.stringify({
              typeName: attributeTypeDetail.typeName,
              typeStyle: attributeTypeDetail.typeStyle,

              //   inventory_category_description: attributeTypeDetail.inventory_category_description,
              //   minOrderQty: attributeTypeDetail.minOrderQty,
              //   orderPickupFee: attributeTypeDetail.orderPickupFee,
              typeActive: attributeTypeDetail.typeActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success == true) {
              setOpen(true);
              setMessage(data.message);
              setMessageState('success');
              setTimeout(() => {
                history.push('/inventory/listAttributeType');
              }, 1500);
            } else {
              setOpen(true);
              setMessage(data.message);
              setMessageState('error');
              // !data.error ? setMessage(data.message[0]) : setMessage(data.error);
            }
          });
  };

  const getSingleAttributeType = () => {
    setLoading(true);
    httpClient.get(`attribute-type/${typeID}`).then(({ data }) => {
      if (data.success == true) {
        setAttributeTypeDetail({
          typeName: data.data.typeName,
          typeStyle: data.data.typeStyle,
          // inventory_category_description: data.data.inventory_category_description,
          // minOrderQty: data.data.minOrderQty,
          // orderPickupFee: data.data.orderPickupFee,
          typeActive: data.data.typeActive === 1 ? true : false,
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleBack = () => {
    history.push('/inventory/listAttributeType');
  };

  return (
    <PageContainer heading={typeID == '' ? 'Add Attribute Type' : 'Edit Attribute Type'}>
      <Card className={classes.root}>
        {loading ? (
          <Box p={2} textAlign={"center"}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12}>
                <TextField
                  required
                  label="Attribute Type Name"
                  variant="outlined"
                  name="typeName"
                  value={attributeTypeDetail.typeName}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Attribute Type Style"
                  variant="outlined"
                  name="typeStyle"
                  value={attributeTypeDetail.typeStyle}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="typeActive"
                      checked={attributeTypeDetail.typeActive}
                      onChange={handleCheck}
                      value={attributeTypeDetail.typeActive}
                      color="primary"
                    />
                  }
                  label="Make Attribute Type Active"
                />
              </Grid>
              <Grid item sm={12}>
                <Box textAlign="right" pt={4}>
                  <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    {typeID === '' ? 'Add Attribute Type' : 'Edit Attribute Type'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddAttributeType;
