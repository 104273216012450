import React, { useState, useEffect } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { httpClient } from 'appUtility/Api';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';

const WeeklySales = props => {
  let companyName;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);
  const [state, setState] = useState([]);
  const [company, setCompany] = useState([]);
  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  useEffect(() => {
    getState();
    getAllCompany();
  }, []);

  const getState = () => {
    httpClient.get('/state').then(({ data }) => {
      if (data) {
        setState(data.data);
      } else {
      }
    });
  };

  const getAllCompany = () => {
    httpClient.get('member-company/all').then(({ data }) => {
      if (data.success === true) {
        let newData = [];
        let curr = data.data;
        for (let i = 0; i < curr.length; i++) {
          if (curr[i].companyActive == '1') {
            newData.push(curr[i]);
          }
        }
        setCompany(newData);
      }
    });
  };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  // company.length > 0 && company.map(com => {
  //   if(com.companyID == props.filterData.company){
  //     companyName = com.tradingName;
  //   }
  // })

  // console.log('company', company);

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          {/* <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '10px' }}>
            +Add
          </Button> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '15px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* <a
            className={classes.exportButton}
            href="https://backadmin.franchise.care/php/api/app/1.0/lead/export"
            target="_blank"
            download
            rel="noreferrer">
            + Export
          </a> */}
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                  <DatePicker
                    // disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    label="From Date"
                    name="from"
                    helperText="Leads Date From"
                    // format="MM-DD-YYYY"
                    format="DD-MM-YYYY"
                    value={props.filterData.from ? props.filterData.from : null}
                    onChange={props.handleDateFrom}
                    className={classes.text}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <DatePicker
                    // disableToolbar
                    inputVariant="outlined"
                    name="to"
                    variant="inline"
                    label="To Date"
                    helperText="Leads Date To"
                    // format="MM-DD-YYYY"
                    format="DD-MM-YYYY"
                    value={props.filterData.to ? props.filterData.to : null}
                    onChange={props.handleDateTo}
                    className={classes.text}
                    minDate={props.filterData.from ? props.filterData.from : new Date()}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  {/* <InputLabel className={classes.inputLabel}>Company</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="company"
                      value={props.filterData.company}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">Select Company</MenuItem>
                      {company.length > 0 &&
                        company.map(com => (
                          <MenuItem key={com && com.companyID} value={com && com.tradingName}>
                            {com && com.tradingName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}
                  <InputLabel className={classes.inputLabel}>Company</InputLabel>

                  <Autocomplete
                    disablePortal
                    id="leads_company"
                    options={company}
                    onChange={(event, newValue) => {
                      props.handleChangeCompany(newValue);
                    }}
                    // value={memberDetails.leadsForwardToMemberName}
                    inputValue={props.filterData.companyName}
                    // sx={{ width: 300 }}
                    getOptionLabel={option => option.tradingName}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={props.handleChange}
                        value={props.filterData.company}
                        variant="outlined"
                        name="company"
                        // label="Company"
                      />
                    )}
                  />
                </Grid>

                {props.customerNotified && (
                  <Grid item sm={12} md={12}>
                    <InputLabel className={classes.inputLabel}>Customer Notified</InputLabel>
                    <FormControl variant="outlined" className={classes.text}>
                      <Select
                        name="customerNotified"
                        value={props.filterData.customerNotified}
                        onChange={props.handleChange}
                        className={classes.text}>
                        <MenuItem value=" ">&nbsp;</MenuItem>
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>FirstName </InputLabel>
                  <TextField
                    variant="outlined"
                    name="firstName"
                    value={props.filterData.firstName}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>LastName </InputLabel>
                  <TextField
                    variant="outlined"
                    name="lastName"
                    value={props.filterData.lastName}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Service Area </InputLabel>
                  <TextField
                    variant="outlined"
                    name="serviceArea"
                    value={props.filterData.serviceArea}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Post Code </InputLabel>
                  <TextField
                    variant="outlined"
                    name="postCode"
                    value={props.filterData.postCode}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                {/* <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Phone </InputLabel>
                  <TextField
                    variant="outlined"
                    name="phone"
                    value={props.filterData.phone}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid> */}
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Email </InputLabel>
                  <TextField
                    variant="outlined"
                    name="email"
                    value={props.filterData.email}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Address </InputLabel>
                  <TextField
                    variant="outlined"
                    name="address"
                    value={props.filterData.address}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                {/* <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Search </InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Suburb </InputLabel>
                  <TextField
                    variant="outlined"
                    name="suburb"
                    value={props.filterData.suburb}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Converted To Customer</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="convertedToCustomer"
                      value={props.filterData.convertedToCustomer}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>Forwarded To Head Office</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="forwardedToHeadOffice"
                      value={props.filterData.forwardedToHeadOffice}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={4}>
                  <InputLabel className={classes.inputLabel}>State</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="stateID"
                      // label="stateID"
                      value={props.filterData.stateID}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {state &&
                        state.map(st => (
                          <MenuItem key={st.stateID} value={st.stateID}>
                            {st.stateName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.firstName ||
        props.submittedData.lastName ||
        props.submittedData.from ||
        props.submittedData.to ||
        props.submittedData.serviceArea ||
        props.submittedData.postCode ||
        props.submittedData.phone ||
        props.submittedData.email ||
        props.submittedData.company ||
        props.submittedData.customerNotified ||
        props.submittedData.address ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.from && (
              <p>
                From:<span> {moment(props.submittedData.from).format('MMMM DD , YYYY')} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('from')} />
              </p>
            )}
            {props.submittedData.to && (
              <p>
                To:<span> {moment(props.submittedData.to).format('MMMM DD , YYYY')} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('to')} />
              </p>
            )}
            {props.submittedData.company && (
              <p>
                Company: <span>{props.submittedData.companyName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('company')} />
              </p>
            )}
            {props.submittedData.firstName && (
              <p>
                First Name: <span>{props.submittedData.firstName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('firstName')} />
              </p>
            )}
            {props.submittedData.lastName && (
              <p>
                Last Name:<span> {props.submittedData.lastName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('lastName')} />
              </p>
            )}
            {props.submittedData.serviceArea && (
              <p>
                Service Area:<span> {props.submittedData.serviceArea} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('serviceArea')} />
              </p>
            )}
            {props.submittedData.postCode && (
              <p>
                Post Code:<span> {props.submittedData.postCode} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('postCode')} />
              </p>
            )}
            {props.submittedData.phone && (
              <p>
                Phone:<span> {props.submittedData.phone} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('phone')} />
              </p>
            )}
            {props.submittedData.email && (
              <p>
                Email:<span> {props.submittedData.email} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('email')} />
              </p>
            )}
            {props.submittedData.addresss && (
              <p>
                Address:<span> {props.submittedData.addresss} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('addresss')} />
              </p>
            )}
            {props.submittedData.customerNotified && (
              <p>
                Customer Notified : <span> {props.submittedData.customerNotified === '1' ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('customerNotified')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleView={props.handleView}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          currentColumn={props.currentColumn}
          direction={props.direction}
          hoForwarded={props.hoForwarded}
          customerNotified={props.customerNotified}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
