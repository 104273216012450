import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Alert, AlertTitle } from '@material-ui/lab';
import WarningIcon from '@material-ui/icons/Warning';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  appMainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '30px 15px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 65,
      paddingRight: 65,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 88,
      paddingRight: 88,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
}));

const CmtContent = React.forwardRef(function LayoutContent(props, ref) {
  const { children } = props;

  useImperativeHandle(ref, () => ({}));

  const contentRef = React.createRef();
  const classes = useStyles();

  const location = useLocation();

  const userData = JSON.parse(localStorage.getItem('login'));

  const localStorageValue = JSON.parse(localStorage.getItem('enableTwoFactor'));
  const [twoFactorNotEnabled, setTwoFactorNotEnabled] = useState(!!userData && userData.user.enableTwoFactor === 0);
  const TwoFALocalValueChanged = localStorage.getItem('2FALocalValueChanged');
  const hasLocalStorageValueChanged = TwoFALocalValueChanged === 'true';

  useEffect(() => {
    if (localStorageValue) {
      setTwoFactorNotEnabled(localStorageValue === '0');
    }
  }, [localStorageValue, hasLocalStorageValueChanged]);

  const handleClick = () => {
    window.location = '/security/TwoFA';
  };

  return (
    <Box flex="1">
      {twoFactorNotEnabled && location.pathname !== '/security/TwoFA' ? (
        <Box my={4} mx={8}>
          <Alert
            mb={4}
            icon={<WarningIcon fontSize="inherit" />}
            severity="error"
            action={
              <Button color="primary" size="small" variant="contained" onClick={handleClick}>
                ENABLE NOW
              </Button>
            }>
            <AlertTitle>Two Factor Authentication</AlertTitle>
            To enhance the security of your account, we recommend enabling Two-Factor Authentication (2FA). This additional
            layer of protection helps prevent unauthorized access to your account.
          </Alert>
        </Box>
      ) : (
        ''
      )}
      <Box ref={contentRef} className={clsx(classes.appMainContent, 'Cmt-appMainContent')} bgcolor="background.main">
        {children}
      </Box>
    </Box>
  );
});

export default CmtContent;
CmtContent.defaultProps = {
  name: 'LayoutContent',
};
